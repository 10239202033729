import React from 'react';
import { inject, observer } from 'mobx-react';

import filter from 'lodash/filter';
import { useTheme } from '@mui/material/styles';
import { Theme, styled } from '@mui/material/styles';

import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import theme from '@extensions/services/Theme';
import Dataset from '@extensions/models/Dataset';
import { Measure } from '@extensions/models/Quality';

const StyledSummaryGraphicDiv = styled('div')(({
  margin: 'auto',
  width: '100px'
}));

const StyledStatValueDecimalDiv = styled('div')(() => ({
  ...theme.Stat.statValue,
  fontSize: '1.73rem',
  lineHeight: 1.3
}));

const StyledStatValueDiv = styled('div')(() => ({
  ...theme.Stat.statValue,
}));

const StyledStatDescriptionDiv = styled('div')(() => ({
  ...theme.Stat.statDescription,
}));

interface IQualityMetricsSummaryStatCompProps {
  dataset: Dataset;
  dapTheme: Theme;
}

@observer
class QualityMetricsSummaryStatComp<
  ExtraProps extends object = {}
> extends React.Component<
  IQualityMetricsSummaryStatCompProps & ExtraProps
> {
  render() {
    const { dataset } = this.props;
    const getValue = (value: number, isInteger: boolean) => {
      if (!isInteger) {
        if (value > 0.0 && value < 0.01) {
          return '< 0.01';
        } else if (
          value > 99.99 &&
          value < 100.0
        ) {
          return '> 99.99';
        } else {
          return `${value.toFixed(2)}`;
        }
      } else {
        return `${value}`;
      }
    }

    const renderValue = (value: number) => {
      if (value % 1 !== 0) {
        return (
          <StyledStatValueDecimalDiv>
            {getValue(value, false)}
          </StyledStatValueDecimalDiv>
        )
      } else {
        return (
          <StyledStatValueDiv>
            {getValue(value, true)}
          </StyledStatValueDiv>
        )
      }
    }

    let summaryGraphic = <React.Fragment />;
    if (dataset.quality) {
      const summaryMeasures = filter(
        dataset.quality.measures,
        (measure: Measure) => measure.showAsSummary
      );
      if (summaryMeasures && summaryMeasures.length > 0) {
        const summaryMeasure: Measure = summaryMeasures[0];

        summaryGraphic = (
          <StyledSummaryGraphicDiv>
            <CircularProgressbarWithChildren
              value={summaryMeasure.value}
              styles={buildStyles({
                pathColor: `${this.props.dapTheme.palette.secondary.main}`,
              })}
            >
              <StyledStatValueDiv>
                {renderValue(summaryMeasure.value)}
              </StyledStatValueDiv>
              <StyledStatDescriptionDiv>Quality</StyledStatDescriptionDiv>
            </CircularProgressbarWithChildren>
          </StyledSummaryGraphicDiv>
        );
      }
    }
    return summaryGraphic;
  }
}

const Wrapped = inject((store: any) => ({
  metricsService: store.metricsService,
}))(QualityMetricsSummaryStatComp);

export default function QualityMetricsSummaryStat(props) {
  const dapTheme = useTheme();
  return <Wrapped {...props} dapTheme={dapTheme} />
};
