import { IAppService } from '@extensions/services/IAppService';

export default class AppService implements IAppService {
  uploadClientEnabled: boolean = false;

  public constructor(
    uploadClientEnabled: boolean
  ) {
    this.uploadClientEnabled = uploadClientEnabled;
  }
}
