import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { inject } from 'mobx-react';
import { Acknowledge as DapAcknowledge } from '@dapclient/components/page/Acknowledge';

const TextArea = styled('textarea')(() => ({
  '&&': {
    width: '100%',
    height: 'auto',
    minHeight: '50px',
    fontFamily: "'Open Sans',sans-serif",
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.65)'
  }
}));

export class Acknowledge extends DapAcknowledge {
  renderTextArea = () => {
    return (
      <>
        <Typography variant="h1" gutterBottom>
          Acknowledging Benchmark Datasets for Buildings
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          To acknowledge general use of Benchmark Datasets for Buildings, select:
        </Typography>
        <Typography 
          variant="body1" 
          gutterBottom
          sx={{
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          Click to copy to clipboard:
        </Typography>
        <TextArea
          ref={(textarea)=>this.textarea = textarea}
          readOnly
          onClick={this.handleClick}
          value="Data were obtained from Buildings Data Hub funded by U.S. Department of Energy, 
                Office of Energy Efficiency and Renewable Energy's Building Technologies Office operated
                and maintained by Pacific Northwest National Laboratory at https://bbd.labworks.org." />
        <hr></hr>
        <Typography 
          variant="body1"
          sx={{
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          For dataset-specific citations, please refer to individual past data orders or specific dataset web pages.
        </Typography>
      </>
    )
  }
}

export default inject((store: any) => ({
  cachingService: store.cachingService,
  notificationService: store.notificationService,
}))(Acknowledge);
