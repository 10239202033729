import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button as MuiButton, Menu, MenuItem } from '@mui/material';

import { IAppService } from '@extensions/services/IAppService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import User from '@extensions/models/User';
import AddIcon from '@extensions/utils/AddIcon';
import { faLockOpen, faLock } from '@fortawesome/free-solid-svg-icons';

const StyledButton = styled(MuiButton)(({
  display: 'flex',
  alignItems: 'center',
  '& .svg-inline--fa': {
    fontSize: '70%',
  },
}));

const StyledButtonDiv = styled('div')(({
  display: 'flex',
  alignItems: 'center',
  '& .svg-inline--fa': {
    fontSize: '70%',
  },
}));

export interface IUserMenuButtonProps
  extends RouteComponentProps {
  className?: string;
  isRenderedWithinButton?: boolean;
  appService?: IAppService;
  securityService?: ISecurityService;
}

export interface IUserMenuButtonState {
  anchorEl: HTMLElement | null;
}

@inject('appService', 'securityService')
@observer
class UserMenuButton extends React.Component<
  IUserMenuButtonProps,
  IUserMenuButtonState
> {
  static defaultProps = {
    appService: undefined,
    securityService: undefined,
  };

  constructor(props: IUserMenuButtonProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  onSignOut = () => {
    this.props.securityService && this.props.securityService.logout();
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.handleClose();
    }
  }

  render() {
    const { anchorEl } = this.state;
    const {
      appService,
      securityService,
      isRenderedWithinButton,
    } = this.props;

    const user: User | null = securityService?.user ? securityService.user : null;
    let userMenuIcon = <PersonOutlineIcon />;
    if (
      process.env.REACT_APP_MFA_ENABLED === 'true' &&
      user &&
      user.authenticatedMfa
    ) {
      userMenuIcon = (
        <React.Fragment>
          {userMenuIcon}
          <AddIcon
            size="2x"
            icon={faLock}
            aria-hidden
            title="Logged in with MFA token"
          />
        </React.Fragment>
      );
    } else if (process.env.REACT_APP_MFA_ENABLED === 'true') {
      userMenuIcon = (
        <React.Fragment>
          {userMenuIcon}
          <AddIcon
            icon={faLockOpen}
            aria-hidden
            title="NOT Logged in with MFA token"
          />
        </React.Fragment>
      );
    }

    const renderButton = (isRenderedWithinButton: boolean | undefined) => {
      if (isRenderedWithinButton) {
        return <StyledButtonDiv aria-controls="user-menu" aria-haspopup="true" onClick={this.handleClick}>{userMenuIcon}</StyledButtonDiv>
      } else {
        return <StyledButton aria-controls="user-menu" aria-haspopup="true" onClick={this.handleClick}>{userMenuIcon}</StyledButton>
      }
    };

    return (
      <>
        {renderButton(isRenderedWithinButton)}
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem>
            <Link to="/profile" style={{textDecoration: 'none'}}>My Profile</Link>
          </MenuItem>
          {appService !== undefined && appService.uploadClientEnabled && user !== null && user.canEdit && (
            <MenuItem>
              <Link to="/upload" style={{textDecoration: 'none'}}>Submit Data</Link>
            </MenuItem>
          )}
          <MenuItem onClick={this.onSignOut}>Sign Out</MenuItem>
        </Menu>
      </>
    );
  }
}

export default withRouter(UserMenuButton);
