import React from 'react';
import { inject, observer } from 'mobx-react';
import { Slider, OutlinedInput, Typography } from '@mui/material';

import { styled } from '@mui/material/styles';

const StyledRootDiv = styled('div')(({
  padding: '0 0.5rem'
}));

const StyledMinMaxWrapperDiv = styled('div')(({
  display: 'flex',
  alignItems: 'baseline',
}));

export type DataRange = [number, number] | undefined | null;

export interface IRangeControlsProps {
  className?: string;
  // inclusive
  min: number;
  // inclusive
  max: number;
  range: DataRange;
  onRangeChange: (newRange: DataRange) => void;
}

export interface IRangeControlsState { }

@observer
export class RangeControls extends React.Component<
  IRangeControlsProps,
  IRangeControlsState
> {
  get defaultValue(): [number, number] {
    const { min, max } = this.props;
    return [min, max];
  }
  get range(): [number, number] {
    const { range } = this.props;
    return range || this.defaultValue;
  }

  handleInputBlur = () => {
    const { min, max, onRangeChange } = this.props;
    let newRange = [...this.range] as [number, number];
    if (this.range[0] > this.range[1]) {
      newRange.reverse();
    }
    if (this.range[0] < min) {
      this.range[0] = this.defaultValue[0];
    }
    if (this.range[1] > max) {
      this.range[1] = this.defaultValue[1];
    }
    onRangeChange(newRange);
  };

  handleEndpointChange = ({
    endpointIndex,
    inputValue,
  }: {
    endpointIndex: 0 | 1;
    inputValue: string;
  }) => {
    const { onRangeChange } = this.props;
    const newValue =
      inputValue === '' ? this.defaultValue[endpointIndex] : Number(inputValue);
    const newRange = [...this.range] as [number, number];
    newRange[endpointIndex] = newValue;
    onRangeChange(newRange);
  };

  renderRangeInput = ({
    endpointIndex,
    label,
  }: {
    endpointIndex: 0 | 1;
    label: string;
  }) => {
    const { min, max } = this.props;
    return (
      <OutlinedInput
        sx={{
          flex: '1 1 auto'
        }}
        value={this.range[endpointIndex]}
        margin="dense"
        onChange={event =>
          this.handleEndpointChange({
            endpointIndex: endpointIndex,
            inputValue: event.target.value,
          })
        }
        onBlur={this.handleInputBlur}
        inputProps={{
          min,
          max,
          type: 'number',
          'aria-label': label,
        }}
      />
    );
  };

  render() {
    const { min, max, range, onRangeChange } = this.props;
    const definedRange = range || [min, max];
    return (
      <StyledRootDiv>
        <Slider
          min={min}
          max={max}
          value={definedRange}
          onChange={(e, value) =>
            Array.isArray(value) && onRangeChange(value as [number, number])
          }
          valueLabelDisplay="off"
          getAriaLabel={() => 'Filter range by min and max'}
        />
        <StyledMinMaxWrapperDiv>
          {this.renderRangeInput({ endpointIndex: 0, label: 'minimum value' })}
          <Typography
            sx={{
              flex: '0 0 auto',
              margin: '0 0.5rem',
            }}
            component="span"
            aria-hidden
          >
            TO
          </Typography>
          {this.renderRangeInput({ endpointIndex: 1, label: 'maximum value' })}
        </StyledMinMaxWrapperDiv>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(RangeControls);
