import React from 'react';
import { Location } from 'history';
import { inject, observer } from 'mobx-react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Button, Tab, Tabs, Alert, AlertTitle } from '@mui/material';

import { IHistoryService } from '@extensions/services/IHistoryService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { INotificationService } from '@extensions/services/INotificationService';

import User from '@extensions/models/User';
import theme from '@extensions/services/Theme';
import DataOrders from '@extensions/components/user/DataOrders';
import UserProfileForm from '@extensions/components/user/UserProfileForm';

const StyledFormDiv = styled('div')(({
  paddingTop: '10px',
  maxWidth: '450px'
}));

const StyledAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedWarning,
  maxWidth: '450px',
  marginBottom: theme.spacing(2),
}));

const StyledDataOrders = styled(DataOrders)(({
  marginTop: '2rem',
}));

const StyledTab = styled(Tab)(({theme}) => ({
  '&.MuiTab-root': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    color: '#000',
  }
}));

export interface IProfileProps extends RouteComponentProps {
  securityService: ISecurityService;
  historyService: IHistoryService;
  notificationService: INotificationService;
  classes: any;
}

export interface IProfileState {
  activeTab: number;
}

@inject('securityService', 'historyService', 'notificationService')
@observer
export class Profile extends React.Component<IProfileProps, IProfileState> {
  constructor(props: IProfileProps) {
    super(props);

    this.state = {
      activeTab: 0,
    };
  }

  componentDidMount() {
    this.props.securityService.checkForCallback(this.props.location.search);
    this.updateActiveTab();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.updateActiveTab();
    }
  }

  updateActiveTab = (): void => {
    const newActiveTab = this.activeTab(this.props.location);
    this.setState({ activeTab: newActiveTab });
  };

  activeTab = (location: Location): number => {
    switch (location.pathname) {
      case '/profile/orders':
        return 1;
      case '/profile':
        return 0;
      default:
        console.error(`Unknown path: ${location.pathname}`);
        return 0;
    }
  };

  onTabsChange = (event: React.ChangeEvent<{}>, activeKey: number) => {
    this.setState({ activeTab: activeKey });
    const url = activeKey === 0 ? '/profile' : '/profile/orders';
    this.props.historyService.redirect(url);
  };

  onResendEmail = (e) => {
    if (this.props.securityService.user) {
      this.props.securityService.resendEmailAction(
        this.props.securityService.user.email
      );
    }
  };

  renderProfilePage(user: User) {
    const notVerifiedAlert = !user.emailVerified ? (
      <StyledAlert severity="warning">
        <AlertTitle>Verify Email</AlertTitle>
        Please check your inbox to verify your email account. If you don't
        receive an email within a few minutes, check your junk/spam folder.{' '}
        <Button variant="contained" onClick={this.onResendEmail}>
          Re-send verification email
        </Button>
      </StyledAlert>
    ) : null;

    const awaitingApprovalAlert =
      user.emailVerified && user.approvalPending ? (
        <StyledAlert severity="warning">
          <AlertTitle>Awaiting Approval</AlertTitle>
          Your account is pending approval. This may take up to 2 business days.
        </StyledAlert>
      ) : null;

    const tabContent =
      this.state.activeTab === 0 ? (
        <React.Fragment>
          {notVerifiedAlert}
          {awaitingApprovalAlert}

          <StyledFormDiv>
            {/* Adding a unique key will force the form to recreate itself every time this page is rendered */}
            <UserProfileForm key={new Date().getMilliseconds()} />
          </StyledFormDiv>
        </React.Fragment>
      ) : (
        <StyledDataOrders />
      );

    return (
      <React.Fragment>
        <Tabs
          value={this.state.activeTab}
          indicatorColor="primary"
          textColor="primary"
          aria-label="user tabs"
          onChange={this.onTabsChange}
        >
          <StyledTab label="Profile" />
          <StyledTab label="Data Orders" />
        </Tabs>
        {tabContent}
      </React.Fragment>
    );
  }

  render() {
    const user: User | null = this.props.securityService.user;

    if (user && user.authenticated) {
      return this.renderProfilePage(user);
    } else {
      // If not signed in, redirect to signIn
      return <Redirect to={{ pathname: '/signIn' }} />;
    }
  }
}

export default (withRouter(Profile));
