import { inject } from 'mobx-react';
import { UserProfileForm as DapUserProfileForm } from '@dapclient/components/user/UserProfileForm';

export class UserProfileForm extends DapUserProfileForm {
  getJustificationPlaceholder = () => 'How do you plan to use the data?';
}

export default inject((store: any) => ({
  securityService: store.securityService,
  contactUsService: store.contactUsService,
}))(UserProfileForm);
