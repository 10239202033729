import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

export interface IResponsiveLogoProps {
  desktop: React.ReactNode;
  mobile: React.ReactNode;
}

export default function ResponsiveLogo({
  desktop,
  mobile,
}: IResponsiveLogoProps): React.ReactElement | null {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const menu = isMobile ? mobile : desktop;

  return <>{menu}</>;
}
