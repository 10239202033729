import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { Grid, Button, Box } from '@mui/material';

import { ISecurityService } from '@extensions/services/ISecurityService';

import {
  SEARCH_BAR_REACTIVE_ID,
  SearchBar,
  SearchProvider,
  SearchResults,
  SelectedFilters,
} from '@extensions/components/search-core';
import SearchFilters, {
  FILTER_REACTIVE_IDS,
} from '@extensions/components/publications/SearchFilters';
import SearchHit from '@extensions/components/publications/SearchHit';

const StyledSearchBar = styled(SearchBar)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}));

const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledSearchHitDiv = styled('div')(({
  marginTop: '24px',
  marginBottom: '24px'
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  color: '#000',
  '&:hover': {
    backgroundColor: '#bab8b8',
  }
}));

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row-reverse'
});

export interface IPublicationsSearchProps {
  className?: string;
  securityService?: ISecurityService;
}

export interface IPublicationsSearchState { }

@observer
export class PublicationsSearch extends React.Component<
  IPublicationsSearchProps,
  IPublicationsSearchState
> {

  searchHitGuard = (hit: any, securityService?: ISecurityService) => {
    if (securityService !== undefined && hit) {
      return (
        <StyledSearchHitDiv
          key={hit.id}
        >
          <SearchHit publication={hit} key={hit.id} />
        </StyledSearchHitDiv>
      );
    }
    return null;
  }
  render() {
    const { securityService } = this.props;
    return (
      <div>
        <SearchProvider
          elasticIndex="refs"
          apiPrefix="api"
          theme={{
            colors: {
              primaryColor: '#53b03f',
            },
          }}
        >
          <StyledSearchBar
            dataField={[
              'title',
              'abstract',
              'doi',
              'keywords',
              'authorNames',
              'journalName',
              'authorInstitutions',
              'sponsors',
            ]}
            autosuggest={true}
            URLParams={true}
            queryFormat="and"
          />
          {
            Boolean(securityService?.user && (securityService.user.canAdminPubs || securityService.user.canEdit))
            &&
            <div>
              <StyledBox>
                <StyledButton
                  variant="contained"
                  href="/publication/new"
                >
                  New Publication
                </StyledButton>
              </StyledBox>
            </div>
          }
          <StyledSelectedFilters />
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <SearchFilters />
            </Grid>
            <Grid item xs={8}>
              <SearchResults
                resultsText="publications"
                dataField="title.keyword"
                sortBy="desc"
                react={{
                  and: [SEARCH_BAR_REACTIVE_ID, ...FILTER_REACTIVE_IDS],
                }}
                renderItem={(hit) => {
                  return this.searchHitGuard(hit, securityService)
                }}
              />
            </Grid>
          </Grid>
        </SearchProvider>
      </div>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
}))(PublicationsSearch);
