import React from 'react';
import moment from 'moment';
import { inject, observer } from 'mobx-react';

import { Typography, TextField, Chip, Autocomplete } from '@mui/material';

import FileMetadataSchema, {
  DisplayType,
} from '@extensions/models/FileMetadataSchema';
import Dataset from '@extensions/models/Dataset';
import RangeControls from '@extensions/components/dataset/RangeControls';
import DatePicker, { DateRange } from '@extensions/components/core/date-picker';

export interface IAttributeFilterProps {
  className?: string;
  attribute: FileMetadataSchema;
  value: [number, number] | string[] | DateRange | null;
  onChange: (newVal: [number, number] | string[] | DateRange | null) => void;
  /** Only given for list type attributes */
  options?: string[];
  /** Only given for range type attributes */
  min?: number;
  /** Only given for range type attributes */
  max?: number;
  dataset: Dataset;
}

export interface IAttributeFilterState { }

@observer
export class AttributeFilter extends React.Component<
  IAttributeFilterProps,
  IAttributeFilterState
> {
  renderRangeControls = ({
    attribute,
    dataset,
  }: {
    attribute: FileMetadataSchema;
    dataset: Dataset;
  }): React.ReactNode => {
    const { min, max, onChange, value } = this.props;
    if (min == null || max == null) {
      return null;
    }
    return (
      <RangeControls
        min={min}
        max={max}
        onRangeChange={(newRange) => onChange(newRange || null)}
        range={value as [number, number] | null}
      />
    );
  };

  renderListControls = ({
    attribute,
    dataset,
  }: {
    attribute: FileMetadataSchema;
    dataset: Dataset;
  }): React.ReactNode => {
    const { value, options, onChange } = this.props;
    const definedValue = (value || []) as string[];
    return (
      <Autocomplete
        multiple
        options={options || []}
        value={definedValue}
        onChange={(event, newValues) => {
          onChange(newValues);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={attribute.label} />
        )}
      />
    );
  };

  renderDateControls = ({
    attribute,
    dataset,
  }: {
    attribute: FileMetadataSchema;
    dataset: Dataset;
  }): React.ReactNode => {
    const { value, onChange } = this.props;
    const { startDate, endDate } = (value as DateRange | null) ?? {
      startDate: null,
      endDate: null,
    };
    return (
      <>
        {startDate && endDate && (
          <Chip
            label={`${startDate.format('YYYY-MM-DD')} to ${endDate.format(
              'YYYY-MM-DD'
            )}`}
            onDelete={() => onChange(null)}
            sx={{ marginBottom: '0.25rem' }}
          />
        )}
        <DatePicker
          startDate={startDate}
          startDateId="start-date-filter"
          endDate={endDate}
          endDateId="end-date-filter"
          onDatesChange={(newRange) => onChange(newRange)}
          oldestAllowed={dataset.dynamoDataBegins ? moment(dataset.dynamoDataBegins) : null}
          newestAllowed={dataset.dynamoDataEnds ? moment(dataset.dynamoDataEnds) : null}
        />
      </>
    );
  };

  renderControls = ({
    attribute,
    dataset,
  }: {
    attribute: FileMetadataSchema;
    dataset: Dataset;
  }): React.ReactNode => {
    switch (attribute.downloadDisplay) {
      case DisplayType.RANGE:
        return this.renderRangeControls({ attribute, dataset });
      case DisplayType.LIST:
        return this.renderListControls({
          attribute,
          dataset,
        });
      case DisplayType.DATE:
        return this.renderDateControls({
          attribute,
          dataset,
        });
      case DisplayType.TIME:
      default:
        return null;
    }
  };

  render() {
    const { attribute, dataset } = this.props;
    if (
      attribute.downloadDisplay === DisplayType.NONE ||
      attribute.downloadDisplay === DisplayType.NO_FILTER ||
      attribute.downloadDisplay === DisplayType.TIME
    ) {
      return null;
    }

    return (
      <div>
        <Typography variant="subtitle1" component="h4">
          {attribute.label}
        </Typography>
        {this.renderControls({ attribute, dataset })}
      </div>
    );
  }
}

export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(AttributeFilter);
