export const IDs = {
  USER_APPROVED: 'userApproved',
  USER_LOGGED_OUT: 'userLoggedOut',
  USER_LOGGED_IN: 'userLoggedIn',
  USER_TOKEN_EXPIRED: 'userTokenExpired',
  ORDER_PLACED: 'orderPlaced',
  FILES_SUBMITTED: 'filesSubmitted',
  GET_DATASET: 'getDataset',
  GET_DATASET_FILES: 'getDatasetFiles',
  GET_PROJECT: 'getProject',
  REQUEST_SENT: 'requestSent',
  MEMBERS_ADDED: 'membersAdded',
  ROLE_REMOVED: 'roleRemoved',
  GROUP_CREATED: 'groupCreated',
  GROUP_UPDATED: 'groupUpdated',
  GROUP_DELETED: 'groupDeleted',
};

export enum Status {
  Idle = 'IDLE',
  Running = 'RUNNING',
  BackgroundRunning = 'BACKGROUND_RUNNING',
  Downloading = 'DOWNLOADING',
  Info = 'INFO',
  Success = 'SUCCESS',
  Warn = 'WARN',
  Error = 'ERROR',
}

export enum Description {
  FailedLoginAttempt = 'We cannot find an account with that email address, or you entered the wrong password.',
  EmailAlreadyUsed = 'This email address has already been registered.',
}

export class Notification {
  // should we add something to the class in order to know if its a notification that gets displayed as a message as opposed to something individual components use?
  status: Status;
  message: any; // could be string or component for better rendering
  description: any;
  id: string; // can be any unique string

  constructor(id: string, status: Status, message: any, description: any) {
    this.id = id;
    this.status = status;
    this.message = message;
    this.description = description;
  }
}

export interface INotificationService {
  notifications: Notification[];
  stack: Notification[];
  showNotifications: boolean;

  /**
   * Gets the top error from the notification stack.
   * Returns null if no errors exist.
   */
  readonly error: Notification | null;

  /**
   * Gets the top running from the notification stack.
   * Returns null if no running exist.
   */
  readonly running: Notification | null;
  readonly downloading: Notification | null;

  /**
   * Gets the latest (end) success from the notification stack.
   * Returns null if no success exist.
   */
  readonly success: Notification | null;

  shiftStack(): Notification | null;

  /**
   * Adds a notification to the stack
   */
  addNotification(
    id: string,
    status: Status,
    message: string,
    description?: any,
    drawerAutoOpen?: boolean,
    sentryIgnore?: boolean
  ): void;

  getNotification(id: string): Notification | null;

  /**
   * Removes the given notification from the stack
   */
  removeNotification(id: string): void;
  deleteAllNonRunningNotices(): void;

  setShowNotifications(show: boolean): void;

  /**
   * Show a loading spinner until pending resolves or rejects. If successMessage
   * or successDescription is given, a success message will be displayed if the
   * pending resolves. If pending rejects, an error message is displayed. If
   * errorDescription is not given, the value pending rejects with will be used.
   */
  showStateInUI(params: {
    pending: Promise<any>;
    notificationId: string;
    successMessage?: string;
    successDescription?: any;
    errorMessage: string | ((error: any) => string);
    errorDescription?: string | ((error: any) => string);
    onError?: (error: Error) => void;
    onSuccess?: () => void;
    sentryIgnore?: boolean;
  }): Promise<any>;
}
