export interface QuestionAndAnswer {
  question: string;
  answerMarkdown: string;
  id: string;
}

export default class FaqSection {
  label: string;
  questionAndAnswers: QuestionAndAnswer[] = [];

  constructor(label: string, questionAndAnswers: QuestionAndAnswer[]) {
    this.label = label;
    this.questionAndAnswers = questionAndAnswers;
  }
}
