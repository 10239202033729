import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

export interface IResponsiveMenuProps {
  mobile: React.ReactNode;
  desktop: React.ReactNode;
}

const StyledDiv = styled('div')(({ theme }) => ({
  height: '100%',
  marginLeft: 'auto',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 'unset',
  paddingBottom: '10px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  color: 'white',
  marginTop: '4px',
}));

export default function ResponsiveMenu({
  desktop,
  mobile,
}: IResponsiveMenuProps): React.ReactElement | null {

  const [state, setState] = useState({
    open: false,
  });

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setState((state) => ({ ...state, open }));
  };

  const location = useLocation();
  useEffect(() => {
    setState((state) => ({ ...state, open: false }));
  }, [location]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const menu = isMobile ? mobile : desktop;

  const menuInDrawer = (
    <StyledDiv>
      <StyledIconButton
        onClick={toggleDrawer(true)}
        aria-label="menu"
      >
        <StyledMenuIcon />
      </StyledIconButton>
      <Drawer anchor='right' open={state.open} onClose={toggleDrawer(false)}>
        {menu}
      </Drawer>
    </StyledDiv>
  );

  return isMobile ? menuInDrawer : <>{menu}</>;
}
