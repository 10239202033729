import {
  observable,
  runInAction,
  when,
  computed,
  makeObservable,
} from 'mobx';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import theme from '@extensions/services/Theme';
import Project from '@extensions/models/Project';
import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import Link from '@dapclient/components/core/Link';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IMembershipService } from '@extensions/services/IMembershipService';
import { PublicationType, ReferenceTypePretty } from '@extensions/models/Publication';
import CenteredCircularProgress from '@dapclient/components/core/CenteredCircularProgress';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// App specific imports
const NOTIFICATION_ID = 'PROJECT_PUBLICATIONS_TABLE';

interface Publication {
  publication_id: number;
  publication_title: string;
  publication_type: string;
}

interface AssociatedReferenceDocument {
  ref_doc_id: number;
  ref_doc_title: string;
  ref_doc_type: string;
  ref_doc_url?: string;
}

export interface IProjectPublicationsProps
  extends RouteComponentProps {
  project: Project;
  securityService?: ISecurityService;
  notificationService?: INotificationService;
  membershipService?: IMembershipService;
  tableSize?: 'small' | 'medium';
}

const StyledTableHead = styled(TableHead)(({
  '& th': {
    whiteSpace: 'noWrap'
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50]
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50]
}));

const StyledAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedInfo,
}));


@observer
export class ProjectPublications extends React.Component<IProjectPublicationsProps> {

  @observable
  associatedPublications: Array<Publication> | null = null;
  @observable
  associatedReferenceDocument: Array<AssociatedReferenceDocument> | null = null;
  @observable
  showRequestAccessModal = false;

  constructor(props: IProjectPublicationsProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get requiresAuth() {
    return !process.env.REACT_APP_SHOW_PUBLIC_DS;
  }

  componentDidMount() {
    if (this.requiresAuth) {
      when(() => this.props.securityService!.userIsLoggedIn, this.fetchData);
    } else {
      this.fetchData();
    }
    this.fetchData();
  }

  fetchData = async () => {
    const { project, notificationService } = this.props;
    try {
      const projectSearchResponse = await DapApiAgent.agent
        .post('/api/refs/_msearch').send(
          JSON.stringify({ preference: "SearchResult" }) + "\n" +
          JSON.stringify({
            query: {
              term: {
                'projects.name.keyword': project.name,
              },
            },
          })
        );
      runInAction(() => {
        this.associatedReferenceDocument = projectSearchResponse.body.responses[0].hits.hits
          .filter(
            (hit) => hit._source.referenceTypePretty !== ReferenceTypePretty.JOURNAL_ARTICLE
              && hit._source.referenceTypePretty !== ReferenceTypePretty.TECHNICAL_REPORT
          ).map(
            (hit) => {
              const associatedRefDoc: AssociatedReferenceDocument = {
                ref_doc_id: hit._source.id,
                ref_doc_title: hit._source.title,
                ref_doc_type: hit._source.referenceTypePretty,
                ref_doc_url: hit._source.url ? hit._source.url : ''
              };
              return associatedRefDoc;
            }
          );
        this.associatedPublications = projectSearchResponse.body.responses[0].hits.hits
          .filter(
            (hit) => (hit._source.referenceTypePretty === ReferenceTypePretty.JOURNAL_ARTICLE
              || hit._source.referenceTypePretty === ReferenceTypePretty.TECHNICAL_REPORT)
          ).map(
            (hit) => {
              const publication: Publication = {
                publication_id: hit._source.id,
                publication_title: hit._source.title,
                publication_type: hit._source.referenceTypePretty,
              };
              return publication;
            }
          )
      });
    } catch (error: any) {
      notificationService!.addNotification(
        NOTIFICATION_ID,
        Status.Error,
        `Could not fetch associated reference document for project ${project.name}`,
        error.message
      );
    }
  };

  sortPubs() {
    if (this.associatedPublications && this.associatedPublications.length) {
      const sortedPubs = this.associatedPublications
        .slice()
        .sort((a, b) => a.publication_title.toLowerCase() < b.publication_title.toLowerCase() ? -1 : 1)
        .sort((a, b) => a.publication_type.toLowerCase() < b.publication_type.toLowerCase() ? -1 : 1)
      return sortedPubs;
    }
    return [];
  }

  renderPublicationTable = (isStacked: boolean) => {
    const tableSize = this.props.tableSize ? this.props.tableSize : 'medium';
    if (this.associatedPublications && this.associatedPublications.length > 0) {
      const sortedPubs = this.sortPubs();
      return (
        <TableContainer component={Paper} style={isStacked ? { maxHeight: '500px', marginBottom: '24px' } : { maxHeight: '500px' }}>
          <Table size={tableSize} stickyHeader>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>
                  Publication <small>({this.associatedPublications.length})</small>
                </StyledTableCell>
                <StyledTableCell align='right'>Publication Type</StyledTableCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {
                sortedPubs
                  .slice()
                  .map((publication) => {
                    const publicationUrl = `/publication/${publication.publication_id}`;
                    return (
                      <TableRow key={publication.publication_title}>
                        <TableCell sx={{ fontSize: '14px' }}>
                          {this.renderPublicationTitle(publicationUrl, publication)}
                        </TableCell>
                        <TableCell align='right' sx={{ fontSize: '14px' }}>
                          <code>{publication.publication_type}</code>
                        </TableCell>
                      </TableRow>
                    )
                  })
              }
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else return null;
  };

  renderPublicationTitle = (publicationUrl: string, publication: Publication) => {
    return (
      <>
        <Link to={publicationUrl}>
          {publication.publication_title}
        </Link>
      </>
    )
  };

  checkIsMember = () => {
    const { securityService, project } = this.props;
    if (securityService && securityService.user) {
      if (securityService.user.canAdminPubs || securityService.user.memberOf.includes(project.identifier)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  renderReferenceDocumentTitle = (refUrl: string, refDoc: AssociatedReferenceDocument) => {
    if (this.checkIsMember()) {
      return <Link to={refUrl}>{refDoc.ref_doc_title}</Link>;
    } else {
      if (refDoc.ref_doc_url?.startsWith('/api/')) {
        return <Link to={`${window.location.origin}${refDoc.ref_doc_url}`} download>{refDoc.ref_doc_title}</Link>;
      } else {
        return <Link to={refDoc.ref_doc_url}>{refDoc.ref_doc_title}</Link>;
      }
    }
  };

  sortRefs() {
    if (this.associatedReferenceDocument && this.associatedReferenceDocument.length) {
      const otherRef = this.associatedReferenceDocument
        .slice()
        .filter(
          (ref) => ref.ref_doc_type !== PublicationType.PlatformMetadata && ref.ref_doc_type !== PublicationType.PlatformReference
        )
        .sort((a, b) => a.ref_doc_title.toLowerCase() < b.ref_doc_title.toLowerCase() ? -1 : 1)
        .sort((a, b) => a.ref_doc_type.toLowerCase() < b.ref_doc_type.toLowerCase() ? -1 : 1);
      const platFormRef = this.associatedReferenceDocument
        .slice()
        .filter(
          (ref) => ref.ref_doc_type === PublicationType.PlatformMetadata || ref.ref_doc_type === PublicationType.PlatformReference
        )
        .sort((a, b) => a.ref_doc_title.toLowerCase() < b.ref_doc_title.toLowerCase() ? -1 : 1)
        .sort((a, b) => a.ref_doc_type.toLowerCase() < b.ref_doc_type.toLowerCase() ? -1 : 1);
      return [...otherRef, ...platFormRef];
    }
    return [];
  }

  renderReferenceDocumentTable = () => {
    const tableSize = this.props.tableSize ? this.props.tableSize : 'medium';
    if (this.associatedReferenceDocument && this.associatedReferenceDocument.length > 0) {
      const sortedRefs = this.sortRefs();
      return (
        <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
          <Table size={tableSize} stickyHeader>
            <StyledTableHead>
              <TableRow>
                <StyledTableHeadCell>
                  Reference Document <small>({this.associatedReferenceDocument.length})</small>
                </StyledTableHeadCell>
                <StyledTableHeadCell align='right'>Reference Type</StyledTableHeadCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {
                sortedRefs
                  .slice()
                  .map((refDoc) => {
                    const refUrl = `/publication/${refDoc.ref_doc_id}`;
                    return (
                      <TableRow key={refDoc.ref_doc_title}>
                        <TableCell sx={{ fontSize: '14px' }}>
                          {this.renderReferenceDocumentTitle(refUrl, refDoc)}
                        </TableCell>
                        <TableCell align='right' sx={{ fontSize: '14px' }}>
                          <code>{refDoc.ref_doc_type}</code>
                        </TableCell>
                      </TableRow>
                    )
                  })
              }
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else return null;
  };

  renderAdditionalReferences = (isStacked: boolean): JSX.Element | null => null;

  render() {
    let content: React.ReactNode;
    if (this.associatedReferenceDocument === null || this.associatedPublications === null) {
      content = <CenteredCircularProgress color="secondary" />;
    } else if (
      (this.associatedReferenceDocument && this.associatedReferenceDocument.length > 0)
      && (this.associatedPublications && this.associatedPublications.length > 0)
    ) {
      content = (
        <>
          {this.renderPublicationTable(true)}
          {this.renderReferenceDocumentTable()}
          {this.renderAdditionalReferences(true)}
        </>
      )
    } else if (this.associatedReferenceDocument && this.associatedReferenceDocument.length > 0) {
      content = (
        <>
          {this.renderReferenceDocumentTable()}
          {this.renderAdditionalReferences(true)}
        </>
      );
    } else if (this.associatedPublications && this.associatedPublications.length > 0) {
      content = (
        <>
          {this.renderPublicationTable(false)}
          {this.renderAdditionalReferences(true)}
        </>
      )
    } else {
      if (this.renderAdditionalReferences(false) === null) {
        content = <StyledAlert severity="info" >No Associated Reference Document Found</StyledAlert>;
      } else {
        content = this.renderAdditionalReferences(false);
      }
    }
    return <>{content}</>;
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
  notificationService: store.notificationService,
  membershipService: store.membershipService,
}))(withRouter(ProjectPublications));
