import { inject } from 'mobx-react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { ProjectResult as DapProjectResult } from '@dapclient/components/project-search/ProjectResult';
import Project from '@extensions/models/Project';
import Link from '@dapclient/components/core/Link';

export class ProjectResult extends DapProjectResult {
  getPublicationPanel = (hit: Project) => {
    if (hit.references && hit.references.length) {
      return this.getPanel({
        title: 'References',
        body: (
            <TableContainer component={Paper} style={{maxHeight: '500px'}}>
              <Table size={'medium'} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Reference Title
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    hit.references
                    .slice()
                    .map((reference) => {
                      return (
                        <TableRow key={reference.referenceURL}>
                          <TableCell>
                            <Link to={reference.referenceURL}>{reference.referenceTitle}</Link>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          
        )
      })
    }
    return null;
  };
}

export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(ProjectResult);

