import React from 'react';
import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';
import Link from '@extensions/components/core/Link';

const StyledAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedError,
}));

export interface IMustVerifyEmailAlertProps {
  className?: string;
}

class MustVerifyEmailAlert extends React.Component<IMustVerifyEmailAlertProps> {
  render() {
    return (
      <div>
        <StyledAlert
          severity="error"
        >
          <div>
            Your email address has not been verified. Please go to your{' '}
            <Link to="/profile">profile</Link> page if you need us to re-send
            the verification email.
          </div>
        </StyledAlert>
      </div>
    );
  }
}

export default MustVerifyEmailAlert;
