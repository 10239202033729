// Copied from https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/autocomplete/Virtualize.tsx
// and then made more reusable.
import React from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Autocomplete, AutocompleteProps, UseAutocompleteProps, useMediaQuery, ListSubheader } from '@mui/material';

import theme from '@extensions/services/Theme';

export type IVirtualizedAutocompleteProps<T = any> = Omit<
  AutocompleteProps<T, undefined, undefined, undefined>,
  'ListboxComponent' | 'disableListWrap'
> &
  UseAutocompleteProps<T, undefined, undefined, undefined>;

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

export default function VirtualizedAutocomplete<T>(
  props: IVirtualizedAutocompleteProps<T>
) {

  return (
    <Autocomplete
      {...props}
      sx={{ width: '100%' }}
      disableListWrap
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
    />
  );
}

//==================The Original VirtualizedAutocomplete.tsx code===================
//==================The Original VirtualizedAutocomplete.tsx code===================
//==================The Original VirtualizedAutocomplete.tsx code===================
//==================The Original VirtualizedAutocomplete.tsx code===================
//==================The Original VirtualizedAutocomplete.tsx code===================
// // Copied from https://github.com/mui-org/material-ui/blob/master/docs/src/pages/components/autocomplete/Virtualize.tsx
// // and then made more reusable.
// import React from 'react';
// import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
// import { UseAutocompleteProps } from '@material-ui/lab/useAutocomplete';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import { useTheme, makeStyles } from '@material-ui/core/styles';
// import { VariableSizeList, ListChildComponentProps } from 'react-window';
// import clsx from 'clsx';

// export type IVirtualizedAutocompleteProps<T = any> = Omit<
//   AutocompleteProps<T, undefined, undefined, undefined>,
//   'ListboxComponent' | 'disableListWrap'
// > &
//   UseAutocompleteProps<T, undefined, undefined, undefined>;

// const LISTBOX_PADDING = 8; // px

// function renderRow(props: ListChildComponentProps) {
//   const { data, index, style } = props;
//   return React.cloneElement(data[index], {
//     style: {
//       ...style,
//       top: (style.top as number) + LISTBOX_PADDING,
//     },
//   });
// }

// const OuterElementContext = React.createContext({});

// const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
//   const outerProps = React.useContext(OuterElementContext);
//   return <div ref={ref} {...props} {...outerProps} />;
// });

// function useResetCache(data: any) {
//   const ref = React.useRef<VariableSizeList>(null);
//   React.useEffect(() => {
//     if (ref.current != null) {
//       ref.current.resetAfterIndex(0, true);
//     }
//   }, [data]);
//   return ref;
// }

// // Adapter for react-window
// const ListboxComponent = React.forwardRef<HTMLDivElement>(
//   function ListboxComponent(props, ref) {
//     const { children, ...other } = props;
//     const itemData = React.Children.toArray(children);
//     const theme = useTheme();
//     const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
//     const itemCount = itemData.length;
//     const itemSize = smUp ? 36 : 48;

//     const getChildSize = (child: React.ReactNode) => {
//       if (React.isValidElement(child) && child.type === ListSubheader) {
//         return 48;
//       }

//       return itemSize;
//     };

//     const getHeight = () => {
//       if (itemCount > 8) {
//         return 8 * itemSize;
//       }
//       return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
//     };

//     const gridRef = useResetCache(itemCount);

//     return (
//       <div ref={ref}>
//         <OuterElementContext.Provider value={other}>
//           <VariableSizeList
//             itemData={itemData}
//             height={getHeight() + 2 * LISTBOX_PADDING}
//             width="100%"
//             ref={gridRef}
//             outerElementType={OuterElementType}
//             innerElementType="ul"
//             itemSize={index => getChildSize(itemData[index])}
//             overscanCount={5}
//             itemCount={itemCount}
//           >
//             {renderRow}
//           </VariableSizeList>
//         </OuterElementContext.Provider>
//       </div>
//     );
//   }
// );

// const useStyles = makeStyles({
//   root: {
//     width: '100%',
//   },
//   listbox: {
//     boxSizing: 'border-box',
//     '& ul': {
//       padding: 0,
//       margin: 0,
//     },
//   },
// });

// export default function VirtualizedAutocomplete<T>(
//   props: IVirtualizedAutocompleteProps<T>
// ) {
//   const { className } = props;
//   const classes = useStyles();

//   return (
//     <Autocomplete
//       {...props}
//       className={clsx(classes.root, className)}
//       classes={classes}
//       disableListWrap
//       ListboxComponent={
//         ListboxComponent as React.ComponentType<
//           React.HTMLAttributes<HTMLElement>
//         >
//       }
//     />
//   );
// }
