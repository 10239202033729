import { Header as DapHeader } from '@dapclient/components/nav/Header';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';
import { inject } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import doeEereLogo from '@dapclient/resources/doe-eere-logo.png';

const StyledRootDiv = styled('div')(() => ({
  display: 'inline-flex',
  flexDirection: 'column',
  paddingTop: '2px',
  paddingLeft: '10px',
}));

const StyledLogoHeader = styled(Typography)(() => ({
  textTransform: 'uppercase',
  color: '#ebf2f5',
  component: 'span',
}));

const StyledMobileLogoHeader = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  component: 'span',
  color: '#ebf2f5',
  '@media (max-width:288px)': {
  fontSize: '14px'
  }
}));

const StyledLogoSubHeader = styled(Typography)(() => ({
  color: '#ebf2f5',
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightMedium,
}));

const StyledMobileLogo = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledSponsorLogo = styled('img')(({
  float: 'left',
  marginTop: '-15px',
  width: '155px',
}));

export class Header extends DapHeader {
  getDesktopLogo = (): React.ReactNode => {
    return (
      <>
        <StyledSponsorLogo src={doeEereLogo} alt="logo" />
        <StyledRootDiv>
          <StyledLogoHeader
            variant="h2"
          >
            Benchmark Datasets
          </StyledLogoHeader>
          <StyledLogoSubHeader
            variant="h4"
          >
            of Building Environmental Conditions and Occupancy Parameters
          </StyledLogoSubHeader>
        </StyledRootDiv>
      </>
    );
  };

  getMobileLogo = (): React.ReactNode => {
    return (
      <StyledMobileLogo>
        <StyledMobileLogoHeader
          variant="h3"
        >
          Building Benchmark Datasets
        </StyledMobileLogoHeader>
      </StyledMobileLogo>
    )
  }

  getPublicMenuItems = () => [
    {
      value: '/',
      label: 'Home',
      to: { pathname: '/', state: { logout: false } },
    },
    {
      value: '/project-search',
      label: 'Projects',
    },
    {
      value: '/dataset-search',
      label: 'Datasets',
    },
    // Todo - Hide Publications tab until backend supports it
    // {
    //   value: '/publications',
    //   label: 'Publications',
    // },
    {
      value: '/metrics',
      label: 'Metrics',
    },
    {
      value: '/faq',
      label: 'FAQ',
    },
  ];

  getProtectedMenuItems = () => [];
}

export default inject((store: any) => ({
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(withRouter(Header));
