
import { useState, useCallback, useEffect } from 'react'
import { getHashValue, setHashValue } from '@dapclient/utils/url'

export function useHashStateKey(key, initialValue) {
    const [value, setValue] = useState(getHashValue(key) || initialValue)
    const onSetValue = useCallback(newValue => {
        setValue(newValue)
        setHashValue(key, newValue)
    }, [key])

    return [value, onSetValue]
}

export function useHashState(initialObject) {
    for (const key in initialObject) {
        initialObject[key] = getHashValue(key) || initialObject[key]
    }
    const [values, setValues] = useState(initialObject)
    const onSetValues = useCallback(newValues => {
        setValues(newValues)
        for (const key in newValues) {
            setHashValue(key, newValues[key])
        }
    }, [])

    useEffect(() => {
        onSetValues(initialObject)
    }, [onSetValues, initialObject])

    return [values, onSetValues]
}
