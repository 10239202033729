import React from 'react';
import { inject, observer } from 'mobx-react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Autocomplete, TextField } from '@mui/material';

import { ISecurityService } from '@extensions/services/ISecurityService';

import { PublicationFormData } from '@extensions/models/PublicationFormData';
import { RefCategory } from '@extensions/models/Publication';

export interface IRefFileTypeSelectionFieldProps {
    className?: string;
    control: UseFormReturn<PublicationFormData>['control'];
    securityService?: ISecurityService;
}

export interface IRefFileTypeSelectionFieldState { }

@observer
export class RefFileTypeSelectionField extends React.Component<
    IRefFileTypeSelectionFieldProps,
    IRefFileTypeSelectionFieldState
> {
    typeOptions: RefCategory[] = [
        { name: 'Presentation', label: "Presentation" },
        { name: 'Publication', label: "Publication" },
        { name: 'Repository', label: "Repository" },
        { name: 'Standard', label: "Standard or Specification" },
        { name: 'Support Data', label: "Supporting Data" },
        { name: 'Vendor Info', label: "Vendor Information" },
        { name: 'Video', label: "Video" },
        { name: 'Web', label: "Web Resource" },
    ];
    adminTypeOptions: RefCategory[] = [
        { name: 'Documentation', label: "Project/Dataset Documentation" },
        { name: 'Reference', label: "Project/Dataset Reference" },
        { name: 'Metadata', label: "Project/Dataset-provided Metadata" },
        ...this.typeOptions
    ];
    render() {
        const { control, securityService } = this.props;
        let typeOptions: RefCategory[] = this.typeOptions;
        if (securityService && securityService.user && securityService.user.canAdminPubs) {
            typeOptions = this.adminTypeOptions;
        }
        return (
            <Controller
                name="refCategory"
                control={control}
                render={({ field }) => {
                    return (
                        <Autocomplete
                            options={typeOptions}
                            value={field.value}
                            isOptionEqualToValue={(option, value) => {
                                return option.name === value.name;
                            }}
                            getOptionLabel={(option) => {
                                return option.label
                            }}
                            onChange={(event, value) => {
                                return field.onChange(value)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onBlur={field.onBlur}
                                    label="Reference Category"
                                    variant="outlined"
                                    margin="dense"
                                    required
                                />
                            )}
                        />
                    );
                }}
            />
        );
    }
}

export default inject((store: any) => ({
    securityService: store.securityService,
}))(RefFileTypeSelectionField);
