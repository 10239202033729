import React from 'react';
import {
  Controller,
  UseFormReturn,
  ControllerRenderProps,
} from 'react-hook-form';
import { inject, observer } from 'mobx-react';

import { Typography } from '@mui/material';

import FileUpload from '@extensions/components/core/FileUpload';
import { PublicationFormData } from '@extensions/models/PublicationFormData';


export interface IPdfFieldProps {
  className?: string;
  control: UseFormReturn<PublicationFormData>['control'];
}

export interface IPdfFieldState { }

@observer
export class PdfField extends React.Component<
  IPdfFieldProps,
  IPdfFieldState
> {
  djsConfig = {
    maxFiles: 1,
    chunking: true,
    paramName: 'report',
    acceptedFiles: ".pdf",
    autoProcessQueue: true
  };
  uploadConfig = {
    postUrl: '/api/refs/file',
  };
  handleSuccess = ({
    response,
    field,
  }: {
    response: { url: string; name: string };
    field: ControllerRenderProps<PublicationFormData, 'fileName'>;
  }): void => {
    field.onChange(response.name);
  };
  render() {
    const { control } = this.props;

    return (
      <div>
        <Typography variant="h2" component="h2">
          Report PDF
        </Typography>
        <Controller
          control={control}
          name="url"
          render={({ field: urlField }) => {
            return <Controller
              control={control}
              name="url"
              render={({ field: nameField }) => (
                <FileUpload
                  config={this.uploadConfig}
                  djsConfig={this.djsConfig}
                  eventHandlers={{
                    success: (files) => {
                      nameField.onChange(JSON.parse(files.xhr.responseText).name);
                      urlField.onChange(JSON.parse(files.xhr.responseText).url);
                    },
                  }}
                />
              )}
            />
          }
          }
        />
      </div>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(PdfField);
