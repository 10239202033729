import JSZip from 'jszip';

export default function buildScript(
  respBody: any,
  fileName: string,
  platform: string
) {
  const zip = new JSZip();
  const folder = zip.folder(`${platform}-downloader`);
  if (!folder) {
    throw new Error('Unexpected NULL folder');
  }
  const readmeContents = [
    `The other file in this folder (i.e. ${fileName}) is an`,
    'executable program which you can use to download the files in',
    'your order. To run it, do the following:',
    '',
    '  1. Open Terminal',
    '  2. cd to the directory which contains this README',
    `  3. Run "chmod +x ${fileName}"`,
    `  4. Run "./${fileName}"`,
    `  5. Follow the prompts. You will have to enter your ${platform.toUpperCase()} password to authenticate,`,
    '',
    'If you have any questions or concerns, please email dapteam@pnnl.gov.',
  ].join('\n');
  const readmeBlob = new Blob([readmeContents], {
    type: 'text/plain',
  });
  folder.file('READ_ME.txt', readmeBlob);
  folder.file(fileName, respBody);
  return zip;
}
