import cloneDeep from 'lodash/cloneDeep';

export enum DisplayType {
  LIST = 'list',
  RANGE = 'range',
  DATE = 'date',
  TIME = 'time',
  NONE = 'none',
  NO_FILTER = 'nofilter',
  AUTOCOMPLETE = 'autocomplete',
}

export interface FieldFormat {
  regex: string;
  label: string;
  example: string;
}

export default class FileMetadataSchema {
  name: string;
  label: string;
  dynamoFieldName: string;
  fieldFormat: FieldFormat | undefined;
  downloadDisplay: DisplayType | undefined;

  constructor(data) {
    // map __DATE and __TIME  in the name field to our defined types:
    if (data.name === '__DATE') {
      data = cloneDeep(DATE_TYPE_FILE_METADATA);
    } else if (data.name === '__TIME') {
      data = cloneDeep(TIME_TYPE_FILE_METADATA);
    } else if (data.name === '__RANGE') {
      data = cloneDeep(RANGE_TYPE_FILE_METADATA);
    }

    this.name = data.name;
    this.label = data.label;
    this.downloadDisplay = data.downloadDisplay || DisplayType.LIST;

    // starts off as empty string, have to loop thru all schema instances in order
    // to populate this field using its index in the list
    if (data.dynamoFieldName) {
      this.dynamoFieldName = data.dynamoFieldName;
    } else {
      this.dynamoFieldName = '';
    }

    if (data.format) {
      this.fieldFormat = data.format;
    }
  }

  setDynamoFieldName(value: string) {
    this.dynamoFieldName = value;
  }
}

export const DATE_TYPE_FILE_METADATA = {
  name: 'date',
  label: 'Date',
  downloadDisplay: DisplayType.DATE,
  dynamoFieldName: 'data_date',
  format: {
    regex: '^20[0-9]{6}$',
    label: 'digits[0-9], 8 characters long YYYYMMDD',
    example: '20190825',
  },
};

export const TIME_TYPE_FILE_METADATA = {
  name: 'time',
  label: 'Time',
  downloadDisplay: DisplayType.TIME,
  dynamoFieldName: 'data_time',
  format: {
    regex: '^[0-9]{6}$',
    label: 'digits[0-9], 6 characters long HHMMSS',
    example: '184500',
  },
};

export const RANGE_TYPE_FILE_METADATA = {
  name: 'range',
  label: 'Range',
  downloadDisplay: DisplayType.RANGE,
  dynamoFieldName: 'range',
  format: {
    regex: '^[0-9]{1,30}$',
    label: 'digits[0-9], 1 to 30 characters long',
    example: '0123',
  },
};
