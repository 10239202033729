import React from 'react';

import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
} from '@mui/material';
import { inject, observer } from 'mobx-react';
import FormButtonsAndErrors from '@extensions/components/publications/FormButtonsAndErrors';
import theme from '@extensions/services/Theme';

export enum PublicationFormMethod {
  MANUAL = 'manual',
  OSTI_LOOKUP = 'osti_lookup',
}

export interface IChooseMethodProps {
  className?: string;
  method: PublicationFormMethod;
  onChange: (newMethod: PublicationFormMethod) => void;
  moveForward: () => void;
  moveBackward: () => void;
  clickCancelBtn: () => void;
  canMoveForward: boolean;
  canMoveBackward: boolean;
}

export interface IChooseMethodState { }

@observer
export class ChooseMethod extends React.Component<
  IChooseMethodProps,
  IChooseMethodState
> {
  render() {
    const {
      method,
      onChange,
      moveForward,
      moveBackward,
      clickCancelBtn,
      canMoveForward,
      canMoveBackward,
    } = this.props;
    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={(event) => event.preventDefault()}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">
            How would you like to add a publication or reference document to your project or dataset?
          </FormLabel>
          <RadioGroup
            aria-label="choose method"
            value={method}
            onChange={(event, newMethod) =>
              onChange(newMethod as PublicationFormMethod)
            }
          >
            <FormControlLabel
              value={PublicationFormMethod.OSTI_LOOKUP}
              control={<Radio color='secondary' />}
              label="Add your publication from www.osti.gov (DOI lookup)."
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: theme.palette.grey[700]
                }
              }}
            />
            <FormControlLabel
              value={PublicationFormMethod.MANUAL}
              control={<Radio color='secondary' />}
              label="Manually add your publication or reference document."
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: theme.palette.grey[700]
                }
              }}
            />
          </RadioGroup>
        </FormControl>
        <FormButtonsAndErrors
          moveBackward={moveBackward}
          clickCancelBtn={clickCancelBtn}
          moveForward={moveForward}
          canMoveBackward={canMoveBackward}
          canMoveForward={canMoveForward}
          error={null}
        />
      </form>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(ChooseMethod);
