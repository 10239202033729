import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Chip,
  Tooltip
} from '@mui/material';
import omit from 'lodash/omit';
import filesize from 'filesize';
import { styled } from '@mui/material/styles';
import PowerIcon from '@mui/icons-material/Power';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import theme from '@extensions/services/Theme';
import Dataset from '@extensions/models/Dataset';
import Link from '@dapclient/components/core/Link';
import { removeMdExcept } from '@extensions/utils/SearchUtils';
import ContactsTable from '@extensions/components/core/ContactsTable';
import ExpandableText from '@extensions/components/core/ExpandableText';
import CardExpansionPanel from '@extensions/components/core/CardExpansionPanel';
import ReferencesPanel from '@extensions/components/core/ReferencesPanel';
import SearchHighlight from '../core/SearchHighlight';

export interface IDatasetResultProps {
  data: Dataset;
  highlight?: { string: string[] };
  className?: string;
  renderCounts: boolean;
  searchFieldLabels?: { string: string };
}

type TypographyExtraProps = {
  component: React.ElementType;
};

const StyledChip = styled(Chip)({
  marginTop: '5px',
  marginRight: '5px',
  color: theme.palette.common.black
});

const StyledFileSummaryText = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const DatasetTitle = styled(Link)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  display: 'block',
  color: '#005b82',
}));

const StyledAccessMethod = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightMedium as number,
  display: 'flex',
  columnGap: '15px',
}));

const StyledParentProjectTitle = styled(Link)(({ theme }) => ({
  display: 'inline',
  color: '#005b82',
  fontSize: '1rem',
  marginBottom: theme.spacing(1),
}));

const StyledDatasetName = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  fontSize: '1rem',
  fontWeight: theme.typography.fontWeightMedium as number,
  display: 'block',
}));

const StyledSubHeaderTypography = styled(Typography)(({ theme }) => ({
  '& mark': {
    background: `${theme.palette.highlight.main}`,
  },
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '1rem',
  paddingLeft: theme.spacing(2),
}));

const StyledCardExpansionPanel = styled(CardExpansionPanel)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '& mark': {
    background: `${theme.palette.highlight.main}`,
  },
  '& span': {
    fontSize: '14px'
  },
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(3),
}));

const StyledHeaderTypography = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const StyledIconDiv = styled('div')(({
  color: theme.palette.text.primary
}));

const StyledExitIcon = styled(ExitToAppIcon)(({ theme }) => ({
  verticalAlign: 'sub',
  marginRight: theme.spacing(0.1),
  fontSize: theme.typography.pxToRem(24),
}));

const StyledPowerIcon = styled(PowerIcon)(({ theme }) => ({
  verticalAlign: 'sub',
  marginRight: theme.spacing(0.1),
  fontSize: theme.typography.pxToRem(24),
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  '& mark': {
    background: `${theme.palette.highlight.main}`,
  },
  padding: 0,
  paddingTop: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  '& mark': {
    background: `${theme.palette.highlight.main}`,
  },
  marginBottom: theme.spacing(3),
}));

const StyledHlKeyDiv = styled('div')(({
  border: '1px solid #ddd',
  borderRadius: '3px',
  backgroundColor: '#f3f3f3',
  margin: '0.5rem 0',
  padding: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
}));

export class DatasetResult<
  ExtraProps extends object = {}
> extends React.Component<
  IDatasetResultProps & ExtraProps
> {
  renderFileSummary(datasetFileSize, datasetFileCount) {
    if (datasetFileSize && datasetFileCount && datasetFileCount > 1) {
      return (
        <span>
          {datasetFileCount} Files • {filesize(datasetFileSize, { round: 0 })}
        </span>
      );
    } else if (datasetFileSize && datasetFileCount && datasetFileCount <= 1) {
      return (
        <span>
          {datasetFileCount} File • {filesize(datasetFileSize, { round: 0 })}
        </span>
      );
    }
  }

  renderReferencesPanel(dataset: Dataset) {
    if (dataset) {
      return (
        <StyledCardExpansionPanel
          detailsId={`${dataset.name}-references`}
          summaryContent={
            <StyledSubHeaderTypography
              variant="h4"
            >
              References
            </StyledSubHeaderTypography>
          }
          detailsContent={
            <ReferencesPanel dataset={dataset} styleAdjust />
          }
        />
      )
    }
    return null;
  }

  render() {
    const {
      data,
      highlight,
      renderCounts,
      searchFieldLabels,
    } = this.props;
    let fileSummary: React.ReactNode = null;
    let accessMethodIcons: React.ReactNode[] = [];

    const hlField = f => {
      return highlight && highlight.hasOwnProperty(f)
        ? highlight[f][0]
        : data[f]
    }

    const externalLinkIcon = <Tooltip
      aria-label="External Link"
      title="Data linked from external source"
      children={
        <StyledIconDiv>
          <StyledExitIcon
            titleAccess={"Access Method: External Link"}
          />External Link
        </StyledIconDiv>
      }></Tooltip>

    const apiIcon = <Tooltip
      aria-label="Access Method: API"
      title="Data access via programmatic interface (API)"
      children={
        <StyledIconDiv>
          <StyledPowerIcon
            titleAccess={"Access Method: API"}
          />API
        </StyledIconDiv>
      }></Tooltip>

    if (renderCounts) {
      fileSummary = this.renderFileSummary(
        data.dynamoTotalFileSize,
        data.dynamoFileCount
      );
    }
    if (data.accessMethods && data.accessMethods.length > 0) {
      data.accessMethods.forEach((accessMethod) =>
        accessMethod === 'External Link'
          ? accessMethodIcons.push(externalLinkIcon)
          : accessMethod === 'API'
            ? accessMethodIcons.push(apiIcon)
            : accessMethodIcons.push(null))
    }

    // const [projectName, baseName] = data.name.toLocaleLowerCase().split('/');
    const displayProjectName = hlField('project_name') || data['projectName'];
    const displayBaseName = hlField('name').slice(displayProjectName.length + 1);

    const title = hlField('title');
    const desc = removeMdExcept(hlField('description'), ['mark'])
      .replace(/^[^<>]+(\s[^<>]{300,}<mark>)/, '...$1')
    // .replace(/[^<>]{16,}(\s[^<>]{32,64})<mark>/g, ' ...$1<mark>')
    // .replace(/<\/mark>([^<>]{32,64}\s)[^<>]{16,}/g, '</mark>$1... ');

    return (
      <StyledCard>
        <StyledCardHeader
          title={
            <StyledHeaderTypography
              variant="h2"
            >
              <StyledDatasetName
                variant="body1"
                component="span"
                color="textSecondary"
              >
                <code style={{color: theme.palette.text.primary}}>
                  <StyledParentProjectTitle
                    to={`/project/${data.projectName}`}
                  >
                    <span dangerouslySetInnerHTML={{ __html: displayProjectName }} />
                  </StyledParentProjectTitle>&nbsp;/&nbsp;<span dangerouslySetInnerHTML={{ __html: displayBaseName }} />
                </code>
              </StyledDatasetName>
              <DatasetTitle
                to={`/ds/${data.name}`}
              >
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </DatasetTitle>
              <StyledAccessMethod
                variant="body1"
                component="span"
                color="textSecondary"
                sx={{mb: -1}}
              >
                {!renderCounts && accessMethodIcons.map((icon, i) => (
                  <div key={i}>
                    {icon}
                  </div>
                ))}
              </StyledAccessMethod>
            </StyledHeaderTypography>
          }
        />
        <StyledCardContent>
          <StyledFileSummaryText>{fileSummary}</StyledFileSummaryText>

          <ExpandableText dangerous>
            {desc}
          </ExpandableText>
            {Boolean(highlight) && Boolean(searchFieldLabels) && (
            <SearchHighlight
              highlight={highlight as {string: string[]}}
              searchFieldLabels={
                omit(searchFieldLabels, [
                  'name',
                  'project_name',
                  'title',
                  'description',
                ]) as {string: string}
              }
            />
          )} 
        </StyledCardContent>
        <StyledCardExpansionPanel
          detailsId={`${data.name}-contacts`}
          summaryContent={
            <StyledSubHeaderTypography
              variant="h4"
            >
              Contacts
            </StyledSubHeaderTypography>
          }
          detailsContent={
            <ContactsTable contacts={data.contacts} name={data.name} title={data.title} />
          }
        />
        {Boolean(data.keywords && data.keywords.length > 0) && (
          <StyledCardExpansionPanel
            detailsId={`${data.name}-keywords`}
            summaryContent={
              <StyledSubHeaderTypography
                variant="h4"
              >
                Keywords
              </StyledSubHeaderTypography>
            }
            detailsContent={data.keywords.map((word) => (
              <StyledChip
                label={word}
                variant="outlined"
                size="small"
                key={word}
              />
            ))}
          />
        )}
        {this.renderReferencesPanel(data)}
      </StyledCard>
    );
  }
}

export default DatasetResult;
