export class Measure {
  showAsSummary: boolean;
  name: string;
  value: number;
  units: string;
  notes: string | null = null;
  showAsGraphic: boolean;
  graphicLegend: string | null = null;

  //data is an item from the "measures" array from the json file
  constructor(data) {
    this.name = data.name;
    this.units = data.units;
    this.value = +data.value; //+ to force typescript to convert to number
    this.showAsSummary = data.showAsSummary ? data.showAsSummary : false;
    this.showAsGraphic = data.showAsGraphic ? data.showAsGraphic : false;

    if (data.notes) {
      this.notes = data.notes;
    }

    if (data.graphicLegend) {
      this.graphicLegend = data.graphicLegend;
    }
  }
}

export default class Quality {
  measures: Measure[] = [];
  notes: string;
  detailsUrl: string;

  //data is the "dataQualitySummary" json segment
  constructor(data) {
    this.detailsUrl = data.referenceURL;
    this.notes = data.notes;

    if (data.measures) {
      this.measures = data.measures.map(measure => new Measure(measure));
    }
  }
}
