import React from 'react';
import { inject, observer } from 'mobx-react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import PublicationForm from '@extensions/components/publications/PublicationForm';

const StyledPublicationForm = styled(PublicationForm)(({
  marginTop: '1rem'
}));

export interface INewPublicationProps {
  className?: string;
}

export interface INewPublicationState { }

@observer
export class NewPublication extends React.Component<
  INewPublicationProps,
  INewPublicationState
> {
  renderTitle = () => {
    return <Typography variant="h1" sx={{ mb: '1rem' }}>Add a Publication or Reference Document</Typography>
  }

  render() {
    return (
      <div>
        {this.renderTitle()}
        <StyledPublicationForm isEdit={false} />
      </div>
    );
  }
}

export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(NewPublication);
