import React from 'react';

import { Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import theme from '@extensions/services/Theme';
import Link from '@dapclient/components/core/Link';

const StyledLink = styled(Link)(() => ({
    color: theme.palette.metrics?.main,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(-0.2)
}));

const StyledPaperDiv = styled('div')(() => ({
    border: 'none',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    maxWidth: 500,
    fontStyle: 'italic',
}));

export interface IDatasetMetricsNotesButtonProps {
    notes: string;
    classes?: any;
}

const DatasetMetricsNotesButton: React.FC<IDatasetMetricsNotesButtonProps> = props => {
    const { notes } = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <>
            <StyledLink onClick={handleClick}>
                <InfoOutlinedIcon fontSize='small' />
            </StyledLink>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handleClose}
            >
                <StyledPaperDiv
                    dangerouslySetInnerHTML={{
                        __html: notes
                    }}
                />
            </Popover>
        </>
    );
};

export default DatasetMetricsNotesButton;
