import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import ProjectModel from '@extensions/models/Project';
import DatasetSearch from '@extensions/components/page/DatasetSearch';
import { IProjectService } from '@extensions/services/IProjectService';
import { IHistoryService } from '@extensions/services/IHistoryService';
import ProjectOverview from '@extensions/components/project/ProjectOverview';
import { INotificationService } from '@extensions/services/INotificationService';

import { styled } from '@mui/material/styles';
import {
  ABILITIES,
  ISecurityService,
} from '@extensions/services/ISecurityService';
import { Button, Grid, Tab, Tabs, Typography } from '@mui/material';

export interface IProjectContentProps {
  project: ProjectModel;
  projectService: IProjectService;
  securityService: ISecurityService;
  notificationService: INotificationService;
  historyService: IHistoryService;
}

const StyledButton = styled(Button)(({theme}) => ({
  float: 'right',
  backgroundColor: theme.palette.primary.main
}));

const StyledTab = styled(Tab)(({theme}) => ({
  '&.MuiTab-root': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    color: '#000',
  }
}));

@inject(
  'projectService',
  'historyService',
  'securityService',
  'notificationService'
)
@observer
class ProjectContent extends React.Component<IProjectContentProps> {
  render() {
    let content = <div />;
    const { historyService, securityService, project } = this.props;

    const abilities: string[] | null = this.props.projectService.abilities;
    const datasetsPathSuffix = '/data';
    const tabIndex = historyService.history.location.pathname.endsWith(datasetsPathSuffix) ? 1 : 0;

    content = (
      <React.Fragment>
        <Helmet>
          <title>{project.title}</title>
          <meta name="description" content={project.description}></meta>
        </Helmet>
        <Grid container>
          <Grid item sm={10} xs={12}>
            <Typography variant="h2" gutterBottom>
              {project.title}
            </Typography>
          </Grid>
        </Grid>
        <div>
          {securityService.userIsLoggedIn &&
            abilities &&
            abilities.includes(ABILITIES.ADMIN) && (
              <Link to={`/project/${project.name}/manage`}>
                <StyledButton
                  variant="contained"
                >
                  Manage
                </StyledButton>
              </Link>
            )}
          <Tabs
            value={tabIndex}
            indicatorColor="primary"
            textColor="primary"
            aria-label="project tabs"
            onChange={(event, value) => {
              if (value === tabIndex) {
                return;
              }
              switch (value) {
                case 0:
                  return historyService.redirect(
                    historyService.history.location.pathname.replace(datasetsPathSuffix, '')
                  );
                case 1:
                  return historyService.redirect(
                    historyService.history.location.pathname + datasetsPathSuffix
                  );
              }
            }}
          >
            <StyledTab value={0} label="Project" />
            <StyledTab value={1} label={`Datasets (${project.datasetCount})`} />
          </Tabs>
          {tabIndex === 0 && (
            <ProjectOverview />
          )}
          {tabIndex === 1 && (
            <DatasetSearch projectContext={project.name} />
          )}
        </div>
      </React.Fragment>
    );

    return <div>{content}</div>;
  }
}

export default ProjectContent;
