import {
  observable,
  action,
  computed,
  autorun,
  makeObservable,
} from 'mobx';

import { IContactUsService } from '@extensions/services/IContactUsService';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import { ISecurityService } from '@extensions/services/ISecurityService';
import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';

const NOTIFICATION_ID = 'contact_us_service';

export default class ContactUsService implements IContactUsService {
  @observable
  private name: string | null = null;
  @observable
  private title: string | null = null;
  @observable
  private feedback: string | null = null;
  @observable
  showModal: boolean = false;

  private securityService: ISecurityService;
  private notificationService: INotificationService;

  constructor(
    securityService: ISecurityService,
    notificationService: INotificationService,
  ) {
    makeObservable(this);
    this.securityService = securityService;
    this.notificationService = notificationService;
    autorun(() => {
      if (this.name && this.title) {
        this.setName(this.name);
        this.setTitle(this.title);
      } else {
        this.setName(null);
        this.setTitle(null);
        this.setFeedback(this.feedback);
      }
    })
  }

  @computed
  get modalTitle(): string {
    if (this.name) {
      return 'Message Contacts';
    }
    if (this.feedback) {
      return 'Feedback';
    }
    return 'Contact Us';
  }

  @computed
  get modalSubTitle(): string | null {
    if (this.name) {
      if (this.title === null) {
        return null;
      }
      return `You will be CC'd on an email to the contacts of ${this.title}. `;
    }

    return `You will be CC'd on an email to our team`;
  }

  @action
  setName = (name: string | null) => this.name = name;
  
  @action
  setTitle = (title: string | null) => this.title = title;

  @action
  setFeedback = (feedback: string | null) => this.feedback = feedback;

  @action
  openModal = (
    name?: string | null | undefined,
    title?: string | null | undefined,
    feedback?: string | null | undefined
  ) => {
    this.name = name || null;
    this.title = title || null;
    this.feedback = feedback || null;
    this.showModal = true;
  };

  @action
  closeModal = () => {
    this.showModal = false;
  };
  
  submitMessage({
    emailAddress,
    subject,
    message,
    showSuccessNotification = true,
  }: {
    emailAddress: string;
    subject: string;
    message: string;
    showSuccessNotification?: boolean;
  }) {
    this.notificationService.addNotification(
      NOTIFICATION_ID,
      Status.Running,
      '',
      ''
    );
    let endpoint = '/api/contact';
    const body = {
      email: emailAddress,
      subject: subject,
      message,
      url: window.location.href,
      //to be filed in later
      g_recaptcha_response: '',
    };
    if (this.name) {
      endpoint += `/${this.name}`;
    }
    return this.securityService.recaptchaPromise
      .then((recaptcha) => {
        return recaptcha.execute('contact');
      })
      .then((token) => {
        body['g_recaptcha_response'] = token;
        return DapApiAgent.agent.post(endpoint).send(body);
      })
      .then(() => {
        let notificationMsg = '';
        let notificationDescription = '';
        if (showSuccessNotification) {
          notificationMsg = 'Thank you for your message!';
          notificationDescription =
            'We will get back to you within two business days.';
        }

        this.notificationService.addNotification(
          NOTIFICATION_ID,
          Status.Success,
          notificationMsg,
          notificationDescription
        );
      })
      .catch((error) => {
        this.notificationService.addNotification(
          NOTIFICATION_ID,
          Status.Error,
          'Failed to send message',
          'Contact us if the issue persists'
        );
      });
  }
}
