import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { IFaqService } from '@dapclient/services/IFaqService';
import FaqSection, { QuestionAndAnswer } from '@dapclient/models/FaqSection';
import QandAnswerAccordion from '@dapclient/components/faq/QandAnswerAccordion';

const PaddedContent = styled('div')(({}));

type TypographyExtraProps = {
  component: React.ElementType;
};

const StyledTitle = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.secondary.main,
  fontSize: theme.typography.h2.fontSize
}));

const SectionContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(5),
  paddingTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({theme})=>({
  input: { 
    color: "#000" 
  }, 
  label: {
    color: theme.palette.text.primary
  } 
}));

export interface IFAQProps
  extends RouteComponentProps {
  faqService: IFaqService;
}
export interface IFAQState {
  searchText: string;
}

@inject('faqService')
@observer
export class FAQ extends React.Component<IFAQProps, IFAQState> {
  constructor(props: IFAQProps) {
    super(props);
    this.state = {
      searchText: '',
    };
  }

  /**
   * Updates state after the user changes their search.
   * @param {object} event The change event on the search box.
   */
  handleSearchTextChange = event => {
    this.setState({
      searchText: event.target.value,
    });
  };

  collapseWhtespace = str => {
    const collapsed = str.trim();
    return collapsed.replace(/\s+/g, ' ');
  };

  roughlyIncludes = (searchString, subString) => {
    const normalize = str => this.collapseWhtespace(str).toLowerCase();
    return normalize(searchString).includes(normalize(subString));
  };

  componentDidMount() {
    this.props.faqService.loadFaqsIfNeeded();
  }

  render() {
    const searchText = this.state.searchText;
    const sectionUi = this.props.faqService.sections.map(
      (section: FaqSection, sectionKey: number) => {
        const sectionTitle = section.label.toUpperCase();
        const questionPanels = section.questionAndAnswers.map(
          (qAndA: QuestionAndAnswer) => {
            const match =
              this.roughlyIncludes(qAndA.answerMarkdown, searchText) ||
              this.roughlyIncludes(qAndA.question, searchText);
            return (
              <QandAnswerAccordion
                qAndA={qAndA}
                searchText={searchText}
                searchmatch={match}
                key={qAndA.id}
              />
            );
          }
        );
        return (
          <SectionContainer key={sectionKey}>
            <StyledTitle
              gutterBottom
              component='div'
            >
              {sectionTitle}
            </StyledTitle>
            <StyledTitle
              variant="body1"
              component='div'
            >
              {questionPanels}
            </StyledTitle>
          </SectionContainer>
        );
      }
    );
    return (
      <PaddedContent>
        <StyledTitle component='div'>
          FREQUENTLY ASKED QUESTIONS
        </StyledTitle>
        <StyledTextField
          label="How can we help you?"
          id="faq-search"
          value={searchText}
          onChange={this.handleSearchTextChange}
          fullWidth={true}
          variant='standard'
          size='small'
        />
        <div>{sectionUi}</div>
      </PaddedContent>
    );
  }
}
export default withRouter(FAQ);
