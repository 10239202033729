import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

import Link from '@dapclient/components/core/Link';

export interface IExpandableTextProps {
  maxCharLength?: number;
  children: string;
  dangerous?: boolean;
}

const StyledLink = styled(Link)(({
  display: 'inline-block',
  verticalAlign: 'baseline',
}));

@observer
class ExpandableText extends React.Component<IExpandableTextProps> {
  static defaultProps = {
    maxCharLength: 400,
  };
  @observable truncatedTextVisible: boolean = false;

  @action
  toggleTruncatedText = () =>
    (this.truncatedTextVisible = !this.truncatedTextVisible);

  constructor(props: IExpandableTextProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    const { maxCharLength, dangerous, children } = this.props;
    const shortenedDescription = children.slice(0, maxCharLength);
    const remainingDescription = children.slice(maxCharLength);
    return (
      <>
        <Typography variant="body1" sx={{ color: theme.palette.grey[900] }}>
          {dangerous && (
            <span dangerouslySetInnerHTML={{
              __html: shortenedDescription,
            }} />
          )}
          {!dangerous && shortenedDescription}
          {remainingDescription && !this.truncatedTextVisible
            ? '...'
            : (
              dangerous ? (
                <span dangerouslySetInnerHTML={{
                  __html: remainingDescription,
                }} />
              ) : remainingDescription
            )}
          {remainingDescription && (
            <>
              {' '}
              <StyledLink
                color="secondary"
                variant="body1"
                onClick={this.toggleTruncatedText}
              >
                {this.truncatedTextVisible ? 'show less' : 'show more'}
              </StyledLink>
            </>
          )}
        </Typography>
      </>
    );
  }
}

export default ExpandableText;
