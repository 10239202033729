import React from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface ICardExpansionPanelProps {
  detailsId: string;
  detailsContent: React.ReactNode;
  summaryContent: React.ReactNode;
}

class CardExpansionPanel extends React.Component<ICardExpansionPanelProps> {
  render() {
    const { detailsId, detailsContent, summaryContent } = this.props;
    return (
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="small" />}
          aria-controls={detailsId}
          sx={{
            '& .MuiAccordionSummary-expandIconWrapper': {
              marginRight: (theme) => theme.spacing(2),
            },
          }}
        >
          {summaryContent}
        </AccordionSummary>
        <AccordionDetails id={detailsId} sx={{ flexWrap: 'wrap' }}>
          {detailsContent}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default CardExpansionPanel;
