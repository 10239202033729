import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StyleWrapperDiv = styled('div')(({
    height: '560'
}));

export interface IDataPlotProps {
    className?: string;
    data?: any[];
    sensorID?: number;
}

export interface IDataPlotState { }

class DataPlot extends React.Component<
    IDataPlotProps,
    IDataPlotState
> {

    plotRender = (data: any[], sensorID: number) => {
        if (data && data.length > 0) {
            return (
                <StyleWrapperDiv>
                    <Typography
                        variant="body1"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        {`Sensor ID: ${sensorID}`}
                    </Typography>
                    <ResponsiveContainer width="100%" height={400} debounce={2}>
                        <LineChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 0,
                                left: 0,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" interval="preserveStartEnd" />
                            <Tooltip />
                            {this.renderLines(sensorID, data)}
                            <Legend layout="horizontal" verticalAlign="bottom" align="center" wrapperStyle={{ bottom: -1 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </StyleWrapperDiv >
            );
        }
    }

    singleAxisRender = (data: any[]) => {
        const lines = Object.keys(data[0]).filter((dk) => dk !== "date")
            .map((key, i) => <Line type="monotone" dataKey={key} stroke={this.randomLineStrokeGen()} dot={false} key={i} />)
        lines.push(<YAxis key={"single-yaxis"} />);
        return lines;
    }

    dualAxisRender = () => {
        return (
            <>
                <YAxis yAxisId="Humidity" />
                <YAxis yAxisId="WetBulb_Fahrenheit" orientation="right" />
                <Line type="monotone"
                    yAxisId="Humidity"
                    dataKey={"Humidity"}
                    stroke={this.randomLineStrokeGen()}
                    dot={false}
                    key={"Humidity"} />
                <Line type="monotone"
                    yAxisId="WetBulb_Fahrenheit"
                    dataKey={"WetBulb_Fahrenheit"}
                    stroke={this.randomLineStrokeGen()}
                    dot={false}
                    key={"WetBulb_Fahrenheit"} />
            </>
        )
    }

    renderLines = (sensorID: number, data: any[]) => {
        /* Hard coded sensorID for different plotting pattern, 
         will update it once we have clear instructions of which 
         sensor ID needs its own plotting logic 
        */
        if (sensorID === 656355) {
            return this.dualAxisRender()
        }
        return this.singleAxisRender(data)
    }

    randomLineStrokeGen = () => "#" + ((1 << 24) * Math.random() | 0).toString(16);

    render() {
        const { data, sensorID } = this.props;
        if (data && data.length > 0 && sensorID) {
            return (
                this.plotRender(data, sensorID)
            )
        }
        return (
            <Typography variant="body1"
                sx={{
                    mt: '1rem',
                    mb: '1rem'
                }}
            >
                No available dataset files to view.
            </Typography>
        )
    }
}
export default DataPlot;
