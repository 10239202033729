import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ICachingService } from '@extensions/services/ICachingService';
import { INotificationService, Status } from '@dapclient/services/INotificationService';

import Link from '@extensions/components/core/Link';

const StyledRootDiv = styled('div')(({
  paddingTop: '20px !important',
  paddingBottom: '50px !important',
  textAlign: 'left',
  '& > textarea': {
    width: '100%',
    height: 'auto',
    minHeight: '80px'
  }
}));

const SiteDOITypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.43,
}));

export interface IAcknowledgeProps {
  className?: string;
  notificationService?: INotificationService;
  cachingService?: ICachingService;
}

export interface IAcknowledgeState {
  copied: boolean;
}

@observer
export class Acknowledge extends React.Component<
  IAcknowledgeProps,
  IAcknowledgeState
> {

  @observable
  siteDoi: string | null | undefined;
  textarea: any;

  constructor(props) {
    super(props);
    this.state = {
      copied: false
    }
    makeObservable(this);
  }

  componentDidMount() {
    this.fetchSiteDoi();
  }

  fetchSiteDoi = async () => {
    const { cachingService } = this.props;
    try {
      const siteDoi = await cachingService?.getSiteDoi();
      runInAction(() => (this.siteDoi = siteDoi));
    } catch (error: any) {
      console.log('error when fetching site DOI.');
    }
  };

  componentDidUpdate() {
    if (this.state.copied) {
      this.props.notificationService?.addNotification(
        'copy',
        Status.Success,
        'Copied to clipboard!',
        '',
        true
      )
    }
  }

  handleClick = (e) => {
    e.target.select();
    this.textarea.select();
    document.execCommand('copy');
    this.setState({
      copied: true
    })
  };

  renderSiteDOI = () => {
    return (
      this.siteDoi &&
      <SiteDOITypography variant="body1">
        Site DOI: <Link to={`https://www.osti.gov/search/semantic:${this.siteDoi}`}>{this.siteDoi}</Link>
      </SiteDOITypography>
    )
  }

  renderTextArea = () => {
    return (
      <>
        <Typography variant="h1" gutterBottom>
          Acknowledge
        </Typography>
        <hr></hr>
        <Typography variant="subtitle1" gutterBottom>
          To acknowledge general use of Livewire, select:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Click to copy to clipboard:
        </Typography>
        <textarea
          ref={(textarea) => this.textarea = textarea}
          readOnly
          onClick={this.handleClick}
          value="Data were obtained from Livewire Data Platform funded
          by U.S. Department of Energy, Office of Energy Efficiency and
          Renewable Energy's Vehicle Technologies Office operated and
          maintained by Pacific Northwest National Laboratory, Idaho
          National Laboratory and National Renewable Energy at
          https://livewire.energy.gov." />
      </>
    )
  }

  render() {
    return (
      <StyledRootDiv>
        {this.renderTextArea()}
        {this.renderSiteDOI()}
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  cachingService: store.cachingService,
  notificationService: store.notificationService,
}))(Acknowledge);
