import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import LicenseLogo from '@extensions/resources/cc-zero.png';

const StyledRootDiv = styled('div')(() => ({
  paddingTop: '20px !important',
  paddingBottom: '50px !important',
  textAlign: 'left',
}));

const StyledTextDiv = styled('div')(() => ({
  textAlign: 'center',
}));

const StyledTextSpan = styled('span')(() => ({
  fontWeight: 'bold',
}));

const StyledLabTitle = styled('h2')(() => ({
  fontWeight: 'bold',
  fontSize: '1rem',
  marginBottom: '0em',
  color: 'rgba(0, 0, 0, 0.65)',
}));

const StyledImgSpan = styled('span')(() => ({
  float: 'right',
}));

const StyledLicenseLogo = styled('img')(() => ({
  height: '50px',
  marginTop: '-25px',
}));

export interface ILicenseProps {
  className?: string;
}

class License extends React.Component<ILicenseProps> {
  render() {
    const className = this.props;
    return (
      <StyledRootDiv>
        <StyledImgSpan>
          <StyledLicenseLogo
            src={LicenseLogo}
            alt="license-logo"
          />
        </StyledImgSpan>
        <Typography variant="h1" gutterBottom>
          License
        </Typography>
        <hr></hr>
        <p>
          <a href="https://creativecommons.org/publicdomain/zero/1.0/legalcode" style={{textDecoration: 'none'}}>
            Creative Commons Public Domain Dedication
          </a>
        </p>
        <p>
          This license is one of the open Creative Commons licenses and is like
          a public domain dedication. It allows you, as a dataset owner, to use
          a license mechanism to surrender your rights in a dataset when you
          might not otherwise be able to dedicate your dataset to the public
          domain under applicable law..
        </p>
        <Typography variant="h2" gutterBottom>
          Disclaimer
        </Typography>
        <p>
          This material was prepared as an account of work sponsored by an
          agency of the United States Government. Neither the United States
          Government nor the United States Department of Energy, nor the
          Contractor, nor any or their employees, nor any jurisdiction or
          organization that has cooperated in the development of these
          materials, makes any warranty, express or implied, or assumes any
          legal liability or responsibility for the accuracy, completeness, or
          usefulness or any information, apparatus, product, software, or
          process disclosed, or represents that its use would not infringe
          privately owned rights.
        </p>
        <p>
          Reference herein to any specific commercial product, process, or
          service by trade name, trademark, manufacturer, or otherwise does not
          necessarily constitute or imply its endorsement, recommendation, or
          favoring by the United States Government or any agency thereof, or
          Battelle Memorial Institute. The views and opinions of authors
          expressed herein do not necessarily state or reflect those of the
          United States Government or any agency thereof.
        </p>
        <hr></hr>
        <br></br>
        <StyledTextDiv>
          <StyledLabTitle>
            PACIFIC NORTHWEST NATIONAL LABORATORY
          </StyledLabTitle>
          <em>operated by</em>
          <br />
          <StyledTextSpan>BATTELLE</StyledTextSpan>
          <br />
          <em>for the</em>
          <br />
          <StyledTextSpan>
            UNITED STATES DEPARTMENT OF ENERGY
          </StyledTextSpan>
          <br />
          <em>under Contract DE-AC05-76RL01830</em>
          <br />
        </StyledTextDiv>
      </StyledRootDiv>
    );
  }
}

export default License;
