import User from '@extensions/models/User';
import { IHistoryService } from '@dapclient/services/IHistoryService';
import {
  IDs,
  INotificationService,
  Description,
  Status,
} from '@dapclient/services/INotificationService';
import { ISecurityService } from '@dapclient/services/ISecurityService';
import config from '@extensions/utils/ConfigUtil';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import ITokenService from '@extensions/services/ITokenService';
import {
  action,
  observable,
  reaction,
  computed,
  runInAction,
  autorun,
  makeObservable,
} from 'mobx';
import qs from 'query-string';
import * as superagent from 'superagent';
import moment from 'moment';
import endsWith from 'lodash/endsWith';
import find from 'lodash/find';
import { load } from 'recaptcha-v3';
import * as Sentry from '@sentry/react';

export default class SecurityService implements ISecurityService {
  @computed
  get userIsLoggedIn() {
    return !!(this.user && this.user.authenticated);
  }
  @observable user: User | null = null;
  @observable callbackUrl: string | null = null;
  @observable autoLoginDone: boolean = false;
  historyService: IHistoryService;
  notificationService: INotificationService;
  tokenService: ITokenService;
  recaptchaPromise: ReturnType<typeof load>;
  @observable private onLogoutListeners: Array<() => void> = [];
  @observable private onLoginListeners: Array<() => void> = [];

  constructor(
    historyService: IHistoryService,
    notificationService: INotificationService,
    tokenService: ITokenService
  ) {
    makeObservable(this);
    this.historyService = historyService;
    this.notificationService = notificationService;
    this.tokenService = tokenService;
    this.recaptchaPromise = load(this.getRecaptchaSiteKey());

    // observe changes to notifications, if user's session times out or lambda
    // token expires, log them out
    // SJB: changed this from observe() to reaction() per docs recommendation
    reaction(
      () =>
        find(
          this.notificationService.notifications,
          (n) => n.id === IDs.USER_TOKEN_EXPIRED
        ) !== undefined,
      (tokenExpired: boolean) => {
        if (tokenExpired) {
          this.logout();
        }
      }
    );
    autorun(() => {
      if (this.user) {
        Sentry.setUser({
          username: this.user.username,
          email: this.user.email,
          emailVerified: this.user.emailVerified,
          approved: this.user.approved,
        });
      } else {
        Sentry.setUser(null);
      }
    });
    this.autoLogin();
  }

  requireLogin(redirect: string | null) {
    this.historyService.setRedirectPath(redirect || window.location.pathname);
    this.historyService.redirect('/signIn');
  }

  @action
  addLogoutListener = (listener: () => void): void => {
    this.onLogoutListeners.push(listener);
  };

  @action
  removeLogoutListener = (listenerToRemove: () => void): void => {
    this.onLogoutListeners = this.onLogoutListeners.filter(
      (listener) => listener !== listenerToRemove
    );
  };

  @action
  addLoginListener = (listener: () => void): void => {
    this.onLoginListeners.push(listener);
  };

  @action
  removeLoginListener = (listenerToRemove: () => void): void => {
    this.onLoginListeners = this.onLoginListeners.filter(
      (listener) => listener !== listenerToRemove
    );
  };

  @action async resendEmailAction(email: string) {
    const url = `/api/email/resend`;

    try {
      this.notificationService.addNotification(
        'resendEmail',
        Status.Running,
        '',
        ''
      );
      await DapApiAgent.agent.get(url);

      this.notificationService.addNotification(
        'resendEmail',
        Status.Success,
        'Verification email resent.',
        'Look in your inbox for an email with a verify email address button.'
      );
    } catch (error) {
      this.notificationService.addNotification(
        'resendEmail',
        Status.Error,
        'Failed to send resend verify email request',
        error
      );
    }
  }

  @action async forgotPassword(email: string) {
    const payload = { email: email.toLowerCase() };
    try {
      this.notificationService.addNotification(
        'forgotPassword',
        Status.Running,
        '',
        ''
      );
      await DapApiAgent.agent.post('/api/password/email').send(payload);
      this.notificationService.addNotification(
        'forgotPassword',
        Status.Success,
        'Forgot password email sent.',
        'Look in your inbox for an email with a reset button.'
      );
    } catch (error) {
      this.notificationService.addNotification(
        'forgotPassword',
        Status.Error,
        'Failed to send reset password request.',
        error
      );
    }
  }

  @action async login(email: string, password: string) {
    await this.loginWithMfa(email, password, null);
  }

  @action async loginWithMfa(email: string, password: string, mfaCode: number | null) {
    this.notificationService.addNotification(
      IDs.USER_LOGGED_IN,
      Status.Running,
      '',
      ''
    );
    // remove the notice about needing to log in if there is one:
    this.notificationService.removeNotification(IDs.USER_TOKEN_EXPIRED);
    let payload: any = { email, password };
    if (mfaCode) {
      payload = {
        email,
        password,
        code: mfaCode,
      };
    }

    try {
      email = email.toLowerCase();
      const response = await DapApiAgent.agent.post('/api/login').send(payload);
      const responseData = JSON.parse(response.text);
      // Wrap in a mobx transaction to not update observers until the whole block is complete
      runInAction(() => {
        this.tokenService.dapToken = responseData.csrf_token;
        this.tokenService.lambdaToken = responseData.api_cert;
        this.tokenService.rawLambdaExpires = responseData.api_cert_expires;
        this.tokenService.rawLambdaRenews = responseData.api_cert_renews;
        this.startRenewTokenTimer();
        this.updateAuthenticatedUser(response.body);
        this.onLoginListeners.forEach((func) => func());
        this.notificationService.addNotification(
          IDs.USER_LOGGED_IN,
          Status.Success,
          '',
          ''
        );
      });
    } catch (error: any) {
      let description = error;
      // check for expected error cases and add a more user friendly description
      if (
        error.response &&
        error.response.text &&
        error.response.text.startsWith(
          '{"message":"The given data was invalid.'
        )
      ) {
        description = Description.FailedLoginAttempt;
      }
      this.notificationService.addNotification(
        IDs.USER_LOGGED_IN,
        Status.Error,
        'Login failed',
        description
      );
    }
  }

  startRenewTokenTimer() {
    if (
      this.tokenService.lambdaToken &&
      this.tokenService.lambdaExpires &&
      this.tokenService.lambdaRenews
    ) {
      // start a timer to automatically renew the lambda token shortly before (1 min) it times out:
      const now = moment().unix();
      const renewAtSeconds = this.tokenService.lambdaRenews - now - 60;

      setTimeout(() => {
        if (
          this.tokenService.lambdaToken &&
          this.tokenService.lambdaExpires &&
          this.tokenService.lambdaRenews
        ) {
          //this keeps the cookie alive, or we could the server config to keep cookies alive longer
          this.tokenService.refreshDapToken();

          superagent
            .put(
              `${config.getConfig().lambdaApi}/creds?cert=${
                this.tokenService.lambdaToken
              }&action=renew`
            )
            .set('Accept', 'application/json')
            .then((res) => {
              const certData = JSON.parse(res.text);
              runInAction(
                () => (this.tokenService.rawLambdaRenews = certData.renews)
              );
              this.startRenewTokenTimer();
            })
            .catch((err) => {
              console.log('error when trying to renew lambda token');
              // if we catch an error trying to renew the cert, log the user out
              this.notificationService.addNotification(
                IDs.USER_TOKEN_EXPIRED,
                Status.Warn,
                'Session Expired',
                'Your session has expired and you were logged out.'
              );
            });
        }
      }, renewAtSeconds * 1000);
    }
  }

  @action async autoLogin() {
    const notificationId = 'AUTO_LOGIN';
    this.notificationService!.addNotification(
      notificationId,
      Status.Running,
      '',
      ''
    );
    try {
      if (this.tokenService.dapToken) {
        // for autologin, don't use the dap api agent because we want to
        // ignore 401 (Unauthorized) because that just means we can't autologin
        const response = await superagent
          .get('/api/user')
          .accept('json')
          .set('X-Requested-With', 'XMLHttpRequest')
          .set('x-csrf-token', this.tokenService.dapToken);

        // If we've gotten this far the user is authenticated with the
        // PHP server. If we don't have a Lambda cert, then that means the user
        // is not authenticated with the Lambda API. The only way to deal
        // with that inconsistency is to log the user out :(
        if (!this.tokenService.lambdaToken) {
          await this.logout();
        } else {
          // Wrap in a mobx transaction to not update observers until the whole block is complete
          runInAction(() => {
            this.updateAuthenticatedUser(response.body);
            this.startRenewTokenTimer();
          });
          await this.callbackIfNeeded();
        }
      } else {
        this.tokenService.clearLambdaTokens();
        await this.tokenService.refreshDapToken();
      }
    } catch (error) {
      // if autologin fails, its not considered an error & we don't need to let the user know so don't use notification service to add an error.
      // but if autologin fails it likely means any cached tokens are no longer valid, so remove them:
      this.tokenService.clearLambdaTokens();
      await this.tokenService.refreshDapToken();
    } finally {
      runInAction(() => (this.autoLoginDone = true));
      this.notificationService.addNotification(
        notificationId,
        Status.Idle,
        '',
        ''
      );
    }
  }

  @action logout = async () => {
    const logoutSuccessful = async () => {
      this.notificationService.addNotification(
        IDs.USER_LOGGED_OUT,
        Status.Success,
        '',
        ''
      );
      this.setUser(User.getGuestUser());
      this.setCallbackUrl(null);
      this.tokenService.clearLambdaTokens(); // clear cached tokens
      await this.tokenService.refreshDapToken();
    };

    try {
      for (const listener of this.onLogoutListeners) {
        listener();
      }

      // await DapApiAgent.agent.post('/api/logout');
      // for logout, don't use the dap api agent because we want to
      // ignore 401 (Unauthorized) and 419 errors because that means
      // server had already deleted the user's session and so getting token mismatches
      if (this.tokenService.dapToken) {
        await superagent
          .post('/api/logout')
          .accept('json')
          .set('X-Requested-With', 'XMLHttpRequest')
          .set('x-csrf-token', this.tokenService.dapToken);
      }

      runInAction(() => {
        logoutSuccessful();
      });
    } catch (error: any) {
      // ignore 419 and 401 errors, just means the server had already deleted
      // the user's session and so getting token mismatches
      if (error.status !== 419 && error.status !== 401) {
        this.notificationService.addNotification(
          IDs.USER_LOGGED_OUT,
          Status.Error,
          'Failed to log out',
          error
        );
      } else {
        runInAction(() => {
          logoutSuccessful();
        });
      }
    }
  };

  @action async generateMfa(password: string) {
    this.notificationService.addNotification(
      'generateMfa',
      Status.Running,
      '',
      ''
    );
    const payload = {
      password,
    };
    try {
      const response = await DapApiAgent.agent
        .post('/api/mfa/generate')
        .send(payload);
      const responseData = JSON.parse(response.text);
      // Wrap in a mobx transaction to not update observers until the whole block is complete
      runInAction(() => {
        this.notificationService.addNotification(
          'generateMfa',
          Status.Success,
          '',
          ''
        );
        if (this.user) {
          this.user.setMfaAccessKey(responseData.accessKey);
          this.user.setMfaToken(responseData.token);
          this.user.setMfaQRCode(responseData.qr);
        }
      });
    } catch (error) {
      this.notificationService.addNotification(
        'generateMfa',
        Status.Error,
        'Failed to enable mfa',
        error
      );
    }
  }

  @action async validateMfa(code: number) {
    this.notificationService.addNotification(
      'validateMfa',
      Status.Running,
      '',
      ''
    );
    if (this.user) {
      const payload = {
        accessKey: this.user.mfaAccessKey,
        code,
      };
      try {
        await DapApiAgent.agent.post('/api/mfa/validate').send(payload);
        runInAction(() => {
          this.notificationService.addNotification(
            'validateMfa',
            Status.Success,
            'MFA Token Validated.',
            'Multi-factor Authentication set up was successful.'
          );
          if (this.user) {
            this.user.setMfaNew(true);
            this.user.setMfaEnabled(true);
            this.user.setMfaAccessKey('');
            this.user.setMfaToken('');
            this.user.setMfaQRCode('');
          }
        });
      } catch (error) {
        this.notificationService.addNotification(
          'generateMfa',
          Status.Error,
          'Failed to enable mfa',
          error
        );
      }
    }
  }

  @action async register(formValues) {
    const url = `/api/register`;
    this.notificationService.addNotification(
      'register',
      Status.Running,
      '',
      ''
    );

    const recaptcha = await this.recaptchaPromise;
    const token = await recaptcha.execute('register');

    const payload = {
      name_given: formValues.name_given,
      name_family: formValues.name_family,
      orcId: formValues.orcId,
      email: formValues.email.toLowerCase(),
      password: formValues.password,
      password_confirmation: formValues.confirm,
      agreed_to_terms: true,
      justification: formValues.justification,
      g_recaptcha_response: token,
    };

    try {
      await DapApiAgent.agent.post(url).send(payload);

      // Wrap in a mobx transaction to not update observers until the whole block is complete
      runInAction(() => {
        this.notificationService.addNotification(
          'register',
          Status.Success,
          '',
          ''
        );
        this.login(
          formValues.email.toLowerCase(),
          formValues.password,
        );
      });
    } catch (error: any) {
      let description = error;
      // check for expected error cases and add a more user friendly description
      if (
        error.response &&
        error.response.text &&
        endsWith(
          error.response.text,
          'This email address has already been registered"]}}'
        )
      ) {
        description = Description.EmailAlreadyUsed;
      }
      this.notificationService.addNotification(
        'register',
        Status.Error,
        'Account already exists.',
        description
      );
    }
  }

  @action checkForCallback(routeQueryString: string): boolean {
    const redirect: string | (string | null)[] | null | undefined =
      qs.parse(routeQueryString).redirect;
    if (redirect) {
      if (Array.isArray(redirect)) {
        this.setCallbackUrl(redirect[0]);
      } else {
        this.setCallbackUrl(redirect);
      }
      return true;
    }
    return false;
  }

  @action async callbackIfNeeded() {
    if (this.user && this.user.authenticated && this.callbackUrl) {
      try {
        //test if the callbackUrl is an back end API route that the UI needs to call to let the user know something
        //otherwise the UI should just do a url rewrite:
        if (this.callbackUrl.indexOf('/api/user/approve') !== -1) {
          const response = await DapApiAgent.agent.get(this.callbackUrl);
          const payload = JSON.parse(response.text);

          runInAction(() => {
            this.notificationService.addNotification(
              IDs.USER_APPROVED,
              Status.Info,
              'Success',
              `New user account for ${payload.email} approved.`
            );
            this.setCallbackUrl(null);
          });
        } else if (this.callbackUrl.indexOf('/email/verified') !== -1) {
          runInAction(() => {
            if (this.user) {
              this.user.setEmailVerified(true);
            }
            this.notificationService.addNotification(
              'Email verified',
              Status.Info,
              'Success',
              `Your email was successfully verified.`
            );
            this.setCallbackUrl(null);
          });
        } else {
          window.location.href = this.callbackUrl;
          this.setCallbackUrl(null);
        }
      } catch (error) {
        // assuming if user is approved, she was just trying to approve someone else, otherwise she was verifying her email
        const message = this.user.approved
          ? 'Error approving user'
          : 'Error verifying email';
        this.notificationService.addNotification(
          'redirect',
          Status.Error,
          message,
          error
        );
      }
    }
  }

  @action async updateProfile(values) {
    const url = `/api/user/profile`;

    try {
      const response = await DapApiAgent.agent.post(url).send(values);

      // Wrap in a mobx transaction to not update observers until the whole block is complete
      runInAction(() => {
        this.updateAuthenticatedUser(response.body);

        this.notificationService.addNotification(
          'profile',
          Status.Success,
          'Success',
          'Your profile has been updated.'
        );
      });
    } catch (error) {
      this.notificationService.addNotification(
        'profile',
        Status.Error,
        'Failed to update profile',
        error
      );
    }
  }

  @action async resetPassword(
    email: string,
    password: string,
    passwordConfirm: string,
    resetToken: string
  ) {
    const url = `/api/password/reset`;
    const payload = {
      email: email.toLowerCase(),
      token: resetToken,
      password,
      password_confirmation: passwordConfirm,
    };

    try {
      await DapApiAgent.agent
        .post(url) // http method
        .send(payload);

      this.notificationService.addNotification(
        'resetPassword',
        Status.Success,
        'Success',
        'Password reset successfully'
      );
      this.login(email,
        password,
      );
    } catch (error) {
      this.notificationService.addNotification(
        'resetPassword',
        Status.Error,
        'Failed to reset password',
        error
      );
    }
  }

  @action updateAuthenticatedUser(userData: any): void {
    const user = new User(userData);
    user.setAuthenticated(true);
    this.setUser(user);
    this.callbackIfNeeded();
  }

  @action setUser(user: User | null): void {
    this.user = user;
  }

  @action setCallbackUrl(callbackUrl: string | null): void {
    this.callbackUrl = callbackUrl;
  }

  getRecaptchaSiteKey(): string {
    return process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';
  }
}
