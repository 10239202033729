import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { Typography, Chip } from '@mui/material';

const StyledRootDiv = styled('div')(({
  marginTop: '1rem',
  marginBottom: '20px',
}));

const StyledChip = styled(Chip)(({
  marginBottom: '7px',
  marginRight: '5px',
}));


export interface IKeywordsListProps {
  className?: string;
  keywords: string[];
}

export interface IKeywordsListState { }

@observer
export class KeywordsList extends React.Component<
  IKeywordsListProps,
  IKeywordsListState
> {
  render() {
    const { keywords } = this.props;
    let body: React.ReactNode;
    if (keywords.length === 0) {
      body = <Typography>No Keywords Provided</Typography>;
    } else {
      body = keywords.map((keyword) => (
        <StyledChip
          key={keyword}
          label={keyword}
          variant="outlined"
          size="small"
          sx={{
            '& .MuiChip-label': {
              color: (theme) => theme.palette.grey[900],
            }
          }}
        />
      ))
    }
    return (
      <StyledRootDiv>
        <Typography variant="h2">Keywords</Typography>
        {body}
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(KeywordsList);
