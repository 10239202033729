import React from 'react';
import { inject, observer } from 'mobx-react';

import { Typography } from '@mui/material';

export interface IPublicationNotFoundProps {
  className?: string;
}

export interface IPublicationNotFoundState { }

@observer
export class PublicationNotFound extends React.Component<
  IPublicationNotFoundProps,
  IPublicationNotFoundState
> {
  render() {
    return (
      <div>
        <Typography variant="h1">Not Found</Typography>
        <Typography>
          We could not find this publication in our database. If you believe
          this is an error, please contact us.
        </Typography>
      </div>
    );
  }
}

export default inject((store: any) => ({
  // TODO: Put any services you need here
  // EXAMPLE => metricsService: store.metricsService
}))(PublicationNotFound);
