import React from 'react';
import { inject } from 'mobx-react';
import { Footer as DapFooter } from '@dapclient/components/nav/Footer';

class Footer extends DapFooter {
  getTopText = () =>
    "Benchmark Datasets is a resource of the U.S. Department of Energy's Building Technologies Office.";
  getLinks = (): React.AnchorHTMLAttributes<{}>[] => [
    {
      children: 'Contact Us',
      onClick: () =>
        this.props.contactUsService && this.props.contactUsService.openModal(),
    },
    {
      children: 'Acknowledge Benchmark Datasets for Buildings',
      href: '/acknowledge',
    },
    {
      children: <>Office of Energy Efficiency &amp; Renewable Energy</>,
      href: 'https://www.energy.gov/eere/office-energy-efficiency-renewable-energy',
    },
    {
      children: 'Web Policies',
      href: 'https://www.energy.gov/about-us/web-policies',
    },
    {
      children: 'Privacy',
      href: 'https://www.energy.gov/about-us/web-policies/privacy',
    },
  ];
}

export default inject((store: any) => ({
  contactUsService: store.contactUsService,
}))((Footer));
