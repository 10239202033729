import 'react-dates/initialize';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import Tracker from '@dapclient/components/core/Tracker';
import * as serviceWorker from '@dapclient/services/serviceWorker';

import App from '@extensions/components/App';
import theme from '@extensions/services/Theme';
import config from '@extensions/utils/ConfigUtil';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import LambdaApiAgent from '@extensions/utils/LambdaApiAgent';
import ContactModal from '@extensions/components/core/ContactModal';
import GlobalFallback from '@extensions/components/core/GlobalFallback';

import AppService from '@extensions/services/AppService';
import LayoutService from '@extensions/services/LayoutService';
import HistoryService from '@extensions/services/HistoryService';
import SecurityService from '@extensions/services/SecurityService';
import MetricsService from '@extensions/services/MetricsService';
import NotificationService from '@extensions/services/NotificationService';
import DatasetService from '@extensions/services/DatasetService';
import ProjectService from '@extensions/services/ProjectService';
import FaqService from '@dapclient/services/FaqService';
import CartService from '@extensions/services/CartService';
import MetadataService from '@extensions/services/MetadataService';
import MembershipService from '@extensions/services/MembershipService';
import TokenService from '@extensions/services/TokenService';
import ContactUsService from '@extensions/services/ContactUsService';
import CachingService from '@extensions/services/CachingService';
import UploadService from '@extensions/services/UploadService';
import { IAppService } from '@extensions/services/IAppService';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { INotificationService } from '@extensions/services/INotificationService';
import { ITokenService } from '@extensions/services/ITokenService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { ICartService } from '@extensions/services/ICartService';
import { IContactUsService } from '@extensions/services/IContactUsService';
import { IMetadataService } from '@extensions/services/IMetadataService';
import { IMetricsService } from '@extensions/services/IMetricsService';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { IProjectService } from '@extensions/services/IProjectService';
import { IMembershipService } from '@extensions/services/IMembershipService';
import { IFaqService } from '@extensions/services/IFaqService';
import { ICachingService } from '@extensions/services/ICachingService';
import { IUploadService } from '@extensions/services/IUploadService';
import ILayoutService from '@extensions/services/ILayoutService';
import PublicationService from '@extensions/services/PublicationService';
import { IPublicationService } from '@extensions/services/IPublicationService';
import NewsletterService from '@extensions/services/NewsletterService';
import { INewsletterService } from '@extensions/services/INewsletterService';

type Services = {
  appService: IAppService;
  layoutService: ILayoutService;
  notificationService: INotificationService;
  tokenService: ITokenService;
  historyService: IHistoryService;
  securityService: ISecurityService;
  cartService: ICartService;
  contactUsService: IContactUsService;
  metadataService: IMetadataService;
  metricsService: IMetricsService;
  datasetService: IDatasetService;
  projectService: IProjectService;
  membershipService: IMembershipService;
  faqService: IFaqService;
  cachingService: ICachingService;
  uploadService: IUploadService;
  publicationService: IPublicationService;
  newsletterService: INewsletterService;
};

declare global {
  interface Window {
    App: any;
    gapi: any;
  }
}

export const configureServices = (
  appService: IAppService | undefined = undefined
): Services => {
  const historyService = new HistoryService();
  const tokenService = new TokenService();
  const notificationService = new NotificationService();

  DapApiAgent.initialize(notificationService, tokenService);
  LambdaApiAgent.initialize(notificationService, tokenService);

  const securityService = new SecurityService(
    historyService,
    notificationService,
    tokenService
  );

  const metadataService = new MetadataService(
    notificationService,
    securityService,
    historyService
  );

  const cachingService = new CachingService(
    notificationService,
    securityService
  );

  const datasetService = new DatasetService(
    notificationService,
    cachingService,
    securityService
  );

  const projectService = new ProjectService(
    notificationService,
    cachingService,
    securityService
  );

  const contactUsService = new ContactUsService(
    securityService,
    notificationService,
  );

  const cartService = new CartService(
    notificationService,
    securityService,
    contactUsService,
    cachingService,
    historyService
  );

  // Services that will be provided to components
  return {
    appService: appService || new AppService(false),
    layoutService: new LayoutService(),
    notificationService,
    tokenService,
    historyService,
    securityService,
    cartService,
    contactUsService,
    metadataService,
    metricsService: new MetricsService(notificationService),
    newsletterService: new NewsletterService(notificationService),
    datasetService,
    projectService,
    membershipService: new MembershipService(
      notificationService,
      securityService
    ),
    faqService: new FaqService(notificationService),
    cachingService,
    uploadService: new UploadService(notificationService, securityService),
    publicationService: new PublicationService(
      notificationService,
      historyService,
      securityService
    ),
  };
};

export const bootstrap = (services: Services) => {
  const sentryDsn = config.getConfig().sentryDsn;
  if (sentryDsn) {
    Sentry.init({ dsn: sentryDsn });
  }

  // the typing module uses typescript module augmentation to update the
  // material theme type
  configure({
    enforceActions: 'observed',
  });

  // Adding services to the global namespace for easy debugging
  window.App = window.App || {};
  window.App.services = services;

  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={<GlobalFallback />} showDialog>
      <Provider {...services}>
        <ThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <ConfirmProvider>
              <Router history={services.historyService.history}>
                <Tracker />
                <App />
                <ContactModal />
              </Router>
            </ConfirmProvider>
          </EmotionThemeProvider>
        </ThemeProvider>
      </Provider>
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
};
