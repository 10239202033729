import React from 'react';
import { inject, observer } from 'mobx-react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  TextField,
  Autocomplete
} from '@mui/material';

import { PublicationFormData } from '@extensions/models/PublicationFormData';

export interface IKeywordsFieldProps {
  className?: string;
  control: UseFormReturn<PublicationFormData>['control'];
}

export interface IKeywordsFieldState { }

@observer
export class KeywordsField extends React.Component<
  IKeywordsFieldProps,
  IKeywordsFieldState
> {
  render() {
    const { control } = this.props;
    return (
      <Controller
        name="keywords"
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={field.value}
              onChange={(event, value) => field.onChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={field.onBlur}
                  label="Keywords (Please press enter after typing)"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          );
        }}
      />
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(KeywordsField);
