import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';

import busGraphic from '@extensions/resources/Bus Stop.png';
import houseGraphic from '@extensions/resources/House.png';
import trainGraphic from '@extensions/resources/Train Station.png';

const PaddedContent = styled.div`
  text-align: left;
  h1,
  h2,
  h3 {
    text-transform: uppercase;
  }
`;

const VehicleImage = styled.img`
  float: right;
  width: 51%;
  height: auto;
`;

const SectionDiv = styled.div`
  padding-bottom: 25px;
  font-size: 14px;
  p {
    color: rgba(0,0,0,.65);
  }
`;

export interface IAboutProps { }

const About: React.SFC<IAboutProps> = () => {
  return (
    <PaddedContent>
      <Grid container>
        <SectionDiv>
        <Typography sx={{ mb: 2, fontWeight: 'normal', mt: 0.625 }} variant='h2' id='livewire'>
          What is the Livewire Data Platform?
        </Typography>
          <Typography variant='body1'>
            The Livewire Data Platform collects, preserves, curates, and disseminates a catalog of transportation
            and mobility-related projects. It allows transportation researchers, industry, and academic partners
            to increase the visibility of their projects within the research community, securely share and preserve
            data, and leverage datasets from other projects. The Livewire Data Platform supports the U.S. Department
            of Energy’s (DOE) Office of Energy Efficiency and Renewable Energy’s (EERE) goal of providing an affordable,
            efficient, safe, and accessible transportation future where mobility is decoupled from energy consumption.
          </Typography>

          <VehicleImage src={busGraphic} alt="bus" />
          <Typography sx={{ mt: 2 }} variant='body1'>
            The Livewire Data Platform functions as a repository for data
            and supports the following objectives:
          </Typography>
          <ol>
            <li>
              <Typography variant='body1'>
                Users can catalog, document, and share project datasets via file-based sharing or Application Programming
                Interface (API) and can provide access management and usage auditing for those resources.
                Users can securely search, discover, and access shared datasets, their metadata, and available APIs.
              </Typography>
            </li>
          </ol>
        </SectionDiv>
        <SectionDiv>
          <Typography sx={{ fontWeight: 'normal', mb: 2 }} variant='h2' id="type">What types of data are there?</Typography>
          <VehicleImage src={trainGraphic} alt="train" />
          <Typography variant='body1'>
            The Livewire Data Platform includes behavioral, experimental,
            modeled, analytical, and raw data at the vehicle, traveler, and
            system level to support research efforts in the following topic areas:
          </Typography>

          <ul>
            <li><Typography variant='body1'>Urban Science</Typography></li>
            <li><Typography variant='body1'>Connected and Automated Vehicles</Typography></li>
            <li><Typography variant='body1'>Alternative Fueling Infrastructure</Typography></li>
            <li><Typography variant='body1'>Mobility Decision Science</Typography></li>
            <li><Typography variant='body1'>Multimodal Transportation</Typography></li>
            <li><Typography variant='body1'>Vehicle Efficiency</Typography></li>
          </ul>

          <Typography variant='body1'>
            Livewire’s catalog is made of data from DOE-funded research,
            as well as data from other sources such as public repositories and
            federated data from other relevant catalogs like data.transportation.gov.
          </Typography>
        </SectionDiv>
        <SectionDiv>
          <Typography sx={{ fontWeight: 'normal', mb: 2 }} variant='h2' id="use">How can I use the data?</Typography>
          <Typography variant='body1'>
            The Livewire Data Platform contains data related to the impact of a
            variety of advanced technologies on transportation energy consumption.
            It also helps communities determine how they can plan for and encourage
            energy efficiency increases in transportation. If you are a mobility researcher,
            transportation planner, or otherwise engaged in the energy efficient mobility effort,
            you can use the Livewire Data Platform to access a variety of datasets to support your
            projects and inform community-level decision-making around new technologies. Some
            examples of how data can be used include:
          </Typography>
          <VehicleImage src={houseGraphic} alt="house" />
          <ul>
            <li>
              <Typography variant='body1'>
                A researcher seeking to understand travel choice patterns, preferences, and decision-making
                processes with the advent of new mobility technologies may use our behavioral data.
                A modeler using the experimental and modeled data to validate traffic simulations.
                Someone seeking to understand vehicle attributes under different road and traffic conditions may use raw
                data from telematics devices installed on vehicles.
              </Typography>
            </li>
          </ul>
        </SectionDiv>
        <SectionDiv>
          <Typography sx={{ fontWeight: 'normal', mb: 2 }} variant='h2' id="provide">CAN I UPLOAD DATA TO LIVEWIRE?</Typography>
          <Typography variant='body1'>
            Livewire is open to the public and lets any user upload data directly to the platform where
            it will be stored and maintained at no cost to users by the Livewire team. After setting up an account, data
            owners can upload their own data, as well as reference documents and publications. For more information about this
            feature, contact the Livewire team at livewirecontact@lyris.pnnl.gov.
          </Typography>
          <Typography variant='body1'>
            Projects and datasets on Livewire fall into three categories: open, privileged, and restricted. This three-tiered
            approach allows data owners to manage who can access their data, providing flexible options to
            control access for more sensitive data. For open datasets, anyone can view descriptions, although they might have
            to request access to download the actual data. For privileged datasets, anyone can view the project page on Livewire,
            but only members (i.e., those with .gov and .mil accounts) can view the data without requesting permission. For restricted
            datasets, only members can view the project page and data.
          </Typography>
        </SectionDiv>
      </Grid>
    </PaddedContent>
  );
};

export default About;