export enum PublicationType {
  JOURNAL_ARTICLE = 'JournalArticle',
  TECHNICAL_REPORT = 'TechnicalReport',
  REFERENCE_DOCUMENT = 'ReferenceDocument',
  Platform_Reference = 'platform-reference',
  Platform_Metadata = 'platform-metadata',
  PlatformReference = 'Platform Reference',
  PlatformMetadata = 'Platform Metadata',
  Reference = 'reference',
  Documentation = 'documentation',
  Publication = 'publication',
  Support_Data = 'support-data',
  Web = 'web',
  Metadata = 'metadata',
  Presentation = 'presentation',
  Standard = 'standard',
  Repository = 'repository',
  Vendor_Info = 'vendor-info',
  Video = 'video'
}

export enum ReferenceTypePretty {
  JOURNAL_ARTICLE = 'Journal Article',
  TECHNICAL_REPORT = 'Technical Report',
  REFERENCE_DOCUMENT = 'Reference Document',
}

export interface PubPerson {
  firstName: string;
  lastName: string;
}

export interface PubContact extends PubPerson {
  email: string;
  username?: string;
}

export interface PubOrganization {
  name: string;
  inputValue?: string;
}

export interface RefCategory {
  name: string;
  label: string;
  inputValue?: string;
}

export interface PubAuthor extends PubPerson {
  affiliation?: PubOrganization;
}

export interface PubDataset {
  /**@example 'wfip2' */
  project: string;
  /**@example 'wfip2/met.z01.00' */
  name: string;
  title?: string;
  project_obj?: any;
}

export interface PubProject {
  /**@example 'test' */
  name: string;
  /**@example 'A Test Project' */
  title: string;
}

export interface BasePublication {
  id?: number;
  title: string;
  abstract?: string;
  doi?: string;
  keywords: string[];
  primaryContact?: PubContact; // still need
  sponsorOrganizations: PubOrganization[];
  authors: PubAuthor[];
  datasets: PubDataset[];
  projects: PubProject[] | string[];
  /**The publication date, in ISO-8601 format. Only year, month, day will be used. */
  publicationDate: string;
  isPublic?: boolean;
  isApproved?: boolean;
  isRejected?: boolean;
  type: PublicationType;
  referenceTypePretty: string;
}

export interface JournalArticle extends BasePublication {
  journalIssue?: string;
  journalVolume?: string;
  journalName?: string;
}

export interface TechnicalReport extends BasePublication {
  url?: string;
  pdfUrl?: string;
  pdfName?: string;
  fileName?: string | null;
  reportNumber?: string;
}

export interface ReferenceDocument {
  id: number;
  title: string;
  datasets: PubDataset[];
  projects: PubProject[] | string[];
  type: PublicationType;
  uploadReferenceUrl?: string;
  uploadReferenceName?: string;
  url?: string;
  fileName: string;
  refCategory: RefCategory;
  uploadedFileName?: string;
  uploadedFileUrl?: string;
  uploadOption: string;
}

type Publication = TechnicalReport & JournalArticle & ReferenceDocument;

export default Publication;
