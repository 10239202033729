import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Snackbar as MuiSnackbar,
  Typography,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { autorun } from 'mobx';
import { inject, observer } from 'mobx-react';
import filter from 'lodash/filter';
import theme from '@extensions/services/Theme';

import {
  INotificationService,
  Notification,
  Status,
} from '@dapclient/services/INotificationService';

const StyledDrawer = styled(Drawer)(({
  '& .MuiDrawer-paperAnchorRight': {
    zIndex: 1200,
    minWidth: '300px',
    maxWidth: '400px',
    overflowX: 'hidden',
  }
}));

const StyledDrawerHeaderBox = styled(Box)(({
  padding: '1rem',
  borderBottom: '1px solid #e8e8e8',
}));

const StyledDrawerBodyBox = styled(Box)(({
  padding: '1rem',
}));

const StyledDrawerFooterBox = styled(Box)(({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  borderTop: '1px solid #e8e8e8',
  padding: '1rem',
  textAlign: 'right',
}));

const StyledBackdrop = styled(Backdrop)(({
  zIndex: 1201,
  '& .message': {
    color: '#fff',
    marginTop: '1rem',
    padding: '0.25rem .5rem',
    borderRadius: '5px',
    backgroundColor: 'rgba(0,0,0,0.7)',
  }
}));

export interface INotificationManagerProps {
  notificationService?: INotificationService;
  children: any;
}

const NotificationManager = ({ notificationService, children }: INotificationManagerProps) => {

  const onClose = React.useCallback(() => {
    if (notificationService) {
      notificationService.setShowNotifications(false);
    }
  }, [notificationService]);

  const onCloseNotice = React.useCallback((notice: Notification) => {
    if (notificationService) {
      notificationService.removeNotification(notice.id);
    }
  }, [notificationService]);

  const onClearAll = React.useCallback(() => {
    if (notificationService) {
      notificationService.deleteAllNonRunningNotices();
      notificationService.setShowNotifications(false);
    }
  }, [notificationService]);

  const onRemoveSnack = React.useCallback(() => {
    if (notificationService) {
      notificationService.shiftStack();
    }
  }, [notificationService]);

  const onSelectSnack = React.useCallback(() => {
    if (notificationService) {
      notificationService.shiftStack();
      notificationService.setShowNotifications(true);
    }
  }, [notificationService]);

  const rootId = "dapRoot";

  if (!notificationService) {
    return (
      <Box id={rootId}>
        {children}
      </Box>
    );
  }

  const notices: Notification[] = notificationService.notifications;

  const runningNotice = notificationService.running;
  const isRunning = runningNotice !== null;
  const runningMessage = runningNotice ? runningNotice.message : '';

  return (
    <Box id={rootId}>
      <StyledDrawer
        anchor="right"
        onClose={onClose}
        open={notificationService.showNotifications}
      >
        <StyledDrawerHeaderBox>
          <Typography variant="h2" sx={{ color: theme.palette.secondary.main }}>
            Notifications
          </Typography>
        </StyledDrawerHeaderBox>
        <StyledDrawerBodyBox>
          {filter(notices, notif => Boolean(notif.message && notif.description))
            .slice(0)
            .reverse()
            .map((notice: Notification, i: number) => (
              <Notice
                key={i}
                showDetail={true}
                handleRemove={() => onCloseNotice(notice)}
                notice={notice}
              />
            )
            )}
        </StyledDrawerBodyBox>
        <StyledDrawerFooterBox>
          <Button
            size="small"
            sx={{
              float: 'left'
            }}
            onClick={onClearAll}
          >
            Clear All Notices
          </Button>
        </StyledDrawerFooterBox>
      </StyledDrawer>
      {children}
      <Snackbar
        stack={notificationService.stack}
        onShiftStack={onRemoveSnack}
        onSelect={onSelectSnack}
      />
      <StyledBackdrop open={isRunning}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="secondary" />
          {Boolean(runningMessage) && (
            <Typography className="message">{runningMessage}</Typography>
          )}
        </Box>
      </StyledBackdrop>
    </Box>
  );
};

const statusToSeverity = (status: Status) => {
  switch (status) {
    case Status.Success:
      return 'success';
    case Status.Warn:
      return 'warning';
    case Status.Error:
      return 'error';
  }
  return 'info';
};

const statusIsRunning = (status: Status) => {
  switch (status) {
    case Status.Running:
    case Status.Downloading:
      return true;
  }
  return false;
}

interface ISnackbarProps {
  stack: Notification[]
  onShiftStack: () => void
  onSelect: () => void
}

const Snackbar = observer(({ stack, onShiftStack, onSelect }: ISnackbarProps) => {

  const [snackKey, setSnackKey] = React.useState(undefined as string | undefined);
  const [snackNotice, setSnackNotice] = React.useState(null as Notification | null);

  React.useEffect(() => autorun(() => {
    if (stack == null || stack.length === 0) {
      setSnackKey(undefined);
      return;
    }
    const next = stack[0];
    setSnackNotice(next);
    setSnackKey(String(new Date().getTime()));
  }), [stack]);

  const close = React.useCallback(() => {
    setSnackKey(undefined);
    onShiftStack();
  }, [onShiftStack]);

  return (
    <MuiSnackbar
      key={snackKey}
      open={snackKey !== undefined}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={
        snackNotice !== null && statusIsRunning(snackNotice.status)
          ? undefined
          : 5000
      }
      onClose={close}
    >
      <div>
        <Notice
          notice={snackNotice}
          showDetail={false}
          handleRemove={close}
          handleClick={snackNotice !== null && snackNotice.status === Status.Success
            ? close
            : onSelect
          }
        />
      </div>
    </MuiSnackbar>
  );
});

interface INoticeProps {
  notice: Notification | null;
  showDetail: boolean;
  handleRemove: () => void;
  handleClick?: () => void;
}

const StyledAlert = styled(Alert)`
  cursor: ${props => props.onClick !== undefined ? 'pointer' : 'default'};
`;

const Notice = ({ notice, handleRemove, handleClick, showDetail }: INoticeProps) => {

  if (notice === null) {
    return <></>;
  }

  const isClosable = statusIsRunning(notice.status) === false;

  return (
    <StyledAlert
      severity={statusToSeverity(notice.status)}
      onClick={handleClick || undefined}
      onClose={() => {
        if (isClosable) {
          handleRemove();
        }
      }}
      action={
        isClosable ? (
          <IconButton
            aria-label="close"
            size="small"
            onClick={e => {
              e.stopPropagation();
              handleRemove();
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <CircularProgress size="small" />
        )
      }
    >
      {showDetail ? (
        <>
          <Typography>
            <strong>{notice.message}</strong>
          </Typography>
          {notice.description}
        </>
      ) : notice.message}
    </StyledAlert>
  );
};

export default inject('notificationService')(observer(NotificationManager));
