import React from 'react';
import {
    observable,
    runInAction,
    when,
    computed,
    makeObservable,
} from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
    Paper,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
    INotificationService,
    Status,
} from '@extensions/services/INotificationService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import Project from '@extensions/models/Project';
import Link from '@dapclient/components/core/Link';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import Publication from '@extensions/models/Publication';
import DatasetGuard from '@extensions/components/core/DatasetGuard';
import CenteredCircularProgress from '@dapclient/components/core/CenteredCircularProgress';

// App specific imports
const NOTIFICATION_ID = 'PUBLICATION_PROJECTS_TABLE';

export interface IPublicationProjectsProps extends RouteComponentProps {
    publication: Publication;
    securityService?: ISecurityService;
    notificationService?: INotificationService;
    tableSize?: 'small' | 'medium';
}

const StyledCode = styled('code')(({ theme }) => ({
    color: theme.palette.grey[500],
    fontSize: theme.spacing(1.75),
}));

const StyledLink = styled(Link)(({ theme }) => ({
    fontSize: theme.spacing(1.75),
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50]
}));

@observer
export class PublicationProjects extends React.Component<IPublicationProjectsProps> {
    @observable
    fetchedProjects: Array<{ project: Project }> | null = null;;

    constructor(props: IPublicationProjectsProps) {
        super(props);
        makeObservable(this);
    }

    @computed
    get requiresAuth() {
        return !process.env.REACT_APP_SHOW_PUBLIC_DS;
    }

    componentDidMount() {
        if (this.requiresAuth) {
            when(() => this.props.securityService!.userIsLoggedIn, this.fetchData);
        } else {
            this.fetchData();
        }
    }

    fetchData = async () => {
        const { publication, notificationService } = this.props;
        const projectsToSearch = publication['projects'] as unknown as string[];
        const queryStrings = projectsToSearch.map((project) => {
            return {
                query_string: {
                    query: project["name"],
                    fields: ["name"],
                    default_operator: "and"
                }
            }
        });
        try {
            const projectSearchResponse = await DapApiAgent.agent
                .post('/api/projects/_msearch')
                .send(
                    JSON.stringify({ preference: "SearchResult" }) + "\n" +
                    JSON.stringify({
                        query: {
                            bool: {
                                should: queryStrings
                            }
                        },
                        size: 500,
                    })
                );
            runInAction(() => {
                this.fetchedProjects = projectSearchResponse.body.responses[0].hits.hits.map((hit) => {
                    const fetchedProject = new Project(hit._source, hit._source.name);
                    return {
                        project: fetchedProject
                    };
                });
            });
        } catch (error: any) {
            notificationService!.addNotification(
                NOTIFICATION_ID,
                Status.Error,
                `Could not fetch projects for publication ${publication.title}`,
                error.message
            );
        }
    };

    renderProjectTable = () => {
        const tableSize = this.props.tableSize ? this.props.tableSize : 'medium';
        const associatedProjects = this.fetchedProjects?.filter(project => project);
        if (associatedProjects) {
            return (
                <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
                    <Table size={tableSize} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell>
                                    Projects <small>({associatedProjects.length})</small>
                                </StyledTableHeadCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {associatedProjects
                                .slice()
                                .sort((a, b) => a.project.name.localeCompare(b.project.name))
                                .map(({ project }, idx) => {
                                    const projectUrl = `/project/${project.name}`;
                                    return (
                                        <TableRow
                                            key={idx}
                                        >
                                            <TableCell sx={{ fontSize: '14px' }}>
                                                <StyledCode>{project.name}</StyledCode>
                                                <br />
                                                <StyledLink to={projectUrl}>{project.title}</StyledLink>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else return null;
    }

    render() {
        let requiresAuth = this.requiresAuth;
        let content: React.ReactNode;
        if (this.fetchedProjects === null) {
            content = <CenteredCircularProgress color="secondary" />;
        } else if (this.fetchedProjects && this.fetchedProjects.length > 0) {
            content = this.renderProjectTable();
        } else if (this.fetchedProjects && this.fetchedProjects.length === 0) {
            requiresAuth = true;
        } else {
            content = <Alert severity='info'>No Associated Project Found.</Alert>
        }

        if (requiresAuth) {
            return <DatasetGuard isProjectIncluded={true} >{content}</DatasetGuard>;
        }
        return <>{content}</>;
    }
}

export default inject((store: any) => ({
    securityService: store.securityService,
    notificationService: store.notificationService,
}))(withRouter(PublicationProjects));
