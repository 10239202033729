import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

export type ICenteredCircularProgress = React.ComponentProps<
  typeof CircularProgress
>;

const StyledDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}))

class CenteredCircularProgress extends React.Component<
  ICenteredCircularProgress
> {
  render() {
    const {
      ...otherProps
    } = this.props;
    return (
      <StyledDiv>
        <CircularProgress
          {...otherProps}
          color="secondary"
        />
      </StyledDiv>
    );
  }
}

export default CenteredCircularProgress;
