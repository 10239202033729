import React from 'react';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';

import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';

import { ISecurityService } from '@extensions/services/ISecurityService';
import { IPublicationService } from '@extensions/services/IPublicationService';

import TypeField from '@extensions/components/publications/TypeField';
import AuthorsField from '@extensions/components/publications/AuthorsField';
import { PublicationFormData } from '@extensions/models/PublicationFormData';
import Publication, { PublicationType } from '@extensions/models/Publication';
import ReactHookForm from '@extensions/components/publications/ReactHookForm';
import KeywordsField from '@extensions/components/publications/KeywordsField';
import SponsorOrgsField from '@extensions/components/publications/SponsorOrgsField';
import TypeSpecificFields from '@extensions/components/publications/TypeSpecificFields';
import ReactHookTextField from '@extensions/components/publications/ReactHookTextField';
import { PublicationFormMethod } from '@extensions/components/publications/ChooseMethod';
import PublicationDateField from '@extensions/components/publications/PublicationDateField';
import FormButtonsAndErrors from '@extensions/components/publications/FormButtonsAndErrors';

const StyledGridTypeField = styled(Grid)(({
  '&&&': {
    paddingBottom: '0',
  },
}));

const StyledGridColumnRow = styled(Grid)(({
  '&&&': {
    paddingTop: '0.5rem',
  },
}));

export interface IFormDetailsProps {
  method: PublicationFormMethod;
  publication: Publication | null;
  setPublication: (publication: Publication) => void;
  moveForward: () => void;
  clickCancelBtn: () => void;
  moveBackward: () => void;
  canMoveForward: boolean;
  canMoveBackward: boolean;
  securityService?: ISecurityService;
  publicationService?: IPublicationService;
}

export interface IFormDetailsState { }

@observer
export class FormDetails extends React.Component<
  IFormDetailsProps,
  IFormDetailsState
> {
  requiredMessage = 'Required';

  datasetSchema = Yup.object().shape({
    project: Yup.string().required(this.requiredMessage),
    name: Yup.string().required(this.requiredMessage),
  });

  organizationSchema = Yup.object().shape({
    name: Yup.string().required(this.requiredMessage),
  });

  personSchema = Yup.object().shape({
    firstName: Yup.string().required(this.requiredMessage),
    lastName: Yup.string().required(this.requiredMessage),
  });

  authorSchema = this.personSchema.shape({
    affiliation: this.organizationSchema,
  });

  schema = Yup.object().shape({
    title: Yup.string().required(this.requiredMessage),
    abstract: Yup.string().nullable(),
    doi: Yup.string().nullable(),
    keywords: Yup.array().of(Yup.string()),
    sponsorOrganizations: Yup.array().of(this.organizationSchema),
    authors: Yup.array().of(this.authorSchema),
    datasets: Yup.array().of(this.datasetSchema),
    publicationDate: Yup.date().nullable(),
    type: Yup.string().required(this.requiredMessage),
    journalIssue: Yup.string().nullable(),
    journalVolume: Yup.string().nullable(),
    journalName: Yup.string().nullable(),
    url: Yup.string().nullable(),
    pdfName: Yup.string().nullable(),
    reportNumber: Yup.string().nullable(),
    fileName: Yup.string().nullable(),
    refCategory: Yup.object().nullable(),
    uploadOption: Yup.string().nullable(),
  });

  getInitialValues = (): PublicationFormData => {
    return {
      id: 0,
      type: PublicationType.JOURNAL_ARTICLE,
      title: '',
      abstract: '',
      doi: '',
      keywords: [],
      sponsorOrganizations: [],
      authors: [],
      datasets: [],
      projects: [],
      publicationDate: undefined,
      isPublic: false,
      isApproved: false,
      isRejected: false,
      journalIssue: '',
      journalVolume: '',
      journalName: '',
      pdfName: '',
      reportNumber: '',
      fileName: '',
      refCategory: { name: '', label: '' },
      uploadOption: '',
      referenceTypePretty: '',
    };
  };

  publicationToFormData = (publication: Publication): PublicationFormData => {
    return {
      ...publication,
      publicationDate: publication.publicationDate ? new Date(publication.publicationDate) : undefined,
    };
  };

  publicationFromFormData = (formData: PublicationFormData): Publication => {
    return {
      ...formData,
      publicationDate: formData.publicationDate ? formData.publicationDate.toISOString() : '',
    };
  };

  submit = (data: PublicationFormData): void => {
    const { moveForward, setPublication } = this.props;
    const publication = this.publicationFromFormData(data);
    setPublication(publication);
    moveForward();
  };

  render() {
    const {
      publication,
      method,
      canMoveBackward,
      canMoveForward,
      moveBackward,
      clickCancelBtn,
    } = this.props;
    const defaultValues = publication
      ? this.publicationToFormData(publication)
      : this.getInitialValues();
    // Work around for properly calling this.renderReferenceDocumentFields() in TypeSpecificFields.tsx
    if (defaultValues.type !== PublicationType.JOURNAL_ARTICLE && defaultValues.type !== PublicationType.TECHNICAL_REPORT) {
      defaultValues.type = PublicationType.REFERENCE_DOCUMENT;
    }
    return (
      <ReactHookForm
        formProps={{
          defaultValues,
          mode: 'onBlur',
          resolver: yupResolver<Yup.AnyObjectSchema>(this.schema),
        }}
      >
        {({ handleSubmit, control, watch }) => {
          const [selectedPubType, selectedUploadOption] = watch(["type", "uploadOption"]);
          return (
            <form
              onSubmit={handleSubmit(this.submit)}
            >
              <Grid container spacing={4}>
                <StyledGridTypeField item xs={12}>
                  <TypeField control={control} />
                </StyledGridTypeField>
                <StyledGridColumnRow item xs={6}>
                  <ReactHookTextField
                    control={control}
                    fieldName="title"
                    textFieldProps={{
                      multiline: true,
                      rows: 4,
                      required: true,
                    }}
                  />
                  {
                    selectedPubType !== PublicationType.REFERENCE_DOCUMENT
                    &&
                    <>
                      <ReactHookTextField
                        control={control}
                        fieldName="abstract"
                        textFieldProps={{
                          multiline: true,
                          rows: 8,
                        }}
                      />
                      <KeywordsField control={control} />
                    </>
                  }
                  <TypeSpecificFields control={control} selectedUploadOption={selectedUploadOption} />
                </StyledGridColumnRow>
                <StyledGridColumnRow item xs={6}>
                  {
                    selectedPubType !== PublicationType.REFERENCE_DOCUMENT
                    && <ReactHookTextField control={control} fieldName="doi" />
                  }
                  {
                    selectedPubType !== PublicationType.REFERENCE_DOCUMENT
                    &&
                    <>
                      <PublicationDateField
                        control={control}
                        method={method}
                      />
                      <SponsorOrgsField control={control} />
                      <AuthorsField control={control} />
                    </>
                  }
                </StyledGridColumnRow>
              </Grid>
              <FormButtonsAndErrors
                canMoveBackward={canMoveBackward}
                canMoveForward={canMoveForward}
                clickCancelBtn={clickCancelBtn}
                moveBackward={moveBackward}
                // No op since we move forward in the
                // onSubmit handler
                moveForward={() => { }}
                error={null}
              />
            </form>
          )
        }}
      </ReactHookForm>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
  publicationService: store.publicationService,
}))(FormDetails);
