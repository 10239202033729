import qs, { ParsedQuery } from 'query-string'

const setHashWithoutReload = hash => {
    const loc = window.location
    const url = `${loc.protocol}//${loc.host}${loc.pathname}${loc.search}#${hash}`
    window.history.replaceState({ url: url }, "", url)
}

export const setHashValue = (key: string, value: any) => {
    const hash =  window.location.hash
    const values = qs.parse(hash)
    const newHash = qs.stringify({ ...values, [key]: value })
    setHashWithoutReload(newHash)
}

export const getHashValue = (key: string) => {
    const hash = window.location.hash
    const values = qs.parse(hash)
    return values[key] || null
}

export const setHashValues = (hash: {[key:string]: string} | ParsedQuery<string>) => {
    setHashWithoutReload(stringify(hash))
}

export const getHashValues = () => {
    return qs.parse(window.location.hash)
}

export const stringify = (hash: {[key:string]: string} | ParsedQuery<string>) => {
    return qs.stringify(hash)
}

export const parse = (query: string) => {
    return { ...qs.parse(query) }
}