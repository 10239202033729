import { default as BaseMetricsService } from '@dapclient/services/MetricsService';
import { INotificationService } from '@extensions/services/INotificationService';
import { makeObservable} from 'mobx';

export default class MetricsService extends BaseMetricsService {
    constructor(notificationService: INotificationService) {
        super(notificationService);
        makeObservable(this);
    };

    fetchPubsStats = () => Promise.resolve();
}
