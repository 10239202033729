import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';

import theme from '@extensions/services/Theme';

import RangeControls from '@extensions/components/dataset/RangeControls';

const StyledRootDiv = styled('div')(({
  padding: '1rem 2rem',
  width: '300px',
}));

const StyledButtonBarDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.palette.grey['300']}`,
  paddingTop: '0.5rem',
  marginTop: '1rem',
}));

export type DataRange = [number, number] | undefined | null;

export interface IRangeFilterProps {
  className?: string;
  title: string;
  // inclusive
  min: number;
  // inclusive
  max: number;
  range: DataRange;
  onRangeChange: (newRange: DataRange) => void;
  onConfirm: () => void;
  onClear: () => void;
}

export interface IRangeFilterState { }

@observer
export class RangeFilter extends React.Component<
  IRangeFilterProps,
  IRangeFilterState
> {
  render() {
    const {
      min,
      max,
      range,
      onRangeChange,
      title,
      onClear,
      onConfirm,
    } = this.props;
    return (
      <StyledRootDiv>
        <Typography variant="h4" component="h2">
          {title}
        </Typography>
        <RangeControls
          min={min}
          max={max}
          range={range}
          onRangeChange={onRangeChange}
        />
        <StyledButtonBarDiv>
          <Button onClick={onClear} variant="outlined">
            Reset
          </Button>
          <Button onClick={onConfirm} variant="contained" color="primary">
            Filter
          </Button>
        </StyledButtonBarDiv>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(RangeFilter);
