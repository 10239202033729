import React from 'react';
import { styled } from '@mui/material/styles';

export interface SearchResultStats {
  numberOfResults: number;
  numberOfPages: number;
  currentPage: number;
  time: number;
  displayedResults: number;
  hidden: number;
  promoted: number;
}

export interface IResultStats {
  stats: SearchResultStats | null | undefined;
  resultsText?: string;
  className?: string;
}

const StyledResultsSpan = styled('span')(({theme}) => ({
  fontSize: theme.spacing(1.75),
  color: theme.palette.grey[900],
  ...(theme.typography.h6 as { [key: string]: any }),
}));

const StyledPageSummarySpan = styled('span')(({theme}) => ({
  fontSize: theme.spacing(2),
  color: theme.palette.grey[600],
}));

class PrettyResultStats extends React.Component<IResultStats> {
  render() {
    const { stats, resultsText } = this.props;
    if (!stats) {
      return null;
    }
    const { currentPage, numberOfPages, numberOfResults } = stats;
    return (
      <div aria-live="assertive">
        <StyledResultsSpan>
          {numberOfResults}{' '}
          {resultsText ? resultsText.toLocaleUpperCase() : 'RESULTS'}
        </StyledResultsSpan>
        <StyledPageSummarySpan>
          {' '}
          (page {currentPage + 1} of {Math.max(numberOfPages, 1)})
        </StyledPageSummarySpan>
      </div>
    );
  }
}

export default PrettyResultStats;
