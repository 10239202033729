import { load } from 'recaptcha-v3';
import User from '@extensions/models/User';

export const ABILITIES = {
  ADMIN: 'admin',
  UPLOAD: 'upload',
  DOWNLOAD: 'download',
  DOWNLOAD_PENDING: 'download_pending',
};

export interface ISecurityService {
  user: User | null;
  userIsLoggedIn: boolean;
  callbackUrl: string | null;
  recaptchaPromise: ReturnType<typeof load>;
  autoLoginDone: boolean;
  requireLogin(redirect: string | null);

  addLogoutListener(listener: () => void): void;
  removeLogoutListener(listenerToRemove: () => void): void;
  addLoginListener(listener: () => void): void;
  removeLoginListener(listenerToRemove: () => void): void;

  login(
    email: string,
    password: string,
  ): void;
  loginWithMfa(
    email: string,
    password: string,
    mfaCode: number | null
  ): void;
  generateMfa(password: string): void;
  validateMfa(code: number): void;

  /**
   * If there is currently a saved session cookie or portal
   * token, will automatically log in user   *
   */
  autoLogin(): void;
  logout(): Promise<void>;
  /**
   * Check if there is a redirect url present in the current route
   * @param routeQueryString
   */
  checkForCallback(routeQueryString: string): boolean;
  callbackIfNeeded(): void;
  register(data): void;
  updateProfile(data): void;
  forgotPassword(email: string): void; // sends a reset password request
  resetPassword(
    email: string,
    password: string,
    confirmPassword: string,
    resetToken: string
  ): void;

  resendEmailAction(email: string); // sends the verify email email again to the logged in user's email address
  setUser(user: User): void;
  setCallbackUrl(callbackUrl: string | null): void;
  getRecaptchaSiteKey(): string;
}
