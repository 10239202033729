import React from 'react';
import * as yup from 'yup';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';
import { Button, Typography, Alert, AlertTitle } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Checkbox } from 'formik-mui';
import TextField from '@mui/material/TextField';
import { observable, action, makeObservable } from 'mobx';

import User from '@extensions/models/User';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IContactUsService } from '@extensions/services/IContactUsService';
import SetupMFAModal from '@extensions/components/user/SetupMFAModal';
import Link from '@extensions/components/core/Link';

const StyledAgreementDiv = styled('div')(({
  marginLeft: '-9px',
  marginBottom: '1rem',
}));

const StyledSuccessAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedSuccess
}));

const StyledInfoAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedInfo
}));

const StyledEmailPasswordResetDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2)
}));

const StyledReadonlyFormItemsDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const StyledButtonDescription = styled('small')(({ theme }) => ({
  color: theme.palette.grey[700],
  fontSize: theme.spacing(1.6),
}));

const StyledTextField = styled(TextField)(({
  input: { 
    color: "#000" 
  }, 
  label: {
    color: theme.palette.text.primary
  },
  '& .MuiInputBase-multiline': {
    color: "#000" 
  }
}));

const StyledCheckbox = styled(Checkbox)(({
  color: theme.palette.text.primary
}));

export interface IUserProfileFormProps {
  className?: string;
  securityService?: ISecurityService;
  contactUsService?: IContactUsService;
  resetToken?: any;
  email?: any;
}

export interface IUserProfileFormState { }

@observer
export class UserProfileForm extends React.Component<
  IUserProfileFormProps,
  IUserProfileFormState
> {
  recaptchaToken: string | undefined;
  fieldProps: any;
  mfaModalVisible: boolean;

  constructor(props) {
    super(props);
    this.recaptchaToken = undefined;
    this.fieldProps = {
      variant: 'outlined',
      fullWidth: true,
      margin: 'normal',
      size: 'small',
    };
    this.mfaModalVisible = false;

    makeObservable(this, {
      recaptchaToken: observable,
      fieldProps: observable,
      mfaModalVisible: observable,
      setMfaModalVisible: action,
    });
  }

  setMfaModalVisible = (newValue: boolean) => {
    this.mfaModalVisible = newValue;
  };

  isResettingPassword(): boolean {
    return !!this.props.resetToken;
  }

  isUpdatingProfile(): boolean {
    return !this.isResettingPassword() && !this.isRegistering();
  }

  isRegistering(): boolean {
    return !this.getUser().authenticated;
  }

  getUser(): User {
    const { securityService, email } = this.props;

    if (securityService && securityService.user) {
      return securityService.user;
    } else {
      const user = User.getGuestUser();
      if (email) {
        user.setEmail(email);
      }
      return user;
    }
  }

  // must be method not arrow so we can use super
  transformValues(values) {
    const { firstname, lastname, ...otherValues } = values;
    if (otherValues.sshkey) {
      otherValues.sshkey = otherValues.sshkey.trim().replace(/\++$/g, '').trim();
    }
    return {
      ...otherValues,
      name_given: firstname,
      name_family: lastname,
    };
  }

  onFormSubmit = (values, { setSubmitting }) => {
    const { securityService, email, resetToken } = this.props;
    values = this.transformValues(values);
    if (securityService) {
      if (this.isResettingPassword()) {
        securityService.resetPassword(
          email,
          values.password,
          values.confirm,
          resetToken
        );
      } else if (this.isUpdatingProfile()) {
        delete values['password'];
        delete values['confirm'];
        delete values['email'];
        securityService.updateProfile(values);
      } else if (this.isRegistering()) {
        securityService.register(values);
      } else {
        console.error('error submitting profile form, unknown state');
      }
    }
    setSubmitting(false);
  };

  onAgreeClicked = (e) => {
    window.open(window.location.origin + '/agree', '', '');
  };

  getJustificationPlaceholder = () =>
    'Identify which EEMS project you are working on.';

  renderPasswordField = () => {
    return (
      <Field name="password">
        {({ field, form }) => (
          <StyledTextField
            type="password"
            label="Password"
            id="password-input"
            {...field}
            {...this.fieldProps}
            error={form.touched.password && Boolean(form.errors.password)}
            helperText={form.touched.password && form.errors.password}
          />
        )}
      </Field>
    );
  };

  renderFirstnameField = () => {
    return (
      <Field name="firstname">
        {({ field, form }) => (
          <StyledTextField
            type="text"
            label="First Name"
            id="first-name-input"
            {...field}
            {...this.fieldProps}
            error={form.touched.firstname && Boolean(form.errors.firstname)}
            helperText={form.touched.firstname && form.errors.firstname}
          />
        )}
      </Field>
    )
  };

  renderLastnameField = () => {
    return (
      <Field name="lastname">
        {({ field, form }) => (
          <StyledTextField
            label="Last Name"
            id="last-name-input"
            type="text"
            {...field}
            {...this.fieldProps}
            error={form.touched.lastname && Boolean(form.errors.lastname)}
            helperText={form.touched.lastname && form.errors.lastname}
          />
        )}
      </Field>
    )
  };

  renderConfirmPasswordField = () => {
    return (
      <Field name="confirm">
        {({ field, form }) => (
          <StyledTextField
            type="password"
            label="Confirm Password"
            id="confirm-password-input"
            {...field}
            {...this.fieldProps}
            error={form.touched.confirm && Boolean(form.errors.confirm)}
            helperText={form.touched.confirm && form.errors.confirm}
          />
        )}
      </Field>
    );
  };

  renderEmailField = () => {
    return (
      <Field name="email">
        {({ field, form }) => (
          <StyledTextField
            type="email"
            label="Email"
            id="email-input"
            {...field}
            {...this.fieldProps}
            error={form.touched.email && Boolean(form.errors.email)}
            helperText={form.touched.email && form.errors.email}
          />
        )}
      </Field>
    );
  };

  renderORCIDField = () => {
    return (
      <Field name="orcId">
        {({ field }) => (
          <StyledTextField
            type="text"
            label="ORCID"
            id="orcid-input"
            {...field}
            {...this.fieldProps}
          />
        )}
      </Field>
    );
  };

  renderJustificationField = () => {
    return (
      <Field name="justification">
        {({ field, form }) => (
          <StyledTextField
            type="text"
            label="Justification"
            id="justification-input"
            multiline
            rows={3}
            placeholder={this.getJustificationPlaceholder()}
            {...field}
            {...this.fieldProps}
            error={form.touched.justification && Boolean(form.errors.justification)}
            helperText={form.touched.justification && form.errors.justification}
          />
        )}
      </Field>
    );
  };

  renderAgreementField = () => {
    return (
      <StyledAgreementDiv>
        <Field component={StyledCheckbox} name="agreement" type="checkbox" />
        <label htmlFor="agreement" style={{ color: theme.palette.grey[700] }}>
          I have read the{' '}
          <Link to="/agree" target="_blank">
            Agreement
          </Link>
        </label>
        {/* built in error messaging with formik for checkboxes with labels was not rendering */}
        <div
          style={{ 
            marginLeft: theme.spacing(1),
            color: theme.palette.error.main,
          }}
        >
          <ErrorMessage
            name="agreement"
            component="p"
          />
        </div>
      </StyledAgreementDiv>
    );
  };

  renderMfaSection = () => {
    const { contactUsService } = this.props;
    const user = this.getUser();
    let content = <></>;
    if (
      process.env.REACT_APP_MFA_ENABLED === 'true' &&
      !user.mfaEnabled &&
      user.emailVerified
    ) {
      content = (
        <span>
          <Button
            fullWidth
            onClick={() => this.setMfaModalVisible(true)}
            variant="contained"
            color="secondary"
          >
            Enable Multifactor Authentication (MFA)
          </Button>
          <StyledButtonDescription>MFA is required in order to access proprietary data</StyledButtonDescription>
        </span>
      );
    } else if (
      process.env.REACT_APP_MFA_ENABLED === 'true' &&
      user.mfaEnabled
    ) {
      content = (
        <StyledSuccessAlert severity="success">
          <AlertTitle>MFA Configured</AlertTitle>
          <span>
            Multifactor authentication is enabled for your account.
            {user.mfaNew && (
              <strong>
                You will need to log out and log back in using your newly
                configured MFA token in order to access resources that require a
                multifactor login.{' '}
              </strong>
            )}
            <Typography>
              If you need to reset your MFA token, please{' '}
              <Link
                onClick={() => {
                  contactUsService && contactUsService.openModal();
                }}
              >
                contact us
              </Link>
              .
            </Typography>
          </span>
        </StyledSuccessAlert>
      );
    }
    return content;
  };

  renderUsername = (user: User) => {
    return (
      <Typography sx={{ overflowWrap: 'break-word', color: theme.palette.grey[700] }}>Username: {user.username}</Typography>
    );
  };

  renderEmail = (user: User) => {
    return (
      <Typography sx={{ overflowWrap: 'break-word', color: theme.palette.grey[700] }}>Email: {user.email}</Typography>
    );
  };

  renderApiKey = (user: User) => {
    if (Boolean(user.apiKey)) {
      return (
        <Typography sx={{ overflowWrap: 'break-word', color: theme.palette.grey[700] }}>API Key: {user.apiKey}</Typography>
      );
    }
  }

  /**
   * The Yup schema for ONLY the fields in the extension but not the base
   */
  getExtensionSchema = (): Record<string, yup.AnySchema> => {
    return {};
  };

  getValidationSchema(): any {
    const passwordSchema = yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters.')
      .matches(
        /^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w]|[&.-]).*$/,
        'Password must have uppercase, lowercase, symbol, and numeric characters.'
      );
    const confirmSchema = yup
      .string()
      .oneOf([yup.ref('password'), undefined], 'Passwords must match')
      .required('Please confirm your password.');

    const extensionSchema = this.getExtensionSchema();
    let schema;

    if (this.isResettingPassword()) {
      schema = yup.object({
        password: passwordSchema,
        confirm: confirmSchema,
      });
    } else if (this.isRegistering()) {
      schema = yup.object({
        firstname: yup.string().required('First Name is required'),
        lastname: yup.string().required('Last Name is required'),
        justification: yup.string().required('Justification is required'),
        email: yup
          .string()
          .email('Enter a valid email')
          .required('Email is required'),
        password: passwordSchema,
        confirm: confirmSchema,
        agreement: yup.boolean().oneOf([true], 'Please read the agreement.'),
        ...extensionSchema,
      });
    } else if (this.isUpdatingProfile()) {
      schema = yup.object({
        firstname: yup.string().required('First Name is required'),
        lastname: yup.string().required('Last Name is required'),
        justification: yup.string().required('Justification is required'),
        ...extensionSchema,
      });
    } else {
      console.error(
        'error getting validation schema for profile form, unknown state'
      );
    }
    return schema;
  }

  renderSshKeyField = (): React.ReactNode => {
    return null;
  };

  renderExtensionFields = (): React.ReactNode => {
    // To be used by the extensions
    return null;
  };

  // Needs to be method function instead of arrow function
  // so that we can call super
  getInitialFormData(): object {
    const user = this.getUser();
    return {
      firstname: user.firstname || '',
      lastname: user.lastname || '',
      orcId: user.orcId || '',
      justification: user.justification || '',
      email: user.email || '',
      password: '',
      confirm: '',
      agreement: user.agreed,
    };
  }

  render() {
    const user = this.getUser();

    //by default the form will render for registering
    let form = (
      <React.Fragment>
        {this.renderFirstnameField()}
        {this.renderLastnameField()}
        {this.renderORCIDField()}
        {this.renderJustificationField()}
        {this.renderEmailField()}
        {this.renderPasswordField()}
        {this.renderConfirmPasswordField()}
        {this.renderExtensionFields()}
        {this.renderAgreementField()}
      </React.Fragment>
    );
    if (this.isResettingPassword()) {
      form = (
        <React.Fragment>
          <StyledInfoAlert severity="info">
            <AlertTitle>Password Reset</AlertTitle>
            Enter a new password for this account.
          </StyledInfoAlert>
          <StyledEmailPasswordResetDiv>
            <Typography>Email: {user.email}</Typography>
          </StyledEmailPasswordResetDiv>
          {this.renderPasswordField()}
          {this.renderConfirmPasswordField()}
        </React.Fragment>
      );
    } else if (this.isUpdatingProfile()) {
      form = (
        <React.Fragment>
          {this.renderMfaSection()}
          {this.renderFirstnameField()}
          {this.renderLastnameField()}
          {this.renderORCIDField()}
          {this.renderJustificationField()}
          {this.renderSshKeyField()}
          {this.renderExtensionFields()}
          <StyledReadonlyFormItemsDiv>
            {this.renderUsername(user)}
            {this.renderEmail(user)}
            {this.renderApiKey(user)}
          </StyledReadonlyFormItemsDiv>
        </React.Fragment>
      );
    }

    return (
      <div>
        <SetupMFAModal
          visible={this.mfaModalVisible}
          onCancel={(e) => this.setMfaModalVisible(false)}
        />
        <Formik
          initialValues={this.getInitialFormData()}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.onFormSubmit}
        >
          {({ submitForm }) => (
            <Form>
              {form}
              <Button
                fullWidth
                onClick={submitForm}
                color="secondary"
                variant="contained"
              >
                {this.isResettingPassword()
                  ? 'Reset Password'
                  : user.authenticated && user.agreed
                    ? 'Save Profile'
                    : 'Register'}
              </Button>
              {this.isRegistering() && !this.isResettingPassword() && (
                <Typography variant='body1' sx={{ color: theme.palette.grey[700], mt: 2 }}>
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://policies.google.com/privacy"
                    style={{textDecoration: 'none'}}
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://policies.google.com/terms"
                    style={{textDecoration: 'none'}}
                  >
                    Terms of Service
                  </a>{' '}
                  apply.
                </Typography>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
  contactUsService: store.contactUsService,
}))(UserProfileForm);
