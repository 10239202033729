import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { observer, inject } from 'mobx-react';
// import { TextField } from 'formik-material-ui';
import { TextField } from 'formik-mui';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { IHistoryService } from '@extensions/services/IHistoryService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IContactUsService } from '@extensions/services/IContactUsService';
import { INotificationService } from '@extensions/services/INotificationService';

type TypographyExtraProps = {
  component: React.ElementType;
};

const StyledSubTitleTypography = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  marginTop: '0.5rem',
  color: theme.palette.secondary.main,
  '& .MuiDialogTitle-root': {
    padding: 0,
  }
}));

const StyledButtonBarDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  '& > *:nth-last-of-type(n+1)': {
    marginLeft: '1rem',
  },
}));

const StyledDialogTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '1.9375rem'
}));

const StyledCancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.black,
  border: '1px solid rgba(0, 0, 0, 0.23)'
}))

export interface IContactModalProps {
  className?: string;
  contactUsService?: IContactUsService;
  securityService?: ISecurityService;
  notificationService?: INotificationService;
  historyService?: IHistoryService;
}

@inject(
  'contactUsService',
  'securityService',
  'notificationService',
  'historyService'
)
@observer
class ContactModal extends React.Component<IContactModalProps> {
  render() {
    const {
      contactUsService,
      securityService,
    } = this.props;
    if (!contactUsService || !securityService) {
      return null;
    }
    return (
      <Dialog
        open={contactUsService.showModal}
        onClose={contactUsService.closeModal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <StyledDialogTitle variant="body1" style={{ marginBlockEnd: 0 }}>{contactUsService.modalTitle}</StyledDialogTitle>
          <StyledSubTitleTypography variant="subtitle2" component="p">
            {contactUsService.modalSubTitle || ''}
          </StyledSubTitleTypography>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              email: securityService.user ? securityService.user.email : '',
              message: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Invalid Email')
                .required('Required'),
              message: Yup.string().required('Required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
              contactUsService.closeModal();
              contactUsService.submitMessage({
                emailAddress: values.email,
                message: values.message,
              });
              setSubmitting(false);
            }}
          >
            {({ submitForm, isSubmitting }) => (
              // translate yes is a quick fix to a typing issue
              <Form translate="yes">
                <Field
                  component={TextField}
                  name="email"
                  type="email"
                  label="Your Email"
                  variant="outlined"
                  fullWidth
                  id="email-input"
                  style={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                  }}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      padding: '8.5px 14px',
                    }
                  }}
                />
                <Field
                  component={TextField}
                  name="message"
                  type="text"
                  id="message-input"
                  label="Message"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  style={{
                    marginBottom: '1rem',
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: '10px 14px',
                    }
                  }}
                />
                <StyledButtonBarDiv>
                  <StyledCancelButton
                    onClick={contactUsService.closeModal}
                    variant="outlined"
                  >
                    Cancel
                  </StyledCancelButton>
                  <Button
                    onClick={() => !isSubmitting && submitForm()}
                    color="primary"
                    variant="outlined"
                  >
                    Submit
                  </Button>
                </StyledButtonBarDiv>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

export default ContactModal;
