export default class FileUpload {
  name: string;
  owner: string;
  size: number;
  created: number;

  constructor(data) {
    this.name = data.name;
    this.owner = data.owner;
    this.size = data.size;
    this.created = data.mtime;
  }
}
