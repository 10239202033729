import React from 'react';
import ReactMarkdown from 'react-markdown';
import { inject, observer } from 'mobx-react';
import {
    Typography,
    Paper,
    Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import greenDivider from '@extensions/resources/green-divider_original.png';
import eereLogo from '@extensions/resources/eere_logo_horiz_green_reversed.jpg';
import livewireNews from '@extensions/resources/livewire-news-pic-1_original.png';

import MarkdownImage from '@extensions/components/core/MarkdownImage';
import MarkdownLink from '@extensions/components/core/MarkdownLink';
import type Newsletter from '@extensions/models/Newsletter';
import NewsletterService from '@extensions/services/NewsletterService';
import CenteredCircularProgress from '@dapclient/components/core/CenteredCircularProgress';
import theme from '@extensions/services/Theme';

const StyledLogoImage = styled('img')({
    width: '50%',
    marginRight: '0.5rem',
    float: 'left',
});

const StyledEEREImg = styled('img')(({
    width: '100%'
}));

const StyledMarkDownContainerDiv = styled('div')({
    marginBottom: '2rem',
    '& img': {
        display: 'block'
    }
});

const StyledSpinnerDiv = styled('div')(({
    marginTop: '2rem',
    marginBottom: '2rem',
}));

const StyledResultWrapperDiv = styled('div')(({
    marginLeft: '3rem',
    marginRight: '3rem',
    marginBottom: '3rem',
    paddingBottom: '1rem',
}));

const StyledDividerImg = styled('img')(({
    color: 'black',
    width: '100%'
}));

const StyledFinePrintP = styled('p')(({
    color: 'gray',
    fontStyle: 'italic',
    fontSize: '14px'
}));

export interface INewsletterResultProps extends RouteComponentProps<{ id: string }> {
    className?: string;
    newsletterService?: NewsletterService;
}

export interface INewsletterResultState { }

@observer
export class NewsletterResult extends React.Component<
    INewsletterResultProps,
    INewsletterResultState
> {

    componentDidMount() {
        const { newsletterService } = this.props;
        if (newsletterService) {
            const id = this.getId();
            newsletterService.selectNewsletter(id);
        }
    }

    componentWillUnmount(): void {
        this.props.newsletterService && this.props.newsletterService.emptyNewsletters();
    }

    getId = (): number => {
        const { match } = this.props;
        return Number(match.params.id);
    };

    renderContent = (selectedNewsletter: Newsletter) => {
        return (
            <>
                <StyledLogoImage src={livewireNews} alt='livewire-news' />
                <StyledMarkDownContainerDiv>
                    <ReactMarkdown
                        children={selectedNewsletter.content}
                        components={{
                            img: MarkdownImage,
                            a: MarkdownLink,
                            h1: ({...props}) => <Typography variant='h1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1.5em' }} {...props} />,
                            h2: ({...props}) => <Typography variant='h2' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1.313em' }} {...props} />,
                            h3: ({...props}) => <Typography variant='h3' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1em', lineHeight: 1.6 }} {...props} />,
                            h4: ({...props}) => <Typography variant='h4' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1em' }} {...props} />,
                            h5: ({...props}) => <Typography variant='h5' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px' }} {...props} />,
                            p: ({...props}) => <Typography variant='body1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', color: theme.palette.grey[900] }} {...props} />,
                          }}
                    />
                </StyledMarkDownContainerDiv>
            </>
        )
    }

    render() {
        const { newsletterService } = this.props;
        let content = (
            <StyledSpinnerDiv>
                <CenteredCircularProgress />
            </StyledSpinnerDiv>
        );

        let publishedDate;
        let title;

        if (newsletterService?.selectedNewsletter) {
            const { selectedNewsletter } = newsletterService;
            if (selectedNewsletter !== null) {
                content = this.renderContent(selectedNewsletter);
                title = selectedNewsletter.title;
                if (selectedNewsletter.published_date) {
                    publishedDate = selectedNewsletter.published_date;
                }
            }
        } else if (newsletterService?.newsletters) {
            if (newsletterService?.newsletters[0].published_date) {
                publishedDate = newsletterService?.newsletters[0].published_date;
            }
            content = this.renderContent(newsletterService?.newsletters[0]);
            title = newsletterService?.newsletters[0].title;
        }

        if (newsletterService?.selectedNewsletterResultNotFound) {
            content = <Alert severity="error">No Newsletter Found</Alert>;
        }

        return (
            <div>
                <Paper elevation={9}>
                    <StyledEEREImg src={eereLogo} alt='eere-logo' />
                    <StyledResultWrapperDiv>
                        <Typography
                            variant='h1'
                            sx={{
                                color: 'black',
                                marginTop: '2rem',
                            }}
                        >
                            {title}
                        </Typography>
                        <StyledDividerImg src={greenDivider} alt='divider' />
                        <Typography
                            variant='subtitle2'
                            sx={{
                                color: 'black',
                                marginTop: '1rem',
                            }}
                        >
                            {publishedDate}
                        </Typography>
                        <Typography
                            variant='h2'
                            sx={{
                                color: 'black',
                                marginTop: '1rem',
                            }}
                        >
                            Livewire News
                        </Typography>
                        <StyledFinePrintP>Livewire News is a quarterly publication with information about
                            new platform features and data available on the
                            &nbsp;<a href="https://livewire.energy.gov/" style={{textDecoration: 'none'}}>Livewire Data Platform</a>.
                        </StyledFinePrintP>
                        {content}
                    </StyledResultWrapperDiv>
                </Paper>
            </div>
        );
    }
}

export default inject((store: any) => ({
    notificationService: store.notificationService,
    newsletterService: store.newsletterService
}))(withRouter(NewsletterResult));
