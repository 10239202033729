import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { observable, makeObservable } from 'mobx';

import {
  SEARCH_BAR_REACTIVE_ID,
  SearchBar,
  SearchProvider,
  SearchResults,
  SelectedFilters,
} from '@extensions/components/search-core';
import Project from '@extensions/models/Project';
import ProjectFilters, {
  REACTIVE_IDS as FILTER_REACTIVE_IDS,
} from '@extensions/components/project-search/ProjectFilters';
import ProjectResult from '@extensions/components/project-search/ProjectResult';
import { SearchResultStats } from '@extensions/components/search-core/PrettyResultStats';

const StyledSearchBar = styled(SearchBar)(({ theme }) => ({
  marginTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
}));

const StyledSelectedFilters = styled(SelectedFilters)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

type ProjectResultExtraProps = {
  hit: Project
};

const StyledProjectResult = styled(ProjectResult)<ProjectResultExtraProps>(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export interface IProjectSearchProps { }

const searchFields: any = {
  'name': 'Project Name',
  'title': 'Title',
  'description': 'Description',
  'participatingOrganizations.name': 'Participating Organization',
  'dataset.distributionType': 'Dataset Distribution Type',
  'dataset.keyword': 'Dataset Keyword',
  'contactPoint.fn': 'Contact Name',
  'contactPoint.hasOrg': 'Contact Organization',
};

@observer
class ProjectSearch extends React.Component<
  IProjectSearchProps
> {
  @observable
  resultStats: SearchResultStats | null = null;

  elasticHitToProject: (hit) => Project = (hit) => {
    return new Project(hit, hit.name);
  };

  constructor(props: IProjectSearchProps) {
    super(props);
    makeObservable(this);
  }

  render() {
    return (
      <SearchProvider
        elasticIndex="projects"
        apiPrefix="api"
      >
        <StyledSearchBar
          dataField={Object.keys(searchFields)}
          highlight={true}
          customHighlight={d => ({
            highlight: {
              pre_tags: ['<mark>'],
              post_tags: ['</mark>'],
              fields: {
                ...d.dataField.reduce((carry, field) => {
                  return {
                    ...carry,
                    [field]: (field === 'description' || field === 'title')
                      ? { number_of_fragments: 0 }
                      : {},
                  };
                }, {}),
              },
            },
          })}
          autosuggest={false}
          queryFormat="and"
          URLParams={true}
        />
        <StyledSelectedFilters />
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <ProjectFilters />
          </Grid>
          <Grid item xs={8}>
            <SearchResults
              resultsText="projects"
              dataField="title.keyword"
              sortBy="asc"
              react={{
                and: [SEARCH_BAR_REACTIVE_ID, ...FILTER_REACTIVE_IDS],
              }}
              renderItem={(hit) => {
                const project = this.elasticHitToProject(hit);
                return (
                  <StyledProjectResult
                    key={`${project.identifier}`}
                    hit={project}
                    highlight={hit.highlight}
                    searchFieldLabels={searchFields}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </SearchProvider>
    );
  }
}

export default ProjectSearch;
