import {
  IDs,
  INotificationService,
  Status,
} from '@dapclient/services/INotificationService';
import Dataset from '@extensions/models/Dataset';
import Group from '@extensions/models/Group';
import Project from '@extensions/models/Project';
import User from '@extensions/models/User';
import { ICachingService } from '@extensions/services/ICachingService';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import { action, observable, reaction, makeObservable } from 'mobx';
import find from 'lodash/find';

import { IProjectService } from '@extensions/services/IProjectService';
import {
  ABILITIES,
  ISecurityService,
} from '@extensions/services//ISecurityService';

export default class ProjectService implements IProjectService {
  @observable project: Project | null = null;
  @observable groups: Group[] | null = null;
  @observable users: User[] | null = null;
  @observable canAdmin: boolean = false;
  @observable abilities: string[] | null = null;
  @observable datasets: Dataset[] | null = null;
  private notificationService: INotificationService;
  private cachingService: ICachingService;
  private securityService: ISecurityService;

  constructor(
    notificationService: INotificationService,
    cachingService: ICachingService,
    securityService: ISecurityService
  ) {
    makeObservable(this);
    this.notificationService = notificationService;
    this.cachingService = cachingService;
    this.securityService = securityService;

    // SJB: changed this from observe() to reaction() per the docs
    reaction(
      () =>
        find(
          this.notificationService.notifications,
          (n) => n.id === IDs.USER_LOGGED_OUT && n.status === Status.Success
        ) !== undefined,
      (loggedOut: boolean) => {
        if (loggedOut) {
          this.clearState();
        }
      }
    );
  }

  async loadProject(projectName: string) {
    this.notificationService.addNotification(
      IDs.GET_PROJECT,
      Status.Running,
      '',
      ''
    );
    // clear previously loaded project info
    this.clearState();

    try {
      const project = await this.cachingService.getProject(projectName);

      this.notificationService.addNotification(
        IDs.GET_PROJECT,
        Status.Success,
        '',
        ''
      );
      this.setProject(project);
    } catch (error: any) {
      if (error.status === 403 && !this.securityService.userIsLoggedIn) {
        this.notificationService.removeNotification(IDs.GET_PROJECT);
        return true;
      }
      this.notificationService.addNotification(
        IDs.GET_PROJECT,
        Status.Error,
        'Failed to get project',
        error
      );
    }
    return false;
  }

  @action async getProjectAbilitiesIfNeeded() {
    if (this.abilities === null) {
      this.getProjectAbilities();
    }
  }

  @action async getProjectAbilities() {
    this.notificationService.addNotification(
      'projectAbilities',
      Status.Running,
      '',
      ''
    );
    try {
      if (this.project) {
        const canAdminResponse = await DapApiAgent.agent.get(
          `/api/can/admin/${this.project.identifier}`
        );
        const abilities: string[] = [];
        const admin = JSON.parse(canAdminResponse.text);
        if (admin.can) {
          abilities.push(ABILITIES.ADMIN);
        }

        this.setAbilities(abilities);
      }

      this.notificationService.addNotification(
        'projectAbilities',
        Status.Success,
        '',
        ''
      );
    } catch (error) {
      this.notificationService.addNotification(
        'projectAbilities',
        Status.Error,
        'Failed to get project abilities',
        error
      );
    }
  }

  @action async getProjectDatasetsIfNeeded() {
    if (this.datasets === null) {
      this.getProjectDatasets();
    }
  }

  @action async getProjectDatasets() {
    this.notificationService.addNotification(
      'getProjectDatasets',
      Status.Running,
      '',
      ''
    );

    if (this.project) {
      try {
        const projectName = this.project.identifier;
        const dapApiResponse = await DapApiAgent.agent.get(
          `/api/projects/${projectName}/datasets`
        );

        this.notificationService.addNotification(
          'getProjectDatasets',
          Status.Success,
          '',
          ''
        );
        const response = JSON.parse(dapApiResponse.text);
        const datasets: Dataset[] = response.map((group) => new Dataset(group));
        this.setDatasets(datasets);
      } catch (error) {
        this.notificationService.addNotification(
          'getProjectDatasets',
          Status.Error,
          'Failed to get project datasets',
          error
        );
      }
    } else {
      this.notificationService.addNotification(
        'getProjectDatasets',
        Status.Error,
        'Failed to get projects datasets, project not loaded',
        ''
      );
    }
  }

  @action async getProjectGroupsIfNeeded(identifier: string) {
    if (this.groups === null) {
      this.getProjectGroups(identifier);
    }
  }

  @action async getProjectGroups(identifier: string) {
    this.notificationService.addNotification(
      'getProjectGroups',
      Status.Running,
      '',
      ''
    );

    try {
      const dapApiResponse = await DapApiAgent.agent.get(
        `/api/membership/${identifier}/groups`
      );

      this.notificationService.addNotification(
        'getProjectGroups',
        Status.Success,
        '',
        ''
      );
      const response = JSON.parse(dapApiResponse.text);
      const groups: Group[] = response.map((group) => new Group(group));
      this.setGroups(groups);
    } catch (error) {
      this.notificationService.addNotification(
        'getProjectGroups',
        Status.Error,
        'Failed to get groups',
        error
      );
    }
  }

  @action async updateProjectGroup(
    oldGroupName: string,
    groupName: string,
    groupUsers: User[]
  ) {
    this.notificationService.addNotification(
      IDs.GROUP_UPDATED,
      Status.Running,
      '',
      ''
    );

    if (this.project) {
      try {
        const projectName = this.project.identifier;
        const users: string[] = [];
        groupUsers.forEach((user: User) => {
          users.push(user.email);
        });
        await DapApiAgent.agent
          .put(`/api/membership/${projectName}/groups/${oldGroupName}`)
          .send({ name: groupName, label: groupName, users });

        this.notificationService.addNotification(
          IDs.GROUP_UPDATED,
          Status.Success,
          'Group Updated',
          `Group ${groupName} successfully updated.`
        );

        // refresh the list of groups to see this new one:
        this.getProjectGroups(this.project.getIdentifier());
      } catch (error) {
        this.notificationService.addNotification(
          IDs.GROUP_UPDATED,
          Status.Error,
          'Failed to create group',
          error
        );
      }
    } else {
      this.notificationService.addNotification(
        IDs.GROUP_UPDATED,
        Status.Error,
        'Failed to create group, project not loaded',
        ''
      );
    }
  }

  @action async createProjectGroup(groupName: string, groupUsers: User[]) {
    this.notificationService.addNotification(
      IDs.GROUP_CREATED,
      Status.Running,
      '',
      ''
    );

    if (this.project) {
      try {
        const projectName = this.project.identifier;
        await DapApiAgent.agent
          .post(`/api/membership/${projectName}/groups`)
          .send({ name: groupName, label: groupName });

        const users: string[] = [];
        groupUsers.forEach((user: User) => {
          users.push(user.email);
        });
        await DapApiAgent.agent
          .put(`/api/membership/${projectName}/groups/${groupName}`)
          .send({ name: groupName, label: groupName, users });

        this.notificationService.addNotification(
          IDs.GROUP_CREATED,
          Status.Success,
          'Group Created',
          `Group ${groupName} created successfully.`
        );

        // refresh the list of groups to see this new one:
        this.getProjectGroups(this.project.getIdentifier());
      } catch (error) {
        this.notificationService.addNotification(
          IDs.GROUP_CREATED,
          Status.Error,
          'Failed to create group',
          error
        );
      }
    } else {
      this.notificationService.addNotification(
        IDs.GROUP_CREATED,
        Status.Error,
        'Failed to create group, project not loaded',
        ''
      );
    }
  }

  @action async deleteProjectGroup(groupName: string) {
    this.notificationService.addNotification(
      IDs.GROUP_DELETED,
      Status.Running,
      '',
      ''
    );

    if (this.project) {
      try {
        const projectName = this.project.identifier;
        await DapApiAgent.agent.delete(
          `/api/membership/${projectName}/groups/${groupName}`
        );

        this.notificationService.addNotification(
          IDs.GROUP_DELETED,
          Status.Success,
          '',
          ''
        );

        // refresh the list of groups to remove this deleted one:
        this.getProjectGroups(this.project.getIdentifier());
      } catch (error) {
        this.notificationService.addNotification(
          IDs.GROUP_DELETED,
          Status.Error,
          'Failed to delete group',
          error
        );
      }
    } else {
      this.notificationService.addNotification(
        IDs.GROUP_DELETED,
        Status.Error,
        'Failed to delete group, project not loaded',
        ''
      );
    }
  }

  @action async getProjectUsersIfNeeded(identifier: string) {
    if (this.users === null) {
      this.getProjectUsers(identifier);
    }
  }

  @action async getProjectUsers(identifier: string) {
    this.notificationService.addNotification(
      'getProjectUsers',
      Status.Running,
      '',
      ''
    );

    try {
      const dapApiResponse = await DapApiAgent.agent.get(
        `/api/users/${identifier}`
      );

      this.notificationService.addNotification(
        'getProjectUsers',
        Status.Success,
        '',
        ''
      );
      const response = JSON.parse(dapApiResponse.text);
      const users: User[] = response.map((usr) => new User(usr));
      this.setUsers(users);
    } catch (error) {
      this.notificationService.addNotification(
        'getProjectUsers',
        Status.Error,
        'Failed to get groups',
        error
      );
    }
  }

  clearState() {
    // TODO this is fragile code, can we instead delete and recreate a new ProjectService each time a project is loaded?
    this.setProject(null);
    this.setAbilities(null);
    this.setGroups(null);
    this.setUsers(null);
    this.setDatasets(null);
  }

  @action setDatasets(datasets: Dataset[] | null): void {
    this.datasets = datasets;
  }

  @action setGroups(groups: Group[] | null): void {
    this.groups = groups;
  }

  @action setUsers(users: User[] | null): void {
    this.users = users;
  }

  @action setProject(project: Project | null): void {
    this.project = project;
  }

  @action setAbilities(abilities: string[] | null): void {
    this.abilities = abilities;
  }
}
