import React from 'react';
import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { Alert, AlertTitle } from '@mui/material';

import { IHistoryService } from '@extensions/services/IHistoryService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import User from '@extensions/models/User';
import theme from '@extensions/services/Theme';
import SignInForm from '@dapclient/components/user/SignInForm';

const StyledFullPageFormDiv = styled('div')({
  ...theme.FullPageForm.root,
});

const StyledFormDiv = styled('div')({
  ...theme.FullPageForm.form,
});

const StyledFormFooterDiv = styled('div')({
  ...theme.FullPageForm.formFooter,
  color: theme.palette.grey[700],
});

const StyledSignUpLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}));

export interface ISignInProps extends RouteComponentProps {
  securityService: ISecurityService;
  historyService: IHistoryService;
  location: any;
}

@inject('securityService', 'historyService')
@observer
export class SignIn extends React.Component<ISignInProps> {
  willRedirect = false;

  componentDidMount() {
    const { historyService, securityService, location } = this.props;
    this.willRedirect = securityService.checkForCallback(location.search);
    if (location.state && location.state.pathname) {
      historyService.setRedirectPath(location.state.pathname);
      if (location.state.search) {
        historyService.setRedirectFilter(location.state.search);
      }
      if (location.state.hash) {
        historyService.setRedirectHash(location.state.hash);
      }
    }
  }

  render() {
    const user: User | null = this.props.securityService.user;
    if (user && user.authenticated) {
      if (this.willRedirect) {
        return (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            You have successfully signed in. Redirecting...
          </Alert>
        );
      }
      // after user has successfully authenticated (OR if they go directly to the signIn page),
      // redirect to profile or page they were previously on
      return (
        <Redirect
          to={{
            pathname: this.props.historyService.redirectPath || '/profile',
            search: this.props.historyService.redirectFilter || '',
            hash: this.props.historyService.redirectHash || '',
          }}
        />
      );
    }
    return (
      <StyledFullPageFormDiv>
        <StyledFormDiv>
          {/* Adding a unique key will force the form to recreate itself every time this page is rendered */}
          <SignInForm key={new Date().getMilliseconds()} />
        </StyledFormDiv>
        <StyledFormFooterDiv>
          {' '}
          Don't have an account?{' '}
          <StyledSignUpLink to="/register">Sign Up</StyledSignUpLink>
        </StyledFormFooterDiv>
      </StyledFullPageFormDiv>
    );
  }
}

export default withRouter(SignIn);
