import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const Content = styled.div`
  padding-top: 20px !important;
  padding-bottom: 50px !important;
  text-align: left;
`;

const Agreement: React.SFC<{}> = () => {
  return (
    <Content>
      <Typography variant="h1" gutterBottom>
        Page Not Found
      </Typography>
      <p>Sorry, the page you are looking for could not be found.</p>
    </Content>
  );
};

export default Agreement;
