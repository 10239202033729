import { makeObservable, observable, runInAction } from 'mobx';
import { isEqual } from 'lodash';

import { INotificationService } from '@extensions/services/INotificationService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import {
  IUploadService,
  IProjectConfig,
  IDatasetConfig,
  IClient,
  IClientUpdate,
} from '@extensions/services/IUploadService';
import DapApiAgent from '@extensions/utils/DapApiAgent';

export default class UploadService implements IUploadService {
  private notificationId = 'upload-service';

  private notificationService: INotificationService;
  private securityService: ISecurityService;

  @observable
  projectNames: string[] = [];

  @observable
  datasetNames: string[] = [];

  @observable
  currentProjectConfig: IProjectConfig | null = null;

  @observable
  clients: IClient[] = [];

  @observable
  isLoaded = false

  constructor(
    notificationService: INotificationService,
    securityService: ISecurityService
  ) {
    makeObservable(this);

    this.notificationService = notificationService;
    this.securityService = securityService;

    this.securityService.addLoginListener(this._clearCache);
    this.securityService.addLogoutListener(this._clearCache);
  }

  load = () => {
    if (this.isLoaded) {
      return;
    }

    const fetch = async () => {
      const [projectNames, datasetNames] = await Promise.all([
        DapApiAgent.agent
          .get('/api/uploaders')
          .then(response => response.body),
        DapApiAgent.agent
          .get('/api/datasets/uploadable')
          .then(response => response.body),
      ]);
      runInAction(() => {
        this.projectNames = projectNames;
        this.datasetNames = datasetNames;
        this.isLoaded = true;
      });
    };

    this.notificationService.showStateInUI({
      pending: fetch(),
      notificationId: this.notificationId,
      errorMessage: 'Failed to fetch uploadable resources',
    });
  };

  loadProject = (projectName: string, force: boolean) => {
    if (
      force === false &&
      this.currentProjectConfig !== null &&
      this.currentProjectConfig.name === projectName
    ) {
      return;
    }

    const fetch = async () => {
      const [config, clients] = await Promise.all([
        DapApiAgent.agent.get(
          `/api/uploaders/${projectName}/config`
        ).then(response => response.body),
        this.loadClients(projectName).then(response => response.body),
      ]);
      runInAction(() => {
        this.currentProjectConfig = config;
        this.clients = clients;
      });
    };

    this.notificationService.showStateInUI({
      pending: fetch(),
      notificationId: this.notificationId,
      errorMessage: 'Failed to fetch project configuration',
    });
  };

  loadDatasetConfig = async (datasetName: string) => {
    const [projectName, leafDatasetName] = datasetName.split('/');
    const response = await DapApiAgent.agent.get(
      `/api/uploaders/${projectName}/datasets/${leafDatasetName}`
    );
    const config = response.body;
    if (Array.isArray(config.ui_conf)) {
      // Thanks, PHP
      config.ui_conf = undefined;
    }
    return config;
  };

  testDatasetMapping = async (
    testPath: string,
    sourceName: string,
    rootPath: string,
    mapping: { pattern: string; template: string }[],
  ) => {
    const response = await DapApiAgent.agent.post(
      `/_upload/check-mapping`
    ).send({
      path: testPath,
      source: sourceName,
      root: rootPath,
      mapping: mapping.map(
        ({pattern, template}) => ({from: pattern, to: template})
      ),
    });
    return response.body.mapped || null;
  };

  saveDatasetConfig = async (config: IDatasetConfig) => {
    const [projectName, leafDatasetName] = config.name.split('/');
    const response = await this.notificationService.showStateInUI({
      pending: DapApiAgent.agent.put(
        `/api/uploaders/${projectName}/datasets/${leafDatasetName}`
      ).send({name: config.name, ...config.ui_conf}),
      notificationId: this.notificationId,
      errorMessage: 'Failed to create/update dataset configuration',
    });
    return response ? response.body : null;
  };

  deleteDatasetConfig = async (datasetName: string) => {
    const [projectName, leafDatasetName] = datasetName.split('/');
    const response = await this.notificationService.showStateInUI({
      pending: DapApiAgent.agent.delete(
        `/api/uploaders/${projectName}/datasets/${leafDatasetName}`
      ),
      notificationId: this.notificationId,
      errorMessage: 'Failed to create/update dataset configuration',
    });
    if (response) {
      runInAction(() => {
        if (this.currentProjectConfig) {
          this.currentProjectConfig.datasets = this.currentProjectConfig.datasets.filter(
            name => name !== datasetName
          );
        }
      });
    }
    return response || null;
  };

  getExecutableDownloadUrl = (os: string) => {
    if (this.currentProjectConfig === null) {
      return "";
    }
    return `/api/uploaders/${this.currentProjectConfig.name}/download/${os}`;
  }

  loadClients = (projectName: string) => {
    return DapApiAgent.agent.get(`/api/uploaders/${projectName}/clients`);
  };

  refreshClients = async () => {
    if (this.currentProjectConfig === null) {
      return;
    }
    const response = await this.loadClients(this.currentProjectConfig.name);
    if (!response) {
      return;
    }
    const clients = response.body;
    if (response && !isEqual(clients, this.clients)) {
      runInAction(() => {
        this.clients = clients;
      });
    }
  };

  updateClient = async (update: IClientUpdate) => {
    if (this.currentProjectConfig === null) {
      return;
    }
    const response = await this.notificationService.showStateInUI({
      pending: DapApiAgent.agent.put(
        `/api/uploaders/${this.currentProjectConfig.name}/clients/${update.id}`
      ).send(update),
      notificationId: this.notificationId,
      errorMessage: 'Failed to update client',
    });
    if (response) {
      await this.refreshClients();
    }
  };

  deleteClient = async (id: string) => {
    if (this.currentProjectConfig === null) {
      return;
    }
    const response = await this.notificationService.showStateInUI({
      pending: DapApiAgent.agent.delete(
        `/api/uploaders/${this.currentProjectConfig.name}/clients/${id}`
      ),
      notificationId: this.notificationId,
      errorMessage: 'Failed to delete client',
    });
    if (response) {
      await this.refreshClients();
    }
  };

  private _clearCache = () => {
    this.projectNames = [];
    this.datasetNames = [];
    this.currentProjectConfig = null;
    this.clients = [];
    this.isLoaded = false;
  };
}
