import { Divider, Grid } from '@mui/material';
import * as React from 'react';

const DatasetHelp: React.SFC<{}> = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>What is an API?</h3>
          <p>
            An API, or application programming interface, is a way that machines
            talk to one another. It is a way for one application to provide data
            from its database to another in a standardized, machine readable
            format such as JSON or XML. Sharing data via API is the industry
            standard and the Livewire Data Platform uses web APIs that allows
            users to GET data from one application to another.
          </p>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h3>Why should you use an API?</h3>
          <p>
            APIs make data exchange easy by removing barriers to access. They do
            this by making data machine readable in a format that is
            standardized, downloadable, free, and easy to find. In addition,
            APIs:
          </p>
          <ul>
            <li>Decrease costs;</li>
            <li>Allow reusability and eliminate duplication;</li>
            <li>
              Standardize the exchange of understandable groupings of
              information;
            </li>
            <li>
              Increase the speed, efficiency, and dependability of
              communications;
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h3>When should you use or NOT use an API?</h3>
          <p>
            APIs are most useful when dealing with large amounts of data that
            may change over time. Rather than manually updating the data each
            time it changes, accessing data via API allows applications to
            automatically update as the content changes. An API may not be the
            most practical choice when dealing with a static or private dataset.
          </p>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h3>Sources and additional resources</h3>
          <ul>
            <li>
              <a href="https://github.com/18F/API-All-the-X/tree/master/pages">
                https://github.com/18F/API-All-the-X/tree/master/pages
              </a>
            </li>
            <li>
              <a href="http://apievangelist.com/">http://apievangelist.com/</a>
            </li>
            <li>
              <a href="https://api.data.gov/docs/api-key/">
                https://api.data.gov/docs/api-key/
              </a>
            </li>
            <li>
              <a href="https://api.data.gov/docs/rate-limits/">
                https://api.data.gov/docs/rate-limits/
              </a>
            </li>
            <li>
              <a href="https://api.data.gov/docs/errors/">
                https://api.data.gov/docs/errors/
              </a>
            </li>
            <li>
              <a href="https://www.programmableweb.com/api-university/what-are-apis-and-how-do-they-work">
                https://www.programmableweb.com/api-university/what-are-apis-and-how-do-they-work
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default DatasetHelp;
