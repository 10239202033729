import {
  IDs,
  INotificationService,
  Status,
} from '@dapclient/services/INotificationService';
import config from '@extensions/utils/ConfigUtil';
import Dataset from '@extensions/models/Dataset';
import Project from '@extensions/models/Project';
import { DefaultQuery } from '@extensions/models/RealTimeData';
import FileOrder from '@extensions/models/FileOrder';
import FileUpload from '@extensions/models/FileUpload';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import LambdaApiAgent from '@extensions/utils/LambdaApiAgent';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import find from 'lodash/find';
import map from 'lodash/map';
import {
  action,
  observable,
  reaction,
  transaction,
  makeObservable,
} from 'mobx';
import moment, { Moment } from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { ISecurityService } from '@extensions/services/ISecurityService';

import { ICachingService } from '@extensions/services/ICachingService';
import {
  IDatasetService,
  IDatasetFile,
} from '@extensions/services/IDatasetService';

export default class DatasetService implements IDatasetService {
  @observable dataset: Dataset | null = null;
  @observable project: Project | null = null;
  @observable dynamoFiles: IDatasetFile[] | null = null;
  @observable orders: FileOrder[] | null = null;
  @observable uploads: FileUpload[] | null = null;
  private notificationService: INotificationService;
  private cachingService: ICachingService;
  private securityService: ISecurityService;

  constructor(
    notificationService: INotificationService,
    cachingService: ICachingService,
    securityService: ISecurityService
  ) {
    makeObservable(this);
    this.notificationService = notificationService;
    this.cachingService = cachingService;
    this.securityService = securityService;
    // SJB: changed this from observe() to reaction() per the docs
    reaction(
      () =>
        find(
          this.notificationService.notifications,
          (n) => n.id === IDs.USER_LOGGED_OUT && n.status === Status.Success
        ) !== undefined,
      (loggedOut: boolean) => {
        if (loggedOut) {
          this.clearState();
        }
      }
    );
  }

  @action async loadDataset(datasetName: string) {
    this.notificationService.addNotification(
      IDs.GET_DATASET,
      Status.Running,
      ''
    );
    // clear previously loaded dataset info
    this.clearState();

    try {
      // not using lambdaAgent here as this is not a lambda api call. auth header not necessary here as cookie is used
      const dataset = await this.cachingService.getDataset(datasetName);
      const project = await this.cachingService.getProjectLite(dataset.projectName);

      // Wrap in a mobx transaction to not update observers until the whole block is complete
      transaction(() => {
        this.setDataset(dataset, project);

        this.notificationService.addNotification(
          IDs.GET_DATASET,
          Status.Success,
          ''
        );
      });
    } catch (error: any) {
      if (
        error &&
        error.status === 403 &&
        !this.securityService.userIsLoggedIn
      ) {
        this.notificationService.removeNotification(IDs.GET_DATASET);
        return true;
      }
      this.notificationService.addNotification(
        IDs.GET_DATASET,
        Status.Error,
        'Failed to get dataset',
        error
      );
    }
    return false;
  }

  @action async loadDatasetFilesIfNeeded() {
    if (this.dynamoFiles === null) {
      this.loadDatasetFiles();
    }
  }

  @action async loadDatasetFiles() {
    this.notificationService.addNotification(
      IDs.GET_DATASET_FILES,
      Status.BackgroundRunning,
      ''
    );
    try {
      const datasetName = this.dataset ? this.dataset.name : '';
      const fileList = await getFileList(datasetName);

      this.notificationService.addNotification(
        IDs.GET_DATASET_FILES,
        Status.Success,
        ''
      );
      this.setDynamoFiles(fileList);
    } catch (error) {
      this.notificationService.addNotification(
        IDs.GET_DATASET_FILES,
        Status.Error,
        'Failed to get dataset files',
        error
      );
    }
  }

  @action async getDatasetFilesMetadataIfNeeded() {
    const { user } = this.securityService;
    if (user && user.emailVerified && user.approved) {
      this.getDatasetFilesMetadata();
    }
  }

  @action async getDatasetFilesMetadata() {
    this.notificationService.addNotification(
      'getDatasetFilesMetadata',
      Status.Running,
      '',
      ''
    );
    try {
      const apiUrl = config.getConfig().lambdaApi;
      const datasetName = this.dataset ? this.dataset.name : '';
      const payload = {
        filter: {
          Dataset: datasetName,
        },
        output: 'json',
        source: config.getConfig().reportTable,
      };
      const response = await LambdaApiAgent.agent
        .post(`${apiUrl}/searches`)
        .send(payload);

      // Wrap in a mobx transaction to not update observers until the whole block is complete
      transaction(() => {
        const data = JSON.parse(response.text);
        if (data[0] && this.dataset) {
          const record = data[0];
          this.dataset.setDynamoFileCount(record.file_count);
          this.dataset.setDynamoTotalFileSize(record.size);
          this.dataset.setDynamoLastModified(record.modtime);
          this.dataset.setDynamoFullExtensions(record.full_extensions);
          this.dataset.setDynamoRangeMinMax(
            record.range != null
              ? { min: record.range_min, max: record.range_max }
              : null
          );
          if (record.data_begins) {
            this.dataset.setDynamoDataBegins(record.data_begins);
          }
          if (record.data_ends) {
            this.dataset.setDynamoDataEnds(record.data_ends);
          }
        }

        this.notificationService.addNotification(
          'getDatasetFilesMetadata',
          Status.Success,
          '',
          ''
        );
      });
    } catch (error) {
      this.notificationService.addNotification(
        'getDatasetFilesMetadata',
        Status.Error,
        'Failed to get dataset files metadata',
        error
      );
    }
  }

  @action async placeOrder(downloadType: string, fileList: string[]) {
    this.notificationService.addNotification(
      IDs.ORDER_PLACED,
      Status.Running,
      'Ordering data ...'
    );

    try {
      if (this.dataset) {
        const putPayload = {
          files: fileList,
          dataset: this.dataset.name,
        };
        const apiUrl = config.getConfig().lambdaApi;
        const putResponse = await LambdaApiAgent.agent
          .put(`${apiUrl}/orders`)
          .send(putPayload);

        const order = JSON.parse(putResponse.text);

        const urlsResponse = await LambdaApiAgent.agent.get(
          `${apiUrl}/orders/${order.id}/urls`
        );

        const orderUrls = JSON.parse(urlsResponse.text);
        const orderResponse = await LambdaApiAgent.agent.get(
          `${apiUrl}/orders/${order.id}`
        );

        const orderFiles = JSON.parse(orderResponse.text);

        this.notificationService.addNotification(
          IDs.ORDER_PLACED,
          Status.Success,
          'Data ordered successfully'
        );
        this.downloadFiles(downloadType, orderUrls, orderFiles);
      }
    } catch (error) {
      this.notificationService.addNotification(
        IDs.ORDER_PLACED,
        Status.Error,
        'Failed to place order',
        error
      );
    }
  }

  getAfterLastSlash(nameWithSlash) {
    return nameWithSlash.substring(nameWithSlash.lastIndexOf('/') + 1);
  }

  @action async downloadFiles(downloadType: string, orderUrls, orderFiles) {
    const msgs =
      downloadType === 'zip'
        ? [
            'Downloading data ...',
            'Data downloaded successfully',
            'Failed to download data',
          ]
        : [
            'Generating script ...',
            'Script generated successfully',
            'Failed to generate script',
          ];
    this.notificationService.addNotification(
      'downloadOrder',
      Status.Downloading,
      msgs[0]
    );
    if (this.dataset) {
      try {
        // Wrap in a mobx transaction to not update observers until the whole block is complete
        transaction(async () => {
          let func = generateZip;
          if (downloadType === 'windowsScript') {
            func = generateWindowsScript;
          } else if (downloadType === 'linuxScript') {
            func = generateLinuxScript;
          }
          await func(this.dataset?.name, orderUrls, orderFiles)
          this.notificationService.addNotification(
            'downloadOrder',
            Status.Success,
            msgs[1]
          );
        });
      } catch (error) {
        this.notificationService.addNotification(
          'downloadOrder',
          Status.Error,
          msgs[2],
          error
        );
      }
    }
  }

  @action async getFileOrders() {
    this.notificationService.addNotification(
      'getOrders',
      Status.Running,
      '',
      ''
    );

    try {
      const apiUrl = config.getConfig().lambdaApi;
      const response = await LambdaApiAgent.agent.get(`${apiUrl}/orders`);

      this.notificationService.addNotification(
        'getOrders',
        Status.Success,
        '',
        ''
      );
      const data = JSON.parse(response.text);
      const ordersJsonArray: any[] = data.orders;

      const orders = ordersJsonArray.map(
        (order) =>
          new FileOrder(order, this.notificationService, this.cachingService)
      );
      this.setFileOrders(orders);
    } catch (error) {
      this.notificationService.addNotification(
        'getOrders',
        Status.Error,
        'Failed to get orders',
        error
      );
    }
  }

  @action async loadUploadedFilesIfNeeded() {
    if (this.uploads === null) {
      this.loadUploadedFiles();
    }
  }

  @action async loadUploadedFiles() {
    this.notificationService.addNotification(
      'getUploads',
      Status.Running,
      '',
      ''
    );

    try {
      if (this.dataset) {
        const response = await DapApiAgent.agent.get(
          `/api/datasets/${this.dataset.name}/uploads`
        ); // TODO some dataset's dont include project in the name and for this case the response is currently a 404 - what to do still being discussed
        transaction(() => {
          const data: any[] = JSON.parse(response.text);
          this.setFileUploads(data.map((file) => new FileUpload(file)));
          this.notificationService.addNotification(
            'getUploads',
            Status.Success,
            '',
            ''
          );
        });
      }
    } catch (error) {
      this.notificationService.addNotification(
        'getUploads',
        Status.Error,
        'Failed getting uploaded files',
        error
      );
    }
  }

  @action async downloadFile(owner: string, name: string) {
    this.notificationService.addNotification(
      'downloadFile',
      Status.Downloading,
      'Downloading file(s) ...'
    );

    try {
      if (this.dataset) {
        const response = await DapApiAgent.agent
          .get(`/api/datasets/${this.dataset.name}/uploads/${owner}/${name}`)
          .responseType('blob');
        await saveAs(response.body, name);

        this.notificationService.addNotification(
          'downloadFile',
          Status.Success,
          'File(s) downloaded successfully'
        );
      }
    } catch (error) {
      this.notificationService.addNotification(
        'downloadFile',
        Status.Error,
        'Failed to download file',
        error
      );
    }
  }

  @action async renameFile(owner: string, oldName: string, newName: string) {
    this.notificationService.addNotification(
      'renameFile',
      Status.Running,
      '',
      ''
    );

    try {
      if (this.dataset) {
        await DapApiAgent.agent
          .put(`/api/datasets/${this.dataset.name}/uploads/${owner}/${oldName}`)
          .send({
            name: newName,
          });
        this.notificationService.addNotification(
          'renameFile',
          Status.Success,
          '',
          ''
        );
      }
    } catch (error) {
      this.notificationService.addNotification(
        'renameFile',
        Status.Error,
        'Failed to rename file',
        error
      );
    }
  }

  @action async deleteFile(owner: string, name: string) {
    this.notificationService.addNotification('deleteFile', Status.Running, '');

    try {
      if (this.dataset) {
        await DapApiAgent.agent.delete(
          `/api/datasets/${this.dataset.name}/uploads/${owner}/${name}`
        );
        this.notificationService.addNotification(
          'deleteFile',
          Status.Success,
          ''
        );
      }
    } catch (error) {
      this.notificationService.addNotification(
        'deleteFile',
        Status.Error,
        'Failed deleting file',
        error
      );
    }
  }

  @action async submitFile(owner: string, name: string) {
    // need to uniquely identify each  file's notification since mulitple files can be submitted at once, need to be able
    // to tell which ones were successful/failed

    this.notificationService.addNotification(
      `submitFile${name}`,
      Status.Running,
      `Submitting ${name} to Dataset`
    );
    try {
      if (this.dataset) {
        await DapApiAgent.agent
          .put(`/api/datasets/${this.dataset.name}/uploads/${owner}/${name}`)
          .send({
            release: true,
          });
        this.notificationService.addNotification(
          `submitFile${name}`,
          Status.Success,
          `Submitting ${name} to Dataset`,
          `${name} submitted successfully`
        );
        // clear out any dynamo files previously loaded as that cached list will not contain any of these 'released' files
        this.setDynamoFiles(null);

        // adding a generic (non-ui) notification so that manage files UI knows when to display alert about it taking a while
        this.notificationService.addNotification(
          IDs.FILES_SUBMITTED,
          Status.Success,
          ''
        );
      }
    } catch (error) {
      this.notificationService.addNotification(
        `submitFile${name}`,
        Status.Error,
        `Failed submitting ${name}`,
        error
      );
    }
  }

  clearState() {
    // TODO this is fragile code, can we instead delete and recreate a new DatasetService each time a dataset is loaded?
    transaction(() => {
      this.setDataset(null, null);
      this.setDynamoFiles(null);
      this.setFileUploads(null);
      this.setFileOrders(null);
    });
  }

  @action setFileUploads(uploads: FileUpload[] | null): void {
    this.uploads = uploads;
  }

  @action setFileOrders(orders: FileOrder[] | null): void {
    this.orders = orders;
  }

  @action setDynamoFiles(files: IDatasetFile[] | null): void {
    this.dynamoFiles = (files && files.length) ? files : null;
  }

  @action setDataset(dataset: Dataset | null, project: Project | null): void {
    this.dataset = dataset;
    this.project = project;
  }
}

const buildExtensionsFilter = (exts: string[]) => {
  let filter = {};
  for (const ext of exts) {
    const parts = ext.split('.');
    parts.forEach((x, i) => {
      const k = i < parts.length - 1 ? `ext${i + 1}` : 'file_type';
      if (!filter.hasOwnProperty(k)) {
        filter[k] = [];
      }
      filter[k].push(x);
    });
  }
  return filter;
};

const buildDateRangeFilter = (range: [Moment, Moment]) => {
  return {
    date_time: {
      between: range.map((d) => d.format('YYYYMMDDHHmmss'), range),
    },
  };
};

export const getFileList = (
  dataset: string,
  exts?: string[],
  range?: [Moment, Moment]
) => {
  let filter = {
    Dataset: dataset,
    latest: true,
  };
  if (exts) {
    filter = { ...filter, ...buildExtensionsFilter(exts) };
  }
  if (range) {
    filter = { ...filter, ...buildDateRangeFilter(range) };
  }
  return LambdaApiAgent.agent
    .post(`${config.getConfig().lambdaApi}/searches`)
    .send({
      filter,
      output: 'json',
      source: 'inventory',
    })
    .then(parseDatasetFile);
};

export const getRecentFileList = (
  dataset: string,
  limit: number,
  exts?: string[]
) => {
  let filter = {
    Dataset: dataset,
    received: {
      lt: Math.ceil(Date.now() / 1000),
    },
  };
  if (exts) {
    filter = { ...filter, ...buildExtensionsFilter(exts) };
  }
  return LambdaApiAgent.agent
    .post(`${config.getConfig().lambdaApi}/searches`)
    .send({
      filter,
      output: 'json',
      source: 'inventory',
      reverse: true,
      limit,
    })
    .then(parseDatasetFile);
};

const parseDatasetFile = (d) => {
  return map(d.body as any[], (f) => {
    return {
      ...f,  // Keep original properties because [at least] <SmallDataOrder/> needs them
      name: f.Filename.split('/').pop() as string,
      date: moment(f.data_date, 'YYYYMMDD'),
      iteration: f.iteration as number,
      received: moment.unix(f.received),
      signature: f.signature as string,
      size: f.size as number,
    } as IDatasetFile;
  });
};

export const getDownloadUrls = (
  dataset: string,
  exts?: string[],
  range?: [Moment, Moment]
) => {
  let filter = {
    Dataset: dataset,
    latest: true,
  };
  if (exts) {
    filter = { ...filter, ...buildExtensionsFilter(exts) };
  }
  if (range) {
    filter = { ...filter, ...buildDateRangeFilter(range) };
  }
  return LambdaApiAgent.agent
    .post(`${config.getConfig().lambdaApi}/downloads`)
    .send({
      filter,
      output: 'json',
    });
};

export const getDownloadUrl = (dataset: string, name: string) => {
  const datasetLeaf = dataset.split('/').pop() as string;
  const nparts = datasetLeaf.split('.').length;
  const fparts = name.split('.').slice(nparts);
  const dt = [fparts.shift(), fparts.shift()].join('');
  let filter = {
    Dataset: dataset,
    date_time: dt,
  };
  let i = 1;
  for (const ext of fparts) {
    filter[i === fparts.length ? 'file_type' : `ext${i++}`] = ext;
  }
  return LambdaApiAgent.agent
    .post(`${config.getConfig().lambdaApi}/downloads`)
    .send({
      filter,
      output: 'json',
    });
};

export const downloadViewPaneFiles = (filter: any) => {
  try {
    return LambdaApiAgent.agent
      .post(`${config.getConfig().lambdaApi}/downloads`)
      .send({
        output: 'json',
        converter: 'tiff',
        filter: filter,
      });
  } catch (err) {
    console.log(err);
  }
};

export const downloadDataPlot = (defaultQuery: DefaultQuery, sensorID: number) => {
  let modifiedQuery = cloneDeep(defaultQuery);
  try {
    modifiedQuery.filter["sensorID"] = sensorID;
      return LambdaApiAgent.agent
        .post(`${config.getConfig().lambdaApi}/searches`)
        .send(modifiedQuery);
  } catch (err) {
    console.log(err);
  }
};

export const downloadDataTable = (defaultQuery: DefaultQuery) => {
  try {
    return LambdaApiAgent.agent
        .post(`${config.getConfig().lambdaApi}/searches`)
        .send(defaultQuery);
  } catch (err) {
    console.log(err);
  }
};

const getAfterLastSlash = (nameWithSlash) => {
  return nameWithSlash.substring(nameWithSlash.lastIndexOf('/') + 1);
};

const mergeOrderFilesWithUrls= (orderUrls, orderFiles) => {
  return orderFiles.order.files.map(
    (fileName: any) => {
      const name = getAfterLastSlash(fileName);
      const fileUrl = find(
        orderUrls.urls,
        (url: { indexOf: (arg0: any) => number }) =>
          url.indexOf(name) !== -1
      );
      return {
        name,
        url: fileUrl,
      };
    }
  );
};

// https://stackoverflow.com/questions/17913609/javascript-unicode-base64-encode
const base64EncodeUnicode = (str: string) => {
  const ar = new Array(str.length * 2);
  let i: number;
  let j: number;
  let s: string;
  // Build array of bytes
  for (i = 0, j = 0; i < str.length; j = 2 * ++i) {
    ar[j] = str.charCodeAt(i);
  }
  // Build string from array
  s = String.fromCharCode.apply(String, ar);
  // To base64
  return btoa(s);
};

// This way should work but apparently these interleaved "zero bytes" are necessary?
// Thanks, Microsoft.
// const base64EncodeUnicode = (str: string) => {
//   const strarr = Array.from(str).map((_, i) => str.codePointAt(i) || 0);
//   return btoa(String.fromCharCode(...strarr));
// };

export const generateWindowsScript = async (datasetName, orderUrls, orderFiles) => {
  const datasetFileName = getAfterLastSlash(datasetName);
  const dataOrderFileLinks = mergeOrderFilesWithUrls(orderUrls, orderFiles);
  const body =
    `@echo off\r\n` +
    dataOrderFileLinks
      .map((fileLink: { url: any; name: any }) => {
        const cmd = `(New-Object System.Net.WebClient).DownloadFile('${fileLink.url}', '${fileLink.name}')`;

        return `echo Downloading %~dp0${
          fileLink.name
        } ...\r\n if not exist ${
          fileLink.name
        } powershell -EncodedCommand ${base64EncodeUnicode(cmd)}\r\n`;
      })
      .join('');
  const blob = new Blob([body], {
    type: 'text/x-shellscript',
  });

  await saveAs(blob, `${datasetFileName}.bat`);
};

export const generateLinuxScript = async (datasetName, orderUrls, orderFiles) => {
  const expires = orderUrls.expires;
  const datasetFileName = getAfterLastSlash(datasetName);
  const dataOrderFileLinks = mergeOrderFilesWithUrls(orderUrls, orderFiles);
  const body =
    `#!/bin/bash\n` +
    `# Check for URL expiration\n` +
    `now=$(date +%s)\n` +
    `expires=${Math.floor(expires)}\n` +
    `if (( $now > $expires )); then\n` +
    `    echo "URLs have expired."\n` +
    `   exit\n` +
    `fi` +
    `\n` +
    `# Check for wget/curl\n` +
    `if hash wget 2>/dev/null; then\n` +
    `    program="wget -O"\n` +
    `elif hash curl 2>/dev/null; then\n` +
    `    program="curl -o"\n` +
    `else\n` +
    `    echo "Could not find wget or curl."\n` +
    `    exit\n` +
    `fi\n` +
    `\n` +
    `# Download files one by one\n` +
    dataOrderFileLinks
      .map(
        (fileLink: { name: any; url: any }) =>
          `if [ ! -f ${fileLink.name} ]; then\n` +
          `$program "${fileLink.name}" "${fileLink.url}"\n` +
          'fi\n'
      )
      .join('');
  const blob = new Blob([body], {
    type: 'text/x-shellscript',
  });

  await saveAs(blob, `${datasetFileName}.sh`);
};

export const generateZip = async (datasetName, orderUrls, orderFiles) => {
  const datasetFileName = getAfterLastSlash(datasetName);
  const dataOrderFileLinks = mergeOrderFilesWithUrls(orderUrls, orderFiles);
  const zip = new JSZip();
  const folder = zip.folder(datasetFileName);
  dataOrderFileLinks.forEach(
    (fileLink: { url: RequestInfo; name: any }) => {
      const blobPromise = fetch(fileLink.url).then((r) => {
        if (r.status === 200) {
          return r.blob();
        }

        return Promise.reject(new Error(r.statusText));
      });
      // folder.file(fileLink.name, blobPromise);
      if (folder) {
        folder.file(fileLink.name, blobPromise);
      }
    }
  );
  await zip
    .generateAsync({ type: 'blob' })
    .then((blob: any) => saveAs(blob, `${datasetFileName}.zip`))
    .catch((e: any) => Promise.reject(e));
};
