import React from 'react';
import { styled } from '@mui/material/styles';
import { observer, inject } from 'mobx-react';

// App specific imports
import { ISecurityService } from '@extensions/services/ISecurityService';

import MustSignInAlert from './MustSignInAlert';
import AwaitApprovalAlert from './AwaitApprovalAlert';
import MustVerifyEmailAlert from './MustVerifyEmailAlert';

export interface IDatasetGuardProps {
  securityService?: ISecurityService;
  isProjectIncluded?: boolean;
  children: any;
}

const StyledMustVerifyEmailAlert = styled(MustVerifyEmailAlert)(({
  width: '100%',
}));

const StyledAwaitApprovalAlert = styled(AwaitApprovalAlert)(({
  width: '100%',
}));


@inject('securityService')
@observer
class DatasetGuard extends React.Component<IDatasetGuardProps> {
  render() {
    const { securityService, children } = this.props;
    const loggedInUser = securityService!.user;
    let errorContent: React.ReactNode = null;

    if (!loggedInUser || !loggedInUser.authenticated) {
      errorContent = (
        <MustSignInAlert
          sx={{ width: '100%' }}
          actionDescription={this.props.isProjectIncluded ? "view or request access to project(s)" : "view or request access to dataset(s)"}
        />
      );
    } else if (!loggedInUser.emailVerified) {
      errorContent = <StyledMustVerifyEmailAlert />;
    } else if (loggedInUser.approvalPending) {
      errorContent = <StyledAwaitApprovalAlert />;
    }

    if (errorContent) {
      return errorContent;
    } else {
      return children;
    }
  }
}

export default DatasetGuard;
