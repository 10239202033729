import styled from "@emotion/styled";
import {
  IconButton,
  Modal as MuiModal,
  ModalProps as MuiModalProps,
} from '@mui/material';
import Close from '@mui/icons-material/Close';

const StyledModal = styled(MuiModal)`
  display: flex;
  alignItems: center;
  justifyContent: center;
`

const Outer = styled.div`
  position: relative;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
  border-radius: 3px;
  background-color: #fff;
  & > button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }
`

export interface ModalProps extends MuiModalProps {
  handleClose: () => void
}

export const Modal = ({ children, handleClose, ...rest }: ModalProps) => {
  return (
    <StyledModal onClose={handleClose} {...rest}>
      <Outer>
        <IconButton size="small" onClick={handleClose}>
          <Close />
        </IconButton>
        {children}
      </Outer>
    </StyledModal>
  )
};
