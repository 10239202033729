import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ButtonGroup,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IHistoryService } from '@extensions/services/IHistoryService';
import { IPublicationService } from '@extensions/services/IPublicationService';

const StyledSpan = styled('span')(({ theme }) => ({
  display: 'inline-flex',
  fontWeight: theme.typography.fontWeightBold,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  color: '#000',
  '&:hover': {
    backgroundColor: '#bab8b8',
  }
}));

export interface IAdminAccordionProps {
  className?: string;
  publicationService?: IPublicationService;
  historyService?: IHistoryService;
}

export interface IAdminAccordionState { }

enum PublicationStatus {
  PUBLIC = 'Public',
  PENDING = 'Pending Approval',
  REJECTED = 'Rejected',
}

@observer
export class AdminAccordion extends React.Component<
  IAdminAccordionProps,
  IAdminAccordionState
> {
  getStatus = (): PublicationStatus | null => {
    const { publicationService } = this.props;
    const selected = publicationService?.selectedPublication;
    if (!selected) {
      return null;
    }

    if (selected.isApproved) {
      return PublicationStatus.PUBLIC;
    }
    if (selected.isRejected) {
      return PublicationStatus.REJECTED;
    }
    return PublicationStatus.PENDING;
  };

  getButtons = (): React.ReactNode => {
    const { publicationService, historyService } = this.props;
    const status = this.getStatus();
    const selected = publicationService?.selectedPublication;
    if (
      status === null ||
      !publicationService ||
      !historyService ||
      !selected
    ) {
      return null;
    }

    const buttons: React.ReactNode[] = [];
    if (status !== PublicationStatus.PUBLIC) {
      buttons.push(
        <StyledButton
          key="approve"
          onClick={() => publicationService.approve(selected)}
        >
          Approve
        </StyledButton>
      );
    }
    if (status !== PublicationStatus.PENDING) {
      buttons.push(
        <StyledButton
          key="pending"
          onClick={() => publicationService.makePending(selected)}
        >
          Move to Pending
        </StyledButton>
      );
    }
    if (status !== PublicationStatus.REJECTED) {
      buttons.push(
        <StyledButton
          key="reject"
          onClick={() => publicationService.reject(selected)}
        >
          Reject
        </StyledButton>
      );
    }
    if (status === PublicationStatus.REJECTED) {
      buttons.push(
        <StyledButton
          key="delete"
          onClick={() => {
            publicationService.delete(selected);
          }}
        >
          Delete
        </StyledButton>
      );
    }
    if (!selected['_importedFrom']) {
      buttons.push(
        <StyledButton
          key="edit"
          onClick={() => {
            historyService.history.push(`/publication/edit/${selected.id}`);
          }}
        >
          Edit
        </StyledButton>
      );
    }

    return buttons;
  };

  render() {
    const { publicationService } = this.props;
    const selected = publicationService?.selectedPublication;
    if (!selected) {
      return null;
    }
    const status = this.getStatus();

    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="publication-admin-content"
          id="publication-admin-header"
        >
          <Typography variant="h2">Manage Publication</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography sx={{ fontSize: '1.125rem' }}>
              This publication is currently{' '}
              <StyledSpan>{status}</StyledSpan>
            </Typography>

            <ButtonGroup variant="contained">{this.getButtons()}</ButtonGroup>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default inject((store: any) => ({
  publicationService: store.publicationService,
  historyService: store.historyService,
}))(AdminAccordion);
