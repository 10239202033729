import {
  IDs,
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import ITokenService from '@extensions/services/ITokenService';
import moment from 'moment';
import * as superagent from 'superagent';
import { autorun } from 'mobx';

export default class LambdaApiAgent {
  static agent: superagent.SuperAgentStatic & superagent.Request;
  static notificationService: INotificationService;
  static tokenService: ITokenService;

  static initialize(
    notificationService: INotificationService,
    tokenService: ITokenService
  ) {
    LambdaApiAgent.notificationService = notificationService;
    LambdaApiAgent.tokenService = tokenService;

    // set up a superagent agent to be used for lambda calls that always first checks if the labmda
    // cert needs to be renewed (and renews it) or if it's expired, and if so cancels the request and
    // adds the USER_TOKEN_EXPIRED notice so that the user is notified and is logged out.
    // also this agent adds the authorization header using the encoded token

    LambdaApiAgent.agent = superagent
      .agent()
      .accept('json')
      .on('request', req => {
        const lambdaExpires = tokenService.lambdaExpires;
        const now = moment().unix() - 60; // add a 1 min buffer, so subtract 60 seconds
        if (lambdaExpires !== null && lambdaExpires <= now) {
          LambdaApiAgent.notificationService.addNotification(
            IDs.USER_TOKEN_EXPIRED,
            Status.Warn,
            'Session Expired',
            'Your session has expired and you were logged out.'
          );
          req.abort();
        }
      })
      // this prevents the request from sending, otherwise when calling abort in case above where
      // lambda cert expired user sees http aborted error
      .on('abort', req => {
        req.end();
      });

    // watch for 401 responses, if received log the user's lambda tokens are no longer valid and log them out
    const AuthIntercept = require('superagent-intercept')((err, res) => {
      if (res && (res.status === 419 || res.status === 401)) {
        console.log(`received ${res.status} response, logging user out`);
        tokenService.refreshDapToken();

        LambdaApiAgent.notificationService.addNotification(
          IDs.USER_TOKEN_EXPIRED,
          Status.Warn,
          'Session Expired',
          'Your session has expired and you were logged out.'
        );
      }
    });

    LambdaApiAgent.agent.use(AuthIntercept);

    autorun(LambdaApiAgent.setAuthHeader);
  }

  static setAuthHeader = (): void => {
    const authHeader = 'Authorization';
    if (LambdaApiAgent.tokenService.lambdaToken) {
      LambdaApiAgent.agent.set(
        authHeader,
        `Cert ${btoa(LambdaApiAgent.tokenService.lambdaToken)}`
      );
    } else {
      LambdaApiAgent.agent.set(authHeader, `Basic ${btoa('guest:guest')}`);
    }
  };
}
