const DistributionType = {
  API: 'api',
  ByRequest: 'by-request',
  DownloadExternal: 'download-external',
  DownloadDap: 'download-dap',
  DownloadArm: 'download-arm',
  ExternalLink: 'External Link'
} as const

export function typeToLabel(type: string): string {
  switch (type) {
    case DistributionType.API: return "API";
    case DistributionType.ByRequest: return "By Request";
    case DistributionType.DownloadExternal: return "File Download (External)";
    case DistributionType.DownloadDap: return "File Download";
    case DistributionType.DownloadArm: return "File Download (via ARM)";
    case DistributionType.ExternalLink: return "External Link";
  }
  return type;
};

export default DistributionType;
