import { observable, computed, reaction, runInAction, action, makeObservable } from 'mobx';
import * as superagent from 'superagent';

import ITokenService from '@extensions/services/ITokenService';

export const LOCAL_STORAGE_KEYS = {
  dapToken: 'csrfToken',
  lambdaToken: 'lambdaToken',
  lambdaExpires: 'lambdaExpires',
  lambdaRenews: 'lambdaRenews',
};

export default class TokenService implements ITokenService {
  @computed
  get lambdaExpires(): number | null {
    if (this.rawLambdaExpires) {
      return parseInt(this.rawLambdaExpires, 10);
    } else {
      return null;
    }
  }
  @computed
  get lambdaRenews(): number | null {
    if (this.rawLambdaRenews) {
      return parseInt(this.rawLambdaRenews, 10);
    } else {
      return null;
    }
  }
  @observable
  dapToken: string | null;
  @observable
  lambdaToken: string | null;
  @observable
  rawLambdaExpires: string | null;
  @observable
  rawLambdaRenews: string | null;

  constructor() {
    makeObservable(this);
    // Initialize
    const dapTokenFromLocalStorage = localStorage.getItem(
      LOCAL_STORAGE_KEYS.dapToken
    );
    const dapTokenFromMeta = this.getTokenFromMetaTag();
    this.dapToken = dapTokenFromMeta || dapTokenFromLocalStorage || null;

    // Initialize from Lambda token from local storage
    this.lambdaToken = localStorage.getItem(LOCAL_STORAGE_KEYS.lambdaToken);
    this.rawLambdaExpires = localStorage.getItem(
      LOCAL_STORAGE_KEYS.lambdaExpires
    );
    this.rawLambdaRenews = localStorage.getItem(
      LOCAL_STORAGE_KEYS.lambdaRenews
    );

    // Override Lambda token with HTML tag if present
    const certJsonEl = document.getElementById('api-cert');
    if (certJsonEl) {
      const certInfo = JSON.parse(certJsonEl.textContent as string);
      if (certInfo.CERT !== "{{ session('cert') }}") {
        this.lambdaToken = certInfo.CERT;
      }
      if (certInfo.CERTEXPIRE !== "{{ session('cert-expire') }}") {
        this.rawLambdaExpires = certInfo.CERTEXPIRE;
      }
      if (certInfo.CERTRENEW !== "{{ session('cert-renew') }}") {
        this.rawLambdaRenews = certInfo.CERTRENEW;
      }
    }

    // Write to local storage when the values change in memory
    reaction(
      () => this.lambdaToken,
      lambdaToken =>
        this.writeItemToLocalStorage({
          key: LOCAL_STORAGE_KEYS.lambdaToken,
          value: lambdaToken,
        }),
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.rawLambdaRenews,
      rawLambdaRenews =>
        this.writeItemToLocalStorage({
          key: LOCAL_STORAGE_KEYS.lambdaRenews,
          value: rawLambdaRenews,
        }),
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.rawLambdaExpires,
      rawLambdaExpires =>
        this.writeItemToLocalStorage({
          key: LOCAL_STORAGE_KEYS.lambdaExpires,
          value: rawLambdaExpires,
        }),
      {
        fireImmediately: true,
      }
    );
    reaction(
      () => this.dapToken,
      dapToken =>
        this.writeItemToLocalStorage({
          key: LOCAL_STORAGE_KEYS.dapToken,
          value: dapToken,
        }),
      {
        fireImmediately: true,
      }
    );

    // Listen to local storage changes
    // Only fires when changes originate in other tabs
    window.addEventListener('storage', this.localStorageChangeListener);

    // remove from local storage old cached lambda and dap tokens.  This code can
    // eventually (mid 2020?) be removed once everyone has logged in with new code
    localStorage.removeItem('cacheLambdaCertInfo');
    localStorage.removeItem('data-api-auth-token');
  }

  refreshDapToken = (): Promise<void> => {
    return superagent
      .get('/api/csrf-token')
      .set('Accept', 'application/json')
      .then(response => {
        runInAction(() => {
          this.dapToken = JSON.parse(response.text).token;
        });
      })
      .catch(error => {
        // TODO: Alert user with notification
        console.log(error);
      });
  };

  @action
  clearLambdaTokens = (): void => {
    this.lambdaToken = null;
    this.rawLambdaExpires = null;
    this.rawLambdaRenews = null;

    // If we are clearing the tokens, it's best to update local storage
    // synchronously. If update it asynchronously, then it may not be updated.
    // For instance, the user might sign out, the tokens are updated in memory,
    // but the page redirects before they are cleared in local storage.
    for (const key in [
      LOCAL_STORAGE_KEYS.lambdaToken,
      LOCAL_STORAGE_KEYS.lambdaExpires,
      LOCAL_STORAGE_KEYS.lambdaRenews,
    ]) {
      this.writeItemToLocalStorage({
        key,
        value: null,
      });
    }
  };

  private writeItemToLocalStorage = ({
    key,
    value,
  }: {
    key: string;
    value: string | null;
  }) => {
    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  };

  @action
  private localStorageChangeListener = (event: StorageEvent) => {
    const hasChanged = (key: string): boolean => key === event.key;
    const getNewValue = (key: string): string | null => event.newValue;

    if (hasChanged(LOCAL_STORAGE_KEYS.dapToken)) {
      this.dapToken = getNewValue(LOCAL_STORAGE_KEYS.dapToken);
    }
    if (hasChanged(LOCAL_STORAGE_KEYS.lambdaToken)) {
      this.lambdaToken = getNewValue(LOCAL_STORAGE_KEYS.lambdaToken);
    }
    if (hasChanged(LOCAL_STORAGE_KEYS.lambdaExpires)) {
      this.rawLambdaExpires = getNewValue(LOCAL_STORAGE_KEYS.lambdaExpires);
    }
    if (hasChanged(LOCAL_STORAGE_KEYS.lambdaRenews)) {
      this.rawLambdaRenews = getNewValue(LOCAL_STORAGE_KEYS.lambdaRenews);
    }
  };

  private getTokenFromMetaTag(): string | null {
    const csrfMetaTag = document.head.querySelector('meta[name="csrf-token"]');
    if (
      csrfMetaTag &&
      csrfMetaTag.getAttribute('content') !== '{{ csrf_token() }}'
    ) {
      return csrfMetaTag.getAttribute('content');
    } else {
      return null;
    }
  }
}
