import React, { FocusEvent } from 'react';
import { DateRangePicker } from 'react-dates';
import styled from '@emotion/styled';
import DatePickerNav from '@extensions/components/core/date-picker/DatePickerNav';
import 'react-dates/lib/css/_datepicker.css';
import moment, { Moment } from 'moment';
import { observer } from 'mobx-react';
import { observable, runInAction, action, makeObservable } from 'mobx';

export interface DateRange {
  startDate: Moment | null;
  endDate: Moment | null;
}

export interface IDatePickerProps {
  /**The currently selected start date. */
  startDate: Moment | null;
  /** ID to apply to the start date input */
  startDateId: string;
  /**The currently selected end date */
  endDate: Moment | null;
  /** ID to apply to the end date input */
  endDateId: string;
  /**The oldest date which can be included in the range */
  oldestAllowed: Moment | null;
  /**The most recent date which cna be included in the range */
  newestAllowed: Moment | null;
  /*Passed {startDate, endDate} */
  onDatesChange: (newRange: DateRange) => void;
}

const OuterWrapper = styled.div`
  [type='text'] {
    margin: 0;
    font-size: 1rem;
    box-sizing: border-box;
  }
  .DateInput_fang {
    top: 56px !important;
  }
  .DateRangePicker_picker {
    z-index: 1000;
  }
  .DateRangePickerInput {
    display: inline-flex;
    align-items: center;
    width: 93%;
  }
`;

@observer
export default class DatePicker extends React.Component<IDatePickerProps> {
  @observable
  focusedInput: any;
  wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.changeEndDateEventListener({
      remove: false,
      event: 'blur',
      listener: this.handleEndDateBlur,
    });
  }

  componentWillUnmount() {
    this.changeEndDateEventListener({
      remove: true,
      event: 'blur',
      listener: this.handleEndDateBlur,
    });
  }

  /**
   * This was needed due this known bug:
   * https://github.com/airbnb/react-dates/issues/1809
   *
   * The bug caused the dropdown not to close, which covered content and
   * part of the site unusable for keyboard users.
   */
  @action
  handleEndDateBlur = (e: FocusEvent): void => {
    const nextElement = e.relatedTarget as HTMLElement;
    const wrapper = this.wrapperRef.current;
    if (!nextElement || !wrapper) {
      return;
    }

    const datePickerDropdown = wrapper.querySelector('.DateRangePicker_picker');
    if (datePickerDropdown && !datePickerDropdown.contains(nextElement)) {
      this.focusedInput = null;
    }
  };

  changeEndDateEventListener = ({ remove, event, listener }) => {
    const wrapper = this.wrapperRef.current;
    if (wrapper) {
      const endDateInput = this.getEndDateInput(wrapper);
      if (endDateInput) {
        if (remove) {
          endDateInput.removeEventListener(event, listener);
        } else {
          endDateInput.addEventListener(event, listener);
        }
      }
    }
  };

  getEndDateInput = (wrapper: HTMLDivElement) => {
    return wrapper.querySelector("[aria-label='End Date']");
  };

  /**
   * Should the given moment be disabled in the date pickers?
   * @param dateOption The day to disable or leave selectable.
   * @return True to disable the given date.
   */
  isDisabledDate = (dateOption: Moment): boolean => {
    const { oldestAllowed, newestAllowed } = this.props;
    if (oldestAllowed && newestAllowed) {
      return (
        dateOption.isBefore(oldestAllowed, 'day') ||
        dateOption.isAfter(newestAllowed, 'day')
      );
    }

    return false;
  };

  /**
   * @return The month that should be displayed when the date picker opens.
   */
  initialVisibleMonth = (): Moment => {
    const { startDate, endDate, oldestAllowed } = this.props;
    if (!startDate && !endDate) {
      return oldestAllowed || moment();
    }

    if (!startDate && endDate) {
      return endDate.clone().subtract(1, 'month');
    }

    return startDate as Moment;
  };

  /**
   * @return A new range where the start date is at the start of the day,
   * and the end date is at the end of the day.
   */
  expandRange = (range: DateRange) => {
    const { startDate, endDate } = range;
    let adjustedDates: DateRange = {
      startDate: null,
      endDate: null,
    };
    if (startDate) {
      adjustedDates.startDate = moment(startDate);
      adjustedDates.startDate.startOf('day');
    }
    if (endDate) {
      adjustedDates.endDate = moment(endDate);
      adjustedDates.endDate.endOf('day');
    }
    return adjustedDates;
  };

  render() {
    const {
      startDate,
      endDate,
      onDatesChange,
      oldestAllowed,
      newestAllowed,
      startDateId,
      endDateId,
    } = this.props;
    return (
      <OuterWrapper ref={this.wrapperRef}>
        <DateRangePicker
          startDate={startDate}
          startDateId={startDateId}
          endDate={endDate}
          endDateId={endDateId}
          isOutsideRange={this.isDisabledDate}
          onDatesChange={(newDates: DateRange) =>
            onDatesChange(this.expandRange(newDates))
          }
          focusedInput={this.focusedInput}
          onFocusChange={(focusedInput) =>
            runInAction(() => (this.focusedInput = focusedInput))
          }
          minimumNights={0}
          displayFormat="YYYY-MM-DD"
          initialVisibleMonth={this.initialVisibleMonth}
          renderMonthElement={({ month, onYearSelect }) => (
            <DatePickerNav
              monthBeingShown={month}
              onYearSelect={onYearSelect}
              oldestAllowed={oldestAllowed}
              newestAllowed={newestAllowed}
            />
          )}
          numberOfMonths={2}
        />
      </OuterWrapper>
    );
  }
}
