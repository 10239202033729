import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { Card, CardContent, Typography } from '@mui/material';

import Link from '@dapclient/components/core/Link';
import { PubDataset } from '@extensions/models/Publication';

const StyledCard = styled(Card)(({
  marginBottom: '0.25rem',
}));

export interface IDatasetListProps {
  datasets: PubDataset[];
}

export interface IDatasetListState { }

@observer
export class DatasetList extends React.Component<
  IDatasetListProps,
  IDatasetListState
> {
  render() {
    const { datasets } = this.props;
    let body: React.ReactNode;
    if (datasets.length === 0) {
      body = <Typography>No Datasets Provided</Typography>;
    } else {
      body = <StyledCard key="dataset-list-card">
        {datasets.map((dataset) =>
          <CardContent key={`${dataset.name}`}>
            <Link to={`/ds/${dataset.name}`} style={{ fontSize: '14px' }}>{dataset.name}</Link>
          </CardContent>
        )}
      </StyledCard>
    }
    return (
      <div>
        <Typography variant="h2">Datasets</Typography>
        {body}
      </div>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(DatasetList);
