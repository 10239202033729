import React from 'react';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, Switch, Route } from 'react-router-dom';

import RequiresAuth from '../core/RequiresAuth';
import ProjectModel from '@extensions/models/Project';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { IProjectService } from '@extensions/services/IProjectService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import ManageContent from '@extensions/components/project/ManageContent';
import ProjectContent from '@extensions/components/project/ProjectContent';
import { INotificationService } from '@extensions/services/INotificationService';

export interface IProjectProps extends RouteComponentProps {
  projectService: IProjectService;
  securityService: ISecurityService;
  notificationService: INotificationService;
  historyService: IHistoryService;
}

@observer
export class Project extends React.Component<IProjectProps> {
  async componentDidMount() {
    const path = this.props.location.pathname;
    const pathSegments: string[] = path.split('/');
    const projectName = pathSegments[2];
    const requiresLogin = await this.props.projectService.loadProject(projectName);
    if (requiresLogin) {
      this.props.securityService.requireLogin(null);
      return;
    }
    await when(
      () =>
        this.props.securityService!.userIsLoggedIn &&
        this.props.projectService.project !== null
    );
    this.props.projectService.getProjectAbilitiesIfNeeded();
  }

  render() {
    const { projectService } = this.props;
    const project: ProjectModel | null = projectService.project;
    if (project) {
      return (
        <div>
          <Switch>
            <Route
              exact
              path={`/project/${project.name}(/data)?`}
              render={() => (
                <ProjectContent project={project} {...this.props} />
              )}
            />
            <Route
              exact
              path={`/project/${project.name}/manage`}
              render={() => (
                <RequiresAuth>
                  <ManageContent project={project} {...this.props} />
                </RequiresAuth>
              )}
            />
          </Switch>
        </div>
      );
    }
    return null;
  }
}

export default inject((store: any) => ({
  projectService: store.projectService,
  historyService: store.historyService,
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(Project);
