import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useService } from '@dapclient/hooks/useService';

import { ISecurityService } from '@extensions/services/ISecurityService';
// import { IHistoryService } from '@extensions/services/IHistoryService';

export function useRequiresAuth() {
  const securitySvc = useService('securityService') as ISecurityService;
  // const historySvc = useService('historyService') as IHistoryService;

  // Need v6 ...
  // let navigate = useNavigate();
  // let history = useHistory();
  let location = useLocation();

  const willRedirect = securitySvc.userIsLoggedIn === false;

  useEffect(() => {
    if (willRedirect) {
      securitySvc.requireLogin(location.pathname);
      // securitySvc.setCallbackUrl(location.pathname);

      // history.push('/signIn');
      // navigate('/signIn');
    }
  }, [willRedirect, securitySvc, location]);

  return willRedirect;
}
