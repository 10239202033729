import React from 'react';
import styled from '@emotion/styled';
import { Moment } from 'moment';

export interface IDatePickerNavProps {
  /**
   * A moment object whose month and year are that of the currently
   * displayed month.
   */
  monthBeingShown: Moment;
  /**
   * Called when the user selects a new year. Will be passed monthBeingShown
   * as the first argument and the year (as a single number) as the second
   * argument.
   */
  onYearSelect: (monthBeingShown: Moment, newYear: number) => void;
  /**
   * The most recent moment which the user should be able to select.
   */
  newestAllowed: Moment | null;
  /**
   * The least recent moment which the user should be able to select.
   */
  oldestAllowed: Moment | null;
}

const OuterWrapper = styled.div`
  white-space: nowrap;
  margin-top: -4px;
`;

const Select = styled.select`
  width: 5rem;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-left: 0.5rem;
`;

/**
 * Renders the text and inputs shown above the calendar
 * of the date picker (where the month name and year normally
 * display). This customization is the only way to allow the
 * user to navigate by year.
 */
export default class DatePickerNav extends React.Component<
  IDatePickerNavProps
> {
  /**
   * Updates the displayed year after the user chooses
   * a new year in the select.
   */
  handleYearChange = changeEvent => {
    const { monthBeingShown, onYearSelect } = this.props;
    const newYear = changeEvent.target.value;

    onYearSelect(monthBeingShown, newYear);
  };

  render() {
    const { monthBeingShown, newestAllowed, oldestAllowed } = this.props;
    const currentYear = monthBeingShown.year();
    let years: number[] = [];
    if (newestAllowed && oldestAllowed) {
      for (
        let year = newestAllowed.year();
        year >= oldestAllowed.year();
        year--
      ) {
        years.push(year);
      }
    } else {
      for (let year = currentYear + 20; year > currentYear - 20; year--) {
        years.push(year);
      }
    }
    return (
      <OuterWrapper>
        <strong>{monthBeingShown.format('MMMM')}</strong>
        <Select value={currentYear} onChange={this.handleYearChange}>
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </OuterWrapper>
    );
  }
}
