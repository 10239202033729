import React from 'react';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';

import ProjectModel from '@extensions/models/Project';
import ProjectManagement from '@extensions/components/project/ProjectManagement';
export interface IManageContent {
  project: ProjectModel;
}

class ManageContent extends React.Component<IManageContent> {
  render() {
    let content = <div />;
    const { project } = this.props;
    content = (
      <React.Fragment>
        <Link to={`/project/${project.name}`} style={{textDecoration: 'none'}}>Back to Project</Link>
        <Typography variant="h2" gutterBottom>
          Project Management: {project.identifier.toUpperCase()}
        </Typography>
        <Typography variant="h3" gutterBottom>
          {project.title}
        </Typography>
        <ProjectManagement />
      </React.Fragment>
    );
    return content;
  }
}
export default ManageContent;
