import React from 'react';
import { reaction } from 'mobx';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  List,
  ListItem,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ReactMarkdown from 'react-markdown';
import startCase from 'lodash/startCase';
import identity from 'lodash/identity';
import rehypeRaw from 'rehype-raw';

import CitationButton from '@extensions/components/core/CitationButton';
import Organization from '@extensions/models/Organization';
import Project from '@extensions/models/Project';
import ReferencesPanel from '@extensions/components/core/ReferencesPanel';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { IMetricsService } from '@extensions/services/IMetricsService';
import { INotificationService } from '@extensions/services/INotificationService';
import { IProjectService } from '@extensions/services/IProjectService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import ContactsPanel from '../core/ContactsPanel';
import StatsBar from '../metrics/StatsBar';
import MarkdownImage from '@extensions/components/core/MarkdownImage';
import MarkdownLink from '@extensions/components/core/MarkdownLink';
import theme from '@extensions/services/Theme';

const StyledGrid = styled(Grid)(() => ({
  paddingBottom: theme.spacing(2),
}));

const StyledStatsBar = styled(StatsBar)(({ theme }) => ({
  gridTemplateColumns: 'auto',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'auto auto',
  },
  [theme.breakpoints.down('xs')]: {
    gridTemplateColumns: 'auto',
  },
}));

const StyledRootDiv = styled('div')(() => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(4)
}));

const StyledATag = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'none'
}));

export interface IProjectOverviewProps {
  datasetService: IDatasetService;
  projectService: IProjectService;
  securityService: ISecurityService;
  notificationService: INotificationService;
  metricsService: IMetricsService;
}

export interface IProjectOverviewState { }

@observer
export class ProjectOverview<
  ExtraProps extends object = {}
> extends React.Component<
  IProjectOverviewProps & ExtraProps,
  IProjectOverviewState
> {
  static defaultProps = {
    datasetService: undefined,
    notificationService: undefined,
    securityService: undefined,
    projectService: undefined,
    metricsService: undefined,
  };
  requestSentReaction: any;

  componentDidMount() {
    const { projectService, metricsService } = this.props;
    const { project } = projectService;
    if (project && metricsService) {
      metricsService.getGAMetrics(project);
      return;
    }
    reaction(
      () => this.props.projectService.project,
      (project) => {
        if (project) {
          this.props.metricsService.getGAMetrics(project);
        }
      }
    );
  }

  getPanel = ({
    expand,
    title,
    body,
  }: {
    expand?: boolean;
    title: string;
    body: React.ReactNode;
  }): React.ReactNode => {
    const defaultExpanded = expand ? true : false;
    return (
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${title}-content`}
          id={`${title}-header`}
        >
          <Typography variant="h3">{startCase(title)}</Typography>
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
      </Accordion>
    );
  };

  getListPanel<T>({
    title,
    items,
    renderItem = identity,
    getKey = identity,
  }: {
    title: string;
    items: T[];
    renderItem?: (item: T) => React.ReactNode;
    getKey?: (item: T, index: number) => string | number;
  }): React.ReactNode {
    return this.getPanel({
      title,
      body: (
        <List>
          {items.map((item, index) => (
            <ListItem key={getKey(item, index)}>{renderItem(item)}</ListItem>
          ))}
        </List>
      ),
    });
  }

  getReferencesPanel = (project: Project): JSX.Element | React.ReactNode | null => {
    return <ReferencesPanel project={project} />
  };

  getContactsPanel(project: Project) {
    const contacts = project.contactPoint;
    if (contacts) {
      return (
        <ContactsPanel contacts={contacts} name={project.identifier} title={project.title} />
      );
    }
    return null;
  }

  getDescriptionPanel(project: Project): React.ReactNode {
    const convertImageUri = (imageUri) => {
      return `/api/content/${project.identifier}/${imageUri}`;
    };
    const convertDocumentUri = (uri) => {
      if (uri.search(/^(mailto:|\/|(ht|f)tps?:\/\/)/) < 0) {
        return `/api/content/${project.identifier}/${uri}`;
      }
      return uri;
    }
    return this.getPanel({
      expand: true,
      title: 'Description',
      body: (
        <span>
          <ReactMarkdown
            children={project.description}
            transformImageUri={convertImageUri}
            transformLinkUri={convertDocumentUri}
            linkTarget={(url) => (
              (url.startsWith('http') || url.startsWith('documents'))
                ? '_blank'
                : ''
            )}
            components={{
              img: MarkdownImage,
              a: MarkdownLink,
              h1: ({...props}) => <Typography variant='h1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1.5em' }} {...props} />,
              h2: ({...props}) => <Typography variant='h2' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1.5em' }} {...props} />,
              h4: ({...props}) => <Typography variant='h4' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1em' }} {...props} />,
              h5: ({...props}) => <Typography variant='h5' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px' }} {...props} />,
              p: ({...props}) => <Typography variant='body1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', color: '#000' }} {...props} />,
              li: ({...props}) => <li {...props} style={{color: '#000', fontSize: '14px', lineHeight: '22px'}}></li>,
            }}
            rehypePlugins={[rehypeRaw]}
          />
          {project.doiName
            ? <Typography>
            <strong>Project DOI</strong><br/>
            <StyledATag href={"https://www.osti.gov/search/semantic:" + project.doiName}>{project.doiName}</StyledATag>
          </Typography>
            : null
          }
        </span>
      ),
    });
  }

  getParticipantingOrgsPanel(project: Project) {
    if (
      !project.participatingOrganizations ||
      project.participatingOrganizations.length === 0
    ) {
      return null;
    }
    const orgsGrid = project.participatingOrganizations.map(
      (org: Organization, index: number) => (
        <StyledGrid
          item
          xs={12}
          key={`reflink${org.name}`}
        >
          <Typography component="p" sx={{color: '#000'}}>{org.name}</Typography>
        </StyledGrid>
      )
    );

    return this.getPanel({
      title: 'Participating Organizations',
      body: <Grid container>{orgsGrid}</Grid>,
    });
  }

  getCitationButton(project: Project | null) {
    if (
      project &&
      project.citation &&
      this.props.securityService.userIsLoggedIn
    ) {
      return <CitationButton citation={project.citation} />;
    }
    return null;
  }

  getUsageStats = (project: Project): React.ReactNode | null => {
    return <StyledStatsBar item={project} />;
  };

  getAdditionalPanels = (project: Project): React.ReactNode => {
    return null;
  };

  render() {
    const project: Project | null = this.props.projectService.project;
    let content = <div />;
    if (project) {
      content = (
        <StyledRootDiv>
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item sm={12} md={9}>
              {this.getDescriptionPanel(project)}
              {this.getAdditionalPanels(project)}
              {this.getReferencesPanel(project)}
              {this.getContactsPanel(project)}
              {this.getParticipantingOrgsPanel(project)}
              {this.getCitationButton(project)}
            </Grid>
            <Grid item sm={12} md={3}>
              {this.getUsageStats(project)}
              {project.datasetCount === 0 ? (
                <Button fullWidth color="secondary" disabled={true}>
                  Browse Datasets
                </Button>
              ) : (
                <Link to={`/project/${project.name}/data`}>
                  <Button fullWidth color="secondary">
                    Browse Datasets
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </StyledRootDiv>
      );
    }

    return content;
  }
}

export default inject((store: any) => ({
  projectService: store.projectService,
  datasetService: store.datasetService,
  metricsService: store.metricsService,
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(ProjectOverview);
