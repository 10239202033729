import MetadataDest from '@extensions/models/MetadataDest';
import MetaDocument from '@extensions/models/MetaDocument';

export enum DocAction {
  DraftEditing = 'DraftEditing',
  DraftReviewingReadOnly = 'DraftReviewingReadOnly',
  DraftReviewingEditable = 'DraftReviewingEditable',
  PendingEditing = 'PendingEditing',
  PendingReviewing = 'PendingReviewing',
  PublishedEditing = 'PublishedEditing',
  New = 'New',
}

export interface IDocGroup {
  projectName: string;
  shortName: string;
  documents: MetaDocument[];
}
export interface IMetadataService {
  /** Map from project name to metadata destination */
  destinations: MetadataDest[];
  projectsWithDests: string[];
  documents: MetaDocument[];
  datasetsWithDocs: Set<string>;
  docsByProjectName: Record<string, MetaDocument[]>;
  publishedDocsByProjectName: Record<string, MetaDocument[]>;
  loaded: boolean;
  selectedDocAction: DocAction | undefined;
  load: () => void;
  setSelectedDocAction(docAction: DocAction | undefined): void;
  setSelectedDocAndAction(
    doc: MetaDocument,
    linkClicked: boolean,
    editPencilClicked: boolean
  ): void;
  addNewDocument: () => string | number;
  getDocument: (documentId: string | number) => MetaDocument | null;
  getDocumentByDataset: (datasetName: string) => MetaDocument | null;
  getPublishedDocument: (documentId: string | number) => MetaDocument | null;
  deleteDocument: (document: MetaDocument) => Promise<void>;
  getDestination: (datasetName: string) => MetadataDest | null;
  publish: (doc: MetaDocument) => Promise<void>;
  addNewDocumentFromPublished(doc: MetaDocument): void;
  requestDeletion(docId: string | number): Promise<void>;
}
