import React from 'react';
import { inject } from 'mobx-react';

import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { IContactUsService } from '@extensions/services/IContactUsService';

const StyledButton = styled('button')(({ theme }) => ({
  position: 'fixed',
  bottom: '0px',
  right: '0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '20px',
  background: theme.palette.secondary.main,
  margin: '.5rem',
  width: '90px',
  height: '40px',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  "&:active": {
    outline: 'none',
    border: 'none',
  }
}));

const StyledText = styled('div')(({
  color: 'white',
  fontSize: '16px'
}));

export interface IFeedbackToolProps {
  contactUsService?: IContactUsService;
  name?: string;
  title?: string;
}

export interface IFeedbackToolState { }

@inject('contactUsService')
class FeedbackTool extends React.Component<IFeedbackToolProps> {
  render() {
    const {
      contactUsService,
    } = this.props;

    return (
      <Grid >
        {contactUsService && (
          <StyledButton onClick={() =>
            contactUsService.openModal(undefined, undefined, 'feedback')}
          >
            <StyledText >
              Feedback
            </StyledText>
          </StyledButton>
        )}
      </Grid>
    );
  }
}
export default (FeedbackTool);
