import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRootDiv = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  border: 'none',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  maxWidth: 500,
  fontStyle: 'italic',
  color: theme.palette.grey[700],
}));

export interface ICitationButtonProps {
  citation: string;
}

const CitationButton: React.FC<ICitationButtonProps> = props => {
  const { citation } = props;

  return (
    <StyledRootDiv>
      <StyledTypography>
        {`Citation: ${citation}`}
      </StyledTypography>
    </StyledRootDiv>
  );
};

export default CitationButton;
