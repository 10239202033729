import { App as DapApp } from '@dapclient/components/App';
import Agreement from '@extensions/components/page/Agreement';
import Dataset from '@extensions/components/page/Dataset';
import DatasetSearch from '@extensions/components/page/DatasetSearch';
import FAQ from '@extensions/components/page/FAQ';
import Home from '@extensions/components/page/Home';
import License from '@extensions/components/page/License';
import Metrics from '@extensions/components/page/Metrics';
import NotFound from '@extensions/components/page/NotFound';
import Profile from '@extensions/components/page/Profile';
import Project from '@extensions/components/page/Project';
import ProjectSearch from '@extensions/components/page/ProjectSearch';
import Register from '@extensions/components/page/Register';
import Acknowledge from '@extensions/components/page/Acknowledge';
import SignIn from '@extensions/components/page/SignIn';
import LeftCityScape from '@extensions/resources/LeftSideCityScape.png';
import RightCityScape from '@extensions/resources/RightSideCityScape.png';
import theme from '@extensions/services/Theme';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PublicationDetails from '@extensions/components/page/PublicationDetails';
import PublicationsSearch from '@extensions/components/page/PublicationsSearch';
import NewPublication from '@extensions/components/page/NewPublication';
import EditPublication from '@extensions/components/page/EditPublication';
import { Location } from 'history';

export class App extends DapApp {  
  getLayoutStyle = (): Object => {
    var backgroundColor = this.props.location.pathname === '/'
      ? theme.palette.grey[50]
      : theme.palette.background.default;
    
    if (this.props.location.pathname !== '/') {
      return {
        backgroundColor: backgroundColor + ' !important',
        fontFamily: theme.typography.fontFamily,
        flex: '1 0 auto',
        backgroundImage: `url(${LeftCityScape}),url(${RightCityScape})`,
        backgroundPosition: 'left bottom 98px, right bottom 98px',
        backgroundRepeat: 'no-repeat, no-repeat',
      }
    }
    return {
      backgroundColor: backgroundColor + ' !important',
      fontFamily: theme.typography.fontFamily,
    }
  }

  getLayoutContentStyle(location: Location): Object {
    const homePageStyles =  {
      flex: '1 0 auto',
      backgroundImage: `url(${LeftCityScape}),url(${RightCityScape})`,
      backgroundPosition: 'left bottom, right bottom',
      backgroundRepeat: 'no-repeat, no-repeat',
    }
    if (this.props.location.pathname==='/') {
      return homePageStyles;
    } 
    return {
      display: 'flex',
      maxWidth: theme.contentWidth.max,
      minWidth: theme.contentWidth.min,
      padding: '20px 50px 0px',
      margin: 'auto',
    }
  }

  renderRoutes = () => {
    return (
      <Switch>
        <Redirect from="/search" to="/dataset-search" />
        <Route exact path="/" component={Home} />
        <Route exact path="/signIn" component={SignIn} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/password/reset" component={Register} />
        <Route path="/profile" component={Profile} />
        {/* server will respond with redirect to /account/approved when user clicks approve link in emails */}
        <Route path="/account/approved" component={Profile} />
        <Route exact path="/dataset-search" component={DatasetSearch} />
        <Route exact path="/project-search" component={ProjectSearch} />
        <Route path="/ds" component={Dataset} />
        <Route path="/project" component={Project} />
        <Route exact path="/agree" component={Agreement} />
        <Route exact path="/acknowledge" component={Acknowledge} />
        <Route exact path="/license" component={License} />
        <Route exact path="/metrics" component={Metrics} />
        <Route exact path="/publication/new" component={NewPublication} />,
        <Route
          exact
          path="/publication/edit/:id(\d+)"
          component={EditPublication}
        />
        <Route
          exact
          path="/publication/:id(\d+)"
          component={PublicationDetails}
        />
        <Route path="/publications" component={PublicationsSearch} />
        <Route exact path="/faq" component={FAQ} />
        <Route component={NotFound} />
      </Switch>
    );
  };
}

export default inject((store: any) => ({
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(withRouter(App));
