import React from 'react';
import { Typography } from '@mui/material';
import theme from '@extensions/services/Theme';

export interface IFilterSectionHeaderProps
  extends React.ComponentProps<typeof Typography> {
  children: string;
}

class FilterSectionHeader extends React.Component<
  IFilterSectionHeaderProps
> {
  render() {
    const { children } = this.props;
    return (
      <Typography
        sx={{
          color: theme.palette.grey[500],
          fontSize: '1rem',
          marginBottom: theme.spacing(2),
        }}
      >
        {children.toLocaleUpperCase()}
      </Typography>
    );
  }
}

export default FilterSectionHeader;
