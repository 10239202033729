import { Component } from 'react';
import { LinkProps, Tab } from '@mui/material';
import theme from '@extensions/services/Theme';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export interface ISignInLinkProps extends LinkProps, RouteComponentProps {
  label: string;
  children?: any;
}
class SignInLink extends Component<ISignInLinkProps> {
  componentDidMount() {
    window.addEventListener('popstate', this.handlePopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  handlePopState = () => {
    const { pathname, search, hash } = window.location;
    this.props.history.replace({
      state: { pathname, search, hash },
    });
  };

  handleClick = () => {
    const { pathname, search, hash } = window.location;
    this.props.history.push({
      pathname: '/signIn',
      state: { pathname, search, hash },
    });
  };

  render() {
    const { children, label } = this.props;
    return (
      <Tab 
        onClick={this.handleClick} 
        label={label}
        sx={{ 
          color: theme.palette.text.primary,
        }}
      >
        {children}
      </Tab>
    );
  }
}

export default withRouter(SignInLink);
