import AccessRequestList from '@extensions/components/membership/AccessRequestList';
import ManageRoles from '@extensions/components/membership/ManageRoles';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { IMembershipService } from '@extensions/services/IMembershipService';
import { INotificationService } from '@extensions/services/INotificationService';
import { IProjectService } from '@extensions/services/IProjectService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { inject, observer } from 'mobx-react';
import React from 'react';

export interface IDatasetAccessProps {
  datasetService: IDatasetService;
  securityService: ISecurityService;
  notificationService: INotificationService;
  membershipService: IMembershipService;
  projectService: IProjectService;
}

export interface IDatasetAccessState {
  activeTab: string;
}

@inject(
  'projectService',
  'membershipService',
  'datasetService',
  'securityService',
  'notificationService'
)
@observer
export default class DatasetAccess extends React.Component<
  IDatasetAccessProps,
  IDatasetAccessState
> {
  // We have to set a default props value for all props injected by mobx or else component will show a missing prop error when rendered
  static defaultProps = {
    datasetService: null,
    securityService: null,
    notificationService: null,
    membershipService: null,
    projectService: null,
  };

  constructor(props: IDatasetAccessProps) {
    super(props);

    this.state = {
      activeTab: 'users',
    };
  }

  onTabsChange = (activeKey: string) => {
    this.setState({ activeTab: activeKey });
  };

  componentDidMount = () => {
    const { datasetService, projectService } = this.props;
    if (!datasetService || !projectService) {
      return;
    }
    if (datasetService.dataset !== null && projectService.project === null) {
      // The <ManageRoles> component needs the project loaded as well and we removed
      // that part from the dataset landing page for a performance gain
      projectService.loadProject(datasetService.dataset.projectName);
    }
  };

  render() {
    const { datasetService } = this.props;

    let content = <div />;

    // render the component's real content only after data from server has been loaded:
    if (datasetService.dataset) {
      content = (
        <React.Fragment>
          <ManageRoles accessControlledItem={datasetService.dataset} />
          <div style={{ marginTop: 15, paddingBottom: 2 }}>
            <AccessRequestList dataset={datasetService.dataset} />
          </div>
        </React.Fragment>
      );
    }

    return content;
  }
}
