import * as yup from 'yup';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import { TextField, Button, Grid } from '@mui/material';

import { Modal, ModalProps } from '@extensions/components/core/Modal';

const Body = styled.div`
  padding: 1rem;
  width: 500px;
`;

const validationSchema = yup.object({
  justification: yup
    .string()
    .required('You are required to provide an explanation'),
});

interface Props extends Omit<ModalProps, 'children'> {
  resourceName: string;
  resourceLabel: string;
  request: (justification: string) => void;
}

const RequestAccessModal = ({
  resourceName,
  resourceLabel,
  request,
  ...rest
}: Props) => {
  const formik = useFormik({
    initialValues: {
      justification: '',
    },
    validationSchema,
    onSubmit: (vals) => request(vals.justification),
  });

  return (
    <Modal {...rest}>
      <Body>
        <h1>Access Request</h1>
        <p>
          <em>
            Briefly explain your interest in <strong>{resourceLabel}</strong>:
          </em>
        </p>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                minRows={5}
                maxRows={10}
                name="justification"
                type="text"
                variant="outlined"
                size="small"
                placeholder="..."
                value={formik.values.justification}
                onChange={formik.handleChange}
                error={
                  formik.touched.justification &&
                  Boolean(formik.errors.justification)
                }
                helperText={
                  formik.touched.justification && formik.errors.justification
                }
                fullWidth
                multiline
              />
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                fullWidth
              >
                Send Request
              </Button>
            </Grid>
          </Grid>
        </form>
      </Body>
    </Modal>
  );
};

export default RequestAccessModal;
