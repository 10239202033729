import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({
  marginBottom: '1rem',
}));

export interface ITextListProps {
  className?: string;
  items: string[];
}

export interface ITextListState { }

@observer
export class TextList extends React.Component<
  ITextListProps,
  ITextListState
> {
  render() {
    const { items } = this.props;
    return (
      <StyledPaper>
        <List>
          {items.map((item, index) => (
            <React.Fragment key={item}>
              {index > 0 && <Divider />}
              <ListItem>
                <ListItemText primary={item} />
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </StyledPaper>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(TextList);
