import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Highlighter from 'react-highlight-words';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';

import { QuestionAndAnswer } from '@dapclient/models/FaqSection';
import MarkdownImage from '@dapclient/components/faq/MarkdownImage';
import MarkdownLink from '@dapclient/components/core/MarkdownLink';
import theme from '@extensions/services/Theme';

export interface IQandAnswerAccordionProps extends RouteComponentProps {
  qAndA: QuestionAndAnswer;
  searchText: string;
  searchmatch: boolean;
}

export interface IQandAnswerAccordionState {
  expanded: boolean;
}

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  ...theme.typography.body1
}));

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  ...((theme.components?.MuiAccordionSummary?.styleOverrides?.root as {}) ?? {}),
  fontSize: theme.typography.body1.fontSize
}));

const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (props) => props !== 'searchmatch',
})<{ searchmatch?: boolean }>(({ searchmatch }) => ({
  display: searchmatch ? 'block' : 'none'
}));

const QandAnswerAccordion = ({
  qAndA,
  searchText,
  searchmatch,
}: IQandAnswerAccordionProps) => {
  const [expanded, setExpanded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash.substring(1) === qAndA.id) {
      setExpanded(true);
      wrapperRef.current?.scrollIntoView();
    }
  }, [location, qAndA.id]);

  const convertImageUri = (imageUri: string) => {
    return `${process.env.PUBLIC_URL}/faq-content/${imageUri}`;
  };

  const getLinkTarget = (url: string) =>
    url.startsWith('http') ? '_blank' : '';

  const getRenderers = (): Components => {
    return {
      img: MarkdownImage,
      a: MarkdownLink,
      p: ({ ...props }) => <Typography variant="body1" sx={{ color: theme.palette.grey[900] }} {...props} />,
      ol: ({ ...props }) => <Typography component="ol" sx={{ color: theme.palette.grey[900], paddingBlockStart: 1 }} {...props} />,
      li: ({ ...props }) => <Typography component="li" sx={{ color: theme.palette.grey[900] }} {...props} />,
    };
  };

  return (
    <div className="wrapperRef" ref={wrapperRef}>
      <StyledAccordion
        expanded={expanded}
        square
        searchmatch={searchmatch}
        onChange={() => setExpanded(!expanded)}
      >
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${qAndA.question}`}
          id={`${qAndA.id}`}
        >
          <Highlighter
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={qAndA.question}
          />
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <div>
            <ReactMarkdown
              children={qAndA.answerMarkdown}
              transformImageUri={convertImageUri}
              linkTarget={getLinkTarget}
              components={getRenderers()}
            />
          </div>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default withRouter(QandAnswerAccordion);
