export interface ISearchHighlightProps {
    highlight: { string: string[] };
    searchFieldLabels: { string: string };
};

const SearchHighlight = ({ highlight, searchFieldLabels }: ISearchHighlightProps) => {

    const hlKeys = Object
        .keys(highlight || {})
        .filter(hl => searchFieldLabels.hasOwnProperty(hl))
        .sort();

    if (hlKeys.length === 0) {
        return null;
    }

    return (
        <div style={{
            border: '1px solid #ddd',
            borderRadius: '3px',
            backgroundColor: '#f3f3f3',
            margin: '0.5rem 0',
            padding: '0.5rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
        }}>
            {hlKeys.map(hl => (
                <div key={hl} style={{ lineHeight: 1 }}>
                    <small><strong>{(searchFieldLabels || {})[hl] || hl}</strong>:&nbsp;</small>
                    <small
                        dangerouslySetInnerHTML={{
                            __html: Array.from(
                                ((highlight || {})[hl] || [])
                                    .reduce((map, h) => {
                                        const k = h.toLowerCase();
                                        map.set(k, (map.get(k) || 0) + 1);
                                        return map;
                                    }, new Map())
                                    .entries(),
                                ([hl, count]) => count > 1
                                    ? `${hl} <small><em>(x${count})</em></small>`
                                    : hl
                            ).join(' &bull; '),
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

export default SearchHighlight;
