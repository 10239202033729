import { inject } from 'mobx-react';
import { Metrics as DapMetrics } from '@dapclient/components/page/Metrics';

export class Metrics extends DapMetrics {
  getIntroSection = () => null;
}

export default inject((store: any) => ({
  metricsService: store.metricsService,
}))(Metrics);
