import find from 'lodash/find';
import pull from 'lodash/pull';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import React, { createRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';
import { inject, observer } from 'mobx-react';
import * as EmailValidator from 'email-validator';
import differenceWith from 'lodash/differenceWith';

import {
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Button,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Alert,
  Dialog,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import theme from '@extensions/services/Theme';
import { IProjectService } from '@extensions/services/IProjectService';
import { IMembershipService } from '@extensions/services/IMembershipService';

import User from '@extensions/models/User';
import Group from '@extensions/models/Group';
import { RoleType } from '@extensions/models/Role';
import UserMember from '@extensions/models/UserMember';
import GroupMember from '@extensions/models/GroupMember';
import IControlledAccess from '@extensions/models/IControlledAccess';
import { IDatasetService } from '@extensions/services/IDatasetService';
import NewUserEmailTextField from '@dapclient/components/core/NewUserEmailTextField';

const StyledAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedError,
}));
export interface IAddMembersModalProps {
  removeFromExistingRole?: string;
  title: string;
  datasetService: IDatasetService;
  membershipService: IMembershipService;
  projectService: IProjectService;
  visible: boolean;
  onCancel: (event: any) => void;

  accessControlledItem: IControlledAccess;
  groups: Group[];
  users: User[];
}

export interface IAddMembersModalState {
  searchText: string;
  open: boolean;
  addedUsers: UserMember[];
  addedGroups: GroupMember[];
  expandedGroups: string[];
  alertText: string | null;
}

@inject('projectService', 'membershipService', 'datasetService')
@observer
class MembersModal extends React.Component<
  IAddMembersModalProps,
  IAddMembersModalState
> {
  static defaultProps = {
    datasetService: undefined,
    membershipService: undefined,
    projectService: undefined,
  };

  static newUserUsernamePrefix = 'NEW_USER_';
  roleMenuItems: JSX.Element[];
  editRoleMenuItems: JSX.Element[];
  topOfListRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      searchText: '',
      addedUsers: [],
      addedGroups: [],
      expandedGroups: [],
      alertText: null,
    };

    const roles: string[] = Object.values(RoleType);
    this.roleMenuItems = [
      <MenuItem key="none" value="">
        <em>Select Role</em>
      </MenuItem>,
    ].concat(
      roles.map((role: string) => (
        <MenuItem key={role} value={role}>
          {upperFirst(role)}
        </MenuItem>
      ))
    );

    this.editRoleMenuItems = [
      <MenuItem key="none" value="remove">
        <em>Remove</em>
      </MenuItem>,
    ].concat(
      roles.map((role: string) => (
        <MenuItem key={role} value={role}>
          {upperFirst(role)}
        </MenuItem>
      ))
    );
    this.topOfListRef = createRef();
  }

  componentDidMount() {
    const { projectService, accessControlledItem } = this.props;
    projectService.getProjectGroupsIfNeeded(
      accessControlledItem.getIdentifier()
    );
    projectService.getProjectUsersIfNeeded(
      accessControlledItem.getIdentifier()
    );
  }

  handleAddMembers = (e) => {
    this.setState({
      alertText: null,
    });
    // remove any users that don't have email address (case where new user button clicked but nothing entered)
    const addedUsers = filter(
      this.state.addedUsers,
      (user: UserMember) => user.member.email
    ) as UserMember[];

    // invalid if there are any NEW users found with invalid email addresses (don't check existing users since their email's cannot be edited)
    const invalidNewUsers = filter(
      addedUsers,
      (user: UserMember) =>
        user.member.username &&
        user.member.username.startsWith(MembersModal.newUserUsernamePrefix) &&
        EmailValidator.validate(user.member.email) === false
    );

    if (invalidNewUsers.length > 0) {
      this.setState({ alertText: 'Correct invalid email addresses.' });
    } else {
      this.props.membershipService.addMembers(
        addedUsers,
        this.state.addedGroups,
        this.props.accessControlledItem.getIdentifier(),
        this.props.accessControlledItem.getType(),
        this.props.removeFromExistingRole
      );
    }
  };

  // member can be either a user or a group
  addMember = (role, member, state) => {
    const level: string = this.props.accessControlledItem.getType();
    const addedMembers = cloneDeep(state);
    const memberRole = {
      member,
      role: {
        label: role,
        level,
      },
    };
    addedMembers.unshift(memberRole);
    return addedMembers;
  };

  addNewUser = (event) => {
    const user: User = User.getGuestUser();
    user.username =
      MembersModal.newUserUsernamePrefix + new Date().getMilliseconds();
    const addedUsers = this.addMember(
      RoleType.MEMBER,
      user,
      this.state.addedUsers
    );
    this.setState({ addedUsers });
    this.topOfListRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  addUser = (event, user) => {
    const addedUsers = this.addMember(
      event.target.value,
      user,
      this.state.addedUsers
    );
    this.setState({ addedUsers });
  };

  addGroup = (event, group) => {
    const addedGroups = this.addMember(
      event.target.value,
      group,
      this.state.addedGroups
    );
    this.setState({ addedGroups });
  };

  editUserRole = (event, editedUserRole: UserMember) => {
    const predicate = (userRole: UserMember) =>
      userRole.member.email === editedUserRole.member.email;
    const addedUsers = this.editMemberRole(
      event,
      this.state.addedUsers,
      predicate
    );
    this.setState({ addedUsers });
  };

  editGroupRole = (event, editedUserRole: GroupMember) => {
    const predicate = (userRole: GroupMember) =>
      userRole.member.name === editedUserRole.member.name;
    const addedGroups = this.editMemberRole(
      event,
      this.state.addedGroups,
      predicate
    );
    this.setState({ addedGroups });
  };

  editMemberRole = (event, state, predicate: CallableFunction) => {
    const role: string = event.target.value;
    const addedMembers = cloneDeep(state);
    if (role === 'remove') {
      remove(addedMembers, predicate);
    } else {
      const editedUser: UserMember = find(addedMembers, predicate);
      editedUser.role.label = role;
    }
    return addedMembers;
  };

  removeUser = (userRoleToRemove: UserMember) => {
    const addedUsers = cloneDeep(this.state.addedUsers);
    remove(addedUsers, (userRole: UserMember) => {
      return userRole.member.email === userRoleToRemove.member.email;
    });
    this.setState({ addedUsers });
  };

  removeGroup = (groupRoleToRemove: GroupMember) => {
    const addedGroups = cloneDeep(this.state.addedGroups);
    remove(addedGroups, (groupRole: GroupMember) => {
      return groupRole.member.name === groupRoleToRemove.member.name;
    });
    this.setState({ addedGroups });
  };

  toggleGroupExpanded = (groupname: string) => {
    // if this group is in the list remove it, if not add it
    const expandedGroups = cloneDeep(this.state.expandedGroups);
    pull(expandedGroups, groupname);
    if (this.state.expandedGroups.length === expandedGroups.length) {
      expandedGroups.unshift(groupname);
    }
    this.setState({ expandedGroups });
  };

  search = (event) => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  renderGroupsAdded = () => {
    const { addedGroups } = this.state;
    return addedGroups.map((groupMember: GroupMember) => {
      const name = groupMember.member.name;
      const expanded = this.state.expandedGroups.indexOf(name) !== -1;
      return (
        <React.Fragment key={name}>
          <ListItem
            onClick={(e) => this.toggleGroupExpanded(name)}
            style={{ background: 'lightgrey' }}
          >
            {expanded ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary={name} />
            <ListItemSecondaryAction>
              <Select
                value={groupMember.role.label}
                onChange={(event) => this.editGroupRole(event, groupMember)}
                variant='standard'
              >
                {this.editRoleMenuItems}
              </Select>
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
            style={{ background: 'lightgrey' }}
          >
            <List dense disablePadding>
              {groupMember.member.users.map((user: User) => (
                <ListItem key={user.email} style={{ paddingLeft: 25 }}>
                  <ListItemText
                    primary={user.fullName}
                    secondary={user.email}
                    primaryTypographyProps={{
                      variant: 'body1',
                      color: theme.palette.common.black
                    }}
                    secondaryTypographyProps={{
                      variant: 'body1',
                      color: theme.palette.grey[700]
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    });
  };

  renderUsersAdded = () => {
    const { addedUsers } = this.state;

    const top = <span id="topRef" key="topRef" ref={this.topOfListRef} />;
    const users = addedUsers.map((userRole: UserMember) => {
      let user;
      if (
        userRole.member.username &&
        userRole.member.username.startsWith(MembersModal.newUserUsernamePrefix)
      ) {
        user = (
          <NewUserEmailTextField
            email={userRole.member.email}
            setEmail={(email) => userRole.member.setEmail(email)}
          />
        );
      } else {
        user = (
          <ListItemText
            primary={userRole.member.fullName}
            secondary={`${userRole.member.email}`}
            primaryTypographyProps={{
              variant: 'body1',
              color: theme.palette.common.black
            }}
            secondaryTypographyProps={{
              variant: 'body1',
              color: theme.palette.grey[700]
            }}
          />
        );
      }
      return (
        <ListItem
          key={userRole.member.username || userRole.member.email}
          style={{ background: 'lightgrey' }}
        >
          {user}
          <ListItemSecondaryAction>
            <Select
              value={userRole.role.label}
              onChange={(event) => this.editUserRole(event, userRole)}
            >
              {this.editRoleMenuItems}
            </Select>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
    return [top].concat(users);
  };

  renderGroupsToAdd = (groups: Group[]) => {
    // remove from list of users that can be added the ones that have already been added
    let prunedGroups = differenceWith(
      groups,
      this.state.addedGroups,
      (group: Group, groupMember: GroupMember) =>
        groupMember.member.name === group.name
    );

    // then remove user's that don't match search string (if entered)
    if (this.state.searchText) {
      remove(prunedGroups, (group: Group) => {
        return (
          camelCase(group.name)
            .toLowerCase()
            .indexOf(camelCase(this.state.searchText).toLowerCase()) === -1
        );
      });
    }

    // sort users alphabetically by last name
    prunedGroups = sortBy(prunedGroups, (group: Group) => group.name);

    return prunedGroups.map((group) => {
      const name = group.name;
      const expanded = this.state.expandedGroups.indexOf(name) !== -1;
      return (
        <React.Fragment key={name}>
          <ListItem button onClick={(e) => this.toggleGroupExpanded(name)}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
            <ListItemText primary={name} />
            <ListItemSecondaryAction>
              <Select
                value=""
                displayEmpty
                onChange={(event) => this.addGroup(event, group)}
                variant='standard'
              >
                {this.roleMenuItems}
              </Select>
            </ListItemSecondaryAction>
          </ListItem>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List dense disablePadding>
              {group.users.map((user: User) => (
                <ListItem key={user.email} style={{ paddingLeft: 10 }}>
                  <ListItemText
                    primary={user.fullName}
                    secondary={user.email}
                    primaryTypographyProps={{
                      variant: 'body1',
                      color: theme.palette.common.black
                    }}
                    secondaryTypographyProps={{
                      variant: 'body1',
                      color: theme.palette.grey[700]
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      );
    });
  };

  renderUsersToAdd = (users: User[]) => {
    // remove from list of users that can be added the ones that have already been added
    let prunedUsers = differenceWith(
      users,
      this.state.addedUsers,
      (user: User, userRole: UserMember) => userRole.member.email === user.email
    );

    // then remove user's that don't match search string (if entered)
    if (this.state.searchText) {
      remove(prunedUsers, (user: User) => {
        const userString = `${user.email}${user.firstname}${user.lastname}`;
        return (
          camelCase(userString)
            .toLowerCase()
            .indexOf(camelCase(this.state.searchText).toLowerCase()) === -1
        );
      });
    }

    // sort users alphabetically by last name
    prunedUsers = sortBy(prunedUsers, (user: User) => user.lastname);

    return prunedUsers.map((user) => {
      return (
        <ListItem key={user.email}>
          <ListItemText 
            primary={user.fullName} 
            secondary={user.email} 
            primaryTypographyProps={{
              variant: 'body1',
              color: theme.palette.common.black
            }}
            secondaryTypographyProps={{
              variant: 'body1',
              color: theme.palette.grey[700]
            }}
          />
          <ListItemSecondaryAction>
            <Select
              value=""
              displayEmpty
              onChange={(event) => this.addUser(event, user)}
              variant='standard'
            >
              {this.roleMenuItems}
            </Select>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  public render() {
    const { users, groups } = this.props;

    let content = <div />;

    const userListItems: React.ReactNode[] = this.renderUsersToAdd(users);
    const addedUsersListItems: React.ReactNode[] = this.renderUsersAdded();

    const groupListItems: React.ReactNode[] = this.renderGroupsToAdd(groups);
    const addedgroupsListItems: React.ReactNode[] = this.renderGroupsAdded();

    const headerStyle = {
      position: 'relative' as 'relative',
      overflow: 'auto',
      paddingLeft: 16,
      paddingRight: 16,
    };

    content = (
      <Dialog open={this.props.visible} onClose={this.props.onCancel} fullWidth={true}>
        <DialogTitle sx={{color: theme.palette.secondary.main}}>{this.props.title}</DialogTitle>
        <DialogContent>
          <div style={headerStyle}>
            <span style={{ float: 'right' }}>
              <TextField label="Search" onChange={this.search} variant='standard'/>
            </span>
          </div>
          <div style={headerStyle}>
            <h4>
              <Typography sx={{float: 'left', color: theme.palette.secondary.main}}>Name</Typography>
              <Typography sx={{float: 'right', color: theme.palette.secondary.main}}>Desired Role</Typography>
            </h4>
          </div>

          <Divider />
          <div style={{ position: 'relative', overflow: 'auto', maxHeight: 300 }}>
            <List dense disablePadding aria-label="users and groups">
              {addedUsersListItems}
              {addedgroupsListItems}
              {userListItems}
              {groupListItems}
            </List>
          </div>
          {this.state.alertText && (
            <StyledAlert severity="error">
              {this.state.alertText}
            </StyledAlert>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            key="newUser"
            color="primary"
            style={{ float: 'left' }}
            onClick={this.addNewUser}
          >
            New User
          </Button>
          <Button
            key="cancel"
            variant="outlined"
            color="primary"
            style={{ marginRight: 10 }}
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>
          <Button
            key="ok"
            variant="contained"
            color="secondary"
            onClick={this.handleAddMembers}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
    return content;
  }
}

export default MembersModal;
