import filesize from 'filesize';
import concat from 'lodash/concat';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import toPairs from 'lodash/toPairs';
import moment from 'moment';

// DAP imports
import { PublicationType } from '@extensions/models/Publication';

export interface LaravelStats {
  total_files?: number;
  total_bytes?: number;
  users_by_month: Record<string, number>;
  projects_by_month: Record<string, number>;
  datasets_by_month: Record<string, number>;
  users_by_domain: Record<string, number>;
  projects_total: number;
  datasets_total: number;
}

export interface FileObj {
  symbol: string;
  value: number;
}

export interface DynamoStat {
  size: number;
  modtime: number;
  file_count: number;
}

export interface DownloadStat {
  file_count: number;
  month: string;
  order_count: number;
  size: number;
  unique_datasets: number;
  unique_users: number;
}

export interface PublicationStats {
  totalNumPubs: number;
  pubsCountsByType: Record<PublicationType, number>;
}

const DOMAIN_MAP = {
  gov: 'Government',
  edu: 'University',
};

export default class Metrics {
  fileCount: number;
  fileSizeStat: FileObj;
  datasetCount: number;
  userCount: number;
  projectCount: number;
  usersByDomain: (string | number)[][];
  userCountsByMonth: [string, number][];
  projectCountsByMonth: [string, number][];
  datasetCountsByMonth: [string, number][];
  byteCountUnit: string;
  downloadStats: DownloadStat[];
  publicationStats?: PublicationStats;

  constructor(
    laravelStats: LaravelStats,
    dynamoStats: DynamoStat[],
    downloadStats: DownloadStat[],
    publicationStats?: PublicationStats
  ) {
    this.downloadStats = sortBy(downloadStats, ['month']);
    // reverse(this.downloadStats); //why did i do this?

    this.userCount = this.parseUserCounts(laravelStats.users_by_month);
    this.userCountsByMonth = toPairs(
      this.parseStatByMonth(laravelStats.users_by_month)
    );
    this.projectCountsByMonth = toPairs(
      this.parseStatByMonth(laravelStats.projects_by_month)
    );
    this.datasetCountsByMonth = toPairs(
      this.parseStatByMonth(laravelStats.datasets_by_month)
    );

    this.usersByDomain = this.getUsersByDomain(laravelStats.users_by_domain);

    this.projectCount = laravelStats.projects_total;
    this.datasetCount = laravelStats.datasets_total;

    // 1. remove blanks from being counted
    remove(dynamoStats, ['file_count', 0]);
    remove(dynamoStats, ['size', 0]);
    remove(dynamoStats, ['modtime', null]);
    remove(dynamoStats, ['modtime', 0]);

    // 2. sort by modtime
    const sortedDynamoStats = sortBy(dynamoStats, (value) => {
      if (value.modtime > new Date().getTime() / 1000) {
        return moment(value.modtime).toDate().getTime();
      } else {
        return moment.unix(value.modtime).toDate().getTime();
      }
    });

    this.fileCount = (laravelStats.total_files || 0) > 0
      ? laravelStats.total_files || 0
      : reduce(
        sortedDynamoStats,
        (sum, record) => (record.file_count ? sum + record.file_count : sum),
        0
      );

    this.fileSizeStat = (laravelStats.total_bytes || 0) > 0
      ? filesize(laravelStats.total_bytes || 0, { output: 'object' })
      : this.parseFileSizeStat(sortedDynamoStats);

    this.byteCountUnit = this.parseByteCountUnit(sortedDynamoStats);

    if (publicationStats) {
      this.publicationStats = publicationStats;
    }
  }

  parseByteCountUnit(dynamoStats: DynamoStat[]) {
    const largestFilesize = reduce(
      dynamoStats,
      (sum, record) => (record.size ? sum + record.size : sum),
      0
    );
    const unitLong: any = filesize(largestFilesize, {
      output: 'object',
      fullform: true,
    });
    const unitShort: any = filesize(largestFilesize, {
      output: 'array',
      fullform: true,
    });
    return `${unitLong.symbol} (${unitShort[1]})`;
  }

  parseByteDownloadsByMonthOld(downloadStats: DownloadStat[]) {
    // 1. sort by month
    const sortedStats = sortBy(downloadStats, ['month']);
    // const countsByYear = {};//key is year, value is array of counts by month
    const countsByYear = {}; // key is year, value is map of counts, keyed by month

    // find largest filesize to get exponent to use on chart
    const sortedBySize = sortBy(downloadStats, 'size');
    const exponent = Number(
      filesize(sortedBySize[sortedBySize.length - 1].size, {
        output: 'exponent',
      })
    );

    forEach(sortedStats, (value) => {
      const year = value.month.substring(0, 4);
      const month = value.month.substring(4, 5);

      // convert the cumulative size from bytes to exponent of largest sized data point for chart to have unified scale
      const size = filesize(value.size, { exponent, output: 'array' })[0];
      if (countsByYear[year]) {
        countsByYear[year][month] = size;
      }
    });

    return concat(
      [['Year', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']],
      toPairs(countsByYear)
    );
  }

  parseFileSizesByMonth(dynamoStats: DynamoStat[]) {
    const countsByMonth: { [key: string]: number } = {};
    // sort the sizes in bytes by month
    const sizeCountsByMonth = this.parseCountsByMonth(dynamoStats, 'size');
    const keys = Object.keys(sizeCountsByMonth);

    // find the largest file size that will be charted (cumulative)
    const largestFilesize = reduce(
      dynamoStats,
      (sum, record) => (record.size ? sum + record.size : sum),
      0
    );

    // get the exponent for y axis of the chart label (KB, MG, GB etc.)
    const exponent = Number(filesize(largestFilesize, { output: 'exponent' }));
    keys.forEach((key, index) => {
      const value = sizeCountsByMonth[key];
      // convert the cumulative size from bytes to exponent of largest size
      const val = filesize(value, { exponent, output: 'array' })[0];
      countsByMonth[key] = +val;
    });
    // return concat([['', 'Count']], toPairs(countsByMonth));
    return toPairs(countsByMonth);
  }

  parseCountsByMonth(dynamoStats: DynamoStat[], whatToCount) {
    // bin by year/month, creating cumulative
    let total: number = 0;
    const totalByMonth = [];
    forEach(dynamoStats, (value) => {
      total = total + value[whatToCount];
      // check to see if the number is greater than "now" in seconds since it shouldn't ever be a future time
      // if so, the precision is in miliseonds, otherwise seconds
      if (value.modtime > new Date().getTime() / 1000) {
        const key = moment(value.modtime).format('YYYY-MM');
        totalByMonth[key] = total;
      } else {
        const key = moment.unix(value.modtime).format('YYYY-MM');
        totalByMonth[key] = total;
      }
    });
    return totalByMonth;
  }

  parseFileSizeStat(dynamoStats: DynamoStat[]): FileObj {
    // create a file object from the byeCount in order to display in most readable units (MB vrs GB vrs TB etc)
    const byeCount = reduce(
      dynamoStats,
      (sum, record) => (record.size ? sum + record.size : sum),
      0
    );
    return filesize(byeCount, { output: 'object' }) as unknown as FileObj;
  }

  parseUserCounts(usersByMonth: Record<string, number>): number {
    let usersTotal: number = 0;
    forEach(usersByMonth, (value: number, key: string) => {
      usersTotal += value;
    });
    return usersTotal;
  }

  parseStatByMonth(usersByMonth: Record<string, number>): {
    [key: string]: number;
  } {
    let usersTotal: number = 0;
    const usersTotalByMonth: { [key: string]: number } = {};
    forEach(usersByMonth, (value: number, key: string) => {
      usersTotalByMonth[key] = usersTotal + value;
      usersTotal += value;
    });
    return usersTotalByMonth;
  }

  private getTopLevelDomain = (domain: string): string => {
    return domain.split('.').slice(-1)[0];
  };

  private getUsersByDomain = (rawUsersByDomain: Record<string, number>) => {
    const domainCountPairs = toPairs(rawUsersByDomain);
    const labelCounts = groupBy(domainCountPairs, ([domain, count]) => {
      return DOMAIN_MAP[this.getTopLevelDomain(domain)] || 'Other';
    });
    return [
      // ['Domain', 'Users'],
      ...toPairs(labelCounts).map(([label, counts]) => [
        label,
        sum(counts.map(([fullDomain, count]) => count)),
      ]),
    ];
  };
}
