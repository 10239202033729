import { computed, observable, makeObservable } from 'mobx';
import sumBy from 'lodash/fp/sumBy';
import filter from 'lodash/fp/filter';
import flatten from 'lodash/fp/flatten';
import uniq from 'lodash/fp/uniq';
import map from 'lodash/fp/map';
import minBy from 'lodash/fp/minBy';
import maxBy from 'lodash/fp/maxBy';
import compose from 'lodash/fp/compose';
import moment, { Moment } from 'moment';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import FileOrder, {
  OrderStatus,
  DownloadOption,
  canDownloadUsingOption,
  DownloadURL,
} from '@extensions/models/FileOrder';
import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import Dataset from '@extensions/models/Dataset';

export enum OperatingSystem {
  LINUX = 'linux',
  MAC = 'darwin',
  WINDOWS = 'windows',
}

const NOTIFICATION_ID = 'FILE_ORDER_GROUP';
export default class FileOrderGroup {
  private notificationService: INotificationService;
  @observable
  fileOrders: FileOrder[];

  constructor(
    fileOrders: FileOrder[],
    notificationService: INotificationService
  ) {
    makeObservable(this);
    if (fileOrders.length < 1) {
      throw Error('FileOrderGroup needs at least one order');
    }
    this.fileOrders = fileOrders;
    this.notificationService = notificationService;
  }

  @computed
  get datasetNames(): string[] {
    return this.fileOrders.map(order => order.datasetName);
  }

  @computed
  get datasets(): Dataset[] | null {
    const atLeastOneNotLoaded = this.fileOrders.some(
      order => order.dataset === null
    );
    if (atLeastOneNotLoaded) {
      return null;
    }
    return this.fileOrders.map(order => order.dataset) as Dataset[];
  }

  @computed
  /**
   * This order contains proprietary data, and the user is not signed in with
   * 2fa.
   */
  get insufficientAuth(): boolean | null {
    const atLeastOneWithAuthIssues = this.fileOrders.some(
      order => order.insufficientAuth === true
    );

    if (atLeastOneWithAuthIssues) {
      return true;
    }

    const atLeastOneNotLoaded = this.fileOrders.some(
      order => order.insufficientAuth === null
    );

    if (atLeastOneNotLoaded) {
      return null;
    }

    return false;
  }

  @computed
  get fileCount(): number {
    return sumBy('fileCount')(this.fileOrders);
  }

  @computed
  get totalSize(): number {
    return sumBy('totalSize')(this.fileOrders);
  }

  @computed
  get created(): Moment {
    return moment.unix(this.fileOrders[0].created);
  }

  @computed
  get id(): string {
    return this.fileOrders[0].groupId;
  }

  @computed
  get status(): OrderStatus {
    const anyHaveFailed = this.fileOrders.some(
      order => order.status === OrderStatus.FAILED
    );
    if (anyHaveFailed) {
      return OrderStatus.FAILED;
    }

    const anyArePending = this.fileOrders.some(
      order => order.status === OrderStatus.PENDING
    );
    if (anyArePending) {
      return OrderStatus.PENDING;
    }

    return OrderStatus.READY;
  }

  @computed
  get expired(): boolean {
    return this.fileOrders.some(order => order.expired);
  }

  @computed
  get fileTypes(): string[] {
    return compose(
      uniq,
      filter(fileType => Boolean(fileType)),
      flatten,
      map(order => (order as FileOrder).fileTypes)
    )(this.fileOrders);
  }

  @computed
  get dateRange(): {
    startDate: Moment | undefined;
    endDate: Moment | undefined;
  } {
    return {
      startDate: compose(
        minBy((dateTime: Moment) => dateTime.valueOf()),
        filter(dateTime => dateTime !== undefined),
        map((order: FileOrder) => order.dateRange.startDate)
      )(this.fileOrders),
      endDate: compose(
        maxBy((dateTime: Moment) => dateTime.valueOf()),
        filter(dateTime => dateTime !== undefined),
        map((order: FileOrder) => order.dateRange.endDate)
      )(this.fileOrders),
    };
  }

  @computed
  get downloadUrls(): DownloadURL[] | null {
    const atLeastOneNotLoaded = this.fileOrders.some(
      order => order.downloadUrls === null
    );
    if (atLeastOneNotLoaded) {
      return null;
    }
    return compose(
      flatten,
      map(order => (order as FileOrder).downloadUrls)
    )(this.fileOrders);
  }

  loadDownloadUrls = (): void => {
    if (!canDownloadUsingOption(DownloadOption.LINKS, this)) {
      throw new Error('Order too big to use download links');
    }
    this.fileOrders.forEach(order => order.loadDownloadUrls());
  };

  private getZipName = (): string => {
    return `order_${this.id}`;
  };

  downloadZip = (): Promise<void> => {
    let bytesDownloaded = 0;
    const onBytesDownloaded = (bytes: number) => {
      bytesDownloaded += bytes;
      const percentDone = Math.min(
        99,
        (bytesDownloaded / this.totalSize) * 100
      );
      this.notificationService.addNotification(
        NOTIFICATION_ID,
        Status.Running,
        `Making zip (${Math.round(percentDone)}% done)...`,
        '',
        false
      );
    };
    const stopSpinner = () =>
      this.notificationService.addNotification(
        NOTIFICATION_ID,
        Status.Success,
        '',
        ''
      );

    // Start the spinner and give the user some hope
    onBytesDownloaded((this.totalSize / 100) * 2);

    const downloadUrls =
      this.downloadUrls !== null
        ? Promise.resolve(this.downloadUrls)
        : Promise.all(
            map((order: FileOrder) => order.fetchDownloadUrls())(
              this.fileOrders
            ) as Promise<DownloadURL[]>[]
          ).then((urls: DownloadURL[][]) => flatten(urls) as DownloadURL[]);
    return downloadUrls
      .then((urls: DownloadURL[]) => {
        const zip = new JSZip();
        const folder = zip.folder(this.getZipName());
        for (const { url, name } of urls) {
          const blobPromise = fetch(url, {
            cache: 'no-store',
          }).then(resp => {
            if (resp.status === 200) {
              const blob = resp.blob();
              blob
                .then(blob => {
                  onBytesDownloaded(blob.size);
                })
                .catch(error => {
                  // This error will be handled during zip creation,
                  // since we pass the blob to JSZip
                });
              return blob;
            }

            return Promise.reject(new Error(resp.statusText));
          });
          if (folder) {
            folder.file(name, blobPromise);
          }
          // folder.file(name, blobPromise);
        }
        return zip.generateAsync({ type: 'blob' }).then((blob: any) => {
          saveAs(blob, `${this.getZipName()}.zip`);
          stopSpinner();
        });
      })
      .catch((error: any) => {
        this.notificationService.addNotification(
          // Unique id needed because otherwise the error may be overwritten
          // when the progress (e.g. "34% done") is set
          `${NOTIFICATION_ID}-ZIP-ERROR`,
          Status.Error,
          'Failed to create zip of files',
          error
        );
      });
  };

  downloadClient = (os: OperatingSystem): Promise<void> => {
    this.notificationService.addNotification(
      NOTIFICATION_ID,
      Status.Running,
      'Building script...',
      '',
      false
    );

    const stopSpinner = () =>
      this.notificationService.addNotification(
        NOTIFICATION_ID,
        Status.Success,
        '',
        ''
      );

    let idQueryString = '';
    for (const order of this.fileOrders) {
      idQueryString += `&id[]=${order.id}`;
    }
    return DapApiAgent.agent
      .get(`/api/file-downloader?os=${os}${idQueryString}`)
      .responseType('blob')
      .then(resp => {
        let fileName = `downloader-${this.id}`;
        if (os === OperatingSystem.MAC || os === OperatingSystem.LINUX) {
          const zip = new JSZip();
          const folder = zip.folder('downloader');
          const readmeContents = [
            `The other file in this folder (i.e. ${fileName}) is an`,
            'executable program which you can use to download the files in',
            'your order. To run it, do the following:',
            '',
            '  1. Open Terminal',
            '  2. cd to the directory which contains this README',
            `  3. Run "chmod +x ${fileName}"`,
            `  4. Run "./${fileName}"`,
            `  5. If an error dialog opens saying the developer can not be verified, see below for the workaround`,
            '  6. Follow the prompts. You will have to enter your password to authenticate',
            '',
            'If you are on macOS, you might receive an error stating that the',
            'developer can not be verified. To fix this issue, use the following',
            'workaround:',
            '',
            '  1. Open System Preferences',
            '  2. Click on "Security & Privacy"',
            '  3. Click on the "General" tab',
            `  4. Click the "Open Anyway" button next to "${fileName} was blocked from use..."`,
            `  5. Try running the script again. This time it should work.`,
            '',
            'If you have any questions or concerns, please email dapteam@pnnl.gov.',
          ].join('\n');
          const readmeBlob = new Blob([readmeContents], { type: 'text/plain' });
          if (folder) {
            folder.file('READ_ME.txt', readmeBlob);
            folder.file(fileName, resp.body);
          }
          return zip.generateAsync({ type: 'blob' }).then(blob => {
            saveAs(blob, 'downloader.zip');
            stopSpinner();
          });
        } else if (os === OperatingSystem.WINDOWS) {
          fileName += '.exe';
          saveAs(resp.body, fileName);
          stopSpinner();
        }
        // TypeScript made me do it ...
        // Apparently Promise<void> resolves to undefined so this works but it's
        // just to appease the linter.
        return undefined;
      })
      .catch((error: any) => {
        this.notificationService.addNotification(
          NOTIFICATION_ID,
          Status.Error,
          'Failed to generate script',
          error
        );
      });
  };

  loadDatasets = (): void => {
    this.fileOrders.forEach(order => order.loadDataset());
  };
}
