import Link from '@dapclient/components/core/Link';
import { Key } from 'react';

export interface IMarkdownLinkProps {
  children?: React.ReactNode;
  href?: string;
  node?: any;
  target?: string;
  key?: string | null | Key;
}


const MarkdownLink = (props: IMarkdownLinkProps) => {
  return (
    <Link to={props.href} {...props}>{props.children}</Link>
  )
};

export default MarkdownLink;
