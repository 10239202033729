import { action, makeObservable, observable, runInAction } from 'mobx';

import DapApiAgent from '@extensions/utils/DapApiAgent';
import type Newsletter from '@extensions/models/Newsletter';
import { INewsletterService } from '@extensions/services/INewsletterService';
import { INotificationService, Status } from '@extensions/services/INotificationService';

export default class NewsletterService implements INewsletterService {
    @observable newsletters: Newsletter[] | null = null;
    @observable selectedNewsletter: Newsletter | undefined = undefined;    
    @observable selectedNewsletterResultNotFound: boolean = false;
    
    notificationService: INotificationService;

    constructor(notificationService: INotificationService) {
        this.notificationService = notificationService;
        makeObservable(this);
    }

    @action async loadNewsletters() {
        this.notificationService.addNotification(
            'loadNewsletter',
            Status.Running,
            '',
            ''
        );
        try {
            await DapApiAgent
                .agent
                .get('/api/site-content/newsletters')
                .then((listOfNewsletterResponse) => {
                    if (listOfNewsletterResponse.body && listOfNewsletterResponse.body.length) {
                        const listOfNewsletterDirName = listOfNewsletterResponse.body.map((file) => file.name);
                        try {
                            listOfNewsletterDirName.forEach(async (dir) => {
                                let respJson;
                                let respText;
                                respJson = await DapApiAgent.agent.get(`/api/site-content/newsletters/${dir}/newsletter.json`);
                                respText = await DapApiAgent.agent.get(`/api/site-content/newsletters/${dir}/content.md`);
                                this.setNewsletter({
                                    "id": dir,
                                    "published_date": respJson.body.published_date,
                                    "title": respJson.body.title,
                                    "content": respText.text
                                });
                            })
                        } catch (error) {
                            this.notificationService.addNotification(
                                'loadNewsletter',
                                Status.Error,
                                'Failed to load news letter',
                                error
                            );
                        }
                    }
                });
            this.notificationService.addNotification(
                'loadNewsletter',
                Status.Success,
                '',
                ''
            );
        } catch (error) {
            this.notificationService.addNotification(
                'loadNewsletter',
                Status.Error,
                'Failed to load news letter',
                error
            );
        } 
    }

    @action setNewsletter(newsletter: Newsletter): void {
        if (this.newsletters === null) {
            this.newsletters = [newsletter]
        } else {
            this.newsletters = [...this.newsletters, newsletter];
        }
        runInAction(()=> this.newsletters?.sort((a,b)=> b.id-a.id));
    }

    @action emptyNewsletters() {
        this.newsletters = null;
    }

    @action selectNewsletter(newsletterId: number) {
        this.resetNotFound();
        if (this.newsletters?.filter((newsletter) => Number(newsletter.id) === newsletterId)[0]) {
            this.selectedNewsletter = this.newsletters?.filter((newsletter) => Number(newsletter.id) === newsletterId)[0];
        } else {
            this.selectNewsletterResult(newsletterId)
        }
    }

    @action setSelectedNewsletterIdNotFound() {
        this.selectedNewsletterResultNotFound = true;
    }

    private selectNewsletterResult = async (id: number) => {
        this.resetNotFound();
        try {
            await this.fetchNewsletterById(id);
        } catch (error: any) {
            throw error;
        }
    }

    private resetNotFound = () => {
        runInAction(() => {
            this.selectedNewsletterResultNotFound = false;
        });
    };

    private fetchNewsletterById = async (id: number) => {
        try {
            const respJson = await DapApiAgent.agent.get(`/api/site-content/newsletters/${id}/newsletter.json`);
            const respMd = await DapApiAgent.agent.get(`/api/site-content/newsletters/${id}/content.md`);
            this.setNewsletter({
                "id": id,
                "published_date": respJson.body.published_date,
                "title": respJson.body.title,
                "content": respMd.text
            });
        } catch (error) {
            this.setSelectedNewsletterIdNotFound();
            this.notificationService.addNotification(
                'loadNewsletter',
                Status.Error,
                `Newsletter ${id} is not found`,
                error
            );
        }
    }
}
