import { isValidElement } from 'react';
import filter from 'lodash/filter';
import last from 'lodash/last';
import remove from 'lodash/remove';
import camelCase from 'lodash/camelCase';
import {
  action,
  computed,
  observable,
  makeObservable,
  transaction,
} from 'mobx';
import * as Sentry from '@sentry/react';

import {
  INotificationService,
  Description,
  Notification,
  Status,
} from '@extensions/services/INotificationService';

export class NotificationService implements INotificationService {
  @observable notifications: Notification[] = [];
  @observable showNotifications: boolean = false;
  @observable stack: Notification[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed get error(): Notification | null {
    return (
      this.getFirstNotificationOfType(Status.Error) ||
      this.getFirstNotificationOfType(Status.Warn)
    );
  }

  @computed get running() {
    return this.getFirstNotificationOfType(Status.Running);
  }

  @computed get downloading() {
    return this.getFirstNotificationOfType(Status.Downloading);
  }

  @computed get success(): Notification | null {
    return this.getLatestUserNotificationOfType(Status.Success);
  }

  @action shiftStack = () => {
    return this.stack.shift() || null;
  };

  @action removeFromStack = (id: string) => {
    for (let i = 0; i < this.stack.length; i++) {
      if (this.stack[i].id === id) {
        this.stack.splice(i, 1);
        break;
      }
    }
  };

  // TODO when notifications are added to the stack that don't appear in the UI as a message
  // for the user to dismiss, they never get removed from the stack. is this a problem?
  @action addNotification(
    id: string,
    status: Status,
    message: string,
    description: any = '',
    drawerAutoOpen: boolean = true,
    sentryIgnore: boolean = false
  ) {
    if (status !== Status.Error) {
      Sentry.addBreadcrumb({
        type: 'debug',
        message: 'Added notification',
        data: {
          id,
          status,
          message,
          description: isValidElement(description)
            ? 'React node (not logged)'
            : description,
          drawerAutoOpen,
        },
      });
      sentryIgnore = true;
    }

    let formattedDesc = description;

    // If the description is an object, then convert it into a string:
    if (
      description !== null &&
      description !== undefined &&
      typeof description === 'object' &&
      !isValidElement(description)
    ) {
      if ('response' in description) {
        // this is an http response error
        // look for the specific case when users attempt an API call they aren't allowed to do and make the message be more user-friendly:
        if (
          description.response.status === 403 &&
          description.response.text &&
          (camelCase(description.response.text) ===
            'messageThisActionIsUnauthorized' ||
            camelCase(description.response.text) === 'messageUnauthenticated.')
        ) {
          formattedDesc = 'You are not authorized to perform this action.';
          sentryIgnore = true;
        } else if (
          description.response.status === 401 &&
          description.response.text &&
          camelCase(description.response.text) === 'messageUnauthenticated'
        ) {
          formattedDesc =
            'You are not logged in.  You must be logged in to perform this action.';
          sentryIgnore = true;
        } else {
          formattedDesc = `HTTP Error (${description.response.status} ${description.response.statusText}): ${description.response.text}`;
        }
      } else if (description instanceof Error) {
        formattedDesc = description.message;
      } else {
        formattedDesc = JSON.stringify(description, null, 2);
      }
    }

    const notification: Notification = new Notification(
      id,
      status,
      message,
      formattedDesc
    );

    const index = this.getNotificationIndex(notification.id);
    if (index >= 0) {
      // overwrite if id already exists
      this.notifications[index] = notification;
    } else {
      this.notifications.push(notification);
    }

    if (drawerAutoOpen && Boolean(message)) {
      switch (status) {
        case Status.Error:
        case Status.Warn:
        case Status.Info:
        case Status.Success:
        case Status.Downloading:
          let found = false;
          for (let i = 0; i < this.stack.length; i++) {
            if (this.stack[i].id === notification.id) {
              this.stack[i] = notification;
              found = true;
              break;
            }
          }
          if (!found) {
            // When adding to the stack, remove success items in favor of what's
            // going on now
            let shift = 0;
            for (let i = 0; i < this.stack.length; i++) {
              switch (this.stack[i].status) {
                case Status.Success:
                case Status.Info:
                  shift++;
                  continue;
              }
              break;
            }
            transaction(() => {
              this.stack.splice(0, shift);
              this.stack.push(notification);
            });
          }
          break;
      }
    }

    // Don't capture 404s, bad passwords, and other known errors that do not
    // indicate system issues.
    if (
      Object.values(Description).includes(description) ||
      (description &&
        description.response &&
        404 === description.response.status)
    ) {
      sentryIgnore = true;
    }

    if (!sentryIgnore) {
      Sentry.withScope((scope) => {
        scope.setFingerprint([id, message, formattedDesc]);
        Sentry.captureException(
          new Error(`Notification with id "${id}": ${message} ${formattedDesc}`)
        );
      });
    }
  }

  @action deleteAllNonRunningNotices() {
    remove(
      this.notifications,
      (notice: Notification) =>
        notice.status !== Status.Running &&
        notice.status !== Status.Downloading &&
        notice.status !== Status.BackgroundRunning
    );
  }

  @action removeNotification(id: string) {
    let index = this.getNotificationIndex(id);
    if (index >= 0) {
      this.notifications.splice(index, 1);
    }
    this.removeFromStack(id);
  }

  @action setShowNotifications(show: boolean) {
    this.showNotifications = show;
  }

  getNotification(id: string): Notification | null {
    let notification: Notification | null = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.notifications.length; i++) {
      if (this.notifications[i].id === id) {
        notification = this.notifications[i];
        break;
      }
    }
    return notification;
  }

  getFirstNotificationOfType(status: Status): Notification | null {
    let notification: Notification | null = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.notifications.length; i++) {
      if (this.notifications[i].status === status) {
        notification = this.notifications[i];
        break;
      }
    }
    return notification;
  }

  // Notifications displayed to the user need a message and description. This filters on status type
  // passed in and notifications with a non-null message and description
  getLatestUserNotificationOfType(status: Status): Notification | null {
    return (
      last(
        filter(
          this.notifications,
          (notif) =>
            notif.status === status &&
            notif.message !== null &&
            notif.description !== null
        )
      ) || null
    );
  }

  getNotificationIndex(id: string): number {
    let index = -1;
    for (let i = 0; i < this.notifications.length; i++) {
      if (this.notifications[i].id === id) {
        index = i;
        break;
      }
    }
    return index;
  }

  showStateInUI = async (params: {
    pending: Promise<any>;
    notificationId: string;
    successMessage?: string;
    successDescription?: string;
    errorMessage: string | ((error: any) => string);
    errorDescription?: string | ((error: any) => string);
    onError?: (error: Error) => void;
    onSuccess?: () => void;
    sentryIgnore?: boolean;
  }): Promise<any> => {
    const {
      notificationId,
      pending,
      successMessage = '',
      successDescription = '',
      errorMessage,
      errorDescription,
      onError,
      onSuccess,
      sentryIgnore = false,
    } = params;
    this.addNotification(notificationId, Status.Running, '', '');
    let response;
    try {
      response = await pending;
      this.addNotification(
        notificationId,
        Status.Success,
        successMessage,
        successDescription
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (err: any) {
      let description: any = err;
      switch (typeof errorDescription) {
        case 'string':
          description = errorDescription;
          break;
        case 'function':
          description = errorDescription(err);
          break;
      }

      let message = '';
      switch (typeof errorMessage) {
        case 'string':
          message = errorMessage;
          break;
        case 'function':
          message = errorMessage(err);
          break;
      }

      this.addNotification(
        notificationId,
        Status.Error,
        message,
        description,
        true,
        sentryIgnore
      );
      if (onError) {
        onError(err);
      }
    }
    return response;
  };
}

export default NotificationService;
