import { observer, inject } from "mobx-react";
import { ChangeEvent, useCallback, useEffect, useMemo, useState, useRef } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Button,
  TextField,
  Alert,
  IconButton
} from "@mui/material";
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ClearIcon from '@mui/icons-material/Clear';

import { IUploadService } from "@extensions/services/IUploadService";

import { useHashStateKey } from '@extensions/hooks/useUrl';
import ConfigureDataset from '@extensions/components/data/uploaders/ConfigureDataset';
import VirtualizedAutocomplete from '@extensions/components/core/VirtualizedAutocomplete';
import theme from '@extensions/services/Theme';

const StyledAccordionDetailsContainerDiv = styled('div')(({
  display: 'flex'
}));

const StyledProjectPrefixSpan = styled('span')(({
  color: '#ccc'
}));

type DatasetLeafProps = {
  highlight?: boolean;
}

const StyledDatasetLeafSpan = styled('span', {
  shouldForwardProp: (props) => props !== 'highlight'
})<DatasetLeafProps & { highlight: boolean }>(({ highlight }) => {
  if (highlight) {
    return {
      fontFamily: 'monospace',
      fontWeight: 'bold'
    }
  }
  return {
    fontFamily: 'monospace',
    fontWeight: 'normal'
  }
});

const StyledFilterTextField = styled(TextField)(({
  '& .MuiInputBase-input': {
    fontFamily: 'monospace'
  }
}))

type IConfigureProps = {
  uploadService?: IUploadService;
}

const Configure = inject('uploadService')(observer(
  ({ uploadService }: IConfigureProps) => {

    const config = uploadService?.currentProjectConfig;

    const [defaultDatasetName, setDefaultDatasetName] = useHashStateKey('dataset', undefined);

    const rawDatasetNames = config?.datasets;

    const datasetNames = useMemo(() => {
      return rawDatasetNames ? rawDatasetNames.slice().sort() : [];
    }, [rawDatasetNames]);

    const addableDatasetNames = uploadService && config
      ? uploadService.datasetNames.filter(name => {
        return name.startsWith(`${config.name}/`)
          && datasetNames.indexOf(name) < 0;
      })
      : [];

    const [addDatasetName, setAddDatasetName] = useState(null as string | null);
    const [addedDatasetNames, setAddedDatasetNames] = useState([] as string[]);

    const addDataset = useCallback((datasetName) => {
      if ((!datasetName && !addDatasetName) || !uploadService || !config) {
        return;
      }
      const fullName = `${config.name}/${datasetName || addDatasetName}`;
      uploadService.saveDatasetConfig({
        name: fullName,
      }).then(() => {
        setAddDatasetName(null);
        setAddedDatasetNames([...addedDatasetNames, fullName]);
        uploadService.loadProject(config.name, true);
      });
    }, [addDatasetName, addedDatasetNames, uploadService, config]);

    const [filter, setFilter] = useHashStateKey("filter", "");

    const regex = new RegExp(filter.replace(/\*/g, '.*'));

    useEffect(() => {
      if (!defaultDatasetName || !config) {
        return;
      }
      if (datasetNames.indexOf(`${config.name}/${defaultDatasetName}`) < 0) {
        addDataset(defaultDatasetName);
      }
      setFilter(defaultDatasetName);
      setDefaultDatasetName(undefined);
    }, [
      defaultDatasetName,
      setDefaultDatasetName,
      setFilter,
      datasetNames,
      addDataset,
      addedDatasetNames,
      config,
    ]);

    return (
      <Grid direction="column" container spacing={2}>
        <Grid item>
          <Grid container spacing={10} alignItems="center">
            <Grid item xs={12} sm={5}>
              <StyledFilterTextField
                variant="standard"
                fullWidth
                placeholder="Filter List ..."
                value={filter}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setFilter(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      onClick={() => setFilter('')}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={10}>
                  <VirtualizedAutocomplete
                    value={addDatasetName}
                    options={
                      addableDatasetNames.map(
                        name => name.split('/').pop() as string
                      ).sort()
                    }
                    onChange={(event, name: string | null) => {
                      setAddDatasetName(name);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Configure New Dataset ..."
                        variant="outlined"
                        size="small"
                        sx={{ 
                          input: { 
                            color: "#000" 
                          }, 
                          label: {
                            color: theme.palette.text.primary
                          } 
                        }} 
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    disabled={addDatasetName === null}
                    color="primary"
                    endIcon={<ArrowDownwardIcon />}
                    fullWidth
                    onClick={() => addDataset(null)}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {datasetNames.filter(datasetName => {
            return regex.test(datasetName);
          }).map(datasetName => (
            <Dataset
              key={datasetName}
              name={datasetName}
              scrollIntoView={
                addedDatasetNames.length > 0 &&
                addedDatasetNames[addedDatasetNames.length - 1] === datasetName
              }
              defaultExpanded={addedDatasetNames.indexOf(datasetName) >= 0}
              load={
                uploadService
                  ? uploadService.loadDatasetConfig
                  : () => Promise.resolve()
              }
            />
          ))}
        </Grid>
      </Grid>
    );
  }
));

type IDatasetProps = {
  name: string;
  scrollIntoView: boolean;
  defaultExpanded: boolean;
  load: (name: string) => Promise<any>;
}

const Dataset = ({ name, scrollIntoView, defaultExpanded, load }: IDatasetProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [config, setConfig] = useState(null as any);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null as any);
  const [edited, setEdited] = useState(false);

  const runLoad = useCallback(() => {
    setLoading(true);
    load(name)
      .then(config => setConfig(config))
      .catch(err => setError(err))
      .finally(() => setLoading(false));
  }, [load, name]);

  useEffect(() => {
    if (expanded && config === null && error === null && !loading) {
      runLoad();
    }
  });

  useEffect(() => {
    if (scrollIntoView && containerRef.current !== null) {
      containerRef.current.scrollIntoView();
    }
  }, [scrollIntoView]);

  const onStatusChange = useCallback(edited => {
    setEdited(edited);
  }, [setEdited]);

  const onForceClose = useCallback(() => {
    setExpanded(false);
    setEdited(false);
  }, [setEdited, setExpanded]);

  return (
    <Accordion
      ref={containerRef}
      key={name}
      expanded={expanded}
      TransitionProps={{
        unmountOnExit: true,
        onExited: () => {
          if (!expanded) {
            setConfig(null);
            setError(null);
          }
        },
      }}
      onChange={(event: ChangeEvent<{}>, expand: boolean) => {
        if (!expand && edited) {
          return;
        }
        setExpanded(expand);
        if (expand) {
          runLoad();
        }
      }}
      sx={{ flexGrow: 1 }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <StyledProjectPrefixSpan>{name.split('/').shift()}&nbsp;/</StyledProjectPrefixSpan>
        <StyledDatasetLeafSpan highlight={expanded}>
          &nbsp;{name.split('/').pop()}
        </StyledDatasetLeafSpan>
        {edited && (
          <em style={{ opacity: 0.5 }}>&nbsp; ...not collapsible while editing</em>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <StyledAccordionDetailsContainerDiv>
          {
            error !== null && (
              <Alert severity="error">
                {error.toString()}
              </Alert>
            )
          }
          {
            config !== null && (
              <ConfigureDataset
                config={config}
                onStatusChange={onStatusChange}
                onForceClose={onForceClose}
              />
            )
          }
          {
            loading && (
              <CircularProgress color="secondary" />
            )
          }
        </StyledAccordionDetailsContainerDiv>
      </AccordionDetails>
    </Accordion>
  );
};

export default Configure;
