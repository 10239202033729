import AccessRestriction from '@extensions/models/AccessRestriction';
import Permission from '@extensions/models/Permission';

export enum Type {
  DATASET = 'dataset',
  PROJECT = 'project',
}

export default interface IControlledAccess {
  getPermissionLabel(): string;
  getRestriction(): AccessRestriction;
  getIdentifier(): string;
  getType(): Type;
  getPermissions(): Permission[];
}
