import React from 'react';
import qs from 'query-string';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import {
  Link,
  Redirect,
  RouteComponentProps,
  withRouter,
} from 'react-router-dom';
import theme from '@extensions/services/Theme';

import User from '@extensions/models/User';
import { ISecurityService } from '@extensions/services/ISecurityService';
import UserProfileForm from '@extensions/components/user/UserProfileForm';

const StyledFullPageFormDiv = styled('div')(({
  ...theme.FullPageForm.root
}));

const StyledFormDiv = styled('div')(({
  ...theme.FullPageForm.form
}));

const StyledFormFooterDiv = styled('div')(({ theme }) => (({
  ...theme.FullPageForm.formFooter,
  color: theme.palette.grey[700]
})));

const StyledLink = styled(Link)(({
  textDecoration: 'none'
}));

export interface IRegisterProps extends RouteComponentProps {
  securityService?: ISecurityService;
}

@inject('securityService')
@observer
export class Register extends React.Component<IRegisterProps> {
  render() {
    const user: User | null = this.props.securityService
      ? this.props.securityService.user
      : null;

    // These will only be set on a reset password url sent via email
    const resetToken = qs.parse(this.props.location.search).token;
    const email = qs.parse(this.props.location.search).email;

    if (user && user.authenticated) {
      return <Redirect to={{ pathname: '/profile' }} />;
    } else {
      return (
        <StyledFullPageFormDiv>
          <StyledFormDiv>
            {/* Adding a unique key will force the form to recreate itself every time this page is rendered */}
            <UserProfileForm
              key={new Date().getMilliseconds()}
              resetToken={resetToken}
              email={email}
            />
          </StyledFormDiv>
          <StyledFormFooterDiv>
            {' '}
            Already have an account? <StyledLink to="/signIn">Sign In</StyledLink>
          </StyledFormFooterDiv>
        </StyledFullPageFormDiv>
      );
    }
  }
}

export default withRouter(Register);
