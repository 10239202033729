import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';

import {
  Grid,
  IconButton,
  Button,
  ButtonGroup,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  TextField,
  Tooltip,
  Select,
  Alert,
  AlertTitle
} from '@mui/material';
import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import {
  IClient,
  IClientFile,
  IClientSourceState,
  IUploadService,
} from "@extensions/services/IUploadService";
import { IDatasetFile } from '@extensions/services/IDatasetService';
import { getRecentFileList } from '@extensions/services/DatasetService';

import { formatBytes } from '@extensions/utils/format';

const Container = styled(Alert)`
  border: 1px solid rgba(0, 0, 0, 0.2);
  & .MuiAlert-message {
    width: 100%;
  }
`;

const Table = styled.table`
  width: 100%;
  & td {
    padding: 0 0.1rem;
    vertical-align: middle;
  }
  & td:first-of-type {
    width: 115px;
    text-align: right;
    font-style: italic;
    padding-right: 0.5rem;
    color: rgba(0, 0, 0, 0.5);
    font-size: 80%;
  }
  & td.small {
    font-size: 80%;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
`;

type IClientProps = {
  client: IClient;
  assignableDatasets: string[];
  uploadService?: IUploadService;
};

const osMap = {
  darwin: 'MacOS',
  linux: 'Linux',
  windows: 'Windows',
};

const Client = inject('uploadService')(observer((
  { client, assignableDatasets, uploadService }: IClientProps
) => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [datasetToAdd, setDatasetToAdd] = useState("");
  const [editingAlias, setEditingAlias] = useState(false);
  const [editedAlias, setEditedAlias] = useState("");
  const [showLog, setShowLog] = useState(false);

  const aliasRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (datasetToAdd !== "" && assignableDatasets.indexOf(datasetToAdd) < 0) {
      setDatasetToAdd("");
    }
  }, [assignableDatasets, datasetToAdd, setDatasetToAdd]);

  useEffect(() => {
    if (editingAlias && aliasRef.current) {
      aliasRef.current.focus();
    }
  }, [editingAlias, aliasRef]);

  const setEnabled = useCallback(async (value: boolean) => {
    if (uploadService) {
      await uploadService.updateClient({
        id: client.id,
        approved: value,
        datasets: client.datasets,
      });
    }
  }, [client, uploadService]);

  const setAlias = useCallback((value: string) => {
    if (uploadService) {
      uploadService.updateClient({
        id: client.id,
        alias: value,
        datasets: client.datasets,
      });
    }
  }, [client, uploadService]);

  const addDataset = useCallback((datasetName: string) => {
    if (uploadService) {
      uploadService.updateClient({
        id: client.id,
        datasets: [...client.datasets, datasetName],
      });
    }
  }, [client, uploadService]);

  const removeDataset = useCallback((datasetName: string) => {
    if (uploadService) {
      uploadService.updateClient({
        id: client.id,
        datasets: client.datasets.filter(d => d !== datasetName),
      });
    }
  }, [client, uploadService]);

  const log = client.state ? client.state.msg.filter(msg => Boolean(msg)) : null;

  return (
    <Container severity={client.approved ? "success" : "warning"}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Grid
            container
            direction="column"
            spacing={2}
            justifyContent="space-between"
            sx={{ minHeight: '100%' }}
          >
            <Grid item>
              <AlertTitle>
                <Table>
                  <tbody>
                    <tr>
                      <td>Computer</td>
                      <td>
                        {Boolean(client.alias) ? client.name : (
                          <strong>{client.name}</strong>
                        )}
                        &nbsp;
                        <small>({osMap[client.os]})</small>
                      </td>
                    </tr>
                    <tr>
                      <td>Alias</td>
                      <td>
                        {editingAlias ? (
                          <TextField
                            variant="standard"
                            fullWidth
                            size="small"
                            inputProps={{
                              ref: aliasRef,
                            }}
                            onBlur={() => setEditingAlias(false)}
                            value={editedAlias || client.alias}
                            onChange={e => setEditedAlias(e.target.value)}
                            onKeyPress={async e => {
                              if (e.key === 'Enter') {
                                await setAlias(editedAlias);
                                setEditedAlias("");
                                setEditingAlias(false);
                              }
                            }}
                          />
                        ) : (
                          <>
                            {Boolean(client.alias) && (
                              <strong>{client.alias}&nbsp;</strong>
                            )}
                            <IconButton size='small' onClick={() => setEditingAlias(true)}>
                              <EditIcon fontSize='small' sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                            </IconButton>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Local IP</td>
                      <td className="small">
                        {client.state && client.state.ip ? (<code>{client.state.ip.join(', ')}</code>) : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Version</td>
                      <td className="small">
                        {client.state ? (<code>{client.state.vers}</code>) : '-'}
                      </td>
                    </tr>
                    <tr>
                      <td>Last Contact</td>
                      <td className="small">
                        {moment(client.state ? client.state.when : client.pinged_at).fromNow()}
                      </td>
                    </tr>
                    {log && (
                      <tr>
                        <td colSpan={2} style={{ paddingTop: '1rem' }}>
                          <Button size="small" fullWidth variant="outlined" onClick={() => setShowLog(prev => !prev)}>
                            {showLog ? <ExpandLessIcon /> : <ExpandMoreIcon />}&nbsp;
                            {showLog ? 'Hide' : 'Show'} Latest Log Messages ({log.length})
                          </Button>
                          <Collapse in={showLog}>
                            <div style={{ paddingTop: '0.5rem' }}>
                              <TextField
                                inputProps={{
                                  style: {
                                    whiteSpace: 'nowrap',
                                    fontSize: '70%',
                                    fontFamily: 'monospace',
                                  }
                                }}
                                fullWidth
                                multiline
                                contentEditable={false}
                                value={log.join("\n")}
                                minRows={log.length}
                                maxRows={log.length}
                              />
                            </div>
                          </Collapse>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </AlertTitle>
            </Grid>
            <Grid item>
              <Buttons>
                <ButtonGroup size="small" variant="text">
                  <Button onClick={() => setEnabled(!client.approved)}>
                    <Tooltip title={client.approved ? "Disable Client" : "Enable / Approve Client"}>
                      {client.approved ? <BlockIcon fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.54)' }} /> : <CheckIcon fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />}
                    </Tooltip>
                  </Button>
                  <Button onClick={() => setDialogOpen(true)}>
                    <Tooltip title="Permanently Delete Client">
                      <DeleteForeverIcon fontSize="small" sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    </Tooltip>
                  </Button>
                </ButtonGroup>
                <Dialog
                  open={dialogOpen}
                  onClose={() => setDialogOpen(false)}
                >
                  <DialogTitle>
                    Are you sure?
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Deleting a client cannot be undone.
                    </DialogContentText>
                    <DialogContentText>
                      <strong>Computer Name</strong>:&nbsp;
                      {client.name} {Boolean(client.alias) && ` / ${client.alias}`}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>
                      No, Cancel
                    </Button>
                    <Button onClick={async () => {
                      if (uploadService) {
                        await uploadService.deleteClient(client.id);
                      }
                      // No need to close the dialog because deleting the
                      // client will cause this whole component to go away
                    }}>
                      Yes, Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </Buttons>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            spacing={1}
            sx={{ minHeight: '100%' }}
          >
            <Grid item>
              <Grid container direction="column" spacing={1}>
                {[...client.datasets].sort().map(dataset => (
                  <Grid item key={dataset}>
                    <Dataset
                      datasetName={dataset}
                      state={client.state ? client.state.sources[dataset] || null : null}
                      onRemove={() => removeDataset(dataset)}
                    />
                  </Grid>
                ))}
                {client.datasets.length === 0 && (
                  <Grid item>
                    <em>No Datasets Assigned</em>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {assignableDatasets.length > 0 && (
              <Grid item>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={10}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="dataset-select">Select Dataset to Add ...</InputLabel>
                      <Select
                        variant='standard'
                        native
                        value={datasetToAdd}
                        inputProps={{
                          id: 'dataset-select',
                          style: {
                            fontFamily: 'monospace',
                          },
                        }}
                        onChange={e => setDatasetToAdd(e.target.value as string)}
                      >
                        <option value="" />
                        {assignableDatasets.map(dataset => (
                          <option key={dataset} value={dataset}>{dataset.split('/').pop()}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      disableElevation
                      size="small"
                      fullWidth
                      disabled={datasetToAdd === ""}
                      onClick={() => addDataset(datasetToAdd)}
                      sx={{
                        fontSize: '13px'
                      }}
                    >
                      Add&nbsp;
                      <ArrowUpwardIcon fontSize="small" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}));

export default Client;

const DatasetWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 0.25rem 0.25rem 0.25rem 0.6rem;

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .state {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0.25rem;
    margin-top: 0.25rem;
    font-size: 70%;
  }

  & .bw {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 3px 5px;
    text-align: center;
    font-style: italic;
  }
`;

interface IDatasetProps {
  datasetName: string
  state: IClientSourceState | null
  onRemove: () => void
}

const Dataset = ({ datasetName, state, onRemove }: IDatasetProps) => {

  const [expanded, setExpanded] = useState(false);
  const [received, setReceived] = useState(null as IDatasetFile[] | null);
  const [filesErr, setFilesErr] = useState(null as string | null);

  let bytes = 0;
  let seconds = 0;
  let bytesPerSecond = 0;
  let idlePct = 100;
  if (state && state.throughput.bytes > 0) {
    bytes = state.throughput.bytes;
    seconds = state.throughput.secs;
    bytesPerSecond = Math.round((bytes / seconds) * 100) / 100;
    idlePct = Math.round(state.throughput.idle);
  }

  const loadRecent = useCallback(async () => {
    setFilesErr(null);
    try {
      setReceived(await getRecentFileList(datasetName, 10));
    } catch (error: any) {
      setFilesErr(error as string);
    }
  }, [datasetName]);

  useEffect(() => {
    if (!expanded) {
      return;
    }
    loadRecent();
    let cancel = setInterval(
      loadRecent,
      60 * 1000
    );
    return () => clearInterval(cancel);
  }, [expanded, loadRecent]);

  return (
    <DatasetWrapper>
      <div className="header">
        <code>
          <IconButton
            size="small"
            onClick={() => setExpanded(prev => !prev)}
          >
            {expanded && (
              <ExpandLessIcon fontSize="small" />
            )}
            {!expanded && (
              <ExpandMoreIcon fontSize="small" />
            )}
          </IconButton>
          {datasetName.split('/').pop()}
        </code>
        <IconButton size="small" onClick={onRemove} disabled={expanded}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
      <Collapse in={expanded}>
        <Grid className="state" container>
          {state && state.files.length === 6 && (
            <>
              <StateBlock
                label="Files Found (Pending Queue)"
                first={state.files[0] || null}
                last={state.files[1] || null}
                count={state.foundCnt}
                bytes={state.foundSize}
              />
              <StateBlock
                label="Files Queued (Pending Transfer)"
                first={state.files[2] || null}
                last={state.files[3] || null}
                count={state.qCnt}
                bytes={state.qSize}
              />
              <Grid item className="bw" xs={12}>
                Session Stats: {formatBytes(bytes)},&nbsp;
                {moment.duration({ seconds }).humanize()},&nbsp;
                {formatBytes(bytesPerSecond)}/s ({idlePct}% idle)
              </Grid>
              <StateBlock
                label="Files Sent"
                first={state.files[4] || null}
                last={state.files[5] || null}
                count={state.sentCnt}
                bytes={state.sentSize}
              />
            </>
          )}
          {Boolean(filesErr) && (
            <Alert severity="error">
              <AlertTitle>{filesErr}</AlertTitle>
            </Alert>
          )}
          <StateBlockWrapper container direction="column">
            <Grid item className="heading">
              <strong>Most Recent Files Stored</strong>
            </Grid>
            <Grid item>
              {
                received === null && (
                  <FileWrapper>
                    <strong><em>Loading ...</em></strong>
                  </FileWrapper>
                )
              }
              {
                received !== null && received.length === 0 && (
                  <FileWrapper>
                    <em>None Found</em>
                  </FileWrapper>
                )
              }
              {
                received !== null && received.map(file => (
                  <File
                    key={file.signature}
                    file={{
                      name: file.name,
                      rename: "",
                      path: file.name,
                      hash: file.signature,
                      size: file.size,
                      time: file.received.format(),
                      sent: true,
                    }}
                    iteration={file.iteration}
                  />
                ))
              }
            </Grid>
          </StateBlockWrapper>
        </Grid>
      </Collapse>
    </DatasetWrapper>
  )
};

interface IStateBlockProps {
  label: string
  first: IClientFile | null
  last: IClientFile | null
  count: number
  bytes: number
}

const StateBlockWrapper = styled(Grid)`
  margin: 0.5rem 0;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  & .heading {
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 3px 5px;
    border-radius: 0 3px 3px 0;
  }
  & .summary {
    margin-left: 2rem;
    border-left: 3px dotted rgba(0, 0, 0, 0.3);
    padding: 1rem 0.5rem;
    font-style: italic;
  }
`;

const StateBlock = ({ label, first, last, count, bytes }: IStateBlockProps) => {
  let remainingCount = count;
  let remainingBytes = bytes;
  if (first) {
    remainingCount--;
    remainingBytes -= first.size;
  }
  last = (first && last && first.path === last.path) ? null : last;
  if (last) {
    remainingCount--;
    remainingBytes -= last.size;
  }

  return (
    <StateBlockWrapper container direction="column">
      <Grid item className="heading">
        <strong>{label}</strong>
      </Grid>
      <Grid item>
        <File file={first} />
      </Grid>
      {remainingCount > 0 && (
        <Grid item className="summary">
          + <strong>{remainingCount.toLocaleString()}</strong> files
          &nbsp;<strong>({formatBytes(remainingBytes)})</strong>
        </Grid>
      )}
      <Grid item>
        <File file={last} />
      </Grid>
    </StateBlockWrapper>
  );
};

interface IFileProps {
  file: IClientFile | null;
  iteration?: number;
}

const FileWrapper = styled(Grid)`
  padding: 0 0 0 2px;
  margin: 3px;
  overflow-wrap: anywhere;
  & .flip {
    display: inline-block;
    transform: scaleX(-1);
    padding: 0 3px;
  }
  & .rename {
    font-style: italic;
    opacity: 0.7;
  }
`;

const File = ({ file, iteration }: IFileProps) => {
  if (file === null) {
    return null;
  }
  return (
    <FileWrapper direction="column" container>
      <Grid item>
        <strong>{file.path}{iteration !== undefined && ` (v${iteration})`}</strong>
      </Grid>
      <Grid item>
        {file.time},&nbsp;
        {formatBytes(file.size)}
      </Grid>
      {Boolean(file.rename) && (
        <Grid item>
          <span className="flip">
            &crarr;
          </span>
          <span className="rename">
            {file.rename}
          </span>
        </Grid>
      )}
    </FileWrapper>
  );
}
