import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  Link
} from '@mui/material';
import { observer } from 'mobx-react';
import kebabCase from 'lodash/kebabCase';
import identity from 'lodash/identity';

import Project from '@extensions/models/Project';
import CardExpansionPanel from '@extensions/components/core/CardExpansionPanel';
import ExpandableText from '@extensions/components/core/ExpandableText';
import ProjectDatasets from '@extensions/components/project-search/ProjectDatasets';
import ProjectPublications from '@extensions/components/project-search/ProjectPublications';
import ContactsTable from '@extensions/components/core/ContactsTable';
import theme from '@extensions/services/Theme';
import omit from 'lodash/omit';
import { removeMdExcept } from '@extensions/utils/SearchUtils';
import SearchHighlight from '@extensions/components/core/SearchHighlight';

type TypographyExtraProps = {
  component: React.ElementType;
};

const StyledTypographyBodyOne = styled(Typography)<TypographyExtraProps>(({
  color: 'rgba(0, 0, 0, 0.54)',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '1rem',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

const StyledTypographyHTwo = styled(Typography)<TypographyExtraProps>(() => ({
  fontSize: `${theme.typography.pxToRem(20.8)} !important`,
  fontWeight: `${theme.typography.fontWeightRegular} !important`,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

const StyledCardExpansionPanel = styled(CardExpansionPanel)(() => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

const StyledCard = styled(Card)(() => ({
  marginBottom: theme.spacing(3)
}));

const StyledCardHeader = styled(CardHeader)(() => ({
  padding: 0,
  paddingTop: theme.spacing(3),
}));

const StyledCardContent = styled(CardContent)(() => ({
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
}));

export interface IProjectResultProps {
  className?: string;
  hit: Project;
  highlight?: {string: string[]};
  searchFieldLabels?: {string: string};
}

export interface IProjectResultState { }

@observer
export class ProjectResult<
  ExtraProps extends object = {}
> extends React.Component<
  IProjectResultProps & ExtraProps,
  IProjectResultState
> {
  getPanel = ({
    title,
    body,
  }: {
    title: string;
    body: React.ReactNode;
  }): React.ReactNode => {
    const { hit } = this.props;
    return (
      <StyledCardExpansionPanel
        detailsId={`${hit.identifier}-${kebabCase(title)}`}
        summaryContent={
          <Typography
            variant="h4"
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightMedium,
              paddingLeft: (theme) => theme.spacing(2),
              fontSize: '1rem !important'
            }}
          >
            {title}
          </Typography>
        }
        detailsContent={body}
      />
    );
  };

  getListPanel<T>({
    title,
    items,
    renderItem = identity,
    getKey = identity,
  }: {
    title: string;
    items: T[];
    renderItem?: (item: T) => React.ReactNode;
    getKey?: (item: T, index: number) => string | number;
  }): React.ReactNode {
    let body: React.ReactNode;
    if (items && items.length > 0) {
      body = (
        <List sx={{ margin: '0 1rem' }}>
          {items.map((item, index) => (
            <ListItem key={getKey(item, index)} sx={{color: theme.palette.common.black}}>{renderItem(item)}</ListItem>
          ))}
        </List>
      );
    } else {
      body = (
        <Typography sx={{ margin: '0 1rem' }}>
          No {title} Found
        </Typography>
      );
    }
    return this.getPanel({
      title,
      body,
    });
  }

  getAdditionalPanels = (hit: Project): React.ReactNode => {
    return null;
  };

  getContactsPanel = (hit: Project): React.ReactNode => {
    return this.getPanel({
      title: 'Contacts',
      body: (
        <ContactsTable
          contacts={hit.contactPoint}
          name={hit.identifier}
          title={hit.title}
        />
      ),
    });
  };

  getDatasetsPanel = (hit: Project): React.ReactNode => {
    return this.getPanel({
      title: 'Datasets',
      body: <ProjectDatasets project={hit} tableSize="small" />,
    });
  };

  getPublicationPanel = (hit: Project): React.ReactNode => {
    return this.getPanel({
      title: 'References',
      body: <ProjectPublications project={hit} tableSize="small" />,
    });
  };

  getParticipatingOrgsPanel = (hit: Project): React.ReactNode => {
    return this.getListPanel({
      title: 'Participating Organizations',
      items: hit.participatingOrganizations,
      renderItem: (org) => org.name,
      getKey: (org) => org.name,
    });
  };

  getExpandableText = (hit: Project): React.ReactNode => {
    const { highlight, searchFieldLabels } = this.props;

    const desc = removeMdExcept(this.hlField('description'), ['mark'])
      .replace(/^[^<>]+(\s[^<>]{300,}<mark>)/, '...$1');

    const fields = omit(searchFieldLabels, [
      'name',
      'title',
      'description',
    ]) as {string: string}

    return (
      <>
        <ExpandableText dangerous>
          {desc}
        </ExpandableText>

        {Boolean(highlight) && Boolean(searchFieldLabels) && (
          <SearchHighlight
            highlight={highlight as {string: string[]}}
            searchFieldLabels={fields}
          />
        )}
      </>
    );
  };

  hlField = f => {
    const { highlight, hit } = this.props;
    return highlight && highlight.hasOwnProperty(f)
      ? highlight[f][0]
      : hit[f]
  };

  render() {
    const { hit } = this.props;
    return (
      <StyledCard>
        <StyledCardHeader
          title={
            <>
              <StyledTypographyBodyOne component="span" variant='body1'>
                <code
                  style={{textTransform: 'uppercase'}}
                  dangerouslySetInnerHTML={{ __html: this.hlField('name') }}
                />
              </StyledTypographyBodyOne>
              <StyledTypographyHTwo
                component='h2'
                variant="h2"
              >
              <Link href={`/project/${hit.identifier}`} underline='hover'>
                <span dangerouslySetInnerHTML={{ __html: this.hlField('title') }} />
              </Link>
              </StyledTypographyHTwo>
            </>
          }
        />
        <StyledCardContent>
          {this.getExpandableText(hit)}
        </StyledCardContent>
        {this.getAdditionalPanels(hit)}
        {this.getContactsPanel(hit)}
        {this.getParticipatingOrgsPanel(hit)}
        {this.getDatasetsPanel(hit)}
        {this.getPublicationPanel(hit)}
      </StyledCard>
    );
  }
}

export default ProjectResult;
