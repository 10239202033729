import React from 'react';
import moment from 'moment';
import { reaction } from 'mobx';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { getBounds, getCenter } from 'geolib';
import 'react-circular-progressbar/dist/styles.css';

import {
  Button,
  Grid,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { LatLngBounds } from 'leaflet';
import ReactMarkdown from 'react-markdown';
import { Map, Marker, TileLayer, Popup } from 'react-leaflet';

import {
  ABILITIES,
  ISecurityService,
} from '@extensions/services/ISecurityService';
import {
  IDs,
  INotificationService,
  Notification,
  Status,
} from '@extensions/services/INotificationService';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { IMetricsService } from '@extensions/services/IMetricsService';
import { IProjectService } from '@extensions/services/IProjectService';
import { IMembershipService } from '@extensions/services/IMembershipService';

import LatLong from '@extensions/models/LatLong';
import DatasetModel from '@extensions/models/Dataset';
import StatsBar from '@extensions/components/metrics/StatsBar';
import MarkdownLink from '@extensions/components/core/MarkdownLink';
import MarkdownImage from '@extensions/components/core/MarkdownImage';
import ContactsPanel from '@extensions/components/core/ContactsPanel';
import CitationButton from '@extensions/components/core/CitationButton';
import ReferencesPanel from '@extensions/components/core/ReferencesPanel';
import MustSignInAlert from '@extensions/components/core/MustSignInAlert';
import AwaitApprovalAlert from '@extensions/components/core/AwaitApprovalAlert';
import DistributionTable from '@extensions/components/dataset/DistributionTable';
import MustVerifyEmailAlert from '@extensions/components/core/MustVerifyEmailAlert';
import QualityMetricsPanel from '@extensions/components/dataset/QualityMetricsPanel';
import RequestAccessModal from '@extensions/components/membership/RequestAccessModal';
import DataRelevantEventsList from '@extensions/components/dataset/DataRelevantEventsList';
import DatasetMetricsNotesButton from '@extensions/components/core/DatasetMetricsNotesButton';
import QualityMetricsSummaryStat from '@extensions/components/dataset/QualityMetricsSummaryStat';

const StyledMapDiv = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

const StyledDistroTableDiv = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4),
}));

const StyledChip = styled(Chip)(({
  marginBottom: '7px !important',
  marginRight: '5px',
  color: '#000'
}));

const StyledAccordionDetails = styled(AccordionDetails)(({
  wordBreak: 'break-word'
}));

export interface IDatasetSummaryProps {
  datasetService: IDatasetService;
  projectService: IProjectService;
  securityService: ISecurityService;
  membershipService: IMembershipService;
  notificationService: INotificationService;
  metricsService: IMetricsService;
  showApiTab: () => void;
}

export interface IDatasetSummaryState {
  requestAccessModalVisible: boolean;
  citationLinkElement: HTMLElement | null;
  activeTab: string;
}

type GridProps = React.ComponentProps<typeof Grid>;

const leafletContainerStyle = css`
  .leaflet-container {
    height: 400px;
    width: 100%;
  }
`;

@observer
export class DatasetSummary<
  ExtraProps extends object = {}
> extends React.Component<
  IDatasetSummaryProps & ExtraProps,
  IDatasetSummaryState
> {
  static defaultProps = {
    datasetService: undefined,
    membershipService: undefined,
    notificationService: undefined,
    securityService: undefined,
    projectService: undefined,
    metricsService: undefined,
  };

  requestSentReaction: any;

  constructor(props) {
    super(props);

    this.state = {
      requestAccessModalVisible: false,
      citationLinkElement: null,
      activeTab: "",
    };
  }

  componentDidMount() {
    const { notificationService, datasetService, metricsService } = this.props;
    this.requestSentReaction = reaction(
      () => notificationService.getNotification(IDs.REQUEST_SENT),
      (requestStatus: Notification | null) => {
        if (requestStatus && requestStatus.status === Status.Success) {
          this.setState({ requestAccessModalVisible: false });
        }
      }
    );
    if (datasetService.dataset) {
      this.setActiveTab(datasetService.dataset);
      metricsService.getGAMetrics(datasetService.dataset);
    }
  }

  setActiveTab = (dataset: DatasetModel) => {
    if (dataset.viewData && dataset.viewData.isViewDataOn) {
      this.updateActiveTab("viewData");
    } else if (dataset.references && dataset.references.length > 0
      && dataset.references.some((ref) => ref.preview === true)) {
      this.updateActiveTab("previewFiles");
    } else if (dataset && dataset.realtimeData && dataset.realtimeData.viewDataPlot) {
      this.updateActiveTab("viewPlot");
    }
  }
  updateActiveTab = (incomingTab: string) => {
    this.setState({ activeTab: incomingTab })
  }

  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset loads
    this.requestSentReaction();
  }

  onTabsChange = (event: React.ChangeEvent<{}>, activeKey: string) => {
    this.setState({ activeTab: activeKey });
  };

  onCancel = (e) => {
    this.setState({
      requestAccessModalVisible: false,
    });
  };

  openRequestAccessModal = () => {
    this.setState({ requestAccessModalVisible: true });
  };

  closeRequestAccessModal = () => {
    this.setState({ requestAccessModalVisible: false });
  };

  requestAccess = (justification) => {
    const accessSvc = this.props.membershipService;
    const datasetSvc = this.props.datasetService;
    if (accessSvc && datasetSvc && datasetSvc.dataset) {
      accessSvc.requestDatasetAccess(datasetSvc.dataset.name, justification);
      this.closeRequestAccessModal();
    }
  };

  getPanel({
    ariaControls,
    id,
    title,
    body,
  }: {
    ariaControls: string;
    id: string;
    title: string;
    body: React.ReactNode;
  }): React.ReactNode {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={ariaControls}
          id={id}
        >
          <Typography variant="h3">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{body}</AccordionDetails>
      </Accordion>
    );
  }

  getMapExplanation(dataset: DatasetModel): React.ReactNode {
    return (
      <Typography variant="body1" gutterBottom sx={{ color: (theme) => theme.palette.grey[700] }}>
        This map shows the geographic locations covered within the dataset.
      </Typography>
    );
  }

  getUsageStats(dataset: DatasetModel): React.ReactNode | null {
    return <StatsBar item={dataset} />;
  }

  getPreviewSection = (dataset: DatasetModel): React.ReactNode | null => {
    return null;
  };

  getMapSection = (dataset: DatasetModel): React.ReactNode | null => {
    if (dataset.latLongs && dataset.latLongs.length > 0) {
      // noticed the mep dataset has duplicate latlongs and causes map to not render
      const uniqLatLongs = uniqWith(dataset.latLongs, isEqual);
      const markers = uniqLatLongs.map((latlong) => (
        <Marker
          key={`marker${latlong.latitude}${latlong.longitude}`}
          position={[+latlong.latitude, +latlong.longitude]}
        >
          <Popup>
            <p><code>Latitude: {latlong.latitude}</code></p>
            <p><code>Longitude: {latlong.longitude}</code></p>
          </Popup>
        </Marker>
      ));

      const center: LatLong = getCenter(dataset.latLongs) as unknown as LatLong;
      const mapCenter = { lat: +center.latitude, lng: +center.longitude };
      const bounds = getBounds(dataset.latLongs);
      const mapBounds = new LatLngBounds(
        { lat: +bounds.minLat, lng: +bounds.minLng },
        { lat: +bounds.maxLat, lng: +bounds.maxLng }
      );

      let map;
      if (markers.length > 1) {
        map = (
          <div className={leafletContainerStyle}>
            <Map bounds={mapBounds} gestureHandling={true}>
              <TileLayer
                attribution="&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markers}
            </Map>
          </div>
        );
      } else {
        map = (
          <div className={leafletContainerStyle}>
            <Map center={mapCenter} zoom={5} gestureHandling={true}>
              <TileLayer
                attribution="&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {markers}
            </Map>
          </div>
        );
      }
      return (
        <StyledMapDiv>
          {this.getMapExplanation(dataset)}
          {map}
        </StyledMapDiv>
      );
    }
    return null;
  };

  getKeywordsSection(dataset: DatasetModel) {
    let content;
    if (dataset.keywords && dataset.keywords.length > 0) {
      content = (
        <div>
          {dataset.keywords && (
            <div>
              {dataset.keywords.map((word) => (
                <StyledChip
                  key={word}
                  label={word}
                  variant="outlined"
                  size="small"
                />
              ))}
            </div>
          )}
        </div>
      );
    }
    return content;
  }

  getContactsPanel(dataset: DatasetModel) {
    const contacts = dataset.contacts;
    if (contacts) {
      return <ContactsPanel contacts={contacts} name={dataset.name} title={dataset.title} />;
    }
    return null;
  }

  getReferencesPanel(dataset: DatasetModel): JSX.Element | null {
    return <ReferencesPanel dataset={dataset} />
  }

  getQualityPanel(dataset: DatasetModel) {
    return <QualityMetricsPanel dataset={dataset} />;
  }

  getDatasetMetricsPanel(dataset: DatasetModel) {
    if (dataset && dataset.datasetMetrics) {
      return this.getPanel({
        ariaControls: 'dataset-metrics',
        id: 'dataset-metrics',
        title: `Dataset Metrics (${dataset.datasetMetrics.measures.length})`,
        body: (
          <TableContainer component={Paper}>
            <Table size="small" stickyHeader>
              <TableBody>
                {dataset.datasetMetrics.measures.map(measure => (
                  <TableRow key={measure.name}>
                    <TableCell sx={{ fontSize: '14px' }}>
                      {measure.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: '14px' }}>
                      {`${measure.value.toLocaleString('en-US')} ${measure.units}`} {measure.notes ? this.renderNotesButton(measure.notes) : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      })
    }
    return null;
  }

  renderNotesButton(notes: string) {
    if (notes) {
      return <DatasetMetricsNotesButton notes={notes} />
    }
  }

  getQualitySummaryGraphic(dataset: DatasetModel) {
    return (
      <QualityMetricsSummaryStat
        dataset={dataset}
      />
    );
  }

  getDescriptionPanel(dataset: DatasetModel) {
    // laravel end will look for the image in the dataset path first, then if not there will return the one from the project,
    // so always include the dataset in the path
    const convertImageUri = (imageUri) => {
      return `/api/content/${dataset.name}/${imageUri}`;
    };
    const convertDocumentUri = (uri) => {
      if (uri.startsWith('http') || uri.startsWith('mailto')) {
        return uri;
      } else {
        return `/api/content/${dataset.name}/${uri}`;
      }
    }
    return (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="description-content"
          id="description-header"
        >
          <Typography variant="h3">Description</Typography>
        </AccordionSummary>
        <StyledAccordionDetails>
        <span>
          <ReactMarkdown
            children={dataset.description}
            transformImageUri={convertImageUri}
            transformLinkUri={convertDocumentUri}
            linkTarget={(url) => ((url.startsWith('http') || url.startsWith('documents')) ? '_blank' : '')}
            components={{
              img: MarkdownImage,
              a: MarkdownLink,
              h1: ({...props}) => <Typography variant='h1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1.5em' }} {...props} />,
              h2: ({...props}) => <Typography variant='h2' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1.5em' }} {...props} />,
              h4: ({...props}) => <Typography variant='h4' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', fontSize: '1em' }} {...props} />,
              h5: ({...props}) => <Typography variant='h5' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px' }} {...props} />,
              p: ({...props}) => <Typography variant='body1' sx={{ fontWeight: 'normal', marginBlockStart: '0', marginBlockEnd: '12px', color: '#000', fontSize: '14px' }} {...props} />,
            }}
          />
        </span>
        </StyledAccordionDetails>
      </Accordion>
    );
  }

  renderDataFilesSection(dataset: DatasetModel) {
    const abilities: string[] | null = dataset.abilities;
    const { user } = this.props.securityService;

    let dataFilesSection: React.ReactNode = null;
    if (!user || !user.authenticated) {
      dataFilesSection = <MustSignInAlert actionDescription="download data" />;
    } else if (!user.emailVerified) {
      dataFilesSection = <MustVerifyEmailAlert />;
    } else if (user.approvalPending) {
      dataFilesSection = <AwaitApprovalAlert />;
    } else if (abilities) {
      if (
        abilities.includes(ABILITIES.DOWNLOAD_PENDING) &&
        !abilities.includes(ABILITIES.DOWNLOAD)
      ) {
        dataFilesSection = (
          <Typography>Your access request is pending approval.</Typography>
        );
      } else if (!abilities.includes(ABILITIES.DOWNLOAD)) {
        dataFilesSection = (
          <Button
            variant="contained"
            color="secondary"
            onClick={this.openRequestAccessModal}
          >
            Request Access
          </Button>
        );
      } else if (abilities.includes(ABILITIES.DOWNLOAD)) {
        dataFilesSection = (
          <DistributionTable showApiTab={this.props.showApiTab} />
        );
      }
    }

    return dataFilesSection;
  }

  getCitationButton(dataset: DatasetModel | null) {
    if (
      dataset &&
      dataset.citation &&
      this.props.securityService.userIsLoggedIn
    ) {
      return <CitationButton citation={dataset.citation} />;
    }
    return null;
  }

  getRelevantEventsPanel(dataset: DatasetModel | null) {
    if (dataset && dataset.events && dataset.events.length > 0) {
      return (
        <DataRelevantEventsList
          events={dataset.events}
        />
      );
    }
    return null;
  }

  getPublicationsPanel(dataset: DatasetModel | null) {
    if (dataset && dataset.publications.length) {
      return this.getPanel({
        ariaControls: 'publications-content',
        id: 'publications',
        title: `Publications (${dataset.publications.length})`,
        body: (
          <TableContainer component={Paper}>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Published</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataset.publications.map(pub => (
                  <TableRow key={pub.id}>
                    <TableCell>
                      <Link to={`/publication/${pub.id}`}>{pub.title}</Link>
                    </TableCell>
                    <TableCell>
                      <code>{moment(pub.publicationDate).format('YYYY-MMM')}</code>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      });
    }
    return null;
  }

  getTimelines(dataset: DatasetModel | null): React.ReactNode {
    return null;
  }

  getAdditionalPanels(dataset: DatasetModel | null): React.ReactNode {
    return null;
  }

  render() {
    const dataset: DatasetModel | null = this.props.datasetService.dataset;
    let content = <div />;
    if (dataset) {
      const hasQuality = dataset.quality ? true : false;
      const mapSection = this.getMapSection(dataset);
      const previewSection = this.getPreviewSection(dataset);
      const keywordsSection = this.getKeywordsSection(dataset);
      const dataFilesSection = this.renderDataFilesSection(dataset);
      const twoGridProps: GridProps = {
        xs: 12,
        sm: 6,
      };

      //leaving in logic to switch between 1 and 2 columns depending on if there is a map in case we need to switch back

      // const oneGridProps: GridProps = {
      //   xs: 12,
      //   sm: 12,
      // };
      // const gridProps: GridProps = mapSection ? twoGridProps : oneGridProps;
      const gridProps: GridProps = twoGridProps;
      let nonPanels;

      //if there is quality, need to put non-panels in a grid so as to have keywords and quality share a row and be responsive
      if (hasQuality) {
        nonPanels = (
          <Grid container>
            <Grid item xs={12}>
              {mapSection}
            </Grid>
            <Grid item xs={12}>
              {keywordsSection}
            </Grid>
            <Grid item xs={12}>
              {this.getUsageStats(dataset)}
            </Grid>
            <Grid item xs={12}>
              {this.getQualitySummaryGraphic(dataset)}
            </Grid>
          </Grid>
        );
      } else {
        nonPanels = (
          <Grid container>
            <Grid item xs={12}>
              {mapSection}
            </Grid>
            <Grid item xs={12}>
              {previewSection}
            </Grid>
            <Grid item xs={12}>
              {keywordsSection}
            </Grid>
            <Grid item xs={12}>
              {this.getUsageStats(dataset)}
            </Grid>
          </Grid>
        );
      }

      // if (mapSection) {
      // nonPanels = (
      //   <React.Fragment>
      //     {mapSection}
      //     {this.getUsageStats(dataset)}
      //     {keywordsSection}
      //   </React.Fragment>
      // );
      // } else {
      //   nonPanels = (
      //     <Grid container spacing={3} alignItems="flex-start">
      //       <Grid item xs={12} sm={6}>
      //         {keywordsSection}
      //       </Grid>
      //       <Grid item xs={12} sm={6}>
      //         {this.getUsageStats(dataset)}
      //       </Grid>
      //     </Grid>
      //   );
      // }

      content = (
        <div>
          <RequestAccessModal
            resourceName={dataset.name}
            resourceLabel={dataset.title}
            open={this.state.requestAccessModalVisible}
            handleClose={this.closeRequestAccessModal}
            request={this.requestAccess}
          />
          <Grid container spacing={3} alignItems="flex-start">
            <Grid item {...gridProps}>
              {this.getDescriptionPanel(dataset)}
              {this.getAdditionalPanels(dataset)}
              {this.getRelevantEventsPanel(dataset)}
              {this.getReferencesPanel(dataset)}
              {this.getPublicationsPanel(dataset)}
              {this.getContactsPanel(dataset)}
              {this.getQualityPanel(dataset)}
              {this.getDatasetMetricsPanel(dataset)}
              {this.getCitationButton(dataset)}
            </Grid>
            <Grid item {...gridProps}>
              {nonPanels}
            </Grid>
          </Grid>
          <StyledDistroTableDiv id="dataFiles">
            {dataFilesSection}
          </StyledDistroTableDiv>
          {this.getTimelines(dataset)}
        </div>
      );
    }
    return content;
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
  membershipService: store.membershipService,
  notificationService: store.notificationService,
  projectService: store.projectService,
  metricsService: store.metricsService,
}))(DatasetSummary);
