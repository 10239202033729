import React from 'react';

import { inject, observer } from 'mobx-react';
import { TextField, Autocomplete, Typography } from '@mui/material';

import { PubDataset, PubProject } from '@extensions/models/Publication';
import { IPublicationService } from '@extensions/services/IPublicationService';
import FormButtonsAndErrors from '@extensions/components/publications/FormButtonsAndErrors';

export interface IChooseDatasetsProps {
  className?: string;
  datasets: PubDataset[];
  projects: PubProject[] | string[];
  onPJChange: (newprojects: PubProject[]) => void;
  onDSChange: (newDatasets: PubDataset[]) => void;
  moveForward: () => void;
  moveBackward: () => void;
  clickCancelBtn: () => void;
  canMoveForward: boolean;
  canMoveBackward: boolean;
  publicationService?: IPublicationService;
}

export interface IChooseDatasetsState { }

@observer
export class ChooseDatasets extends React.Component<
  IChooseDatasetsProps,
  IChooseDatasetsState
> {

  componentDidMount() {
    const { publicationService } = this.props;
    if (publicationService) {
      publicationService.loadDatasetAndProjectOptions();
    }
  }

  findMatch(datasets, datasetOptions) {
    if (datasets && datasetOptions) {
      datasets.forEach(dataset => {
        datasetOptions.forEach(option => {
          if (option.name === dataset.name) {
            dataset['title'] = option.title
          }
        });
      });
    }
    return datasets;
  };

  findProjectMatch(projects, projectOptions) {
    if (projects && projectOptions) {
      projects.forEach(project => {
        projectOptions.forEach(option => {
          if (option.name === project.name) {
            project['name'] = option.name
          }
        });
      });
      return projects;
    } else {
      return [];
    };
  };

  render() {
    const {
      moveForward,
      clickCancelBtn,
      moveBackward,
      canMoveBackward,
      canMoveForward,
      publicationService,
      datasets,
      projects,
      onDSChange,
      onPJChange,
    } = this.props;
    return (
      <>
        <Typography variant='subtitle1'>
          Choose where you would like this document to be displayed.
        </Typography>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(event) => event.preventDefault()}
        >
          <Typography>
            The publication or reference document will be displayed on the project page(s) selected below.
          </Typography>
          <Autocomplete
            multiple
            loading={publicationService?.projectOptions === null}
            options={publicationService?.projectOptions ?? []}
            value={this.findProjectMatch(projects, publicationService?.projectOptions)}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name;
            }}
            getOptionLabel={(option) => {
              return option.name + ' | ' + option.title;
            }}
            onChange={(event, value) => {
              return onPJChange(value);
            }}
            renderInput={(params) => {
              return <TextField
                {...params}
                label="Projects"
                variant="outlined"
                margin="dense"
              />
            }}
          />
          <Typography>
            The publication or reference document will be displayed on the dataset page(s) selected below.
          </Typography>
          <Autocomplete
            multiple
            loading={publicationService?.datasetOptions === null}
            options={publicationService?.datasetOptions ?? []}
            groupBy={(option) => option.project_obj.title}
            value={this.findMatch(datasets, publicationService?.datasetOptions)}
            isOptionEqualToValue={(option, value) => {
              return option.name === value.name;
            }}
            getOptionLabel={(option) => {
              if (option.title) {
                return option.name + ' | ' + option.title;
              }
              return option.name;
            }}
            onChange={(event, value) => {
              return onDSChange(value);
            }}
            renderInput={(params) => {
              return <TextField
                {...params}
                label="Datasets"
                variant="outlined"
                margin="dense"
              />
            }}
          />
          <FormButtonsAndErrors
            moveForward={moveForward}
            moveBackward={moveBackward}
            clickCancelBtn={clickCancelBtn}
            canMoveBackward={canMoveBackward}
            canMoveForward={canMoveForward}
            error={null}
          />
        </form>
      </>
    );
  }
}

export default inject((store: any) => ({
  publicationService: store.publicationService,
}))(ChooseDatasets);
