import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

import { IProjectService } from '@extensions/services/IProjectService';
import { INotificationService } from '@extensions/services/INotificationService';

import Dataset from '@extensions/models/Dataset';

export interface IDatasetListProps {
  projectService: IProjectService;
  notificationService: INotificationService;
}

export interface IDatasetListState { }

@inject('projectService', 'membershipService', 'notificationService')
@observer
class DatasetList extends React.Component<
  IDatasetListProps,
  IDatasetListState
> {
  static defaultProps = {
    projectService: undefined,
    notificationService: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.projectService.project) {
      this.props.projectService.getProjectDatasetsIfNeeded();
    }
  }

  public render() {
    const { projectService } = this.props;
    let content = <div />;
    const { project, datasets } = projectService;
    if (datasets !== null && project !== null) {
      content = (
        <TableContainer component={Paper}>
          <Table aria-label="data files table">
            <TableHead>
              <TableRow>
                <TableCell>Dataset</TableCell>
                <TableCell>Dataset Access</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datasets
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((dataset: Dataset) => (
                  <TableRow
                    key={dataset.name}
                    sx={
                      dataset.restriction === project.restriction
                        ? { backgroundColor: 'lightgrey' }
                        : {}
                    }
                  >
                    <TableCell>
                      <Link to={`/ds/${dataset.name}/manage`} style={{textDecoration: 'none'}}>
                        <code>{dataset.name}</code>
                      </Link>
                      <div>
                        <em>{dataset.title}</em>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {dataset.permissionLabel}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return content;
  }
}

export default DatasetList;
