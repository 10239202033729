import React from 'react';
import { styled } from '@mui/material/styles';
import { DataSearch } from '@appbaseio/reactivesearch';
import { DataSearchProps } from '@appbaseio/reactivesearch/lib/components/search/DataSearch';
import theme from '@extensions/services/Theme';

export const REACTIVE_ID = 'SearchBar';

const StyledDataSearch = styled(DataSearch)(({
  '& .search-icon': {
    fill: theme.palette.secondary.main,
  },
  '& .search-input': {
    '&': {
      fontSize: 'inherit',
      fontFamily: theme.openSansFontFamily,
    },
    width: '100%',
    height: '42px',
    backgroundColor: theme.palette.background.paper,
    fontSize: '0.9rem',
    padding: '8px 32px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgb(204, 204, 204)',
    borderImage: 'initial',
    outline: 'none',
  },
  '& .input-group': {
    boxShadow: theme.shadows[8],
  },
  '& .search-list': {
    '&': {
      fontSize: 'inherit',
    },
  },
  marginTop: '15px',
  paddingBottom: '20px',
})) as unknown as typeof DataSearch;

interface ExtraDataSearchProps extends DataSearchProps {
}
class SearchBar extends React.Component<
  Omit<ExtraDataSearchProps, 'componentId' | 'innerClass'>,
  { value: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  render = () => {
    const { className, ...props } = this.props;
    return (
      <StyledDataSearch
        componentId={REACTIVE_ID}
        filterLabel="Search"
        onKeyDown={(event: React.KeyboardEvent, triggerQuery: () => void) => {
          if (event.key === 'Enter') {
            triggerQuery();
          }
        }}
        value={this.state.value}
        onChange={(value) => this.setState({ value })}
        customQuery={props.customQuery || ((value, props) => {
          const escaped = value
            .replace('/', '\\/')
            .replace(/[^0-9a-zA-Z._\\/]/g, ' ')
            .toLowerCase()
          if (!value) {
            return {};
          }
          return {
            query: {
              query_string: {
                query: escaped,
                fields: props.dataField,
                default_operator: props.queryFormat,
              },
            },
          };
        })}
        {...props}
      />
    );
  };
}

export default SearchBar;
