import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Button, Menu, MenuItem } from '@mui/material';

import { INotificationService } from '@extensions/services/INotificationService';

import AddIcon from '@extensions/utils/AddIcon';
import FileOrder from '@extensions/models/FileOrder';
import FileOrderGroup from '@extensions/models/FileOrderGroup';
import { faTerminal, faFileArchive } from '@fortawesome/free-solid-svg-icons';
import theme from '@extensions/services/Theme';

const StyledGetAppIcon = styled(GetAppIcon)(({
  ...theme.linkIcon
}));

const StyledButton = styled(Button)(({
  marginLeft: '-10px',
  marginRight: '5px',
}));

const StyledZipAddIcon = styled(AddIcon)(({
  marginRight: '12px',
}));
const StyledScriptAddIcon = styled(AddIcon)(({
  marginRight: '5px',
}));

export interface IDownloadButtonProps {
  className?: string;
  row: FileOrder;
  notificationService: INotificationService;
}

export interface IDownloadButtonState {
  anchorEl: HTMLElement | null;
}
@inject('notificationService')
@observer
class DownloadButton extends React.Component<
  IDownloadButtonProps,
  IDownloadButtonState
> {
  static defaultProps = {
    notificationService: undefined,
  };
  constructor(props: IDownloadButtonProps) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  downloadZippedFile = (): void => {
    const newOrders: FileOrder[] = [this.props.row];
    const fileOrderGroup = new FileOrderGroup(
      newOrders,
      this.props.notificationService
    );
    fileOrderGroup.downloadZip();
    this.handleClose();
  };

  startScriptDownloading = (selectedType: string) => {
    this.props.row.downloadScriptFiles(selectedType);
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const tooBig: boolean =
      this.props.row.totalSize > 1073741824 * 3 ? true : false;
    return (
      <span>
        <StyledButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <StyledGetAppIcon color="primary" />
        </StyledButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem disabled={tooBig} onClick={this.downloadZippedFile}>
            <StyledZipAddIcon icon={faFileArchive} />
            Zip
          </MenuItem>
          <MenuItem
            onClick={() => this.startScriptDownloading('windowsScript')}
          >
            <StyledScriptAddIcon icon={faTerminal} />
            Script Windows
          </MenuItem>
          <MenuItem onClick={() => this.startScriptDownloading('linuxScript')}>
            <StyledScriptAddIcon icon={faTerminal} />
            Script Linux
          </MenuItem>
          <MenuItem onClick={() => this.startScriptDownloading('linuxScript')}>
            <StyledScriptAddIcon icon={faTerminal} />
            Script MacOS
          </MenuItem>
        </Menu>
      </span>
    );
  }
}

export default DownloadButton;
