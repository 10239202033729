import get from 'lodash/get';
import allConfigs from '@extensions/utils/conf.json';

export type ApiConfig = {
  inventoryTable: string;
  reportTable: string;
  lambdaApi: string;
  dapClientHost: string;
  sentryDsn: string;
};

export class ConfigUtil {
  config: ApiConfig;

  constructor() {
    const hostname = window.location.hostname;
    let configData = null;

    // Find the correct data for the current deployment
    Object.keys(allConfigs).forEach(key => {
      const dapClientHost = allConfigs[key].dapClientHost;
      if (dapClientHost === hostname) {
        configData = allConfigs[key];
      }
    });

    this.config = {
      inventoryTable: get(configData, 'inventoryTable', ''),
      reportTable: get(configData, 'reportTable', ''),
      lambdaApi: get(configData, 'lambdaApi', ''),
      dapClientHost: get(configData, 'dapClientHost', ''),
      sentryDsn: get(configData, 'sentryDsn', ''),
    };
  }

  getConfig(): ApiConfig {
    return this.config;
  }
}

export default new ConfigUtil();
