import { styled } from '@mui/material/styles';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import theme from '@extensions/services/Theme';

import AddIcon from '@extensions/utils/AddIcon';
import Link from '@extensions/components/core/Link';

interface IQualityWarningProps {
  datasetName?: string
}

const StyledAddIcon = styled(AddIcon)(({
  marginLeft: '2.5px',
  marginRight: '2.5px',
  color: theme.palette.warning.main,
}));

const QualityWarning = ({ datasetName }: IQualityWarningProps) => {

  if (datasetName) {
    return (
      <Link to={`/ds/${datasetName}#event`}>
        <StyledAddIcon
          icon={faExclamationTriangle}
          title={"View issue(s) known to affect data quality"}
        />
      </Link>
    );
  }

  return (
    <StyledAddIcon
      icon={faExclamationTriangle}
      title={"Data includes known issue(s) affecting data quality"}
    />
  );
};

export default QualityWarning;
