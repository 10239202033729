import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { IPublicationService } from '@extensions/services/IPublicationService';

import PublicationSummary from '../publications/PublicationSummary';
import PublicationNotFound from '@extensions/components/publications/PublicationNotFound';

const StyledRootDiv = styled('div')(({
  '&& h2': {
    marginBottom: '0.5rem',
  },
}))

export interface IPublicationDetailsProps extends RouteComponentProps<{ id: string }> {
  className?: string;
  publicationService?: IPublicationService;
}

export interface IPublicationDetailsState { }

@observer
export class PublicationDetails extends React.Component<
  IPublicationDetailsProps,
  IPublicationDetailsState
> {

  componentDidMount() {
    const { publicationService } = this.props;
    if (publicationService) {
      const id = this.getId();
      publicationService.selectPublication(id);
    }
  }

  getId = (): number => {
    const { match } = this.props;
    return Number(match.params.id);
  };

  render() {
    const { publicationService } = this.props;
    let body: React.ReactNode;
    if (publicationService?.selectedPublicationNotFound) {
      body = <PublicationNotFound />;
    } else if (publicationService?.selectedPublication) {
      const selected = publicationService?.selectedPublication;
      body = <PublicationSummary publication={selected} editMode={false} />;
    }
    return <StyledRootDiv>{body}</StyledRootDiv>;
  }
}

export default inject((store: any) => ({
  publicationService: store.publicationService,
}))(PublicationDetails);
