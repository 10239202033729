import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  observable,
  action,
  runInAction,
  when,
  computed,
  makeObservable,
} from 'mobx';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Alert,
  Button,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IMembershipService } from '@extensions/services/IMembershipService';

import Dataset from '@extensions/models/Dataset';
import Project from '@extensions/models/Project';
import Link from '@dapclient/components/core/Link';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import { typeToLabel } from '@extensions/models/DistributionType';
import DatasetGuard from '@extensions/components/core/DatasetGuard';
import QualityWarning from '@extensions/components/dataset/QualityWarning';
import RequestAccessModal from '@extensions/components/membership/RequestAccessModal';
import CenteredCircularProgress from '@dapclient/components/core/CenteredCircularProgress';

// App specific imports
const NOTIFICATION_ID = 'PROJECT_DATASETS_TABLE';

export interface IProjectDatasetsProps
  extends RouteComponentProps {
  project: Project;
  securityService?: ISecurityService;
  notificationService?: INotificationService;
  membershipService?: IMembershipService;
  tableSize?: 'small' | 'medium';
}

const StyledCode = styled('code')(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: theme.spacing(1.75),
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontSize: theme.spacing(1.75),
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50]
}));

@observer
export class ProjectDatasets extends React.Component<IProjectDatasetsProps> {
  @observable
  datasets: Array<{
    dataset: Dataset;
    distributionTypes: string[];
  }> | null = null;
  @observable
  showRequestAccessModal = false;
  constructor(props: IProjectDatasetsProps) {
    super(props);
    makeObservable(this);
  }

  @computed
  get requiresAuth() {
    return !process.env.REACT_APP_SHOW_PUBLIC_DS;
  }

  componentDidMount() {
    if (this.requiresAuth) {
      when(() => this.props.securityService!.userIsLoggedIn, this.fetchData);
    } else {
      this.fetchData();
    }
  }

  fetchData = async () => {
    const { project, notificationService } = this.props;
    try {
      const datasetSearchResponse = await DapApiAgent.agent
        .post('/api/datasets/_search')
        .send({
          sort: ['dapSortOrder'],
          query: {
            prefix: { 'name.keyword': `${project.identifier}/` },
          },
          size: 500,
        });
      runInAction(() => {
        this.datasets = datasetSearchResponse.body.hits.hits.map((hit) => {
          const dataset = new Dataset({
            meta: hit._source,
            project_name: project.name,
            name: hit._source.name,
          });
          if (
            hit._source.dapFileSummary &&
            hit._source.dapFileSummary.count > 0
          ) {
            dataset.setDynamoFileCount(hit._source.dapFileSummary.count);
            dataset.setDynamoTotalFileSize(hit._source.dapFileSummary.size);
            dataset.setDynamoLastModified(hit._source.dapFileSummary.updated);
            dataset.setDynamoDataBegins(hit._source.dapFileSummary.begins);
            dataset.setDynamoDataEnds(hit._source.dapFileSummary.ends);
            dataset.setDynamoFullExtensions(hit._source.dapFileSummary.extensions);
          }
          return {
            dataset,
            distributionTypes: hit._source.distributionType,
          };
        });
      });
    } catch (error: any) {
      notificationService!.addNotification(
        NOTIFICATION_ID,
        Status.Error,
        `Could not fetch datasets for project ${project.name}`,
        error.message
      );
    }
  };

  renderDatasetTable = () => {
    const tableSize = this.props.tableSize ? this.props.tableSize : 'medium';
    if (this.datasets && this.datasets.length > 0) {
      return (
        <TableContainer component={Paper} sx={{ maxHeight: '500px' }}>
          <Table size={tableSize} stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell>
                  Dataset <small>({this.datasets.length})</small>
                </StyledTableHeadCell>
                <StyledTableHeadCell align='right'>Data Access Method</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.datasets
                .slice()
                .sort((a, b) => a.dataset.name.localeCompare(b.dataset.name))
                .map(({ dataset, distributionTypes }) => {
                  const datasetUrl = `/ds/${dataset.name}`;
                  return (
                    <TableRow
                      key={dataset.name}
                    >
                      <TableCell>
                        {this.renderDatasetTitle(datasetUrl, dataset)}
                      </TableCell>
                      <StyledTableCell align='right'>
                        {distributionTypes.map(t => typeToLabel(t)).join(', ')}
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else return null;
  };

  renderDatasetTitle = (datasetUrl: string, dataset: Dataset) => {
    return (
      <>
        <StyledCode>{dataset.name}</StyledCode>
        {Boolean(dataset && dataset.events && dataset.events.some(e => e.affectsDataQuality)) && (
          <QualityWarning datasetName={dataset.name} />
        )}
        <br />
        <Link to={datasetUrl} style={{ fontSize: '14px' }}>
          {dataset.title}
        </Link>
      </>
    )
  }

  @action
  openRequestAccessModal = () => {
    this.showRequestAccessModal = true;
  };

  @action
  closeRequestAccessModal = () => {
    this.showRequestAccessModal = false;
  };

  requestAccess = (justification: string) => {
    const svc = this.props.membershipService;
    if (svc) {
      svc.requestProjectAccess(this.props.project.name, justification);
      this.closeRequestAccessModal();
    }
  };

  render() {
    const { project, securityService } = this.props;
    let requiresAuth = this.requiresAuth;
    let content: React.ReactNode = (
      <RequestAccessModal
        resourceName={this.props.project.name}
        resourceLabel={this.props.project.title}
        open={this.showRequestAccessModal}
        handleClose={this.closeRequestAccessModal}
        request={this.requestAccess}
      />
    );
    if (this.datasets === null) {
      content = <CenteredCircularProgress color="secondary" />;
    } else if (this.datasets && this.datasets.length > 0) {
      content = this.renderDatasetTable();
    } else if (this.datasets && this.datasets.length === 0) {
      const hasRequested =
        securityService !== undefined &&
        securityService['user'] !== null &&
        securityService['user'].resourceNamesRequested.indexOf(project.name) >= 0;
      requiresAuth = true;
      content = (
        <>
          {content}
          {project.datasetCount > 0 && (
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Button
                  disabled={hasRequested}
                  variant="contained"
                  color="secondary"
                  onClick={this.openRequestAccessModal}
                >
                  Request Access
                </Button>
              </Grid>
              {hasRequested && (
                <Grid item>
                  <small>
                    <em>Your access request is pending approval.</em>
                  </small>
                </Grid>
              )}
            </Grid>
          )}
          {this.props.project.datasetCount === 0 && (
            <Alert severity="info">No datasets found</Alert>
          )}
        </>
      );
    }

    if (requiresAuth) {
      return <DatasetGuard>{content}</DatasetGuard>;
    }
    return <>{content}</>;
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
  notificationService: store.notificationService,
  membershipService: store.membershipService,
}))(withRouter(ProjectDatasets));
