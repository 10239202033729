import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, computed, makeObservable, observable, reaction } from 'mobx';

import cloneDeep from 'lodash/cloneDeep';
import { styled } from '@mui/material/styles';

import { IHistoryService } from '@extensions/services/IHistoryService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IPublicationService } from '@extensions/services/IPublicationService';

import ChooseMethod, {
  PublicationFormMethod,
} from '@extensions/components/publications/ChooseMethod';
import SignInLink from '@extensions/components/core/SignInLink';
import EnterDoi from '@extensions/components/publications/EnterDoi';
import FormDetails from '@extensions/components/publications/FormDetails';
import ChooseDatasets from '@extensions/components/publications/ChooseDatasets';
import Publication, { PubDataset, PubProject } from '@extensions/models/Publication';
import ReviewSubmission from '@extensions/components/publications/ReviewSubmission';

const StyledSignInLink = styled(SignInLink)(({
  verticalAlign: 'baseline',
}));

export interface IPublicationFormProps {
  className?: string;
  /**
   * Starting state for the form. Only used when the form first mounts.
   * Subsequent changes to this prop will do nothing.
   */
  publication?: Publication;
  securityService?: ISecurityService;
  publicationService?: IPublicationService;
  historyService?: IHistoryService;
  isEdit: boolean;
}

export interface IPublicationFormState { }

enum PublicationFormStep {
  CHOOSE_METHOD = 'choose_method',
  ENTER_DOI = 'enter_doi',
  DETAILS = 'details',
  DATASETS = 'datasets',
  REVIEW = 'review',
}

@observer
export class PublicationForm extends React.Component<
  IPublicationFormProps,
  IPublicationFormState
> {
  editSteps: PublicationFormStep[] = [
    PublicationFormStep.DETAILS,
    PublicationFormStep.DATASETS,
    PublicationFormStep.REVIEW,
  ];
  manualSteps: PublicationFormStep[] = [
    PublicationFormStep.CHOOSE_METHOD,
    PublicationFormStep.DETAILS,
    PublicationFormStep.DATASETS,
    PublicationFormStep.REVIEW,
  ];
  ostiLookupSteps: PublicationFormStep[] = [
    PublicationFormStep.CHOOSE_METHOD,
    PublicationFormStep.ENTER_DOI,
    PublicationFormStep.DETAILS,
    PublicationFormStep.DATASETS,
    PublicationFormStep.REVIEW,
  ];
  stepsInOrder: PublicationFormStep[] = this.ostiLookupSteps;
  stepIndex: number = 0;
  publication: Publication | null = null;
  method: PublicationFormMethod = PublicationFormMethod.OSTI_LOOKUP;
  doi: string = '';
  get currentStep() {
    return this.stepsInOrder[this.stepIndex];
  }

  constructor(props) {
    super(props);
    if (props.publication) {
      // Used as a starting point
      this.publication = cloneDeep(props.publication);
      this.stepsInOrder = this.editSteps;
    }
    makeObservable(this, {
      currentStep: computed,
      doi: observable,
      stepsInOrder: observable,
      stepIndex: observable,
      publication: observable,
      method: observable,
      moveForward: action,
      moveBackward: action,
      clickCancelBtn: action,
      moveTo: action,
      setMethod: action,
      setPublication: action,
      setDoi: action,
      updateStepsInOrder: action,
    });
  }

  componentDidMount() {
    reaction(() => this.method, this.updateStepsInOrder);
  }

  updateStepsInOrder = (method) => {
    switch (method) {
      case PublicationFormMethod.MANUAL:
        this.stepsInOrder = this.manualSteps;
        break;
      case PublicationFormMethod.OSTI_LOOKUP:
        this.stepsInOrder = this.ostiLookupSteps;
        break;
    }
  };

  setDoi = (newDoi: string): void => {
    this.doi = newDoi;
  };

  updateProjects = (newProjects: PubProject[]): void => {
    if (this.publication) {
      this.publication.projects = newProjects.map((project) => {
        return project;
      });
    }
  };

  updateDatasets = (newDatasets: PubDataset[]): void => {
    if (this.publication) {
      this.publication.datasets = newDatasets.map((dataset) => {
        return dataset;
      });
    }
  };

  setMethod = (newMethod: PublicationFormMethod): void => {
    this.method = newMethod;
  };

  moveTo = (step: PublicationFormStep): void => {
    this.stepIndex = this.stepsInOrder.findIndex((item) => item === step);
  };

  canMoveBackward = (): boolean => {
    return this.stepIndex > 0;
  };

  canMoveForward = (): boolean => {
    return this.stepIndex < this.stepsInOrder.length - 1;
  };

  moveBackward = (): void => {
    if (this.canMoveBackward()) {
      this.stepIndex--;
    }
  };

  moveForward = (): void => {
    if (this.canMoveForward()) {
      this.stepIndex++;
    }
  };

  clickCancelBtn = (): void => {
    this.props.historyService?.redirect('/publications');
  };

  setPublication = (newPublication: Publication): void => {
    this.publication = newPublication;
  };

  renderStep = (): React.ReactNode => {
    const stepToNode: Record<PublicationFormStep, React.ReactNode> = {
      [PublicationFormStep.CHOOSE_METHOD]: (
        <ChooseMethod
          method={this.method}
          onChange={this.setMethod}
          moveForward={this.moveForward}
          moveBackward={this.moveBackward}
          clickCancelBtn={this.clickCancelBtn}
          canMoveBackward={this.canMoveBackward()}
          canMoveForward={this.canMoveForward()}
        />
      ),
      [PublicationFormStep.ENTER_DOI]: (
        <EnterDoi
          doi={this.doi}
          onChange={this.setDoi}
          setPublication={this.setPublication}
          moveForward={this.moveForward}
          moveBackward={this.moveBackward}
          clickCancelBtn={this.clickCancelBtn}
          canMoveBackward={this.canMoveBackward()}
          canMoveForward={this.canMoveForward()}
        />
      ),
      [PublicationFormStep.DETAILS]: (
        <FormDetails
          method={this.method}
          publication={this.publication}
          setPublication={this.setPublication}
          moveForward={this.moveForward}
          moveBackward={this.moveBackward}
          clickCancelBtn={this.clickCancelBtn}
          canMoveBackward={this.canMoveBackward()}
          canMoveForward={this.canMoveForward()}
        />
      ),
      [PublicationFormStep.DATASETS]: (
        <ChooseDatasets
          projects={this.publication?.projects ?? []}
          datasets={this.publication?.datasets ?? []}
          onPJChange={this.updateProjects}
          onDSChange={this.updateDatasets}
          moveForward={this.moveForward}
          moveBackward={this.moveBackward}
          clickCancelBtn={this.clickCancelBtn}
          canMoveBackward={this.canMoveBackward()}
          canMoveForward={this.canMoveForward()}
        />
      ),
      [PublicationFormStep.REVIEW]: (
        <ReviewSubmission
          isEdit={this.props.isEdit}
          publication={this.publication as Publication}
          moveBackward={this.moveBackward}
          clickCancelBtn={this.clickCancelBtn}
          canMoveBackward={this.canMoveBackward()}
        />
      ),
    };
    return stepToNode[this.currentStep];
  };

  render() {
    const { securityService } = this.props;

    if (!securityService || !securityService.userIsLoggedIn) {
      return (
        <>
          <StyledSignInLink>Sign in</StyledSignInLink> to submit or edit a publication.
        </>
      );
    }
    return (
      <div>{this.renderStep()}</div>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
  publicationService: store.publicationService,
  historyService: store.historyService,
}))(PublicationForm);
