import React from 'react';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

const StyledGridRoot = styled(Grid)(() => ({
  justifyContent: "between",
  display: 'flex',
  maxWidth: theme.contentWidth.max,
  minWidth: theme.contentWidth.min,
  margin: '45px auto',
  padding: '20px 50px 0px',
  '& .MuiGrid-item': {
    padding: '40px',
  }
}));

const StyledColumnHeading = styled(Typography)(() => ({
  textTransform: 'uppercase',
  fontSize: '1.125rem',
  fontWeight: theme.typography.fontWeightBold,
  marginBottom: theme.spacing(1),
  width: '275px',
}));

const StyledColumnBody = styled('div')(() => ({
  '& > p': {
    width: '275px',
    marginBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, .65)',
  },
}))

export interface IHomeProps {
  className?: string;
}

function HomePageColumn({
  title,
  body,
}: {
  title: string;
  body: React.ReactNode;
}) {
  return (
    <Grid item xs={12} md={4}>
      <StyledColumnHeading variant="h2">
        {title}
      </StyledColumnHeading>
      <StyledColumnBody>{body}</StyledColumnBody>
    </Grid>
  );
}

class Home extends React.Component<IHomeProps> {
  render() {
    return (
      <StyledGridRoot container spacing={5}>
        <HomePageColumn
          title="Problem"
          body={
            <>
              <Typography>
                The wealth of data available from today's building systems can
                provide the operational insights and solutions that can optimize
                the operation of buildings. Ideally, such data would be securely
                collected at little cost with high temporal and spatial
                fidelity--and include all attributes relevant to building
                performance and occupant comfort.
              </Typography>
              <Typography>
                However, it's impractical to obtain high-resolution, detailed
                measurements for all buildings. As such, there is a need to
                collect, curate, and make publicly available high-resolution
                data from a small number of buildings that have broad
                applicability to a variety of high-impact use cases.
              </Typography>
            </>
          }
        />
        <HomePageColumn
          title="BENCHMARK DATASETS PURPOSE"
          body={
            <>
              <Typography>
                This project is a three-year, four-laboratory collaboration to
                collect and curate a handful of high-resolution building systems
                datasets that have broad applicability to address highest-impact
                use cases.
              </Typography>
              <Typography>
                We will collect and curate high-resolution, well-calibrated time
                series of building operational and indoor/outdoor environmental
                data, which are crucial to understanding and optimizing building
                energy efficiency performance and demand flexibility
                capabilities as well as benchmarking energy algorithms.
              </Typography>
            </>
          }
        />
        <HomePageColumn
          title="METHODS"
          body={
            <>
              <Typography>
                Contributors to the inventory need to include data information
                such as descriptions, collection methodology, target use cases
                for which the collection was performed, availability for public
                use, quality issues (e.g. data gaps, noise, miscalibration,
                incorrect timestamping, inconsistencies, etc.), and other
                relevant programmatic aspects.
              </Typography>
            </>
          }
        />
      </StyledGridRoot>
    );
  }
}

export default Home;
