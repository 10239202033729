// Choices: public (Data asset is or could be made publicly available to all without restrictions),
// restricted public (Data asset is available under certain use restrictions),
// or non-public (Data asset is not available to members of the public).
enum AccessLevel {
  Public = 'public',
  RestrictedPublic = 'restricted public',
  NonPublic = 'non-public',
  Invalid = 'invalid',
}
export default AccessLevel;
