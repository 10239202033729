import React from 'react';
import { inject, observer } from 'mobx-react';

import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';
import { IContactUsService } from '@extensions/services/IContactUsService';

const StyledRootDiv = styled('div')(() => ({
  color: theme.palette.common.white,
  fontSize: '14px',
  padding: '5px 20px',
  textAlign: 'center',
  flex: '0 0 auto',
  backgroundColor: theme.palette.primary.main,
}));

const StyledLinksDiv = styled('div')(() => ({
  fontWeight: 'bold',
  '& a': {
    color: theme.palette.common.white,
    textDecoration: 'none'
  },
  '& a:hover': {
    cursor: 'pointer',
    color: theme.palette.common.white,
  },
}));

export interface IFooterProps {
  contactUsService?: IContactUsService;
}

@observer
export class Footer extends React.Component<IFooterProps> {
  getTopText() {
    return "Livewire is a resource of the U.S. Department of Energy's Vehicle Technologies Office.";
  }

  getLinks(): Array<React.AnchorHTMLAttributes<{}>> {
    return [
      {
        children: 'Contact Us',
        onClick: () =>
          this.props.contactUsService &&
          this.props.contactUsService.openModal(),
      },
      {
        children: 'Vehicle Technologies Office',
        href: 'https://www.energy.gov/eere/vehicles/vehicle-technologies-office',
      },
      {
        children: <>Office of Energy Efficiency &amp; Renewable Energy</>,
        href: 'https://www.energy.gov/eere/office-energy-efficiency-renewable-energy',
      },
      {
        children: 'Web Policies',
        href: 'https://www.energy.gov/about-us/web-policies',
      },
      {
        children: 'Privacy',
        href: 'https://www.energy.gov/about-us/web-policies/privacy',
      },
    ];
  }
  public render() {
    const links = this.getLinks();
    return (
      <StyledRootDiv>
        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '46px' }}>
          <Grid item sx={{ lineHeight: 1.6 }}>
            <span>{this.getTopText()}</span>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item sx={{ p: '10px', lineHeight: 1.6 }}>
            <StyledLinksDiv>
              {links.map((link, index) => (
                <span key={`${link.href}${new Date().getMilliseconds()}`}>
                  {index > 0 && (
                    <>
                      {'  '}|{'  '}
                    </>
                  )}
                  {/*eslint-disable-next-line jsx-a11y/anchor-has-content*/}
                  <a {...link} />
                </span>
              ))}
            </StyledLinksDiv>
          </Grid>
        </Grid>
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  contactUsService: store.contactUsService,
}))(Footer);
