import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';

import { PubAuthor } from '@extensions/models/Publication';

const StyledCard = styled(Card)(({
  marginBottom: '0.25rem',
}));

const StyledCardHeader = styled(CardHeader)(({
  paddingBottom: '0px',
}));

const StyledDiv = styled('div')(({
  marginTop: '1rem'
}));

export interface IAuthorsListProps {
  className?: string;
  authors: PubAuthor[];
}

export interface IAuthorsListState { }

@observer
export class AuthorsList extends React.Component<
  IAuthorsListProps,
  IAuthorsListState
> {
  render() {
    const { authors } = this.props;
    let body: React.ReactNode;
    if (authors.length === 0) {
      body = <Typography>No Authors Provided</Typography>;
    } else {
      body = authors.map((author) => (
        <StyledCard
          key={`${author.firstName}-${author.lastName}-${author.affiliation}`}
        >
          <StyledCardHeader
            title={`${author.firstName} ${author.lastName}`}
            titleTypographyProps={{ variant: 'h3' }}
          />
          <CardContent>{author.affiliation?.name}</CardContent>
        </StyledCard>
      ));
    }
    return (
      <StyledDiv>
        <Typography variant="h2">Authors</Typography>
        {body}
      </StyledDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(AuthorsList);
