import React from 'react';
import { inject, observer } from 'mobx-react';
import { action, observable, makeObservable } from 'mobx';
import {
    Card,
    Paper,
    Table,
    Typography,
    TableRow,
    CardMedia,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TablePagination,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { styled } from '@mui/material/styles';
import DatasetModel from '@extensions/models/Dataset';
import { downloadDataTable } from '@extensions/services/DatasetService';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledCardRoot = styled(Card)(()=> ({
    width: '100%',
    height: '100%',
}));

const StyledCircularProgress = styled('div')(() => ({
    display: 'flex',
    marginTop: '25%',
}));

const StyledTypography = styled(Typography)(() => ({
    marginTop: '1rem',
    marginBottom: '1rem',
}));

const StyledTopTypography = styled(Typography)(() => ({
    display: 'flex',
    justifyContent: 'center'
}));

const StyledCarouselCoreWrapper = styled('div')(() => ({
    marginBottom: '20px',
}));

const StyledCarousel = styled(Carousel)(() => ({
    height: '100%',
}));

const StyledCardMedia = styled(CardMedia)(() => ({
    height: '100%',
    width: '100%',
    backgroundSize: 'contain',
}));


export interface IViewDataPaneProps {
    className?: string;
    dataset: DatasetModel
}

export interface IViewDataPaneState { }

export interface DataPoints {
    dataPoints: Array<{
        messageDate: string,
        plotLabels: string[],
        plotValues: number[],
    }>;
}

@observer
export class ViewDataPane extends React.Component<
    IViewDataPaneProps,
    IViewDataPaneState
    > {

    @observable
    isLoading: boolean = true;
    @observable
    dataPoints: any[] = [];
    @observable
    rowsPerPage: number = 5;
    @observable
    pageNum: number = 0;

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        const { dataset } = this.props;
        if (dataset && dataset.realtimeData && dataset.realtimeData.defaultQuery && dataset.realtimeData.viewDataTable) {
            const defaultQuery = dataset.realtimeData.defaultQuery;
            const sensorID = defaultQuery.filter.sensorID;
            await downloadDataTable(defaultQuery)
                ?.then((response) => {
                    if (response.body) {
                        this.setFetchedDataPoints(response.body, sensorID)
                    }
                })
                .catch((err: any) => {
                    console.log('err=>', err);
                })
                .finally(() => this.setIsLoading(false))
        }
    }

    @action
    setFetchedDataPoints = (dataPointsCache: any, sensorID?: number) => {
        let reshapedData: { sensorID: number | undefined, dataPointsCache: DataPoints[] } = {
            sensorID: sensorID,
            dataPointsCache: dataPointsCache
        }
        this.setDataPoints(reshapedData);
    }

    @action
    setDataPoints = (reshapedData: { sensorID: number | undefined, dataPointsCache: DataPoints[] }) => {
        this.dataPoints = [...this.dataPoints, reshapedData];
    }

    @action
    setIsLoading = (loading: boolean) => {
        this.isLoading = loading;
    }

    @action
    setPageNum = (newPage: number) => {
        this.pageNum = newPage;
    }

    @action
    setRowsPerPage = (newRowsPerPage: number) => {
        this.rowsPerPage = newRowsPerPage;
    }

    carouselGen = () => {
        if (!this.dataPoints) {
            return null;
        }

        if (this.dataPoints && this.dataPoints.length < 1 && !this.isLoading) {
            return (
                <StyledTypography variant="body1">
                    No available data table to view.
                </StyledTypography>
            )
        }

        if (!this.isLoading && this.dataPoints && this.dataPoints.length > 0) {
            return (
                <StyledCarouselCoreWrapper>
                    <StyledCarousel
                        indicators={false}
                        navButtonsAlwaysVisible={true}
                        navButtonsProps={{
                            style: {
                                backgroundColor: "rgba(0, 0, 0, 0.15)"
                            }
                        }}
                        autoPlay={false}
                        cycleNavigation={false}
                        fullHeightHover={false}
                    >
                        {this.cardGen(this.dataPoints)}
                    </StyledCarousel>
                </StyledCarouselCoreWrapper>
            )
        }
    }

    cardGen = (dataPoints: any[]) => {
        return dataPoints.map((dataPoint) => {
            if (dataPoint) {
                return (
                    <div key={dataPoint.sensorID}>
                        <StyledCardRoot key={dataPoint.sensorID}>
                            <StyledCardMedia>
                                {this.renderTable(dataPoint)}
                            </StyledCardMedia>
                        </StyledCardRoot>
                    </div>
                );
            }
            return null;
        });
    };

    renderTable = (dataPoints: any[]) => {
        const numOfRows = dataPoints['dataPointsCache'].length;
        const emptyRows = this.rowsPerPage - Math.min(this.rowsPerPage, numOfRows - this.pageNum * this.rowsPerPage);
        return (
            <>
                <TableContainer component={Paper} >
                    <StyledTopTypography variant="body1">
                        {`Sensor ID: ${dataPoints['sensorID']}`}
                    </StyledTopTypography>
                    <Table aria-label="data points table" size="small">
                        <TableHead>
                            <TableRow key="messageDate">
                                <TableCell>Date</TableCell>
                                <TableCell key={'Humidity'}>Humidity</TableCell>
                                <TableCell key={'WetBulb_Fahrenheit'}>WetBulb_Fahrenheit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataPoints['dataPointsCache'] &&
                                dataPoints['dataPointsCache']
                                    .slice(this.pageNum * this.rowsPerPage, this.pageNum * this.rowsPerPage + this.rowsPerPage)
                                    .map((d, i) => {
                                        return (
                                            <TableRow key={d.messageDate}>
                                                <TableCell component="th" scope="row">
                                                    {d.messageDate}
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="right">
                                                    {d.plotValues[0]}
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="right">
                                                    {d.plotValues[d.plotValues.length - 1]}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 73 * emptyRows }}>
                                    <TableCell colSpan={6}></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[5]}
                    count={numOfRows}
                    rowsPerPage={this.rowsPerPage}
                    page={this.pageNum}
                    onPageChange={(event, pageNum) => this.setPageNum(pageNum)}
                    onRowsPerPageChange={(event) => this.setRowsPerPage(parseInt(event.target.value, 10))}
                />
            </>
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.isLoading && (
                    <StyledCircularProgress>
                        <CenteredCircularProgress />
                    </StyledCircularProgress>
                )}
                {this.carouselGen()}
            </React.Fragment>
        );
    }
}

export default inject((store: any) => ({}))(ViewDataPane);