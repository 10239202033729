import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  ListItemText,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IProjectService } from '@extensions/services/IProjectService';
import { IMembershipService } from '@extensions/services/IMembershipService';
import { INotificationService } from '@extensions/services/INotificationService';

import Dataset from '@extensions/models/Dataset';
import AccessRequest from '@extensions/models/AccessRequest';
import AccessRestriction from '@extensions/models/AccessRestriction';

export interface IAccessRequestListProps {
  projectService: IProjectService;
  membershipService: IMembershipService;
  notificationService: INotificationService;
  dataset?: Dataset;
}

export interface IAccessRequestListState {
  showDialog: boolean;
  approvedRequest: AccessRequest | undefined;
  deniedRequest: AccessRequest | undefined;
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    right: -7,
    top: 4,
  },
});

@inject('projectService', 'membershipService', 'notificationService')
@observer
class AccessRequestList extends React.Component<
  IAccessRequestListProps,
  IAccessRequestListState
> {
  static defaultProps = {
    projectService: undefined,
    membershipService: undefined,
    notificationService: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      approvedRequest: undefined,
      deniedRequest: undefined,
    };
  }

  componentDidMount() {
    if (this.props.dataset) {
      this.props.membershipService.getDatasetAccessRequestsIfNeeded(
        this.props.dataset
      );
    } else if (this.props.projectService.project) {
      this.props.membershipService.getProjectAccessRequestsIfNeeded(
        this.props.projectService.project.identifier
      );
    }
  }

  approveAccessRequest = (request: AccessRequest) => {
    if (this.props.projectService.project) {
      const project = this.props.projectService.project;
      this.props.membershipService.approveAccessRequest(
        request,
        project,
        this.props.dataset
      );
    }

    this.setState({
      showDialog: true,
      approvedRequest: request,
    });
  };

  denyAccessRequest = (request: AccessRequest) => {
    if (this.props.projectService.project) {
      this.props.membershipService.deleteAccessRequest(
        request,
        this.props.projectService.project.identifier,
        this.props.dataset
      );
    }

    this.setState({
      showDialog: true,
      deniedRequest: request,
    });
  };

  public render() {
    const { membershipService, dataset } = this.props;
    const { showDialog, approvedRequest, deniedRequest } = this.state;
    let responseDialogText;
    const request = approvedRequest || deniedRequest;
    if (request) {
      const name = request.user.fullName;
      const email = request.user.email;
      const resource =
        request.datasetName || this.props.projectService.project?.identifier;
      if (resource) {
        responseDialogText = (
          <>
            Please inform{' '}
            <strong>
              {name} ({email})
            </strong>{' '}
            of this {approvedRequest ? 'approved' : 'denied'} access to{' '}
            <em>{resource}</em>.
          </>
        );
      }
    }

    const requests: AccessRequest[] | null = membershipService.accessRequests;
    let content = <div />;

    if (requests !== null) {
      content = (
        <React.Fragment>
          {responseDialogText && (
            <Dialog open={showDialog}>
              <DialogContent>{responseDialogText}</DialogContent>
              <DialogActions>
                <Button
                  onClick={() =>
                    this.setState({
                      showDialog: false,
                      approvedRequest: undefined,
                      deniedRequest: undefined,
                    })
                  }
                  color="primary"
                  autoFocus
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          )}

          <Accordion key="requests">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="requests-content"
              id="requests-header"
            >
              <StyledBadge 
                badgeContent={requests.length} 
                color="primary" 
                showZero 
              >
                <Typography>Pending Access Requests</Typography>
              </StyledBadge>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <List
                    dense
                    disablePadding
                    aria-label="requests"
                    sx={{ width: '100%' }}
                  >
                    {requests.map((request: AccessRequest) => {
                      let datasetLink;
                      // don't display a link to the dataset if list is on dataset's manage page
                      if (dataset === undefined && request.datasetName) {
                        datasetLink = (
                          <Grid item xs={12}>
                            Requested Dataset:{' '}
                            <Link to={`/ds/${request.datasetName}/manage`}>
                              {request.datasetName}
                            </Link>
                          </Grid>
                        );
                      }

                      // don't display approve/deny controls if list is on prjoect's manage page and dataset is under dataset's access controls
                      let enableControls = !(
                        dataset === undefined &&
                        request.datasetRestriction ===
                        AccessRestriction.restrictions.dataset
                      );
                      return (
                        <ListItem key={request.user.email}>
                          {enableControls && (
                            <Tooltip title="Deny access request">
                              <ListItemIcon>
                                <IconButton
                                  aria-label="Deny"
                                  onClick={(e) =>
                                    this.denyAccessRequest(request)
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              </ListItemIcon>
                            </Tooltip>
                          )}
                          {enableControls && (
                            <Tooltip title="Approve access request">
                              <ListItemIcon>
                                <IconButton
                                  aria-label="Approve"
                                  onClick={(e) =>
                                    this.approveAccessRequest(request)
                                  }
                                >
                                  <CheckIcon />
                                </IconButton>
                              </ListItemIcon>
                            </Tooltip>
                          )}
                          <ListItemText
                            disableTypography={true}
                            primary={request.user.email}
                            secondary={
                              <StyledGrid
                                container
                                alignItems="flex-start"
                              >
                                <Grid item xs={6}>
                                  {request.user.fullName}
                                </Grid>
                                <Grid item xs={6}>
                                  Requested:{' '}
                                  {moment(request.requested).calendar()}
                                </Grid>
                                <Grid item xs={12}>
                                  Justification: {request.justification}
                                </Grid>
                                {datasetLink}
                              </StyledGrid>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      );
    }
    return content;
  }
}

export default AccessRequestList;
