import React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import theme from '@extensions/services/Theme';

import {
  Tab,
  Tabs,
  Alert,
  Button,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  IDs,
  INotificationService,
  Notification,
  Status,
} from '@extensions/services/INotificationService';
import { IProjectService } from '@extensions/services/IProjectService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IMembershipService } from '@extensions/services/IMembershipService';

import ProjectModel from '@extensions/models/Project';
import GroupModal from '@extensions/components/project/GroupModal';
import GroupList from '@extensions/components/membership/GroupList';
import ManagePermissions from '@extensions/components/project/ManagePermissions';

export interface IProjectProps {
  projectService: IProjectService;
  securityService: ISecurityService;
  notificationService: INotificationService;
  membershipService: IMembershipService;
}

export interface IProjectState {
  searchText: string;
  activeTab: number;
  createGroupModalVisible: boolean;
}

const StyledGroupPanelGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));

const StyledAlert = styled(Alert)(({
  ...theme.MuiAlert.outlinedInfo,
}));

const StyledTab = styled(Tab)(({theme}) => ({
  '&.MuiTab-root': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    color: '#000',
  }
}));

@inject(
  'membershipService',
  'projectService',
  'securityService',
  'notificationService'
)
@observer
class ProjectManagement extends React.Component<
  IProjectProps,
  IProjectState
> {
  static defaultProps = {
    notificationService: undefined,
    securityService: undefined,
    projectService: undefined,
    membershipService: undefined,
  };

  groupCreatedReaction: any;

  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      activeTab: 0,
      createGroupModalVisible: false,
    };
  }

  openModal = () => {
    this.setState({ createGroupModalVisible: true });
  };

  closeModal = () => {
    this.setState({ createGroupModalVisible: false });
  };

  search = (event) => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  componentDidMount() {
    const { projectService } = this.props;
    if (projectService.project) {
      projectService.getProjectGroupsIfNeeded(
        projectService.project.getIdentifier()
      );
    }

    this.groupCreatedReaction = reaction(
      () => this.props.notificationService.getNotification(IDs.GROUP_CREATED),
      (membersAddedNotification: Notification | null) => {
        if (
          membersAddedNotification &&
          membersAddedNotification.status === Status.Success
        ) {
          this.closeModal();
        }
      }
    );
  }
  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset's data order modal loads
    this.groupCreatedReaction();
  }

  render() {
    const { projectService } = this.props;
    const project: ProjectModel | null = projectService.project;
    let content = <div />;
    if (project) {
      const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ activeTab: newValue });
      };

      content = (
        <React.Fragment>
          {this.state.createGroupModalVisible && (
            <GroupModal
              onCancel={this.closeModal}
              users={this.props.projectService.users}
              mode="create"
              group={undefined}
            />
          )}
          <Tabs
            value={this.state.activeTab}
            indicatorColor="primary"
            textColor="primary"
            aria-label="project tabs"
            onChange={handleChange}
          >
            <StyledTab label="Permissions" />
            <StyledTab label="Groups" />
          </Tabs>

          {this.state.activeTab === 0 && (
            <ManagePermissions project={project} />
          )}
          {this.state.activeTab === 1 && (
            <StyledGroupPanelGrid container>
              <Grid item sm={3} xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.openModal}
                >
                  Create Group
                </Button>
              </Grid>
              <Grid item sm={9} xs={12}>
                <StyledAlert severity="info">
                  Groups need to be assigned to a role in the project or dataset
                  permissions tab above.
                </StyledAlert>
              </Grid>
              <Grid item xs={12}>
                <GroupList />
              </Grid>
            </StyledGroupPanelGrid>
          )}
        </React.Fragment>
      );
    }
    return <div>{content}</div>;
  }
}

export default ProjectManagement;
