import React from 'react';
import { inject, observer } from 'mobx-react';

import 'typeface-oswald';
import {
  Route,
  RouteComponentProps,
  Switch,
  Redirect,
  withRouter,
} from 'react-router-dom';
import { Location } from 'history';
import { detect } from 'detect-browser';
import * as Sentry from '@sentry/react';
import { styled } from '@mui/material/styles';
import { css, injectGlobal } from '@emotion/css';
import { Button, Alert, AlertTitle, Grid } from '@mui/material';

import theme from '@extensions/services/Theme';
import {
  INotificationService,
  Status,
} from '@dapclient/services/INotificationService';
import { IAppService } from '@extensions/services/IAppService';
import ILayoutService from '@dapclient/services/ILayoutService';
import { ISecurityService } from '@dapclient/services/ISecurityService';

import FAQ from '@dapclient/components/page/FAQ';
import Home from '@extensions/components/page/Home';
import About from '@extensions/components/page/About';
import Footer from '@extensions/components/nav/Footer';
import Header from '@extensions/components/nav/Header';
import SignIn from '@extensions/components/page/SignIn';
import Upload from '@extensions/components/page/Upload';
import Metrics from '@extensions/components/page/Metrics';
import Dataset from '@extensions/components/page/Dataset';
import Project from '@extensions/components/page/Project';
import Profile from '@extensions/components/page/Profile';
import Register from '@extensions/components/page/Register';
import NotFound from '@extensions/components/page/NotFound';
import Uploaders from '@extensions/components/page/Uploaders';
import Agreement from '@extensions/components/page/Agreement';
import ScrollToTop from '@dapclient/components/core/ScrollToTop';
import Acknowledge from '@extensions/components/page/Acknowledge';
import RequiresAuth from '@extensions/components/core/RequiresAuth';
import DatasetSearch from '@extensions/components/page/DatasetSearch';
import ProjectSearch from '@extensions/components/page/ProjectSearch';
import NewPublication from '@extensions/components/page/NewPublication';
import EditPublication from '@extensions/components/page/EditPublication';
import ContentFallback from '@extensions/components/core/ContentFallback';
import FeedbackTool from '@extensions/components/core/feedback/FeedbackTool';
import NewsletterList from '@extensions/components/newsletter/NewsletterList';
import PublicationDetails from '@extensions/components/page/PublicationDetails';
import PublicationsSearch from '@extensions/components/page/PublicationsSearch';
import NotificationManager from '@dapclient/components/core/NotificationManager';
import NewsletterResult from '@extensions/components/newsletter/NewsletterResult';

const browser = detect();

const StyledDiv = styled('div')(({
  flex: '1 0 auto',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
}));

const ContentWrapper = styled(Grid)(({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
}));


export interface IAppProps extends RouteComponentProps {
  className?: string;
  appService?: IAppService;
  securityService?: ISecurityService;
  notificationService?: INotificationService;
  layoutService?: ILayoutService;
}

export interface IAppState { }

injectGlobal`
.grecaptcha-badge {
  visibility: hidden;
}
`;
@observer
export class App extends React.Component<IAppProps, IAppState> {
  constructor(props) {
    super(props);
    this.getLayoutContentStyle = this.getLayoutContentStyle.bind(this);
    this.getContentWrapperStyle = this.getContentWrapperStyle.bind(this);
    this.renderRoutes = this.renderRoutes.bind(this);
    this.getLayoutStyle = this.getLayoutStyle.bind(this);
  }

  async componentDidMount() {
    if (browser && browser.name === 'ie' && this.props.notificationService) {
      this.props.notificationService.addNotification(
        'browserNotSupported',
        Status.Warn,
        'Browser not supported',
        <span>
          Livewire Data Platform supports the following browsers for the best
          experience: <a href="https://www.google.com/chrome">Chrome</a>,{' '}
          <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">
            Edge
          </a>
          , or <a href="https://www.mozilla.org/en-US/firefox/">Firefox</a>. We
          recommend downloading the newest version of the above browsers for the
          best experience.
        </span>
      );
    }
  }

  getContentWrapperStyle(location: Location): string {
    return css`
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
    `;
  }

  getLayoutContentStyle(location: Location): Object {
    const isDatasetDetailRoute = location.pathname.startsWith('/ds/');
    return {
      width: '100%',
      maxWidth: theme.contentWidth.max,
      minWidth: theme.contentWidth.min,
      margin: 'auto',
      padding: isDatasetDetailRoute ? '30px 50px 0 50px' : '20px 50px 0 50px',
      flex: '1 0 auto',
    }
  }

  getLayoutStyle(location: Location): Object {
    var backgroundColor = this.props.location.pathname === '/'
      ? 'rgba(84, 95, 102, 1)'
      : theme.palette.background.default;
    return {
      '& a:focus': {
        outline: 'rgba(0, 103, 244, 0.247) auto 0.3125rem'
      },
      '& button:focus': {
        outline: 'rgba(0, 103, 244, 0.247) auto 0.3125rem'
      },
      '& input:focus': {
        outline: 'rgba(0, 103, 244, 0.247) auto 0.3125rem'
      },
      '& textarea:focus': {
        outline: 'rgba(0, 103, 244, 0.247) auto 0.3125rem'
      },
      '& select:focus': {
        outline: 'rgba(0, 103, 244, 0.247) auto 0.3125rem'
      },
      backgroundColor: backgroundColor + ' !important',
    }
  }

  getAdditionalRoutes(): JSX.Element[] {
    return [];
  }

  renderRoutes() {
    return (
      <Switch>
        <Redirect from="/search" to="/dataset-search" />
        <Route exact path="/" component={Home} />
        <Route exact path="/signIn" component={SignIn} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/password/reset" component={Register} />
        <Route path="/profile" component={Profile} />
        {/* server will respond with redirect to /account/approved when user clicks approve link in emails */}
        <Route path="/account/approved" component={Profile} />
        <Route path="/email/verified" component={Profile} />
        <Route exact path="/dataset-search" component={DatasetSearch} />
        <Route exact path="/project-search" component={ProjectSearch} />
        <Route exact path="/metrics" component={Metrics} />
        <Route path="/ds" component={Dataset} />
        <Route path="/project" component={Project} />
        <Route exact path="/about" component={About} />
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/agree" component={Agreement} />
        <Route exact path="/acknowledge" component={Acknowledge} />
        <Route exact path="/publication/new" component={NewPublication} />,
        <Route
          exact
          path="/publication/edit/:id(\d+)"
          component={EditPublication}
        />
        <Route
          exact
          path="/publication/:id(\d+)"
          component={PublicationDetails}
        />
        <Route path="/publications" component={PublicationsSearch} />
        <Route exact path="/news/:id(\d+)" component={NewsletterResult} />
        <Route path="/news" component={NewsletterList} />
        {this.props.appService !== undefined &&
          this.props.appService.uploadClientEnabled && (
            <>
              <Route
                path="/upload"
                render={() => (
                  <RequiresAuth>
                    <Upload />
                  </RequiresAuth>
                )}
              />
              <Route
                path="/uploaders/:project"
                render={(props) => (
                  <RequiresAuth>
                    <Uploaders {...props} />
                  </RequiresAuth>
                )}
              />
            </>
          )}
        {this.getAdditionalRoutes()}
        <Route component={NotFound} />
      </Switch>
    );
  }

  render() {
    const { location, securityService } = this.props;

    const email = securityService?.user?.email || '';
    const emailNeedsReverified =
      securityService?.user?.mfaRequiresEmailVerification || false;

    return (
      <>
        <ScrollToTop />
        <NotificationManager>
          <Grid
            sx={this.getLayoutStyle(location)}
          >
            <StyledDiv>
              <Header />
              <ContentWrapper
                sx={this.getLayoutContentStyle(location)}
              >
                <Sentry.ErrorBoundary
                  fallback={({ resetError }) => (
                    <ContentFallback resetError={resetError} />
                  )}
                >
                  {emailNeedsReverified && (
                    <Alert severity="warning" sx={{ marginBottom: '1rem' }}>
                      <AlertTitle>
                        <strong>Action Required</strong>
                      </AlertTitle>
                      Due to your having access to MFA-restricted data, it is
                      required that your email address be periodically
                      re-verified.
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        sx={{ marginTop: '1rem' }}
                        onClick={() =>
                          securityService?.resendEmailAction(email)
                        }
                      >
                        Send Verification Email to Renew Access
                      </Button>
                    </Alert>
                  )}
                  {this.props.securityService?.autoLoginDone &&
                    this.renderRoutes()}
                </Sentry.ErrorBoundary>
              </ContentWrapper>
              <Footer />
            </StyledDiv>
            {Boolean(process.env.REACT_APP_FEEDBACK_TOOL_ENABLED) && <FeedbackTool />}
          </Grid>
        </NotificationManager>
      </>
    );
  }
}

export default inject((store: any) => ({
  appService: store.appService,
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(withRouter(App));

