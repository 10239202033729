import React from 'react';
import {
  UseFieldArrayProps,
  FieldArrayPath,
  UseFieldArrayReturn,
  useFieldArray,
} from 'react-hook-form';

export interface ReactHookFieldArrayProps<
  TFieldValues extends Record<string, any>,
  TFieldArrayName extends FieldArrayPath<TFieldValues>,
  TKeyName extends string
> {
  fieldArrayProps: UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName>;
  children: (
    args: UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>
  ) => React.ReactElement | null;
}

export default function ReactHookFieldArray<
  TFieldValues extends Record<string, any>,
  TFieldArrayName extends FieldArrayPath<TFieldValues>,
  TKeyName extends string
>({
  fieldArrayProps,
  children,
}: ReactHookFieldArrayProps<
  TFieldValues,
  TFieldArrayName,
  TKeyName
>): React.ReactElement | null {
  const fieldArrayBag = useFieldArray(fieldArrayProps);
  return children(fieldArrayBag);
}
