export enum RoleType {
  LEAD = 'lead',
  EDITOR = 'editor',
  MEMBER = 'member',
}

export const ROLE_OPTIONS: Record<RoleType, { description: string }> = {
  [RoleType.LEAD]: {
    description: 'Full access, assign permissions',
  },
  [RoleType.EDITOR]: {
    description: 'Upload data',
  },
  [RoleType.MEMBER]: {
    description: 'Access data/metadata',
  },
};

export default class Role {
  label: string;
  level: string; // will be project or dataset, describes at what level is this role set

  constructor(label: string, level: string) {
    this.label = label;
    this.level = level;
  }
}
