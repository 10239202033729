import React from 'react';
import { inject, observer } from 'mobx-react';
import {
    Controller,
    UseFormReturn,
    ControllerRenderProps,
} from 'react-hook-form';
import { Typography } from '@mui/material';

import FileUpload from '@extensions/components/core/FileUpload';
import { PublicationFormData } from '@extensions/models/PublicationFormData';

export interface IPdfFieldProps {
    className?: string;
    control: UseFormReturn<PublicationFormData>['control'];
}

export interface IPdfFieldState { }

@observer
export class RefFileUploadField extends React.Component<
    IPdfFieldProps,
    IPdfFieldState
> {
    djsConfig = {
        maxFiles: 1,
        chunking: true,
        paramName: 'report',
    };
    uploadConfig = {
        postUrl: '/api/refs/file',
    };
    handleSuccess = ({
        response,
        field,
    }: {
        response: { url: string; name: string };
        field: ControllerRenderProps<PublicationFormData, 'uploadedFileName'>;
    }): void => {
        field.onChange(response.name);
    };
    render() {
        const { control } = this.props;

        return (
            <div>
                <Typography variant="h2" component="h2">
                    Reference Document
                </Typography>
                <Controller
                    control={control}
                    name="url"
                    render={({ field: urlField }) => (
                        <Controller
                            control={control}
                            name="uploadedFileName"
                            render={({ field: nameField }) => (
                                <FileUpload
                                    config={this.uploadConfig}
                                    djsConfig={this.djsConfig}
                                    eventHandlers={{
                                        success: (files) => {
                                            nameField.onChange(JSON.parse(files.xhr.responseText).name);
                                            urlField.onChange(JSON.parse(files.xhr.responseText).url);
                                        },
                                    }}
                                />
                            )}
                        />
                    )}
                />
            </div>
        );
    }
}

export default inject((store: any) => ({
    // EXAMPLE => metricsService: store.metricsService
}))(RefFileUploadField);
