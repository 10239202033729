import { Typography } from "@mui/material";

import Link from "@extensions/components/core/Link";
import Configure from "@extensions/components/data/uploaders/Configure";
import { IStepProps, Step } from "@extensions/components/data/uploaders/Step";

const StepConfigure = ({ ...stepProps }: IStepProps) => {
  return (
    <Step
      {...stepProps}
      title="Configure Datasets"
      body={
        <>
          <Typography sx={{ color: 'rgba(0, 0, 0, 0.65)' }} variant='body1'>
            Configure each dataset to which you will upload files.&nbsp;
            <em>
              If you can't find one or more you expect to see,&nbsp;
              <Link to="/metadata" target="_blank">submit metadata</Link> for the missing dataset(s) and then refresh this page.
            </em>
          </Typography>
          <Configure />
        </>
      }
    />
  )
};

export default StepConfigure;
