import {
  INotificationService,
  Status,
} from '@dapclient/services/INotificationService';
import FaqSection, { QuestionAndAnswer } from '@dapclient/models/FaqSection';
import forEach from 'lodash/forEach';
import { action, observable, transaction, makeObservable } from 'mobx';
import * as superagent from 'superagent';

import { IFaqService } from '@extensions/services/IFaqService';

export default class FaqService implements IFaqService {
  @observable sections: FaqSection[] = [];
  notificationService: INotificationService;

  constructor(notificationService: INotificationService) {
    makeObservable(this);
    this.notificationService = notificationService;
  }

  @action async loadFaqsIfNeeded() {
    if (this.sections.length === 0) {
      this.loadFaqs();
    }
  }

  private filenameToId = (filename: string): string => {
    filename = filename.replace(/\.[^/.]+$/, '');
    const id = encodeURI(filename);
    return id;
  };

  async loadQAndAContents(qAndA): Promise<QuestionAndAnswer> {
    const mdfilePath = `${process.env.PUBLIC_URL}/faq-content/${qAndA.filename}`;
    const mdFileResponse = await superagent.get(mdfilePath);
    // request won't fail if the file isn't found, it will instead return an html not found
    // doc so have to test response type to make sure it worked
    //if (mdFileResponse.type !== 'text/markdown') {
    //  this.notificationService.addNotification(
    //    'loadFaqs',
    //    Status.Error,
    //    'Failed to load faq file',
    //    `Markdown file ${mdfilePath} not found`
    //  );
    //  return undefined;
    //} else {
    return {
      question: qAndA.label,
      answerMarkdown: mdFileResponse.text,
      id: this.filenameToId(qAndA.filename),
    };
    //}
  }

  @action async loadFaqs() {
    this.notificationService.addNotification(
      'loadFaqs',
      Status.Running,
      '',
      ''
    );

    try {
      const faqPath = `${process.env.PUBLIC_URL}/faq-content/faq-manifest.json`;
      const response = await superagent.get(faqPath);
      transaction(() => {
        this.notificationService.addNotification(
          'loadFaqs',
          Status.Success,
          '',
          ''
        );

        const faqs = JSON.parse(response.text);
        Object.keys(faqs).forEach((key) => {
          const questionAndAnswers: QuestionAndAnswer[] = [];
          const questions = faqs[key].questions;
          const promises: any[] = [];
          // then fetch each question and answer markdown file and add it to the section:
          forEach(questions, async (qAndA) => {
            promises.push(this.loadQAndAContents(qAndA));
          });

          // using the Promise.all method to ensure we don't add the faq section until all of the questions and answers are loaded
          Promise.all(promises)
            .then((results) => {
              results.forEach((qAndA) => {
                if (qAndA) {
                  questionAndAnswers.push(qAndA);
                }
              });

              const section = new FaqSection(
                faqs[key].label,
                questionAndAnswers
              );
              this.addSection(section);
            })
            .catch((e) => {
              this.notificationService.addNotification(
                'loadFaqs',
                Status.Error,
                'Failed to load faqs',
                e
              );
            });
        });
      });
    } catch (error) {
      this.notificationService.addNotification(
        'loadFaqs',
        Status.Error,
        'Failed to load faqs',
        error
      );
    }
  }

  @action addSection(faqSection: FaqSection) {
    this.sections.push(faqSection);
  }
}
