import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IContactUsService } from '@extensions/services/IContactUsService';

const StyledHeadingTypography = styled(Typography)(({
  marginBottom: '1rem',
}));

const StyledTypography = styled(Typography)(({
  marginBottom: '1rem',
}));

export interface ISomethingWentWrongProps extends RouteComponentProps {
  className?: string;
  contactUsService?: IContactUsService;
  resetError: () => void;
}

@inject('contactUsService')
@observer
class SomethingWentWrong extends React.Component<ISomethingWentWrongProps> {
  componentDidMount = () => {
    const { history, resetError } = this.props;
    history.listen(() => {
      // Try resetting after user navigates
      resetError();
    });
  };
  render() {
    return (
      <div>
        <StyledHeadingTypography variant="h1">
          Whoops, something went wrong!
        </StyledHeadingTypography>
        <StyledTypography>
          Our team is working to address the issue. If you'd like to help,{' '}
          <a href={`mailto:${process.env.REACT_APP_TEAM_EMAIL}`}>
            send us an email
          </a>{' '}
          explaining what happened.
        </StyledTypography>
      </div>
    );
  }
}

export default withRouter(SomethingWentWrong);
