import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { LinkProps } from '@mui/material';

import Link from '@dapclient/components/core/Link';

export interface ISignInLinkProps extends LinkProps, RouteComponentProps {
  styleObject?: any;
}
class SignInLink extends Component<ISignInLinkProps> {
  componentDidMount() {
    window.addEventListener('popstate', this.handlePopState);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  handlePopState = () => {
    const { pathname, search, hash } = window.location;
    this.props.history.replace({
      state: { pathname, search, hash },
    });
  };

  handleClick = () => {
    const { pathname, search, hash } = window.location;
    this.props.history.push({
      pathname: '/signIn',
      state: { pathname, search, hash },
    });
  };

  render() {
    const { children, styleObject } = this.props;
    return (
      <Link
        onClick={this.handleClick}
        sx={
          (styleObject && Object.keys(styleObject).length !== 0)
            ? styleObject
            : {}
        }
      >
        {children}
      </Link>
    );
  }
}

export default withRouter(SignInLink);
