import { inject } from 'mobx-react';
import { Typography } from '@mui/material';
import {
    FiltersWrapper,
    FilterSectionHeader,
    MultiList,
} from '@extensions/components/search-core';
import { DatasetFilters as DapDatasetFilters } from "@dapclient/components/dataset-search/DatasetFilters";
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';
import NumericRange from '@extensions/components/search-core/NumericRange';
import { SEARCH_BAR_REACTIVE_ID } from '@extensions/components/search-core';

const PARTICIPATING_ORGS_REACTIVE_ID = 'PARTICIPATING_ORGS';
const KEYWORDS_REACTIVE_ID = 'keywords-filter';
const PROJECT_REACTIVE_ID = 'project-filter';
const DATA_ACCESS_REACTIVE_ID = 'dataAccess-filter';
const FLOOR_AREA_REACTIVE_ID = 'floor-area-filter';
const YEAR_BUILT_REACTIVE_ID = 'year-built-filter';
const FUEL_TYPE_REACTIVE_ID = 'fuel-type-filter';
const STATE_REACTIVE_ID = 'state-filter';
const CLIMATE_ZONE_REACTIVE_ID = 'climate-zone-filter';
const BUILDING_TYPE_REACTIVE_ID = 'building-type-filter';
const HVAC_SYSTEM_REACTIVE_ID = 'hvac-system-type-filter';
const DATA_CATEGORY_REACTIVE_ID = 'data-category-filter';

export const REACTIVE_IDS = [
    PARTICIPATING_ORGS_REACTIVE_ID,
    KEYWORDS_REACTIVE_ID,
    PROJECT_REACTIVE_ID,
    DATA_ACCESS_REACTIVE_ID,
    FLOOR_AREA_REACTIVE_ID,
    YEAR_BUILT_REACTIVE_ID,
    FUEL_TYPE_REACTIVE_ID,
    STATE_REACTIVE_ID,
    CLIMATE_ZONE_REACTIVE_ID,
    BUILDING_TYPE_REACTIVE_ID,
    HVAC_SYSTEM_REACTIVE_ID,
    DATA_CATEGORY_REACTIVE_ID
];

const allReactiveIdsExcept = getAllReactiveIdsExcept([
    ...REACTIVE_IDS,
    SEARCH_BAR_REACTIVE_ID,
]);

export class DatasetFilters extends DapDatasetFilters {
    render() {
        const { projectContext } = this.props;
        return (
            <FiltersWrapper>
                <Typography
                    variant="h6"
                    component="h2"
                    color="textPrimary"
                    sx={{
                        borderBottom: `1px solid #bdbdbd`,
                        marginBottom: '24px',
                    }}
                >
                    FILTERS
                </Typography>
                <div style={Boolean(projectContext) ? { display: 'none' } : {}}>
                    <FilterSectionHeader 
                        variant="h5" 
                    >
                        Project Criteria
                    </FilterSectionHeader>
                    <MultiList
                        componentId={PARTICIPATING_ORGS_REACTIVE_ID}
                        title="Participating Organizations"
                        dataField="project.participatingOrganizations.name.keyword"
                        react={{
                            and: allReactiveIdsExcept(PARTICIPATING_ORGS_REACTIVE_ID),
                        }}
                        maxLabelWidth="none"
                        showBottomBorder
                        URLParams={!Boolean(projectContext)}
                        filterLabel="Participating Organizations"
                        showFilter={!Boolean(projectContext)}
                    />
                    <MultiList
                        componentId={PROJECT_REACTIVE_ID}
                        title="Project"
                        dataField="project_name.keyword"
                        react={{
                            and: allReactiveIdsExcept(PROJECT_REACTIVE_ID),
                        }}
                        maxLabelWidth="none"
                        showBottomBorder
                        URLParams={!Boolean(projectContext)}
                        filterLabel="Project"
                        showFilter={!Boolean(projectContext)}
                        defaultValue={projectContext ? [projectContext] : undefined}
                    />
                </div>
                <div style={{marginTop: '20px'}}>
                <FilterSectionHeader 
                    variant="h5" 
                >
                        Dataset Criteria
                </FilterSectionHeader>
                </div>
                <MultiList
                    componentId={DATA_ACCESS_REACTIVE_ID}
                    title="Data Access Method"
                    dataField="distributionType.keyword"
                    react={{
                        and: allReactiveIdsExcept(DATA_ACCESS_REACTIVE_ID),
                    }}
                    maxLabelWidth="none"
                    showBottomBorder
                    URLParams
                    filterLabel="Keyword"
                />
                <MultiList
                    componentId={KEYWORDS_REACTIVE_ID}
                    title="Keyword"
                    dataField="keyword.keyword"
                    react={{
                        and: allReactiveIdsExcept(KEYWORDS_REACTIVE_ID),
                    }}
                    maxLabelWidth="none"
                    showBottomBorder
                    URLParams
                    filterLabel="Keyword"
                />
                <MultiList
                    componentId={STATE_REACTIVE_ID}
                    title="State"
                    dataField="geoInformation.state.keyword"
                    react={{
                        and: allReactiveIdsExcept(STATE_REACTIVE_ID),
                    }}
                    maxLabelWidth="none"
                    showBottomBorder
                    URLParams
                    filterLabel="State"
                />
                <MultiList
                    componentId={CLIMATE_ZONE_REACTIVE_ID}
                    dataField="geoInformation.climateZone.keyword"
                    title="Climate Zone"
                    filterLabel="Climate Zone"
                    react={{
                        and: allReactiveIdsExcept(CLIMATE_ZONE_REACTIVE_ID),
                    }}
                    maxLabelWidth="none"
                    showBottomBorder
                    URLParams
                />
                <MultiList
                    componentId={BUILDING_TYPE_REACTIVE_ID}
                    dataField="buildingCharacteristics.type.keyword"
                    title="Building Type"
                    filterLabel="Building Type"
                    react={{
                        and: allReactiveIdsExcept(BUILDING_TYPE_REACTIVE_ID),
                    }}
                    maxLabelWidth="none"
                    showBottomBorder
                    URLParams
                />
                <MultiList
                    componentId={HVAC_SYSTEM_REACTIVE_ID}
                    dataField="buildingCharacteristics.type.keyword"
                    title="HVAC System Type"
                    filterLabel="HVAC System Type"
                    react={{
                        and: allReactiveIdsExcept(HVAC_SYSTEM_REACTIVE_ID),
                    }}
                    maxLabelWidth="none"
                    showBottomBorder
                    URLParams
                />
                <MultiList
                    componentId={FUEL_TYPE_REACTIVE_ID}
                    title="Fuel Type"
                    dataField="systemCharacteristics.fuelType.keyword"
                    filterLabel="Fuel Type"
                    react={{
                        and: allReactiveIdsExcept(FUEL_TYPE_REACTIVE_ID),
                    }}
                    maxLabelWidth="none"
                    showBottomBorder
                    URLParams
                />
                <MultiList
                    componentId={DATA_CATEGORY_REACTIVE_ID}
                    dataField="dataCategory.keyword"
                    title="Category"
                    filterLabel="Category"
                    react={{
                        and: allReactiveIdsExcept(DATA_CATEGORY_REACTIVE_ID),
                    }}
                    maxLabelWidth="none"
                    showBottomBorder
                    URLParams
                />
                <NumericRange
                    componentId={YEAR_BUILT_REACTIVE_ID}
                    dataField="buildingCharacteristics.yearBuilt"
                    title="Year Built"
                    react={{
                        and: allReactiveIdsExcept(YEAR_BUILT_REACTIVE_ID),
                    }}
                    showBottomBorder
                    formatValue={value => `${value}`}
                />
                <NumericRange
                    componentId={FLOOR_AREA_REACTIVE_ID}
                    dataField="buildingCharacteristics.totalFloorArea"
                    title="Floor Area (sq. ft)"
                    react={{
                        and: allReactiveIdsExcept(FLOOR_AREA_REACTIVE_ID),
                    }}
                    showBottomBorder
                />
            </FiltersWrapper>
        );
    }
}

export default inject((store: any) => ({
    // EXAMPLE => metricsService: store.metricsService
}))(DatasetFilters);
