import React from 'react';
import { inject } from 'mobx-react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

import {
  FiltersWrapper,
  FilterSectionHeader,
  MultiList,
} from '@extensions/components/search-core';
import { getAllReactiveIdsExcept } from '@extensions/utils/SearchUtils';
import { SEARCH_BAR_REACTIVE_ID } from '@extensions/components/search-core';

export interface IDatasetFiltersProps {
  projectContext?: string
}
export interface IDatasetFiltersState { }

const PARTICIPATING_ORGS_REACTIVE_ID = 'PARTICIPATING_ORGS';
const KEYWORDS_REACTIVE_ID = 'keywords-filter';
const PROJECT_REACTIVE_ID = 'project-filter';
const DATA_ACCESS_REACTIVE_ID = 'dataAccess-filter';

export const REACTIVE_IDS = [
  PARTICIPATING_ORGS_REACTIVE_ID,
  KEYWORDS_REACTIVE_ID,
  PROJECT_REACTIVE_ID,
  DATA_ACCESS_REACTIVE_ID,
];

const allReactiveIdsExcept = getAllReactiveIdsExcept([
  ...REACTIVE_IDS,
  SEARCH_BAR_REACTIVE_ID,
]);

type TypographyExtraProps = {
  component: React.ElementType;
};

const StyledTitleTypography = styled(Typography)<TypographyExtraProps>(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.grey['400']}`,
  marginBottom: theme.spacing(3),
}));

const StyledFilterWrapperDiv = styled('div', {
  shouldForwardProp: (props) => props !== 'projectContext',
})<{ projectContext?: string | undefined }>(({ projectContext }) => ({
  display: Boolean(projectContext) ? 'none' : 'block'
}));

const StyledMultiList = styled(MultiList)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export class DatasetFilters extends React.Component<
  IDatasetFiltersProps,
  IDatasetFiltersState
> {

  getAdditionalFilters(): React.ReactNode {
    return null;
  }

  render() {
    const { projectContext } = this.props;
    return (
      <FiltersWrapper>
        <StyledTitleTypography
          variant="h6"
          component="h2"
          sx={{ color: theme.palette.common.black }}
        >
          FILTERS
        </StyledTitleTypography>
        <StyledFilterWrapperDiv projectContext={projectContext}>
          <FilterSectionHeader 
            variant="h5" 
          // component="h3"
          >
            Project Criteria
          </FilterSectionHeader>
          <StyledMultiList
            componentId={PARTICIPATING_ORGS_REACTIVE_ID}
            title="Participating Organizations"
            dataField="project.participatingOrganizations.name.keyword"
            react={{
              and: allReactiveIdsExcept(PARTICIPATING_ORGS_REACTIVE_ID),
            }}
            maxLabelWidth="none"
            showBottomBorder
            URLParams={!Boolean(projectContext)}
            filterLabel="Participating Organizations"
            showFilter={!Boolean(projectContext)}
          />
          <StyledMultiList
            componentId={PROJECT_REACTIVE_ID}
            title="Project"
            dataField="project_name.keyword"
            react={{
              and: allReactiveIdsExcept(PROJECT_REACTIVE_ID),
            }}
            maxLabelWidth="none"
            showBottomBorder
            URLParams={!Boolean(projectContext)}
            filterLabel="Project"
            showFilter={!Boolean(projectContext)}
            defaultValue={projectContext ? [projectContext] : undefined}
          />
          <FilterSectionHeader 
            variant="h5" 
            // component="h3"
          >
            Dataset Criteria
          </FilterSectionHeader>
        </StyledFilterWrapperDiv>
        <StyledMultiList
          componentId={DATA_ACCESS_REACTIVE_ID}
          title="Data Access Method"
          dataField="distributionType.keyword"
          react={{
            and: allReactiveIdsExcept(DATA_ACCESS_REACTIVE_ID),
          }}
          maxLabelWidth="none"
          showBottomBorder
          URLParams
          filterLabel="Keyword"
        />
        <StyledMultiList
          componentId={KEYWORDS_REACTIVE_ID}
          title="Keyword"
          dataField="keyword.keyword"
          react={{
            and: allReactiveIdsExcept(KEYWORDS_REACTIVE_ID),
          }}
          maxLabelWidth="none"
          showBottomBorder
          URLParams
          filterLabel="Keyword"
        />
        {this.getAdditionalFilters()}
      </FiltersWrapper>
    );
  }
}
export default inject((store: any) => ({
  // Put any services you need here
}))(DatasetFilters);
