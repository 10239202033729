
// NPM packages
import React from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form';
import merge from 'lodash/merge';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import { UseFormReturn } from 'react-hook-form';

type ControllerPropsNoRender<T extends FieldValues> = Omit<ControllerProps<T>, 'render'>;
export interface TextFieldProps<FormData extends FieldValues> {
  control: UseFormReturn<FormData>['control'];
  fieldName: Path<FormData>;
  textFieldProps?: MuiTextFieldProps;
  controllerProps?: ControllerPropsNoRender<FormData>;
}

function TextField<FormData extends FieldValues>(
  props: TextFieldProps<FormData>
): React.ReactElement | null {
  const { control, fieldName, textFieldProps, controllerProps } = props;
  const defaultControllerProps: ControllerPropsNoRender<FormData> = {
    control,
    name: fieldName,
  };
  const finalControllerProps: ControllerPropsNoRender<FormData> = merge(
    defaultControllerProps,
    controllerProps
  );

  const defaultTextFieldProps: MuiTextFieldProps = {
    id: kebabCase(fieldName),
    label: fieldName === 'doi' ? 'DOI' : fieldName === 'url' ? 'URL' : startCase(fieldName),
    fullWidth: true,
    margin: 'dense',
    size: 'small',
    variant: 'outlined',
  };
  const finalTextFieldProps: MuiTextFieldProps = merge(
    defaultTextFieldProps,
    textFieldProps
  );

  return (
    <Controller
      {...finalControllerProps}
      render={({ field, fieldState, formState }) => {
        const { error } = fieldState;
        const showError = Boolean(error);
        return (
          <MuiTextField
            error={showError}
            helperText={error?.message}
            {...field}
            {...finalTextFieldProps}
          />
        );
      }}
    />
  );
}

export default observer(TextField);
