import React from 'react';
import { inject, observer } from 'mobx-react';

import moment from 'moment';
import {
  Grid,
  Button,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

import {
  JournalArticle,
  PublicationType,
  TechnicalReport,
} from '@extensions/models/Publication';
import Link from '@extensions/components/core/Link';
import Publication from '@extensions/models/Publication';
import MustSignInAlert from '@extensions/components/core/MustSignInAlert';
import { ISecurityService } from '@extensions/services/ISecurityService';
import AuthorsList from '@extensions/components/publications/AuthorsList';
import KeywordsList from '@extensions/components/publications/KeywordsList';
import SponsorsList from '@extensions/components/publications/SponsorsList';
import DatasetsList from '@extensions/components/publications/DatasetsList';
import ProjectsList from '@extensions/components/publications/ProjectsList';
import AdminAccordion from '@extensions/components/publications/AdminAccordion';

const StyledTitleDiv = styled('div')(({
  marginBottom: '1rem'
}));

const StyledDiv = styled('div')(({
  marginBottom: '0.5rem',
  '&& h2': {
    marginBottom: '0.5rem',
  },
}));

const StyledButton = styled(Button)(({
  marginBottom: '1rem',
}));

const StyledAdminAccordionDiv = styled('div')(({
  marginBottom: '1.5rem',
}));

const StyledDoiDiv = styled('div')(({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '0.5rem',
}));

const StyledMustSignInAlert = styled(MustSignInAlert, {
  shouldForwardProp: (props) => props !== 'actionDescription'
})<{ actionDescription: string }>(({
  marginBottom: '0.5rem',
  '&& h2': {
    marginBottom: '0.5rem',
  },
}));

const StyledTypography = styled(Typography)(({
  color: theme.palette.grey[700]
}));

export interface IPublicationSummaryProps {
  className?: string;
  publication: Publication;
  securityService?: ISecurityService;
  editMode: boolean;
}

export interface IPublicationSummaryState { }

@observer
export class PublicationSummary extends React.Component<
  IPublicationSummaryProps,
  IPublicationSummaryState
> {
  render() {
    const { editMode, publication, securityService } =
      this.props;
    let body: React.ReactNode;
    let typeSpecificFields: React.ReactNode;
    let typeSpecificFieldsRight: React.ReactNode;
    let linkUrl: string | undefined = (publication.url === "" || !publication.url) ? publication.uploadedFileUrl : publication.url;
    switch (publication.type) {
      case PublicationType.JOURNAL_ARTICLE:
        const article = publication as JournalArticle;
        typeSpecificFields = (
          <Grid container spacing={3}>
            <Grid item sx={{ mt: '1rem' }}>
              <Typography variant="h2">Journal Name</Typography>
              <StyledTypography variant='body1'>{article.journalName || 'N/A'}</StyledTypography>
            </Grid>
            <Grid item sx={{ mt: '1rem' }}>
              <Typography variant="h2">Journal Volume</Typography>
              <StyledTypography variant='body1'>{article.journalVolume || 'N/A'}</StyledTypography>
            </Grid>
            <Grid item sx={{ mt: '1rem' }}>
              <Typography variant="h2" >Journal Issue</Typography>
              <StyledTypography variant='body1'>{article.journalIssue || 'N/A'}</StyledTypography>
            </Grid>
          </Grid>
        );
        break;
      case PublicationType.TECHNICAL_REPORT:
        const report = publication as TechnicalReport;
        let reportLink: string | undefined = '';
        if (report.pdfUrl && report.pdfUrl !== '') {
          reportLink = report.pdfUrl
        }
        if (report.url && report.url !== '') {
          reportLink = report.url
        }
        if (reportLink !== '' && !editMode) {
          typeSpecificFieldsRight = (
            <a href={reportLink} target='_blank' rel="noreferrer">
              <StyledButton
                variant="contained"
                fullWidth
              >
                Download Report
              </StyledButton>
            </a>
          );
        }
        typeSpecificFields = (
          <>
            <Typography variant="h2">Report Number</Typography>
            <StyledTypography variant='body1'>{report.reportNumber || 'N/A'}</StyledTypography>
          </>
        );
        break;
    }

    switch (publication.type) {
      case PublicationType.JOURNAL_ARTICLE:
      case PublicationType.TECHNICAL_REPORT:
        body = (
          <StyledDiv>
            {!editMode && (
              <Typography variant="h1" sx={{ mb: '3rem' }}>
                {publication.title}
              </Typography>
            )}
            {!editMode &&
              securityService &&
              securityService.user &&
              securityService.user.canAdminPubs && (
                <StyledAdminAccordionDiv>
                  <AdminAccordion />
                </StyledAdminAccordionDiv>
              )}
            <Grid container spacing={5}>
              <Grid item xs={12} lg={6} sx={{ mt: '1rem' }}>
                {editMode && (
                  <StyledTitleDiv>
                    <Typography variant="h2">Title</Typography>
                    <StyledTypography variant='body1'>{publication.title}</StyledTypography>
                  </StyledTitleDiv>
                )}
                {publication.abstract && (
                  <>
                    <Typography variant="h2">Abstract</Typography>
                    <StyledTypography variant='body1'>{publication.abstract}</StyledTypography>
                  </>
                )}
                {typeSpecificFields}
                {publication.keywords && <KeywordsList keywords={publication.keywords} />}
              </Grid>
              <Grid item xs={12} lg={6}>
                {typeSpecificFieldsRight}
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    {publication.doi && (
                      <>
                        <Typography variant="h2">DOI</Typography>
                        <StyledDoiDiv>
                          <Link
                            to={`https://doi.org/${publication.doi}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {publication.doi}
                          </Link>
                        </StyledDoiDiv>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    {publication.publicationDate &&
                      <>
                        <Typography variant="h2">Published</Typography>
                        <StyledTypography variant='body1'>
                          {moment(publication.publicationDate).format('YYYY-MM-DD')}
                        </StyledTypography>
                      </>
                    }
                  </Grid>
                </Grid>
                {publication.authors && <AuthorsList authors={publication.authors} />}
                {publication.sponsorOrganizations && <SponsorsList sponsors={publication.sponsorOrganizations} />}
                {securityService && (!securityService.user || !securityService.user.authenticated)
                  ? <>
                    <Typography variant="h2">Datasets</Typography>
                    <MustSignInAlert
                      actionDescription="view or request access to dataset(s)"
                    />
                  </>
                  : <>
                    <DatasetsList datasets={publication.datasets} />
                    <ProjectsList projects={publication.projects} />
                  </>
                }
              </Grid>
            </Grid>
          </StyledDiv>
        )
        break;
      default:
        body = (
          <StyledDiv>
            {!editMode && (
              <Typography variant="h1" sx={{ mb: '3rem' }}>
                {publication.title}
              </Typography>
            )}
            {!editMode &&
              securityService &&
              securityService.user &&
              securityService.user.canAdminPubs && (
                <StyledAdminAccordionDiv>
                  <AdminAccordion />
                </StyledAdminAccordionDiv>
              )}
            <Grid container spacing={5}>
              <Grid item xs={12} lg={6} sx={{ mt: '1rem' }}>
                <Typography variant="h2">Title</Typography>
                <Typography variant='body2'>{publication.title}</Typography>
                {linkUrl &&
                  <>
                    <Typography variant="h2">Reference URL</Typography>
                    <Link
                      to={linkUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ wordBreak: 'break-all', fontSize: '14px' }}
                    >
                      {linkUrl}
                    </Link>
                  </>
                }
                {securityService && (!securityService.user || !securityService.user.authenticated)
                  ? <>
                    <Typography variant="h2">Datasets</Typography>
                    <StyledMustSignInAlert
                      actionDescription="view or request access to dataset(s)"
                    />
                  </>
                  : <>
                    <DatasetsList datasets={publication.datasets} />
                    <ProjectsList projects={publication.projects} />
                  </>
                }
              </Grid>
            </Grid>
          </StyledDiv>
        );
        break;
    }
    return body;
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
}))(PublicationSummary);
