import memoize from 'lodash/memoize';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

import MetadataDest from '@extensions/models/MetadataDest';
import MetaDocument from '@extensions/models/MetaDocument';
import { IMetadataService } from '@extensions/services/IMetadataService';
import {
  action,
  computed,
  observable,
  runInAction,
  makeObservable,
} from 'mobx';
import Dataset from '@extensions/models/Dataset';
import { INotificationService } from '@extensions/services/INotificationService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IHistoryService } from '@extensions/services/IHistoryService';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import { withApiPrefix } from '@extensions/utils/metadata';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { Standard } from '@extensions/models/Measurement';
import { DocAction } from '@extensions/services/IMetadataService';

export default class MetadataService implements IMetadataService {
  private notificationID = 'metadata-service';
  private notificationService: INotificationService;
  private securityService: ISecurityService;
  private historyService: IHistoryService;
  /** Map from project name to metadata destination */
  @observable
  destinations: MetadataDest[];
  @observable
  documents: MetaDocument[];
  @observable
  publishedDocuments: MetaDocument[];
  @observable
  loaded: boolean;
  @observable
  publishedMetadataProjects: string[];
  @observable
  selectedDocAction: DocAction | undefined;

  @computed
  get docsByProjectName(): Record<string, MetaDocument[]> {
    return groupBy(
      this.documents.filter((doc) => Boolean(doc.datasetName)),
      (doc: MetaDocument) => Dataset.extractProjectName(doc.datasetName)
    );
  }

  @computed
  get publishedDocsByProjectName(): Record<string, MetaDocument[]> {
    return groupBy(
      this.publishedDocuments.filter((doc) => Boolean(doc.datasetName)),
      (doc: MetaDocument) => Dataset.extractProjectName(doc.datasetName)
    );
  }

  @computed
  get datasetsWithDocs(): Set<string> {
    const result = new Set<string>();
    this.documents.forEach((doc) => {
      if (doc.datasetName) {
        result.add(doc.datasetName);
      }
    });
    return result;
  }
  @computed
  get projectsWithDests(): string[] {
    const projectNames = new Set<string>(
      this.destinations.map((dest) => dest.projectName)
    );
    return [...projectNames.values()];
  }

  @observable
  standardMeasures: Standard[] | null = null;
  @observable
  standardUnits: Standard[] | null = null;

  load: () => void;

  constructor(
    notificationService: INotificationService,
    securityService: ISecurityService,
    historyService: IHistoryService
  ) {
    makeObservable(this);
    this.notificationService = notificationService;
    this.securityService = securityService;
    this.historyService = historyService;
    this.destinations = [];
    this.documents = [];
    this.publishedDocuments = [];
    this.loaded = false;
    this.selectedDocAction = undefined;
    this.publishedMetadataProjects = [];
    // Memoizing prevents double fetching
    this.load = memoize(this._load);
  }

  getDocument = (documentId: string | number): MetaDocument | null => {
    return (
      this.documents.find((document) => document.id === documentId) || null
    );
  };

  getDocumentByDataset = (datasetName: string): MetaDocument | null => {
    return (
      this.documents.find((document) => document.datasetName === datasetName) ||
      null
    );
  };

  getPublishedDocument = (documentId: string | number): MetaDocument | null => {
    let getPublishedDocumentResult;
    let truePublishedDoc: MetaDocument[] = [];
    this.publishedDocuments.forEach((publishedDoc) => {
      if (publishedDoc.isPublished) {
        truePublishedDoc.push(publishedDoc)
      }
    });
    truePublishedDoc.forEach((truePublished) => {
      if (truePublished.id === documentId) {
        getPublishedDocumentResult = truePublished;
      }
    });
    return getPublishedDocumentResult || null;
  };

  getDestination = (datasetName: string): MetadataDest | null => {
    const match = this.destinations.find(
      (dest) => dest.datasetName === datasetName
    );

    return match || null;
  };

  @action
  addNewDocumentFromPublished = (doc: MetaDocument): void => {
    runInAction(() => {
      //when saving a draft for the first time that came from a
      //published doc need to 1. remove the published doc from that list (it's now a draft and so needs to be edited from drafts list)
      // and 2. add it to the list of draft/submitted docs
      // doc.isPublished = true;  //DON'T DO THIS because it needs to stay 'published'
      this.documents.push(doc);
      const dest = this.getDestination(doc.datasetName);
      if (dest) {
        dest.hasDocument = true;
      }
      this.publishedDocuments = this.publishedDocuments.filter(
        (cachedDoc) => cachedDoc.datasetName !== doc.datasetName
      );
    });
  };

  @action
  setSelectedDocAndAction = (
    doc: MetaDocument,
    linkClicked: boolean,
    editPencilClicked: boolean
  ) => {
    this.selectedDocAction = this.getDocAction(
      doc,
      linkClicked,
      editPencilClicked
    );
  };

  @action
  setSelectedDocAction = (docAction: DocAction): void => {
    this.selectedDocAction = docAction;
  };

  private getDocAction = (
    doc: MetaDocument,
    linkClicked: boolean,
    editPencilClicked: boolean
  ) => {
    if (doc.isPublished && editPencilClicked && doc.can.write) {
      return DocAction.PublishedEditing;
    } else if (
      (doc.isSubmitted && linkClicked) ||
      (doc.isSubmitted && editPencilClicked && !doc.can.write)
    ) {
      return DocAction.PendingReviewing;
    } else if (doc.isSubmitted && editPencilClicked && doc.can.write) {
      return DocAction.PendingEditing;
    } else if (!doc.isSubmitted && editPencilClicked && doc.can.write) {
      return DocAction.DraftEditing;
    } else if (!doc.isSubmitted && linkClicked && doc.can.write) {
      return DocAction.DraftReviewingEditable;
    }
    return DocAction.DraftReviewingReadOnly;
  };

  @action
  addNewDocument = (): string | number => {
    if (!this.securityService.user) {
      throw new Error('User Not Logged In');
    }
    const newDoc = new MetaDocument(
      {
        id: uuid(),
        datasetName: '',
        shortName: '',
        importedFrom: null,
        isSubmitted: false,
        isPublished: false,
        accessRequiresMfa: false,
        lastModified: moment().unix(),
        author: {
          firstName: this.securityService.user.firstname,
          lastName: this.securityService.user.username,
          email: this.securityService.user.email,
        },
        can: {
          read: true,
          write: true,
          delete: true,
          publish: false,
        },
        metadata: null,
        events: [],
      },
      this.notificationService,
      this.historyService,
      this,
      false
    );
    this.setSelectedDocAction(DocAction.New);
    this.documents.push(newDoc);
    return newDoc.id;
  };

  @action
  private removeDoc = (id: string | number) => {
    this.documents = this.documents.filter((cachedDoc) => cachedDoc.id !== id);
  };

  @action
  deleteDocument = (doc: MetaDocument): Promise<void> => {
    const deleteAndUpdate = async () => {
      try {
        await this.requestDeletion(doc.id);
      } catch {}
      this.removeDoc(doc.id);
      const dest = this.getDestination(doc.datasetName);
      if (dest) {
        dest.hasDocument = false;
        //if this draft was created from clicking on the edit pencil of a published dataset, add it back to published list
        if (dest.isPublished) {
          //start doc over with clean slate
          const newDoc = new MetaDocument(
            doc,
            this.notificationService,
            this.historyService,
            this,
            false
          );
          newDoc.metadataLoaded = false;
          this.publishedDocuments.push(newDoc);
        }
      }
      this.historyService.history.replace('/metadata');
    };
    return this.notificationService.showStateInUI({
      pending: deleteAndUpdate(),
      notificationId: this.notificationID,
      errorMessage: 'Failed to delete document',
    });
  };

  @action
  publish = (doc: MetaDocument): Promise<void> => {
    const publishAndUpdate = async () => {
      await DapApiAgent.agent.put(withApiPrefix(`/${doc.id}/publish`));
      runInAction(() => {
        this.removeDoc(doc.id);
        const matchingDest = this.destinations.find(
          (dest) => dest.datasetName === doc.datasetName
        );
        if (matchingDest) {
          matchingDest.isPublished = true;
          matchingDest.hasDocument = false;
        }
      });
      this.historyService.history.push('/metadata');
    };

    return this.notificationService.showStateInUI({
      pending: publishAndUpdate(),
      notificationId: this.notificationID,
      errorMessage: 'Failed to publish',
      successMessage: 'Success',
      successDescription: `Metadata for ${doc.datasetName} has been published`,
    });
  };

  private _load = () => {
    const fetchAndHydrate = async () => {
      const [rawDestinations, rawGroupedDocs, rawStandards] = await Promise.all(
        [this.fetchDestinations(), this.fetchDocuments(), this.fetchStandards()]
      );
      runInAction(() => {
        this.destinations = rawDestinations.map(
          (rawDest) =>
            new MetadataDest(
              rawDest,
              this.notificationService,
              this.securityService
            )
        );
        this.documents = flatMap(rawGroupedDocs, (raw) =>
          raw.documents.map(
            (rawDoc) =>
              new MetaDocument(
                rawDoc,
                this.notificationService,
                this.historyService,
                this,
                true
              )
          )
        );
        //find out what all projects this user can edit an already published metadata for
        //by getting all unique project names from the _config endpoint
        this.publishedMetadataProjects = uniqBy(
          rawDestinations,
          'projectName'
        ).map((conf) => conf.projectName);
      });
      const [rawPublishedDocs] = await Promise.all([
        this.fetchPublishedDocuments(),
      ]);
      runInAction(() => {
        // remove any documents that are in a draft or submitted state
        const hitsByName = {};
        rawPublishedDocs.body.responses[0].hits.hits.forEach((hit) => {
          hitsByName[hit._source.name] = hit._source;
        });
        this.publishedDocuments = this.destinations
          .filter((dest) => {
            return (
              !dest.hasDocument &&
              dest.isPublished &&
              hitsByName[dest.datasetName]
            );
          })
          .map((dest) => {
            return new MetaDocument(
              hitsByName[dest.datasetName],
              this.notificationService,
              this.historyService,
              this,
              false
            );
          });
        this.standardMeasures = this.moveItemToTop(rawStandards['measurements']);
        this.standardUnits = this.moveItemToTop(rawStandards['units']);
        this.loaded = true;
      });
    };

    this.notificationService.showStateInUI({
      pending: fetchAndHydrate(),
      notificationId: this.notificationID,
      errorMessage: 'Failed to load documents',
    });
  };

  private moveItemToTop = (itemArr: any[] | null) => {
    if(itemArr) {
      itemArr.forEach((item, i) => {
        if (item.displayName === 'NOT AVAILABLE') {
          itemArr.splice(i, 1);
          itemArr.unshift(item);
        }
      });
    }
    return itemArr;
  };

  private fetchDestinations = async (): Promise<any[]> => {
    const resp = await DapApiAgent.agent.get(withApiPrefix('/_config'));
    return resp.body;
  };

  private fetchDocuments = async (): Promise<any[]> => {
    const resp = await DapApiAgent.agent.get(withApiPrefix(''));
    return resp.body;
  };

  private fetchStandards = async (): Promise<any> => {
    const resp = await DapApiAgent.agent.get(withApiPrefix('/standard-names'));
    return resp.body;
  };

  private fetchPublishedDocuments = async (): Promise<any> => {
    let prefixes = '';
    this.publishedMetadataProjects.forEach((project) => {
      prefixes += '{"prefix": { "name.keyword": "' + project + '/" }},';
    });

    return await DapApiAgent.agent
      .post('/api/datasets/_msearch?')
      .send(
        '{}\n{"query":{"bool": {"should": [' +
          prefixes.substring(0, prefixes.length - 1) +
          ']}},"size":9999}'
      );
  };

  requestDeletion = async (docId: string | number): Promise<void> => {
    await DapApiAgent.agent.delete(withApiPrefix(`/${docId}`));
  };
}
