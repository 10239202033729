import * as React from 'react';
import { reaction } from 'mobx';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  TextField,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useConfirm } from 'material-ui-confirm';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import filter from 'lodash/filter';
import camelCase from 'lodash/camelCase';
import upperFirst from 'lodash/upperFirst';

import theme from '@extensions/services/Theme';
import {
  IDs,
  INotificationService,
  Notification,
  Status,
} from '@extensions/services/INotificationService';
import { IProjectService } from '@extensions/services/IProjectService';
import { IMembershipService } from '@extensions/services/IMembershipService';

import User from '@extensions/models/User';
import Group from '@extensions/models/Group';
import Dataset from '@extensions/models/Dataset';
import IControlledAccess from '@extensions/models/IControlledAccess';
import AccessRestriction from '@extensions/models/AccessRestriction';
import Role, { ROLE_OPTIONS, RoleType } from '@extensions/models/Role';
import MembersModal from '@extensions/components/membership/MembersModal';
import PermissionsTable from '@extensions/components/membership/PermissionsTable';

export interface IManageRolesProps {
  membershipService: IMembershipService;
  notificationService: INotificationService;
  projectService: IProjectService;
  accessControlledItem: IControlledAccess;
}

export interface IManageRolesState {
  searchText: string;
  selectedGroup: Group | undefined;
  selectedUser: User | undefined;
  addMemberModalVisible: boolean;
  editMemberModalVisible: boolean;
  selectedMemberRemoveFromExistingRole: string;
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .MuiGrid-item': {
    paddingBottom: theme.spacing(1),
  },
}));

const StyledRolesGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(3),
}));

const StyledSpan = styled('span')(({
  float: 'right',
}));

const StyledTypography = styled(Typography)(({
  color: 'rgba(0,0,0,0.65)'
}))

interface IRemoveFromRoleButtonProps {
  group?: Group | undefined;
  user?: User | undefined;
  role: string;
  removeCallBack: (
    role: string,
    group: Group | undefined,
    user: User | undefined
  ) => void;
}

const RemoveFromRoleButton = (props: IRemoveFromRoleButtonProps) => {
  const confirm = useConfirm();

  const handleClick = () => {
    const name = props.user
      ? props.user.fullName || props.user.email
      : props.group
        ? props.group.name
        : '';
    confirm({
      description: `Are you sure you want to remove ${name} from the ${props.role} role?`,
    })
      .then(() => {
        props.removeCallBack(props.role, props.group, props.user);
      })
      .catch(() => {
        // Deletion cancelled
      });
  };

  return (
    <Tooltip title="Remove from role">
      <IconButton aria-label="Remove from role" onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

@inject('projectService', 'membershipService', 'notificationService')
@observer
class ManageRoles extends React.Component<
  IManageRolesProps,
  IManageRolesState
> {
  static defaultProps = {
    membershipService: undefined,
    notificationService: undefined,
    projectService: undefined,
  };
  updateGroupReaction: any;
  updateMembersReaction: any;

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      selectedGroup: undefined,
      selectedUser: undefined,
      addMemberModalVisible: false,
      editMemberModalVisible: false,
      selectedMemberRemoveFromExistingRole: '',
    };
  }

  componentDidMount() {
    const {
      projectService,
      membershipService,
      notificationService,
      accessControlledItem,
    } = this.props;
    if (accessControlledItem) {
      membershipService.getMembersIfNeeded(
        accessControlledItem.getIdentifier(),
        accessControlledItem.getType()
      );
    }

    projectService.getProjectGroupsIfNeeded(
      accessControlledItem.getIdentifier()
    );
    projectService.getProjectUsersIfNeeded(
      accessControlledItem.getIdentifier()
    );

    this.updateMembersReaction = reaction(
      () => notificationService.getNotification(IDs.MEMBERS_ADDED),
      (membersAddedNotification: Notification | null) => {
        if (
          membersAddedNotification &&
          membersAddedNotification.status === Status.Success
        ) {
          this.setState({ selectedUser: undefined, selectedGroup: undefined });
          this.closeModal();
        }
      }
    );
  }

  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset's data order modal loads
    this.updateMembersReaction();
  }

  search = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  closeModal = () => {
    this.setState({
      addMemberModalVisible: false,
      editMemberModalVisible: false,
      selectedMemberRemoveFromExistingRole: '',
    });
  };

  getDataset = () => {
    const { accessControlledItem } = this.props;
    let dataset: Dataset | undefined;
    if (accessControlledItem && accessControlledItem instanceof Dataset) {
      dataset = accessControlledItem as Dataset;
    }
    return dataset;
  };

  removeFromRole = (
    role: string,
    group: Group | undefined,
    user: User | undefined
  ) => {
    this.props.membershipService.removeMemberFromRole(
      role,
      this.props.accessControlledItem.getIdentifier(),
      this.props.accessControlledItem.getType(),
      user,
      group
    );
  };

  getUserList = (users: User[], roleAtProjectLevel: boolean, role: string) => {
    const searchText = this.state.searchText;
    let filteredUsers = users;
    // remove users that don't match search string (if entered)
    if (searchText) {
      filteredUsers = filter(users, (user: User) => {
        if (user) {
          const userString = `${user.email}${user.firstname}${user.lastname}`;
          return (
            camelCase(userString)
              .toLowerCase()
              .indexOf(camelCase(searchText).toLowerCase()) !== -1
          );
        } else {
          return true;
        }
      });
    }

    const list = (
      <List
        dense
        disablePadding
        aria-label="users"
        key={Date.now() + users.length}
        sx={{ width: '100%' }}
      >
        {filteredUsers.map((user: User) => (
          <ListItem key={user.email}>
            {!roleAtProjectLevel && (
              <React.Fragment>
                <ListItemIcon>
                  <RemoveFromRoleButton
                    role={role}
                    user={user}
                    removeCallBack={this.removeFromRole}
                  />
                </ListItemIcon>
                <ListItemIcon>
                  <Tooltip title="Change Role">
                    <IconButton
                      aria-label="change role"
                      onClick={e =>
                        this.setState({
                          editMemberModalVisible: true,
                          selectedUser: user,
                          selectedMemberRemoveFromExistingRole: role,
                        })
                      }
                    >
                      <CreateIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemIcon>
              </React.Fragment>
            )}
            <ListItemText 
              primary={user.fullName} 
              secondary={user.email} 
              primaryTypographyProps={{
                variant: 'body1',
                color: theme.palette.common.black
              }}
              secondaryTypographyProps={{
                variant: 'body1',
                color: theme.palette.grey[700]
              }}
            />
          </ListItem>
        ))}
      </List>
    );
    if (!roleAtProjectLevel) {
      return list;
    } else {
      return (
        <Tooltip title="To make changes go to project" placement="top-start">
          {list}
        </Tooltip>
      );
    }
  };

  getGroupsPanels = (
    groups: Group[],
    roleAtProjectLevel: boolean,
    role: string
  ) => {
    const searchText = this.state.searchText;
    let filteredGroups = groups;
    // remove groups that don't match search string (if entered)
    if (searchText) {
      filteredGroups = filter(groups, (group: Group) => {
        return (
          camelCase(group.name)
            .toLowerCase()
            .indexOf(camelCase(searchText).toLowerCase()) !== -1
        );
      });
    }

    return filteredGroups.map((group: Group) => {
      let controls: React.ReactNode[] = [];
      if (!roleAtProjectLevel) {
        controls = [
          <FormControlLabel
            key="remove"
            aria-label="remove from role"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <RemoveFromRoleButton
                role={role}
                group={group}
                removeCallBack={this.removeFromRole}
              />
            }
            label=""
          />,
          <FormControlLabel
            key="change"
            aria-label="change role"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}
            control={
              <Tooltip title="Change Role">
                <IconButton
                  aria-label="change role"
                  onClick={e =>
                    this.setState({
                      editMemberModalVisible: true,
                      selectedGroup: group,
                      selectedMemberRemoveFromExistingRole: role,
                    })
                  }
                >
                  <CreateIcon />
                </IconButton>
              </Tooltip>
            }
            label=""
          />,
        ];
      }

      return (
        <Accordion key={group.name}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="group-content"
            id="group-header"
          >
            {controls}
            <Typography sx={{ paddingTop: '12px' }}>{group.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List dense disablePadding aria-label="groupmembers">
              {group.users.map((user: User) => (
                <ListItem key={user.email}>
                  <ListItemText
                    primary={user.fullName}
                    secondary={user.email}
                    primaryTypographyProps={{
                      variant: 'body1',
                      color: theme.palette.common.black
                    }}
                    secondaryTypographyProps={{
                      variant: 'body1',
                      color: theme.palette.grey[700]
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  getRolePanel = (
    role: RoleType,
    usersByRole: Map<string, User[]>,
    groupsByRole: Map<string, Group[]>,
    roleAtProjectLevel: boolean
  ) => {
    const users: User[] | undefined = usersByRole.get(role);
    const groups: Group[] | undefined = groupsByRole.get(role);
    let panel: React.ReactNode = null;

    // if this component is being used on a dataset and role at project level
    // the label needs to say Project, otherwise Dataset
    const panelLabel = this.getDataset()
      ? `${roleAtProjectLevel ? 'Project' : 'Dataset'} ${upperFirst(role)}s`
      : `Project ${upperFirst(role)}s`;

    if (users || groups) {
      panel = (
        <Accordion key={panelLabel}>
          <Tooltip title={ROLE_OPTIONS[role].description} placement="top">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="group-content"
              id="group-header"
            >
              <Typography>{panelLabel}</Typography>
            </AccordionSummary>
          </Tooltip>
          <AccordionDetails>
            <Grid container>
              {users && (
                <Grid item xs={12}>
                  {this.getUserList(users, roleAtProjectLevel, role)}
                </Grid>
              )}
              {groups && (
                <Grid item xs={12}>
                  {this.getGroupsPanels(groups, roleAtProjectLevel, role)}
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
    return panel;
  };

  sortMapsByRole = (
    memberByRole,
    uneditableMembersByRole,
    editableMembersByRole
  ) => {
    // since key is an object cannot call 'get' on map, have to user iterator
    // sort lists into editable and uneditable by role
    const roles = memberByRole.keys();
    let result = roles.next();
    while (!result.done) {
      const members = memberByRole.get(result.value);
      const roleLevel: Role = result.value;
      // if we're viewing permissions on a dataset and the role level is set to project, its uneditable
      if (roleLevel.level === 'project' && this.getDataset() && members) {
        uneditableMembersByRole.set(roleLevel.label, members);
      } else if (members) {
        editableMembersByRole.set(roleLevel.label, members);
      }
      result = roles.next();
    }
  };

  renderRolePanels = (
    usersByRole: Map<Role, User[]>,
    groupsByRole: Map<Role, Group[]>
  ) => {
    const rolePanels: React.ReactNode[] = [];

    const editableUsersByRole: Map<string, User[]> = new Map();
    const editableGroupsByRole: Map<string, Group[]> = new Map();
    const uneditableUsersByRole: Map<string, User[]> = new Map();
    const uneditableGroupsByRole: Map<string, Group[]> = new Map();
    this.sortMapsByRole(
      usersByRole,
      uneditableUsersByRole,
      editableUsersByRole
    );
    this.sortMapsByRole(
      groupsByRole,
      uneditableGroupsByRole,
      editableGroupsByRole
    );

    // first show editable leads, editors, members, then the uneditable ones:
    rolePanels.push(
      this.getRolePanel(
        RoleType.MEMBER,
        editableUsersByRole,
        editableGroupsByRole,
        false
      ),
      this.getRolePanel(
        RoleType.EDITOR,
        editableUsersByRole,
        editableGroupsByRole,
        false
      ),
      this.getRolePanel(
        RoleType.LEAD,
        editableUsersByRole,
        editableGroupsByRole,
        false
      ),
      this.getRolePanel(
        RoleType.MEMBER,
        uneditableUsersByRole,
        uneditableGroupsByRole,
        true
      ),
      this.getRolePanel(
        RoleType.EDITOR,
        uneditableUsersByRole,
        uneditableGroupsByRole,
        true
      ),
      this.getRolePanel(
        RoleType.LEAD,
        uneditableUsersByRole,
        uneditableGroupsByRole,
        true
      )
    );

    return rolePanels;
  };

  renderAccessInfo = () => {
    const { accessControlledItem } = this.props;
    const label = upperFirst(accessControlledItem.getType());
    return (
      <StyledGrid container alignItems="flex-start">
        <Grid item xs={3}>
          <StyledTypography variant='body1'>{label} Access:</StyledTypography>
        </Grid>
        <Grid item xs={9}>
          <StyledTypography variant='body1'>{accessControlledItem.getPermissionLabel()}</StyledTypography>
        </Grid>
        <Grid item xs={3}>
          <StyledTypography variant='body1'>{label} Permissions:</StyledTypography>
        </Grid>
        <Grid item xs={9}>
          <PermissionsTable accessControlledItem={accessControlledItem} />
        </Grid>
      </StyledGrid>
    );
  };

  public render() {
    const {
      addMemberModalVisible,
      editMemberModalVisible,
      selectedMemberRemoveFromExistingRole,
      selectedUser,
      selectedGroup,
    } = this.state;
    const {
      membershipService,
      projectService,
      accessControlledItem,
    } = this.props;
    const usersByRole: Map<Role, User[]> | null = membershipService.usersByRole;
    const groupsByRole: Map<Role, Group[]> | null =
      membershipService.groupsByRole;
    const groups: Group[] | null = projectService.groups;
    const users: User[] | null = projectService.users;
    let content = <div />;

    if (
      groups !== null &&
      users !== null &&
      usersByRole &&
      groupsByRole &&
      accessControlledItem &&
      projectService.project
    ) {
      const rolePanels = this.renderRolePanels(usersByRole, groupsByRole);
      const editable = Boolean(
        this.getDataset() === undefined ||
        [
          AccessRestriction.restrictions.dataset,
          AccessRestriction.restrictions.datasetMfa,
        ].indexOf(accessControlledItem.getRestriction()) >= 0
      );

      const accessInfo = this.renderAccessInfo();

      const searchTextField = (
        <StyledSpan>
          <TextField label="Search" variant="standard" onChange={this.search} />
        </StyledSpan>
      );
      const roleSectionHeader = editable ? (
        null
      ) : (
        <React.Fragment>
          <StyledRolesGrid item xs={6}>
            <StyledTypography>
              {upperFirst(accessControlledItem.getType())} Roles: (Managed at
              the{' '}
              <Link to={`/project/${projectService.project.identifier}/manage`}>
                project
              </Link>{' '}
              level)
            </StyledTypography>
          </StyledRolesGrid>
          <Grid item xs={6}>
            {searchTextField}
          </Grid>
        </React.Fragment>
      );

      let assignRoleRow;
      if (editable) {
        assignRoleRow = (
          <React.Fragment>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                onClick={e => this.setState({ addMemberModalVisible: true })}
              >
                Assign Role
              </Button>
            </Grid>
            <Grid item xs={6}>
              {searchTextField}
            </Grid>
          </React.Fragment>
        );
      }

      content = (
        <React.Fragment>
          {accessInfo}
          <Grid container>
            {addMemberModalVisible && (
              <MembersModal
                title={'Assign Role'}
                visible={addMemberModalVisible}
                onCancel={this.closeModal}
                accessControlledItem={accessControlledItem}
                groups={groups}
                users={users}
              />
            )}
            {editMemberModalVisible && (
              <MembersModal
                title="Edit Member"
                visible={editMemberModalVisible}
                onCancel={this.closeModal}
                accessControlledItem={accessControlledItem}
                groups={selectedGroup ? [selectedGroup] : []}
                users={selectedUser ? [selectedUser] : []}
                removeFromExistingRole={selectedMemberRemoveFromExistingRole}
              />
            )}
            <Grid item xs={12}>
              <StyledGrid
                container
                alignItems="flex-start"
              >
                {roleSectionHeader}
                {assignRoleRow}
              </StyledGrid>
            </Grid>

            <Grid item xs={12}>
              {rolePanels}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
    return content;
  }
}

export default ManageRoles;
