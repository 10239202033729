import { ReactNode } from 'react';
// import { Redirect, useLocation } from 'react-router-dom';

import { useRequiresAuth } from '@dapclient/hooks/useRequiresAuth';
// import { useService } from '@dapclient/hooks/useService';
// import { ISecurityService } from '@extensions/services/ISecurityService';

export interface IRequiresAuthProps {
  children?: ReactNode
}

const RequiresAuth = ({ children }: IRequiresAuthProps) => {
  // const location = useLocation();
  // const securitySvc = useService('securityService') as ISecurityService;

  // if (securitySvc.userIsLoggedIn) {
  //   return (<>{children}</>);
  // }

  // securitySvc.setCallbackUrl(location.pathname);

  // return (
  //   <Redirect to="/signIn" />
  // );

  const willRedirect = useRequiresAuth();

  if (willRedirect) {
    return null;
  }

  return (<>{children}</>);
};

export default RequiresAuth;
