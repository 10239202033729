import React from 'react';
import { inject, observer } from 'mobx-react';

import { styled } from '@mui/material/styles';
import { Button, Alert, AlertTitle } from '@mui/material';

const StyledAlert = styled(Alert)(({
  marginTop: '2rem',
  fontSize: '14px'
}));

const StyledButtonBarDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '3rem',
  marginBottom: '3rem',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '0.5rem',
  backgroundColor: '#e0e0e0',
  color: '#000',
  '&:hover': {
    backgroundColor: '#bab8b8',
  }
}));

export interface IFormButtonsAndErrorsProps {
  className?: string;
  canMoveForward: boolean;
  canMoveBackward: boolean;
  clickCancelBtn: () => void;
  moveForward: () => void;
  moveBackward: () => void;
  moveForwardText?: string;
  moveBackwardText?: string;
  error: Error | null;
}

export interface IFormButtonsAndErrorsState { }

@observer
export class FormButtonsAndErrors extends React.Component<
  IFormButtonsAndErrorsProps,
  IFormButtonsAndErrorsState
> {
  render() {
    const {
      clickCancelBtn,
      moveBackward,
      moveForward,
      canMoveBackward,
      canMoveForward,
      moveBackwardText = 'Previous',
      moveForwardText = 'Next',
      error,
    } = this.props;
    return (
      <div>
        {error && (
          <StyledAlert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </StyledAlert>
        )}
        <StyledButtonBarDiv>
          <StyledButton
            variant="contained"
            onClick={clickCancelBtn}
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="contained"
            disabled={!canMoveBackward}
            onClick={moveBackward}
          >
            {moveBackwardText}
          </StyledButton>
          <StyledButton
            variant="contained"
            disabled={!canMoveForward}
            onClick={moveForward}
            type="submit"
          >
            {moveForwardText}
          </StyledButton>
        </StyledButtonBarDiv>
      </div>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(FormButtonsAndErrors);
