import React from 'react';
import { styled } from '@mui/material/styles';
import { MultiList as BaseMultiList } from '@appbaseio/reactivesearch';

import CheckboxGrid, {
  FilterItem,
  ValueType,
} from '@extensions/components/search-core/CheckboxGrid';
import FilterTitle from '@extensions/components/search-core/FilterTitle';

type BaseMultiListProps = React.ComponentProps<typeof BaseMultiList>;

export interface IMultiListProps
  extends Omit<
    BaseMultiListProps,
    'render' | 'renderItem' | 'innerClass' | 'showCheckbox'
  > {
  title: string;
  maxLabelWidth?: string;
  /** Should their be a border at the bottom? */
  showBottomBorder?: boolean;
}

interface RenderArgs {
  loading: boolean;
  error: object;
  data: FilterItem[];
  value: ValueType;
  handleChange: (event: { target: { value: string } }) => void;
}

const StyledDiv = styled('div', {
  shouldForwardProp: (props) => props !== 'showBottomBorder',
})<{ showBottomBorder?: boolean | undefined }>(({ showBottomBorder, theme }) => ({
  borderBottom: showBottomBorder ? `1px solid ${theme.palette.grey['400']} ` : ''
}));

class MultiList extends React.Component<
  IMultiListProps
> {
  render() {
    const { title, maxLabelWidth, showBottomBorder, ...baseProps } =
      this.props;
    const titleElementId = `${this.props.componentId}-title`;
    return (
      <BaseMultiList
        {...baseProps}
        showCheckbox={true}
        showSearch={false}
        render={({ loading, error, ...renderProps }: RenderArgs) => {
          if (
            loading ||
            error ||
            !renderProps.data ||
            renderProps.data.length === 0
          ) {
            return null;
          }
          return (
            <StyledDiv showBottomBorder={showBottomBorder}>
              <FilterTitle id={titleElementId}>{title}</FilterTitle>
              <CheckboxGrid
                {...renderProps}
                checkboxColumnHeader={title}
                labeledBy={titleElementId}
                maxLabelWidth={maxLabelWidth}
              />
            </StyledDiv>
          );
        }}
      />
    );
  }
}

export default MultiList;
