import * as superagent from 'superagent';
import { autorun } from 'mobx';
import {
  INotificationService,
  IDs,
  Status,
} from '@extensions/services/INotificationService';
import ITokenService from '@extensions/services/ITokenService';
import { LOCAL_STORAGE_KEYS } from '@extensions/services/TokenService';

export default class DapApiAgent {
  static agent: superagent.SuperAgentStatic & superagent.Request;
  static notificationService: INotificationService;
  static tokenService: ITokenService;

  static initialize(
    notificationService: INotificationService,
    tokenService: ITokenService
  ) {
    DapApiAgent.notificationService = notificationService;
    DapApiAgent.tokenService = tokenService;

    // set up superagent default headers.  Get the csrf token from the server for local
    // dev in order for proxying to work.  On prod/dev/test servers the token will
    // already be in the index.html's meta tag so parse it from there instead
    DapApiAgent.agent = superagent
      .agent()
      .accept('json')
      .set('X-Requested-With', 'XMLHttpRequest');

    const sessionExpired = () => {
      this.tokenService.refreshDapToken();
      this.tokenService.clearLambdaTokens();
      DapApiAgent.notificationService.addNotification(
        IDs.USER_TOKEN_EXPIRED,
        Status.Warn,
        'Session Expired',
        'Your session has expired and you were logged out.'
      );
    };

    // watch for 419 and 401 responses to any api call, if received log the user out because the server session has expired/csrf token mismatch
    const AuthIntercept = require('superagent-intercept')(async (err, res) => {
      if (res && (res.status === 419 || res.status === 401)) {
        console.log(
          `received ${res.status} response, testing if session expired`
        );

        // if a 419 response is sent, check if the session is expired by making get call to /user and only if that also gets the 401
        // or 419 then it means the session is expired (letting callers to other API routes handle 419/401's themselves)
        const dapTokenFromLocalStorage = localStorage.getItem(
          LOCAL_STORAGE_KEYS.dapToken
        );
        if (dapTokenFromLocalStorage) {
          try {
            const response = await superagent
              .get('/api/user')
              .accept('json')
              .set('X-Requested-With', 'XMLHttpRequest')
              .set('x-csrf-token', dapTokenFromLocalStorage);

            if (
              response &&
              (response.status === 419 || response.status === 401)
            ) {
              sessionExpired();
            }
          } catch (error) {
            sessionExpired();
          }
        } else {
          sessionExpired();
        }
      }
    });

    DapApiAgent.agent.use(AuthIntercept);

    autorun(DapApiAgent.setCsrfHeader);
  }

  static setCsrfHeader() {
    const csrfHeader = 'x-csrf-token';
    if (DapApiAgent.tokenService.dapToken) {
      DapApiAgent.agent.set(csrfHeader, DapApiAgent.tokenService.dapToken);
    }
  }
}
