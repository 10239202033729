import { ReactiveList } from '@appbaseio/reactivesearch';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';
import { PrettyResultStats } from '@extensions/components/search-core';
import {
  IDs,
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import ITokenService from '@extensions/services/ITokenService';
import { styled } from '@mui/material/styles';
import { inject } from 'mobx-react';
import React from 'react';
import theme from '@extensions/services/Theme';

import SearchPagination from '@extensions/components/search-core/SearchPagination';

// App imports
type ReactiveListProps = React.ComponentProps<typeof ReactiveList>;

export interface IResultsListProps
  extends Omit<
    ReactiveListProps,
    | 'componentId'
    | 'scrollTarget'
    | 'pagination'
    | 'infiniteScroll'
    | 'paginationAt'
    | 'showResultStats'
    | 'URLParams'
    | 'render'
    | 'renderError'
    | 'renderPagination'
    | 'onData'
    | 'onPageChange'
    | 'onPageClick'
  > {
  onStatsChange?: (newStats: ResultStats) => void;
  notificationService: INotificationService;
  tokenService: ITokenService;
  resultsText?: string;
  statsClassName?: string;
}

export interface IResultsListState {
  localStoragePageSize: number;
}

export interface ResultStats {
  numberOfResults: number;
  numberOfPages: number;
  currentPage: number;
  time: number;
  displayedResults: number;
  hidden: number;
  promoted: number;
}

const StyledResultList = styled('ul')(() => ({
  listStyle: 'none',
  margin: '0',
  padding: '0',
  flex: '1',
}));

const StyledDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  marginTop: theme.spacing(3),
  fontFamily: theme.openSansFontFamily,
  '& li': {
    fontFamily: theme.openSansFontFamily,
  },
}));

@inject('notificationService', 'tokenService')
class SearchResults extends React.Component<
  IResultsListProps,
  IResultsListState
> {
  static defaultProps = {
    notificationService: undefined,
    tokenService: undefined,
  };
  constructor(props) {
    super(props);
    this.state = {
      localStoragePageSize: 0,
    };
  }

  addAria = () => {
    const sortOptionsElement = document.querySelector(
      'select[name="sort-options"]'
    );
    if (sortOptionsElement) {
      sortOptionsElement.setAttribute('aria-label', 'Sort By');
    }
  };

  componentDidMount() {
    this.addAria();
    if (window.localStorage.getItem('localStoragePageSize') === null) {
      window.localStorage.setItem('localStoragePageSize', '20');
      this.setState({
        localStoragePageSize: 20,
      });
    } else {
      this.setState({
        localStoragePageSize: JSON.parse(
          window.localStorage.getItem('localStoragePageSize')!
        ),
      });
    }
  }
  componentDidUpdate() {
    this.addAria();
  }

  handleError = (error) => {
    // This is code duplicated from DapApiAgent as reactive search had no way to let it use a custom ajax api
    if (error.status === 419 || error.status === 401) {
      this.props.tokenService.refreshDapToken();
      this.props.tokenService.clearLambdaTokens();
      this.props.notificationService.addNotification(
        IDs.USER_TOKEN_EXPIRED,
        Status.Warn,
        'Session Expired',
        'Your session has expired and you were logged out.'
      );
    } else {
      this.props.notificationService.addNotification(
        'search',
        Status.Error,
        `Failed to perform search`,
        error
      );
    }
  };

  getPageNumbers = (current: number, pageSize: number) => {
    window.localStorage.setItem(
      'localStoragePageSize',
      JSON.stringify(pageSize)
    );
    this.setState({
      localStoragePageSize: pageSize,
    });
  };

  render() {
    const {
      renderItem,
      onStatsChange,
      resultsText,
      renderResultStats,
      statsClassName,
      ...reactiveListProps
    } = this.props;
    const { localStoragePageSize } = this.state;
    return (
      <section aria-label="search results">
        <ReactiveList
          componentId="SearchResult"
          pagination={true}
          paginationAt="both"
          size={+localStoragePageSize}
          infiniteScroll={false}
          URLParams={true}
          render={({ loading, error, data, rawData }) => {
            if (loading) {
              return <CenteredCircularProgress />;
            }
            if (error) {
              this.handleError(error);
              return (
                <div>
                  Something went wrong! Please email{' '}
                  <a href={process.env.REACT_APP_TEAM_EMAIL}>our team</a> if
                  this issue persists.
                </div>
              );
            }
            rawData = rawData && rawData.hits && rawData.hits.hits;
            return (
              <StyledResultList>
                {renderItem !== undefined && data.map((d, i) => renderItem(
                  d.highlight && rawData && rawData.length > i
                    ? {
                      ...d,
                      ...rawData[i]._source
                    } : d
                ))}
              </StyledResultList>
            );
          }}
          renderPagination={({ totalPages, currentPage, setPage }) => {
            if (!Number.isFinite(totalPages)) {
              return null;
            }
            if (totalPages === 1) {
              return null;
            }
            return (
              <StyledDiv>
                <SearchPagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setPage={setPage}
                  pageSize={+localStoragePageSize}
                  getPageNumbers={this.getPageNumbers}
                />
              </StyledDiv>
            );
          }}
          showResultStats={true}
          renderResultStats={renderResultStats || ((resultStats) => {
            return (
              <PrettyResultStats
                resultsText={resultsText}
                stats={resultStats}
                className={statsClassName}
              />
            );
          })}
          onError={this.handleError}
          {...reactiveListProps}
        />
      </section>
    );
  }
}

export default SearchResults;
