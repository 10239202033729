import {
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material';
import React from 'react';
import filter from 'lodash/filter';
import styled from '@emotion/styled';
import SwaggerUI from 'swagger-ui-react';
import { inject, observer } from 'mobx-react';

import { IDatasetService } from '@extensions/services/IDatasetService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import 'swagger-ui-react/swagger-ui.css';
import User from '@extensions/models/User';
import DatasetModel from '@extensions/models/Dataset';
import DistributionType from '@extensions/models/DistributionType';

const ApiDiv = styled.div`
  margin-top: -25px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  padding-top: 5px !important;
`;

export interface ISwaggerMetadataProps {
  datasetService: IDatasetService;
  securityService: ISecurityService;
}

export interface ISwaggerMetadataState {
  definitionLink: string;
}

@inject('datasetService', 'securityService')
@observer
export default class SwaggerMetadata extends React.Component<
  ISwaggerMetadataProps,
  ISwaggerMetadataState
> {
  // We have to set a default props value for all props injected by mobx or else component will show a missing prop error when rendered
  static defaultProps = {
    datasetService: null,
    securityService: null,
  };

  constructor(props: ISwaggerMetadataProps) {
    super(props);
    const dataset: DatasetModel | null = this.props.datasetService.dataset;
    if (dataset) {
      const apidistributions = filter(dataset.distribution, {
        distributionType: DistributionType.API,
      });

      this.state = {
        definitionLink: apidistributions[0].describedBy || '',
      };
    }
    this.requestInterceptor = this.requestInterceptor.bind(this);
  }

  onChange = (event) => {
    this.setState({
      definitionLink: event.target.value,
    });
  };

  onSwaggerUiComplete = () => {
    // hide the authorize button to avoid confusion (we append key via request interceptor)
    const authorizeElement: any =
      document.getElementsByClassName('authorize')[0];
    authorizeElement.style.display = 'none';
  };

  requestInterceptor = (req) => {
    const user: User | null = this.props.securityService.user;

    // returning a promise is a work around for a known issue with the swagger UI react component
    // see https://github.com/swagger-api/swagger-ui/issues/4778
    const promise: any = new Promise((resolve, reject) => {
      // don't add the api key to the request to get the open API spec file:
      if (!req.url.startsWith(this.state.definitionLink)) {
        const url = new URL(req.url);
        url.searchParams.append('api_key', user ? user.apiKey : '');
        req.url = url.toString();
      }
      return resolve(req);
    });
    promise.url = req.url;
    return promise;
  };

  getApiTabs = (dataset: DatasetModel) => {
    const apidistributions = filter(dataset.distribution, {
      distributionType: DistributionType.API,
    });

    if (apidistributions && apidistributions.length > 1) {
      return (
        <StyledRadioGroup
          onChange={this.onChange}
          defaultValue={apidistributions[0].describedBy}
        >
          {apidistributions.map((distro) => (
            <FormControlLabel
              key={`${apidistributions.indexOf(distro)}`}
              value={distro.describedBy}
              control={<Radio />}
              label={distro.shortName
                ? distro.shortName
                : apidistributions.indexOf(distro) + 1}
            />
          ))}
        </StyledRadioGroup>
      );
    } else {
      return '';
    }
  };

  render() {
    // test with ds/mep.ds0
    const dataset: DatasetModel | null = this.props.datasetService.dataset;
    let content = <div />;
    if (dataset) {
      content = (
        <div>
          {this.getApiTabs(dataset)}
          <ApiDiv id="api-data">
            <SwaggerUI
              url={this.state.definitionLink}
              onComplete={this.onSwaggerUiComplete}
              requestInterceptor={this.requestInterceptor}
            />
          </ApiDiv>
        </div>
      );
    }

    return content;
  }
}
