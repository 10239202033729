import React from 'react';
import { css } from '@emotion/css';
import isEqual from 'lodash/isEqual';
import {
  faChevronRight,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import AddIcon from '@extensions/utils/AddIcon';

export type TreeNode = {
  /** Displayed as the label */
  displayName: string;

  /**
   * How many documents match this node.
   */
  count: number;

  /** The value to be passed to setQuery */
  value: string;

  children?: TreeNode[];
};

export interface ITreeSelectFilterProps {
  trees: TreeNode[];
  /** Update query after selection changes */
  setQuery: (values: any[]) => void;
  /** The values which are selected */
  checked: string[];
  /**
   * Hide the tree. This is necessary because this component uses
   * componentDidMount and componentDidUpdate to update the Elastic query
   * if users clear the filter from the filter summary. Therefore, this
   * component must be rendered even when there are no results, or it is loading.
   */
  hidden?: boolean;
  className?: string;
}

function toTreeItem(node: TreeNode) {
  return (
    <TreeItem
      nodeId={node.value}
      label={
        <span
          className={css`
            display: flex;
            width: 100%;
            justify-content: space-between;
          `}
        >
          <span>{node.displayName}</span>
          <span>{node.count}</span>
        </span>
      }
      key={node.value}
    >
      {node.children && node.children.map(childNode => toTreeItem(childNode))}
    </TreeItem>
  );
}

export default class TreeSelectFilter extends React.Component<
  ITreeSelectFilterProps
> {
  componentDidMount() {
    if (this.props.checked.length > 0) {
      this.props.setQuery(this.props.checked);
    }
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.checked, prevProps.checked)) {
      this.props.setQuery(this.props.checked);
    }
  }

  isTopLevelNode = (value: string): boolean => {
    const matchingNode = this.props.trees.find(tree => tree.value === value);
    return !!matchingNode;
  };

  handelSelection = (values: string[]) => {
    if (values.length === 1 && this.isTopLevelNode(values[0])) {
      // Top level nodes are not selectable
      return;
    }
    this.props.setQuery(values);
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return (
      <TreeView
        className={this.props.className}
        selected={this.props.checked}
        defaultExpandIcon={<AddIcon icon={faChevronRight} />}
        defaultCollapseIcon={<AddIcon icon={faChevronDown} />}
        multiSelect={true}
        onNodeSelect={(event, value) => this.handelSelection(value)}
      >
        {this.props.trees.map(tree => toTreeItem(tree))}
      </TreeView>
    );
  }
}
