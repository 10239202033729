import FolderZipIcon from '@mui/icons-material/FolderZip';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
  Select,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import {
  DataGrid,
  GridFilterModel,
  GridSortModel,
  GridFooterContainer,
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector
} from '@mui/x-data-grid';
import filesize from 'filesize';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import uniqBy from 'lodash/uniqBy';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import isBetween from 'dayjs/plugin/isBetween';
import React from 'react';
import styled from 'styled-components';
import theme from '@extensions/services/Theme';
import CloseIcon from '@mui/icons-material/Close';

import FileMetadataSchema, {
  DisplayType,
  DATE_TYPE_FILE_METADATA,
} from '@extensions/models/FileMetadataSchema';
import { IDatasetService } from '@extensions/services/IDatasetService';
import {
  IDs,
  INotificationService,
  Notification,
  Status,
} from '@extensions/services/INotificationService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import RangeFilter, {
  DataRange,
} from '@extensions/components/dataset/RangeFilter';
import { DOWNLOAD_LIMITS, DownloadOption } from '@extensions/models/FileOrder';


const Instructions = styled.p`
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1.55;
  margin-block-end: 0.5rem;
  margin-block-start: 0.5rem;
`;
const GridWithPadding = styled(Grid)`
  margin-bottom: 5px;
`;


export interface ISmallDataOrderProps {
  datasetService: IDatasetService;
  securityService: ISecurityService;
  notificationService: INotificationService;
  onOrderCancel: (event?: any) => void;
  visible: boolean;
}

export interface ISmallDataOrderState {
  currentDataSource: any[];
  selectedRowKeys: string[];
  selectedRows: any[];
  filenameFilter: string | null;
  filteredData: any[];
  tableFilters: Record<string, any[]>;
  rangeFilterValues: Record<string, DataRange>;
  open: boolean;
  anchorEl: any;
  startDate: any | null;
  endDate: any | null;
  sortModel: GridSortModel;
  filterModel: GridFilterModel;
  sortInfo: GridSortModel;
  hoverDate: any;
  pageSize: number;
}

@inject('datasetService', 'securityService', 'notificationService')
@observer
class SmallDataOrder extends React.Component<
  ISmallDataOrderProps,
  ISmallDataOrderState
> {
  // We have to set a default props value for all props injected by mobx or else component will show a missing prop error when rendered
  static defaultProps = {
    datasetService: null,
    securityService: null,
    notificationService: null,
    visible: false,
  };
  data: any[];
  columnInfos: FileMetadataSchema[];
  searchInput: any;
  populateTableReaction: any;
  selectAllTriggeredEvent: boolean;

  constructor(props: ISmallDataOrderProps) {
    super(props);
    this.selectAllTriggeredEvent = false;
    this.data = [];
    this.columnInfos = [];

    this.state = {
      currentDataSource: this.data,
      selectedRowKeys: [],
      selectedRows: [],
      filenameFilter: null,
      filteredData: this.data,
      tableFilters: {},
      rangeFilterValues: {},
      open: false,
      anchorEl: null,
      startDate: null,
      endDate: null,
      sortModel: [],
      filterModel: { items: [] },
      sortInfo: [],
      hoverDate: null,
      pageSize: 10,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.visible && !prevState.visible) {
      return {
        selectedRowKeys: [],
        selectedRows: [],
        startDate: nextProps.datasetService.dataset.dynamoDataBegins,
        endDate: nextProps.datasetService.dataset.dynamoDataEnds,
        visible: nextProps.visible,
      };
    }
    return { visible: nextProps.visible };
  }

  componentDidMount() {
    // populate the table with data once the dynamo file records have been retrieved
    this.props.datasetService.loadDatasetFiles();
    this.populateTableReaction = reaction(
      () => this.props.datasetService.dynamoFiles,
      (dynamoFiles: any | null) => {
        if (dynamoFiles && this.props.datasetService.dataset) {
          const downloadSection =
            this.props.datasetService.dataset.getDownloadDistribution();
          this.columnInfos = [];
          const tableFilters = {};
          // downloadSection could be undefined
          if (downloadSection) {
            downloadSection.fileMetadataSchema.forEach(
              (attr: FileMetadataSchema) => {
                // test if this attr should be displayed as a column in the table, if 'none' don't display it
                if (
                  attr.downloadDisplay &&
                  attr.downloadDisplay !== DisplayType.NONE
                ) {
                  this.columnInfos.push(attr);
                  tableFilters[attr.label] = null;
                }
              }
            );
          }

          const temp = cloneDeep(dynamoFiles);
          this.data = temp.map((file) => {
            file.key = file.Filename;
            return file;
          });

          // initialize table state to have all rows selected, no filters applied, but sorted by

          this.setState({
            selectedRowKeys: this.data
              ? this.data.map((datum) => datum.key)
              : [],
            currentDataSource: this.data,
            filteredData: this.data,
            selectedRows: this.data || [],
            tableFilters,
            rangeFilterValues: {},
          });
        }
      }
    );
  }

  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset's data order modal loads
    this.populateTableReaction();
  }

  placeOrder = (e, selectedRowKeys: string[]) => {
    let downloadType: string = '';
    if (e.key && e.key === 'windows') {
      downloadType = 'windowsScript';
    } else if (e.key && e.key === 'linux') {
      downloadType = 'linuxScript';
    } else {
      downloadType = 'zip';
    }

    if (this.props.securityService.userIsLoggedIn) {
      this.props.onOrderCancel();
      this.props.datasetService.placeOrder(downloadType, selectedRowKeys);
    }
  };

  onSelectChange = (selectedRowKeys, selectedRows) => {
    if (this.selectAllTriggeredEvent) {
      this.selectAllTriggeredEvent = false;
    } else {
      this.setState({ selectedRowKeys, selectedRows });
    }
  };

  // not getting called when filters are removed via 'x' button on tag or clear filters button (ant.d bug https://github.com/ant-design/ant-design/issues/26277 )
  // onTableChange = (pagination, filters, sorter, extra) => {
  //   // any time filters change, deselect anything previously selected
  //   if (!isEqual(filters, this.state.tableFilters)) {
  //     this.setState({
  //       selectedRowKeys: [],
  //       selectedRows: [],
  //     });
  //   }

  //   // extra.currentDataSource is the FILTERED rows, not necessarily the selected ones
  //   this.setState({
  //     currentDataSource: extra.currentDataSource,
  //     tableFilters: filters,
  //     sortInfo: sorter,
  //   });
  // };

  onTableChange = (sortModel: GridSortModel, filterModel: GridFilterModel) => {
    this.setState({ sortModel, filterModel });
  };

  handleStartDateChange = (date) => {
    const { endDate } = this.state;

    const startMoment = date ? dayjs(date).format('YYYYMMDD') : null;
    const endMoment = date ? dayjs(endDate).format('YYYYMMDD') : null;

    if (startMoment && endMoment && dayjs(startMoment, 'YYYYMMDD').isAfter(dayjs(endMoment, 'YYYYMMDD'), 'day')) {
      this.setState({ startDate: startMoment, endDate: null }, this.applyDateFilter);
    } else {
      this.setState({ startDate: startMoment }, this.applyDateFilter);
    }
  };

  handleEndDateChange = (date) => {
    const { startDate } = this.state;

    const endMoment = date ? dayjs(date).format('YYYYMMDD') : null;
    const startMoment = date ? dayjs(startDate).format('YYYYMMDD') : null;

    if (endMoment && startMoment && dayjs(endMoment, 'YYYYMMDD').isBefore(dayjs(startMoment, 'YYYYMMDD'), 'day')) {
      this.setState({ endDate: endMoment, startDate: null }, this.applyDateFilter);
    } else {
      this.setState({ endDate: endMoment }, this.applyDateFilter);
    }
  };

  applyDateFilter = () => {
    const { startDate, endDate } = this.state;
    const dataDateFormat = 'YYYYMMDD';
    let filteredData: any = [];

    if (startDate && endDate) {
      const startMoment = dayjs(startDate);
      const endMoment = dayjs(endDate);

      filteredData = filter(this.data, (datum) => {
        const date = dayjs(datum.date._i, dataDateFormat);
        return date.isValid() && date.isBetween(startMoment, endMoment, 'day', '[]');
      });
    } else {
      filteredData = this.data;
    }

    this.setState({ filteredData });
  };

  renderFilterTags(filterTags: any[]): React.ReactNode {
    return (
      <GridWithPadding justify="space-between" align="middle">
        <Grid item xs={21}>{filterTags}</Grid>
      </GridWithPadding>
    );
  }

  renderHighlightDay = (props) => {
    const { day, selected, ...other } = props;
    dayjs.extend(isBetween);
    const { startDate, endDate, hoverDate } = this.state;

    const start = startDate ? dayjs(startDate).startOf('day') : null;
    const end = endDate ? dayjs(endDate).endOf('day') : null;
    const hover = hoverDate ? dayjs(hoverDate).startOf('day') : null;
    const current = dayjs(day).startOf('day');

    const isStartOrEndDate = start && current.isSame(start, 'day') ||
      end && current.isSame(end, 'day');
    const isHighlighted = start && end && (current.isSame(start) || current.isSame(end) || current.isBetween(start, end, null, '[]'));
    const isHovered = hover && current.isAfter(hover) && current.isBefore(end, 'day') || current.isSame(hover, 'day');

    return (
      <PickersDay
        onDaySelect={
          function (day: any): void {
            throw new Error('Function not implemented.');
          } 
        }
        outsideCurrentMonth={false} 
        isFirstVisibleCell={false}
        isLastVisibleCell={false}
        {...other}
        day={day}
        selected={selected ? selected : false}
        onMouseEnter={() => this.setState({ hoverDate: day })}
        onMouseLeave={() => this.setState({ hoverDate: null })}
        sx={{
          ...(isHighlighted && {
            backgroundColor: '#e8f0e2',
            '&:hover': {
              backgroundColor: '#cae6bf',
            },
          }),
          ...(isStartOrEndDate && {
            backgroundColor: theme.palette.secondary.main,
            color: 'white',
          }),
          ...(isHovered && !isStartOrEndDate && {
            backgroundColor: 'rgb(202, 230, 191)',
            '&:hover': {
              backgroundColor: 'rgb(202, 230, 191)',
            },
          }),
          ...(selected && {
            '&&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
              },
            },
          }),
        }}
      />
    );
  };

  renderTimeFilter(begins: Date, ends: Date) {
    return (
      <GridWithPadding>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Instructions>
              Optionally reduce the download size and file count by selecting
              which files to download. Narrow down the files selected by entering
              a date range or filter by metadata fields below.
            </Instructions>
          </Grid>
          <Grid item xs={12} md={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label="Start Date"
                    onChange={this.handleStartDateChange}
                    slots={{ day: this.renderHighlightDay }}
                    value={dayjs(this.state.startDate)}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '35px',
                        marginTop: '15px',
                      },
                      '& .MuiInputLabel-root': {
                        marginTop: '5px'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="End Date"
                    onChange={this.handleEndDateChange}
                    value={dayjs(this.state.endDate)}
                    slots={{ day: this.renderHighlightDay }}
                    sx={{
                      '& .MuiInputBase-root': {
                        height: '35px',
                        marginTop: '15px'
                      },
                      '& .MuiInputLabel-root': {
                        marginTop: '5px'
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </GridWithPadding>
    );
  }

  makeFooter(selectedRowKeys: string[], totalSize: any) {
    const handleMenuClick = (event) => {
      this.setState({ anchorEl: event.currentTarget, open: true });
    };

    const handleMenuClose = () => {
      this.setState({ anchorEl: null, open: false });
    };

    const handleMenuItemClick = (e) => {
      this.placeOrder(e, selectedRowKeys);
      handleMenuClose();
    };

    // 1073741824 = 1 gig in bytes
    const maxSize =
      DOWNLOAD_LIMITS[DownloadOption.ZIP].totalSize ?? 3_000_000_000;
    const tooBig: boolean = totalSize > maxSize;

    const scriptMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        open={this.state.open}
        onClose={handleMenuClose}
      >
        <MenuItem key="windows" onClick={() => handleMenuItemClick({ key: 'windows' })}>
          Windows
        </MenuItem>
        <MenuItem key="linux" onClick={() => handleMenuItemClick({ key: 'linux' })}>
          MacOS/Linux
        </MenuItem>
      </Menu>
    );

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
          <Button
            variant="outlined"
            onClick={this.props.onOrderCancel}
            style={{ marginTop: '40px' }}
          >
            Cancel
          </Button>
          <div style={{ marginLeft: 'auto', textAlign: 'right', marginTop: '10px' }}>
            <Typography
              variant='h3'
              sx={{
                color: 'rgba(0, 0, 0, 0.65)',
                fontSize: theme.spacing(2.0475),
                fontFamily: theme.typography.fontFamily,
                fontWeight: 'normal',
                mb: theme.spacing(1),
              }}
            >
              How would you like to download the data? {tooBig && '(Zip option only for orders less than 3GB)'}
            </Typography>
            <div>
              <Button
                variant="contained"
                disabled={selectedRowKeys.length === 0 || tooBig}
                onClick={(e) => this.placeOrder(e, selectedRowKeys)}
                color="secondary"
                style={{ marginRight: '8px' }}
              >
                <FolderZipIcon />
                &nbsp;Zip
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleMenuClick}
                disabled={selectedRowKeys.length === 0}
              >
                &gt;_ Script <KeyboardArrowUpIcon />
              </Button>
              {scriptMenu}
            </div>
          </div>
        </div>
      </div>
    );
  }

  generateFilterTags(tableFilters) {
    const filters = toPairs(tableFilters);
    const filterTags: any[] = [];
    forEach(filters, (filterObject) => {
      if (filterObject[1]) {
        forEach(filterObject[1] as any[], (filterVal) =>
          filterTags.push(
            <Chip key={`${filterObject[0]}: ${filterVal}`} label={`${filterObject[0]}: ${filterVal}`}/>
          )
        );
      }
    });
    return filterTags;
  }

  generateColumns(sortInfo, tableFilters, filteredData) {
    // generate the table's columns using the dataset's metatdata's identifierSchema list of atts
    const totalColumns = this.columnInfos.length + 1;
    const columnFlexValue = totalColumns >= 4 ? 1 : 4 / totalColumns;
    const columns: any[] = this.columnInfos.map(
      (columnInfo: FileMetadataSchema) => {
        const columnProps: any = {
          title: columnInfo.label,
          dataIndex: columnInfo.dynamoFieldName,
          flex: columnFlexValue,
          minWidth: 100,
          key: columnInfo.label,
          sorter: (a, b) =>
            a[columnInfo.dynamoFieldName]
              .toString()
              .localeCompare(b[columnInfo.dynamoFieldName].toString()),
          sortOrder: sortInfo.columnKey === columnInfo.label && sortInfo.order,
          ...this.getColumnFilterProps(filteredData, columnInfo, tableFilters),
        };

        //default sort the date column ascending
        if (columnInfo.name === DATE_TYPE_FILE_METADATA.name) {
          columnProps.defaultSortOrder = 'ascend'; //this is NOT working so resorting to hard coding sortOrder to ascend if there is no sortInfo already set by the user
          columnProps.sortOrder = sortInfo.columnKey
            ? sortInfo.columnKey === columnInfo.label && sortInfo.order
            : 'ascend';
        }

        // do we need a special render prop if the column is a date/time type?
        // if (columnInfo.downloadDisplay !== DisplayType.) {
        // columnProps.render ...
        // }
        return columnProps;
      }
    );

    columns.push({
      title: 'File Size',
      key: 'File Size',
      dataIndex: 'size',
      sorter: (a, b) => a.size - b.size,
      width: 170,
      flex: columnFlexValue,
      minWidth: 170,
      sortOrder: sortInfo.columnKey === 'File Size' && sortInfo.order,
      renderCell: (params) => <span>{filesize(params.row.size)}</span>,
    });

    return columns;
  }

  // populate the filter drop down options by getting unique values from the data for each one
  getFilterOptions(filteredData: any[], dynamoFieldName: string) {
    const columnFilterOptions: any[] = uniqBy(
      filteredData,
      dynamoFieldName
    ).map((datum) => {
      return {
        text: datum[dynamoFieldName],
        value: datum[dynamoFieldName],
      };
    });

    return sortBy(columnFilterOptions, ['text']);
  }

  handleRangeFilter = ({
    columnInfo,
    confirm,
  }: {
    columnInfo: FileMetadataSchema;
    confirm: () => void;
  }) => {
    let filteredData: any[] = [];
    const range = this.state.rangeFilterValues[columnInfo.label];
    const fieldName = columnInfo.dynamoFieldName;
    if (range) {
      filteredData = this.data.filter(function (item) {
        return item[fieldName] >= range[0] && item[fieldName] <= range[1];
      });
    } else {
      filteredData = [...this.data];
    }

    this.setState(
      {
        filteredData: filteredData,
        selectedRowKeys: filteredData.map((datum) => datum.key),
        selectedRows: filteredData,
      },
      confirm
    );
  };

  handleRangeValueChange = ({
    columnInfo,
    newValue,
    reFilter = false,
    confirm,
  }: {
    columnInfo: FileMetadataSchema;
    newValue: DataRange;
    reFilter?: boolean;
    confirm: () => {};
  }) => {
    this.setState(
      (state) => ({
        rangeFilterValues: {
          ...state.rangeFilterValues,
          [columnInfo.label]: newValue,
        },
      }),
      () => {
        if (reFilter) {
          this.handleRangeFilter({ columnInfo, confirm });
        }
      }
    );
  };

  getColumnFilterProps = (
    filteredData: any[],
    columnInfo: FileMetadataSchema,
    tableFilters: Record<string, null>
  ) => {
    switch (columnInfo.downloadDisplay) {
      case DisplayType.LIST:
        return {
          filters: this.getFilterOptions(
            filteredData,
            columnInfo.dynamoFieldName
          ),
          onFilter: (value, record) =>
            record[columnInfo.dynamoFieldName].indexOf(value) === 0,
          filteredValue: tableFilters[columnInfo.label],
        };
      case DisplayType.RANGE:
        const minFile = minBy(this.data, columnInfo.dynamoFieldName);
        const maxFile = maxBy(this.data, columnInfo.dynamoFieldName);
        const [min, max] =
          filteredData && filteredData.length > 0
            ? [
              minFile[columnInfo.dynamoFieldName],
              maxFile[columnInfo.dynamoFieldName],
            ]
            : [0, 100];
        return {
          filterDropdown: ({ confirm }) => {
            return (
              <RangeFilter
                range={this.state.rangeFilterValues[columnInfo.label]}
                title={columnInfo.label}
                onRangeChange={(newValue) =>
                  this.handleRangeValueChange({ columnInfo, newValue, confirm })
                }
                onConfirm={() =>
                  this.handleRangeFilter({ columnInfo, confirm })
                }
                onClear={() =>
                  this.handleRangeValueChange({
                    columnInfo,
                    newValue: null,
                    reFilter: true,
                    confirm,
                  })
                }
                min={min}
                max={max}
              />
            );
          },
          onFilter: (value, record) => console.log(value, record),
        };
      case DisplayType.DATE:
      case DisplayType.TIME:
      case DisplayType.NO_FILTER:
      case DisplayType.NONE:
      default:
        return {};
    }
  };

  render() {
    const dataset = this.props.datasetService.dataset;

    const { selectedRowKeys, selectedRows, filteredData } =
      this.state;
    const tableFilters = this.state.tableFilters || {};
    const sortInfo = this.state.sortInfo || {};

    const columns = this.generateColumns(sortInfo, tableFilters, filteredData);

    // add up the total download size for the selected files:
    const totalSize = reduce(selectedRows, (sum, row) => sum + row.size, 0);

    // render the applied filters as tags, when the 'x' is clicked that filter will be removed
    const filterTags = this.generateFilterTags(tableFilters);
    const modalFooter = this.makeFooter(selectedRowKeys, totalSize);

    const notification: Notification | null =
      this.props.notificationService.getNotification(IDs.GET_DATASET_FILES);
    let tableLoading = false;
    if (notification && notification.status === Status.BackgroundRunning) {
      tableLoading = true;
    }

    const dataGridColumns = columns.map(column => ({
      field: column.dataIndex,
      headerName: column.title,
      flex: 1,
      minWidth: column.minWidth,
      ...column
    }));

    const dataGridRows = filteredData.map((item, index) => ({
      id: item.key || index,
      ...item
    }));

    const handleSelectionModelChange = (newSelectionModel) => {
      this.setState({ selectedRowKeys: newSelectionModel });

      const newSelectedRows = newSelectionModel.map((id) =>
        this.state.filteredData.find((row) => String(row.key) === String(id))
      ).filter(Boolean);

      this.setState({ selectedRows: newSelectedRows });
    };

    const handleSortModelChange = (newSortModel: GridSortModel) => {
      this.setState({ sortModel: newSortModel });
    };

    const handleFilterModelChange = (newFilterModel: GridFilterModel) => {
      this.setState({ filterModel: newFilterModel });
    };

    const customFooter = () => {
      const apiRef = useGridApiContext();
      const selectedRows = apiRef.current.getSelectedRows();

      const totalSizeOfSelectedRows = Array.from(selectedRows.values()).reduce((totalSize, rowData) => {
        return totalSize + rowData.size;
      }, 0);

      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);

      const handleRowsPerPageChange = (event) => {
        this.setState({ pageSize: event.target.value });
        apiRef.current.setPageSize(event.target.value);
      };

      return (
        <GridFooterContainer sx={{ justifyContent: 'space-between' }}>
          <div style={{ padding: '0 20px', fontSize: theme.spacing(1.750), color: theme.palette.secondary.main }}>
            {selectedRows.size} rows selected ({filesize(totalSizeOfSelectedRows)})
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant='outlined'
              value={this.state.pageSize}
              onChange={handleRowsPerPageChange}
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
            >
              {["10", "20", "30", "40", "50"].map((size) => (
                <MenuItem key={size} value={size}>
                  {size + ' / page'}
                </MenuItem>
              ))}
            </Select>
            <Pagination
              color="primary"
              showFirstButton
              showLastButton
              page={page + 1}
              count={pageCount}
              boundaryCount={1}
              siblingCount={1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
          </div>
        </GridFooterContainer>
      );
    }

    if (dataset) {
      return (
        <Dialog
          open={this.props.visible}
          onClose={this.props.onOrderCancel}
          aria-labelledby="dialog-title"
          maxWidth="md"
          PaperProps={{
            style: {
              zIndex: 1300
            },
          }}
        >
          <DialogTitle id="dialog-title"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              fontSize: theme.spacing(2),
              color: theme.palette.secondary.main,
            }}
          >
            {`Select ${dataset.shortName} Files`}
            <IconButton
              onClick={this.props.onOrderCancel}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[700],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {dataset.dynamoDataBegins &&
              dataset.dynamoDataEnds &&
              this.renderTimeFilter(
                dataset.dynamoDataBegins,
                dataset.dynamoDataEnds
              )}
            {filterTags && this.renderFilterTags(filterTags)}
            <DataGrid
              rows={dataGridRows}
              columns={dataGridColumns}
              checkboxSelection
              onRowModesModelChange={handleSelectionModelChange}
              onRowSelectionModelChange={handleSelectionModelChange}
              loading={tableLoading}
              sortModel={this.state.sortModel}
              onSortModelChange={handleSortModelChange}
              filterModel={this.state.filterModel}
              onFilterModelChange={handleFilterModelChange}
              disableColumnSelector
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: this.state.pageSize,
                  }
                }
              }}
              sx={{
                height: '469px',
                width: '100%',
                minWidth: '800px',
                '& .MuiCheckbox-root': {
                  color: theme.palette.grey[600],
                },
                '& .MuiCheckbox-root.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: theme.palette.secondary.main,
                  bgcolor: '#fafafa'
                },
                '& .MuiDataGrid-row.Mui-selected': {
                  backgroundColor: 'rgb(202, 230, 191)',
                  '&:hover': {
                    backgroundColor: 'rgb(202, 230, 191)',
                  },
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontSize: '14px'
                },
                '& .MuiDataGrid-cell': {
                  fontSize: '14px'
                },
                '& .MuiDataGrid-iconButtonContainer': {
                  visibility: 'visible',
                },
                '& .MuiDataGrid-sortIcon': {
                  opacity: 'inherit !important',
                },
              }}
              slots={{
                footer: customFooter,
              }}
            />
            {modalFooter}
          </DialogContent>
        </Dialog>
      );
    }
  }
}

export default SmallDataOrder;
