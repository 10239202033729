import FileMetadataSchema from '@extensions/models/FileMetadataSchema';
import DistributionType from '@extensions/models/DistributionType';

// Generated by https://quicktype.io
//
// To change quicktype's target language, run command:
//
//   "Set quicktype target language"

/**
 * A distribution for a dataset within a project catalog
 */
export default class Distribution {
  static distTypeToAccessMethod = (dist: string): string => {
    const mapping = {
      [DistributionType.API]: 'API',
      [DistributionType.ByRequest]: 'External',
      [DistributionType.DownloadExternal]: 'Download',
      [DistributionType.DownloadDap]: 'Download',
    };
    return mapping[dist];
  };
  /**
   * URL providing indirect access to the distribution.
   */
  accessURL?: null | string;

  /**
   * URL to the data dictionary for the distribution found at the downloadURL or accessURL.
   */
  describedBy?: null | string;

  /**
   * Distribution Type
   */
  distributionType: string;
  /**
   * URL providing direct access to a downloadable file of a dataset.
   */
  downloadURL?: string;
  /**
   * Structural description of this distribution object's identifier.
   */
  fileMetadataSchema: FileMetadataSchema[] = [];
  /**
   * Concise name for this object; should be human-readable; may be munged for use in UI.
   */
  shortName: string;
  sampleFileName: string | undefined;
  identifier: string;
  title?: string;
  description?: string;

  constructor(data) {
    this.distributionType = data.distributionType;
    this.describedBy = data.describedBy;
    this.shortName = data.shortName;
    this.identifier = data.identifier;
    this.accessURL = data.accessURL;
    this.downloadURL = data.downloadURL;
    this.title = data.title;
    this.description = data.description;
    if (data.identifierSchema && data.identifierSchema.atts) {
      this.fileMetadataSchema = data.identifierSchema.atts.map(
        (attr) => new FileMetadataSchema(attr)
      );
      if (data.identifierSchema.sampleFileName) {
        this.sampleFileName = data.identifierSchema.sampleFileName;
      }
    }
  }
}
