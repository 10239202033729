import { OperatingSystem } from '@extensions/models/FileOrderGroup';

export default function guessOS(): OperatingSystem | null {
  if (navigator.appVersion.indexOf('Win') !== -1) {
    return OperatingSystem.WINDOWS;
  }
  if (navigator.appVersion.indexOf('Mac') !== -1) {
    return OperatingSystem.MAC;
  }
  if (navigator.appVersion.indexOf('Linux') !== -1) {
    return OperatingSystem.LINUX;
  }
  return null;
}
