import React from 'react';
import * as yup from 'yup';
import { inject } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Link as MUILink,
  Paper,
  Typography
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { ISecurityService } from '@extensions/services/ISecurityService';

const StyledForgotPasswordText = styled(MUILink)(({theme}) => (({
  '&:hover': {
    textDecoration: 'underline',
  },
  float: 'right',
  paddingTop: theme.spacing(1.25),
  textDecoration: 'none',
  cursor: 'pointer'
})));

const StyledMFACheckbox = styled(Checkbox)(({theme}) => ({
  color: theme.palette.grey[700]
}));

const StyledFormControlLabel = styled(FormControlLabel)(({theme}) => ({
  '.MuiFormControlLabel-label': {
    color: theme.palette.grey[700]
  },
}));

export interface RouteComponentPropsWithState extends RouteComponentProps {
  location: any;
}

export interface ISignInFormProps extends RouteComponentPropsWithState {
  securityService?: ISecurityService;
}

export interface ISignInFormState {
  forgot: boolean;
  mfaEnabled: boolean;
}

@inject('securityService')
class SignInForm extends React.Component<ISignInFormProps, ISignInFormState> {
  validationSchema: any;

  constructor(props: ISignInFormProps) {
    super(props);
    this.state = {
      forgot: false,
      mfaEnabled: false,
    };
  }

  onForgotPasswordSubmit = (values, { setSubmitting }) => {
    if (this.props.securityService) {
      this.props.securityService.forgotPassword(values.email);
    }
    setSubmitting(false);
  };

  onLoginSubmit = (values, { setSubmitting }) => {
    if (this.props.securityService) {
      if (this.state.mfaEnabled) {
        this.props.securityService.loginWithMfa(
          values.email,
          values.password,
          values.mfaCode
        );
      } else {
        this.props.securityService.login(
          values.email,
          values.password
        );
      }

      setSubmitting(false);
      this.setState({
        forgot: false,
      });
    }
  };

  renderEmailField = () => {
    return (
      <Field
        component={TextField}
        name="email"
        type="email"
        label="Your Email"
        variant="outlined"
        fullWidth
        id="email-input"
        margin="normal"
        size="small"
      />
    );
  };

  renderForgotPassword() {
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={yup.object({
          email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        })}
        onSubmit={this.onForgotPasswordSubmit}
      >
        {() => (
          <Form>
            {this.renderEmailField()}
            <Button color="secondary" variant="contained" type="submit">
              Send Reset Request
            </Button>
          </Form>
        )}
      </Formik>
    );
  }

  renderUseMfaCheckbox = () => {
    if (process.env.REACT_APP_MFA_ENABLED === 'true') {
      return (
        <StyledFormControlLabel
          control={
            <StyledMFACheckbox
              checked={this.state.mfaEnabled}
              onChange={e => this.setState({ mfaEnabled: e.target.checked })}
              name="useMfa"
            />
          }
          label="Use Multifactor Authentication (MFA)"
        />
      );
    } else {
      return null;
    }
  };

  renderMfaCodeField = () => {
    if (this.state.mfaEnabled) {
      return (
        <Paper variant="outlined" style={{ padding: 15 }}>
          <Field
            component={TextField}
            name="mfaCode"
            type="text"
            label="Verification Code"
            id="verification-code-input"
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
            style={{ marginTop: 0 }}
          />
          <span>
            <strong>NOTE:</strong> A second-factor, 6-digit verification code is
            required in order to access proprietary data and other sensitive
            information. This form of authentication can be set up in your user
            profile.
          </span>
        </Paper>
      );
    } else {
      return null;
    }
  };

  getValidationSchema(): any {
    const emailSchema = yup
      .string()
      .email('Enter a valid email')
      .required('Email is required');

    const passwordSchema = yup.string().required('Password is required');

    let schema;
    if (this.state.mfaEnabled) {
      schema = yup.object({
        email: emailSchema,
        password: passwordSchema,
        mfaCode: yup.string().required('MFA token is required'),
      });
    } else {
      schema = yup.object({
        email: emailSchema,
        password: passwordSchema,
      });
    }
    return schema;
  }

  renderSigninForm() {
    return (
      <React.Fragment>
        <Formik
          initialValues={{
            email: '',
            password: '',
            mfaCode: '',
          }}
          validationSchema={this.getValidationSchema()}
          onSubmit={this.onLoginSubmit}
        >
          {() => (
            <Form>
              {this.renderEmailField()}
              <Field
                component={TextField}
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                fullWidth
                id="password-input"
                margin="normal"
                size="small"
                autoComplete="off"
              />
              {this.renderUseMfaCheckbox()}
              {this.renderMfaCodeField()}
              <br />
              <Button color="secondary" variant="contained" type="submit">
                Log In
              </Button>
              <StyledForgotPasswordText
                onClick={e => this.setState({ forgot: true })}
              >
                <Typography variant='body1'>
                  Forgot Password?
                </Typography>
              </StyledForgotPasswordText>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  }

  render() {
    if (this.state.forgot) {
      return this.renderForgotPassword();
    }
    return this.renderSigninForm();
  }
}

export default withRouter(SignInForm);
