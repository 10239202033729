import User from '@extensions/models/User';

export default class Group {
  name: string;
  label: string;
  users: User[];

  constructor(data) {
    this.name = data.name;
    this.label = data.label;
    this.users = [];
    if (data.users) {
      this.users = data.users.map(user => new User(user));
    }
  }
}
