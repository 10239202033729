import React from 'react';
import { inject, observer } from 'mobx-react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import TextList from '@extensions/components/core/TextList';
import { PubOrganization } from '@extensions/models/Publication';

const StyledDiv = styled('div')(({
  marginTop: '1rem'
}));

export interface ISponsorsListProps {
  className?: string;
  sponsors: PubOrganization[];
}

export interface ISponsorsListState { }

@observer
export class SponsorsList extends React.Component<
  ISponsorsListProps,
  ISponsorsListState
> {
  render() {
    const { sponsors } = this.props;
    let body: React.ReactNode;
    if (sponsors.length === 0) {
      body = <Typography>No Sponsors Provided</Typography>;
    } else {
      body = <TextList items={sponsors.map((sponsor) => sponsor.name)} />;
    }
    return (
      <StyledDiv>
        <Typography variant="h2">Sponsors</Typography>
        {body}
      </StyledDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(SponsorsList);
