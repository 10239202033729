import { MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';

import { ISecurityService } from '@extensions/services/ISecurityService';
import { INotificationService } from '@extensions/services/INotificationService';
import ILayoutService from '@extensions/services/ITokenService';
import { ITokenService } from '@extensions/services/ITokenService';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { ICartService } from '@extensions/services/ICartService';
import { IContactUsService } from '@extensions/services/IContactUsService';
import { IMetadataService } from '@extensions/services/IMetadataService';
import { IMetricsService } from '@extensions/services/IMetricsService';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { IProjectService } from '@extensions/services/IProjectService';
import { IMembershipService } from '@extensions/services/IMembershipService';
import { IFaqService } from '@extensions/services/IFaqService';
import { ICachingService } from '@extensions/services/ICachingService';
import { IUploadService } from '@extensions/services/IUploadService';
import { IPublicationService } from '@extensions/services/IPublicationService';
import { INewsletterService } from '@extensions/services/INewsletterService';

export function useService(serviceName: string) {
  const context = useContext(MobXProviderContext);
  return context[serviceName];
}

export function useSecurityService() {
  return useService('securityService') as ISecurityService;
}

export function useNotificationService() {
  return useService('notificationService') as INotificationService;
}

export function useLayoutService() {
  return useService('layoutService') as ILayoutService;
}

export function useTokenService() {
  return useService('tokenService') as ITokenService;
}

export function useHistoryService() {
  return useService('historyService') as IHistoryService;
}

export function useCartService() {
  return useService('cartService') as ICartService;
}

export function useContactUsService() {
  return useService('contactUsService') as IContactUsService;
}

export function useMetadataService() {
  return useService('metadataService') as IMetadataService;
}

export function useMetricsService() {
  return useService('metricsService') as IMetricsService;
}

export function useDatasetService() {
  return useService('datasetService') as IDatasetService;
}

export function useProjectService() {
  return useService('projectService') as IProjectService;
}

export function useMembershipService() {
  return useService('membershipService') as IMembershipService;
}

export function useFaqService() {
  return useService('faqService') as IFaqService;
}

export function useCachingService() {
  return useService('cachingService') as ICachingService;
}

export function useUploadService() {
  return useService('uploadService') as IUploadService;
}

export function usePublicationService() {
  return useService('publicationService') as IPublicationService;
}

export function useNewsletterService() {
  return useService('newsletterService') as INewsletterService;
}
