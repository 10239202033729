import fileSize from 'filesize';
import tinycolor from '@ctrl/tinycolor';
import { formatNumber } from '@extensions/utils/format';

export default class MetricsUtils {
  static formatDownloadsTooltipLabel = (label) => {
    if (!label) {
      return '';
    }

    return `${label.substring(4)}/${label.substring(0, 4)}`;
  };

  static getBarColor = (theme: any) => {
    let barColor: string = '#' + tinycolor(theme.palette.primary.main).toHex();
    if (theme.palette.metrics) {
      barColor = '#' + tinycolor(theme.palette.metrics.main).toHex();
    }
    return barColor;
  };

  static formatXAxisTick = (value, xAxisInterval) =>
    xAxisInterval === 11
      ? value.substring(0, 4)
      : `${value.substring(4)}/${value.substring(0, 4)}`;

  static formatYAxisTick = (tickValue) => {
    if (typeof tickValue === 'number') {
      if (tickValue === 0) {
        return '';
      }
      return formatNumber(tickValue);
    }
    return tickValue;
  };

  static formatYAxisFileSizesTick = (value) =>
    value === 0 ? '' : fileSize(value, { round: 0 });

  static formatFileCountsTooltip = (value, name, props) => {
    return [value, 'Files'];
  };

  static formatFileSizesTooltip = (value, name, props) => {
    return [
      fileSize(value, { output: 'array' })[0],
      fileSize(value, { output: 'array' })[1],
    ];
  };
}
