import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableFooter,
} from '@mui/material';
import moment from 'moment';
import filesize from 'filesize';
import { runInAction } from 'mobx';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';

import { IDatasetService } from '@extensions/services/IDatasetService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import FileOrder from '@extensions/models/FileOrder';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import DownloadButton from '@extensions/components/user/DownloadButton';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

import AddIcon from '@extensions/utils/AddIcon';
import { faLock } from '@fortawesome/free-solid-svg-icons';

const CustomTableContainer = ({ ...props }) => (
  <TableContainer {...props} />
)

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  color: theme.palette.error.dark,
  marginLeft: '0.3rem',
}));

const StyledTableContainer = styled(CustomTableContainer)({
  marginTop: '2rem',
});

const StyledTableCell = styled(TableCell)(({
  fontSize: '14px'
}));

export interface IDataOrdersProps {
  className?: string;
  datasetService: IDatasetService;
  securityService: ISecurityService;
}

export interface IDataOrdersState {
  pageNum: number;
  pageSize: number;
  orders: FileOrder[] | null;
  page: [];
  isLoading: boolean;
}

@observer
export class DataOrders<
  PropsExtension extends object = {}
> extends React.Component<
  IDataOrdersProps & PropsExtension,
  IDataOrdersState
> {
  static defaultProps = {
    datasetService: undefined,
    securityService: undefined,
    rows: null,
  };
  dateFormat = 'YYYY/MM/DD';
  pageSizeOptions = [5, 10, 25];

  constructor(props) {
    super(props);

    this.state = {
      pageNum: 0,
      pageSize: this.pageSizeOptions[0],
      orders: null,
      page: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    await this.fetchOrdersAndDatasets();
  }

  async fetchOrdersAndDatasets() {
    await this.props.datasetService.getFileOrders();
    if (this.props.datasetService.orders !== null) {
      let ordersList = this.props.datasetService.orders;
      const datasetNames: string[] = ordersList.map(order => order.datasetName)
      await this.fetchOrdersMfaInfo(datasetNames, ordersList)
      this.setState({
        isLoading: false,
      });
    }
  }

  async fetchOrdersMfaInfo(datasetNameFromOrdersList: string[], ordersList: FileOrder[]): Promise<any> {
    const queryStrings = datasetNameFromOrdersList.map((dataset) => {
      return {
        query_string: {
          query: dataset.slice(dataset.indexOf('/') + 1),
          fields: ["name"],
          default_operator: "and"
        }
      }
    });
    try {
      const datasetSearchResponse = await DapApiAgent.agent
        .post('/api/datasets/_msearch?')
        .send(
          JSON.stringify({ preference: "SearchResult" }) + "\n" +
          JSON.stringify({
            query: {
              bool: {
                should: queryStrings
              }
            },
            size: 500,
          })
        );
      runInAction(() => {
        const ordersIsMfa: { [key: string]: boolean } = {}
        const resList = datasetSearchResponse.body.responses[0].hits.hits.map(order => order._source)
        resList.forEach(order => {
          ordersIsMfa[order.name] = order.dapAccessRequiresMfa
        })
        ordersList.forEach(order => {
          order.isMfaRestricted = ordersIsMfa[order.datasetName]
        })
        this.setState({
          orders: ordersList,
        });
      })
    } catch (er) {
      console.log('er:', er);
    }
  }

  setPageNum(newPageNum: number) {
    this.setState({
      pageNum: newPageNum,
    });
  }

  setPageSize(newSize: number) {
    this.setState({
      pageSize: newSize,
    });
  }

  getDatasetContent = (order: FileOrder): React.ReactNode => (
    <>
      <DownloadButton row={order} />
      <Link 
        style={{ textDecoration: 'none' }} 
        to={`/ds/${order.datasetName}`}
      >
        {order.datasetName}
      </Link>
    </>
  );

  getDatasetMfa(isMfa: boolean | null) {
    if (isMfa) {
      return (
        <StyledAddIcon
          icon={faLock}
          title="Data is [MFA] restricted"
        />
      )
    } else {
      return null
    }
  }

  getFileCountContent = (order: FileOrder): React.ReactNode => order.fileCount;

  getTotalSizeContent = (order: FileOrder): React.ReactNode =>
    filesize(order.totalSize);

  getCreatedContent = (order: FileOrder): React.ReactNode =>
    moment(moment.unix(order.created), this.dateFormat).calendar();

  getBodyRow = (order: FileOrder): React.ReactNode => (
    <TableRow key={order.id}>
      <StyledTableCell component="th" scope="row">
        {this.getDatasetContent(order)}
        {this.getDatasetMfa(order.isMfaRestricted)}
      </StyledTableCell>
      <StyledTableCell>{this.getFileCountContent(order)}</StyledTableCell>
      <StyledTableCell>{this.getTotalSizeContent(order)}</StyledTableCell>
      <StyledTableCell>{this.getCreatedContent(order)}</StyledTableCell>
    </TableRow>
  );
  getHeaderRow = (): React.ReactNode => (
    <TableRow>
      <TableCell>Dataset</TableCell>
      <TableCell>File Count</TableCell>
      <TableCell>Total Size</TableCell>
      <TableCell>Created</TableCell>
    </TableRow>
  );
  render() {
    const { orders, pageNum, pageSize, isLoading } = this.state;
    return (
      <StyledTableContainer
        component={Paper}
      >
        <Table aria-label="my orders" size="small">
          <TableHead>{this.getHeaderRow()}</TableHead>
          <TableBody>
            {
              isLoading
                ? <TableRow><TableCell key='spinner' colSpan={4}><CenteredCircularProgress /></TableCell></TableRow>
                : orders && orders
                  .slice(pageNum * pageSize, pageNum * pageSize + pageSize)
                  .map(this.getBodyRow)
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx={{
                  '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                    fontSize: '14px',
                  }
                }}
                rowsPerPageOptions={this.pageSizeOptions}
                count={orders ? orders.length : 0}
                rowsPerPage={pageSize}
                page={pageNum}
                onPageChange={(event, pageNum) => this.setPageNum(pageNum)}
                onRowsPerPageChange={(event) =>
                  this.setPageSize(parseInt(event.target.value, 10))
                }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </StyledTableContainer>
    );
  }
}

export default inject((store: any) => ({
  datasetService: store.datasetService,
  securityService: store.securityService,
}))(DataOrders);