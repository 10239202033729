import React from 'react';
import filesize from 'filesize';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import {
  faCloudDownloadAlt,
  faDatabase,
  faEye,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import { styled } from '@mui/material/styles';

import Stat from '@extensions/components/metrics/Stat';
import IHasMetrics from '@extensions/models/IHasMetrics';

const StyledRootDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gridTemplateColumns: 'auto auto', //2 columns of equal width, new rows added as rows fill
  [theme.breakpoints.down('sm')]: {
    display: 'grid',
  },
  [theme.breakpoints.down('md')]: {
    display: 'grid'
  },
  gridGap: theme.spacing(1),
  justifyContent: 'space-evenly',

  marginBottom: '.75rem',
  padding: '.75rem 1rem',
  border: `1px solid ${theme.palette.grey['500']}`,
  borderLeft: 'none',
  borderRight: 'none',
}));

const StyledItemContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: theme.spacing(1),
  borderLeft: 'none',
  borderRight: 'none',
  marginBottom: '0.75rem',
  justifyContent: 'space-evenly',
  gridTemplateColumns: 'auto auto',
}));

interface IStatsBarProps {
  item: IHasMetrics;
}

@observer
export class StatsBar<
  ExtraProps extends object = {}
> extends React.Component<IStatsBarProps & ExtraProps> {
  renderDownloadStats = () => {
    const { item } = this.props;

    const fileCount = item.getOrderFileCount();
    const byteCount = item.getOrderByteCount();

    const orderCount = item.getOrderCount();

    const orderStat = (orderCount) ? (
      <Stat
        variant="beside"
        value={orderCount ? orderCount.toLocaleString() : '-'}
        description="Requests"
        icon={faCloudDownloadAlt}
        iconSize={'2x'}
        fixedWidth
      />
    ) : null;

    const fileCountStat = fileCount ? (
      <Stat
        variant="beside"
        value={fileCount.toLocaleString()}
        description="Files Requested"
        icon={faFileDownload}
        iconSize={'2x'}
        fixedWidth
      />
    ) : null;

    const byteCountStat = byteCount ? (
      <Stat
        variant="beside"
        value={filesize(byteCount)}
        description="Requested"
        icon={faDatabase}
        iconSize={'2x'}
        fixedWidth
      />
    ) : null;

    return (
      <>
        {orderStat}
        {fileCountStat}
        {byteCountStat}
      </>
    );
  };

  renderViewStats = () => {
    const { item } = this.props;
    return (
      <Stat
        variant="beside"
        value={item.getViews() > 0 ? item.getViews().toLocaleString() : '-'}
        description="Views"
        icon={faEye}
        iconSize={'2x'}
        fixedWidth
      />
    );
  };

  renderStats = (): React.ReactNode => {
    return (
      <StyledItemContainer>
        {this.renderViewStats()}
        {this.renderDownloadStats()}
      </StyledItemContainer>
    );
  };

  @computed
  get body(): React.ReactNode {
    return (
      <StyledRootDiv>
        {this.renderStats()}
      </StyledRootDiv>
    );
  }

  render() {
    return this.body;
  }
}

export default StatsBar;
