import {
  Alert,
  AlertTitle,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import filesize from 'filesize';
import { observe, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import theme from '@extensions/services/Theme';
import {
  IDs,
  INotificationService,
  Notification,
  Status,
} from '@extensions/services/INotificationService';
import { IAppService } from '@extensions/services/IAppService';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import Link from '@extensions/components/core/Link';
import DatasetModel from '@extensions/models/Dataset';
import FileUpload from '@extensions/models/FileUpload';
import Distribution from '@extensions/models/Distribution';
import FileMetadataSchema from '@extensions/models/FileMetadataSchema';
import FileUploadDropZone from '@extensions/components/dataset/admin/FileUploadDropZone';
import EditUploadedFilesTable from '@extensions/components/dataset/admin/EditUploadedFilesTable';

const colors = ['#d4c4fb', '#bed3f3', '#fef3bd', '#bedadc', '#c4def6', '#c1e1c5', '#fad0c3'];

const StyledCode = styled('code')(({
  fontSize: '13px',
  marginLeft: "5px"
}));

const StyledColorCode = styled('code')(({
  //need this styled component to dynamically assign color
}));

const StyledSpan = styled('span')(({
  fontSize: '140%',
  fontWeight: 'bold',
  color: 'black',
  padding: '3px',
}));

const ManageStyle = styled('div')(({
  '.dropzone .dz-message': {
    margin: '10px 0',
  },

  '.ant-row': {
    padding: '5px !important',
  },

  '.ant-row p': {
    fontSize: '16px !important',
  },

  '.ant-card-head-title': {
    color: 'rgba(0, 0, 0, 0.65)',
  },

  '.ant-descriptions-title': {
    marginBottom: '5px',
    fontWeight: 'normal',
  },

  '.ant-typography code': {
    background: 'none',
    border: 'none',
  },

  'code.format': {
    fontWeight: 'bold',
  },

  '.datasetname': {
    fontSize: '11px',
  },

  '.color0': {
    background: '#d4c4fb',
  },
  '.color1': {
    background: '#bed3f3',
  },
  '.color2': {
    background: '#fef3bd',
  },
  '.color3': {
    background: '#bedadc',
  },
  '.color4': {
    background: '#c4def6',
  },
  '.color5': {
    background: '#c1e1c5',
  },
  '.color6': {
    background: '#fad0c3',
  },
  '.colorError': {
    backgroundColor: '#fff1f0',
    border: '1px solid #ffa39e',
    padding: '3px',
  },

  '.dot': {
    fontSize: '140%',
    fontWeight: 'bold',
    color: 'black',
    padding: '3px',
  },

  '.MuiAlert-standardWarning': {
    border: '1px solid #ffe58f',
    backgroundColor: '#fffbe6',
    color: 'rgba(0, 0, 0, 0.65)',
  },
  '.MuiAlert-standardInfo': {
    border: '1px solid #91d5ff',
    backgroundColor: '#e6f7ff',
    color: 'rgba(0, 0, 0, 0.65)',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(({
  '&.MuiAccordionSummary-root': {
    display: 'flex',
    color: theme.palette.primary.dark,
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem'
  },
}));

export interface IManageFilesProps {
  appService: IAppService;
  datasetService: IDatasetService;
  securityService: ISecurityService;
  notificationService: INotificationService;
}

export interface IManageFilesState {
  activePanels: string[];
  showSubmittedFilesAlert: boolean;
}

@inject(
  'appService',
  'datasetService',
  'securityService',
  'notificationService'
)
@observer
export default class ManageFiles extends React.Component<
  IManageFilesProps,
  IManageFilesState
> {
  // We have to set a default props value for all props injected by mobx or else component will show a missing prop error when rendered
  static defaultProps = {
    appService: null,
    datasetService: null,
    securityService: null,
    notificationService: null,
  };
  filesUploadedReaction: any;
  filesSubmittedReaction: any;

  constructor(props: IManageFilesProps) {
    super(props);

    this.state = {
      activePanels: ['upload'],
      showSubmittedFilesAlert: false,
    };
  }

  componentDidMount() {
    this.props.datasetService.loadUploadedFilesIfNeeded();

    observe(this.props.datasetService, 'uploads', (change: any) => {
      try {
        // if uploads go from null to any length of files, expand both panels
        if (change.oldValue === null && change.newValue.length > 0) {
          this.setState({ activePanels: ['upload', 'pending'] });
        }

        // if uploads go from zero to more than zero, a file was just uploaded, expand both panels
        if (
          change.oldValue.length === 0 &&
          change.newValue.length > 0 &&
          this.state.activePanels.length === 1 &&
          this.state.activePanels[0] === 'upload'
        ) {
          this.setState({ activePanels: ['upload', 'pending'] });
        }
      } catch (error) {
        // ignore any errors as the old/new values in change may not be non-null yet so can't test them anyways
      }
    });

    this.filesSubmittedReaction = reaction(
      () => this.props.notificationService.getNotification(IDs.FILES_SUBMITTED),
      (currentOrderStatus: Notification | null) => {
        if (
          currentOrderStatus &&
          currentOrderStatus.status === Status.Success
        ) {
          this.setState({ showSubmittedFilesAlert: true });
        }
      }
    );
  }

  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset loads
    this.filesSubmittedReaction();
  }

  onPanelChange = (panelName: string) => {
    const { activePanels } = this.state;

    if (activePanels.includes(panelName)) {
      this.setState({
        activePanels: activePanels.filter((p) => p !== panelName),
      });
    } else {
      this.setState({ activePanels: [...activePanels, panelName] });
    }
  };

  render() {
    const dataset: DatasetModel | null = this.props.datasetService.dataset;
    const uploads: FileUpload[] | null = this.props.datasetService.uploads;

    const activePanels = this.state.activePanels;

    let content = <div />;
    if (dataset && uploads) {
      // const hasPendingUploads = uploads && uploads.length > 0;
      const downloadSection: Distribution = dataset.getDownloadDistribution();

      const formatCodeString: any[] = [];
      const formatInfo: FileMetadataSchema[] = [];

      const datasetName = dataset.getDatasetOnlyName();
      formatCodeString.push(
        <StyledCode key="dsdot">
          {datasetName}.
        </StyledCode>
      );

      downloadSection.fileMetadataSchema.forEach(
        (attr: FileMetadataSchema, index: number) => {
          formatCodeString.push(
            <StyledCode
              key={`${attr.name}format${index}`}
              sx={{ background: colors[index] }}
            >{`{${attr.name}}`}</StyledCode>
          );

          if (index < downloadSection.fileMetadataSchema.length - 1) {
            formatCodeString.push(
              <StyledSpan key={`${attr.name}dot${index}`}>
                .
              </StyledSpan>
            );
          }
          formatInfo.push(attr);
        }
      );

      content = (
        <ManageStyle>
          {this.props.appService !== null &&
            this.props.appService.uploadClientEnabled && (
              <Alert severity="info" style={{ marginBottom: '1rem' }}>
                Have <strong>lots</strong> of files, <strong>large</strong>{' '}
                files, or an <strong>ongoing collection</strong> of files? If
                so, consider using an{' '}
                <Link
                  to={`/uploaders/${dataset.projectName
                    }#dataset=${dataset.getDatasetOnlyName()}`}
                >
                  uploader client
                </Link>{' '}
                instead.
              </Alert>
            )}
          <div>
            {this.state.showSubmittedFilesAlert && (
              <Alert
                onClose={() =>
                  this.setState({ showSubmittedFilesAlert: false })
                }
                severity="info"
              >
                It may take a few minutes for files recently submitted to be
                reflected in file counts and to be available for download.
              </Alert>
            )}
            <Typography variant="h6" gutterBottom>
              Summary
            </Typography>
            <Table size="small" className="rowPadding">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    File Count
                  </TableCell>
                  <TableCell>{dataset.dynamoFileCount || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Total Size
                  </TableCell>
                  <TableCell>
                    {dataset.dynamoTotalFileSize
                      ? filesize(dataset.dynamoTotalFileSize)
                      : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Last Updated
                  </TableCell>
                  <TableCell>{dataset.lastUpdated}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div>
            <Accordion
              expanded={activePanels.includes('upload')}
              onChange={() => this.onPanelChange('upload')}
            >
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                Upload Files
              </StyledAccordionSummary>
              <div style={{ margin: 15 }}>
                <FileUploadDropZone />
              </div>
            </Accordion>
            <Accordion
              expanded={activePanels.includes('pending')}
              onChange={() => this.onPanelChange('pending')}
            >
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="pendingUploads"
              // className="accordionSummary"
              >
                Pending Uploads
              </StyledAccordionSummary>
              {uploads.length > 0 && (
                <Alert severity="warning" style={{ margin: 15 }}>
                  <AlertTitle>Pending Files</AlertTitle>
                  The Files listed below must be submitted before they are
                  available for download.
                </Alert>
              )}
              <Alert severity="info" style={{ margin: 15 }}>
                The file naming system is meant to help end-users choose what
                files to download based on the metadata contained in the file
                defined by its file name.
              </Alert>
              <Table size="small" style={{ marginTop: 20 }}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" style={{ marginLeft: 4, marginBottom: 8, fontSize: 15 }}>
                        Here is how the file name should be constructed
                      </Typography>
                      {formatInfo.map((segment, index) => (
                        <React.Fragment key={segment.name}>
                          <StyledColorCode
                            style={{ marginLeft: 4 }}
                            sx={{ background: colors[index] }}
                          >{`{${segment.name}}`}</StyledColorCode>{' '}
                          {segment.fieldFormat ? (
                            <React.Fragment>
                              The {segment.label}, with the format of{' '}
                              {segment.fieldFormat.label}, for example{' '}
                              {segment.fieldFormat.example}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>The {segment.label}</React.Fragment>
                          )}
                          <br />
                        </React.Fragment>
                      ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6" style={{ marginLeft: 4, marginBottom: 8, fontSize: 15 }}>
                        With the examples above, the file should be named
                      </Typography>
                      {formatCodeString}
                      {downloadSection.sampleFileName && (
                        <React.Fragment>
                          {' '}
                          for example: {downloadSection.sampleFileName}
                        </React.Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div style={{ margin: 10 }}>
                <EditUploadedFilesTable />
              </div>
            </Accordion>
          </div>
        </ManageStyle>
      );
    }
    return content;
  }
}
