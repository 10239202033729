import React from 'react';
import * as EmailValidator from 'email-validator';

import TextField from '@mui/material/TextField';

export interface INewUserEmailTextFieldProps {
  email: string;
  setEmail: (email: string) => void;
}

export interface INewUserEmailTextFieldState {
  valid: boolean;
}

class NewUserEmailTextField extends React.Component<
  INewUserEmailTextFieldProps,
  INewUserEmailTextFieldState
> {
  constructor(props) {
    super(props);
    this.state = {
      valid: true,
    };
  }

  onEmailChange = email => {
    if (EmailValidator.validate(email)) {
      this.setState({ valid: true });
    } else {
      this.setState({ valid: false });
    }
  };

  render() {
    const valid = this.state.valid;
    return (
      <TextField
        error={!valid}
        helperText={valid ? '' : 'Email format is invalid.'}
        id={'' + new Date().getMilliseconds()}
        label="New user's email"
        value={this.props.email}
        onChange={e => {
          const email = e.target.value;
          this.props.setEmail(email);
          this.onEmailChange(email);
        }}
        variant="standard"
      />
    );
  }
}

export default NewUserEmailTextField;
