import moment from 'moment';
import request from 'superagent';
import { v4 as uuid } from 'uuid';
import type { Moment } from 'moment';
import fromPairs from 'lodash/fromPairs';
import { Typography } from '@mui/material';
import { action, observable, runInAction, makeObservable } from 'mobx';

import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { IMetadataService } from '@extensions/services/IMetadataService';

import Dataset from '@extensions/models/Dataset';
import Link from '@extensions/components/core/Link';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import { withApiPrefix } from '@extensions/utils/metadata';
import { AccessRestrictionType } from '@extensions/models/AccessRestriction';

// Do not need full user info (not worth the hassle given
// privacy concerns)
export interface Author {
  firstName: string;
  lastName: string;
  username: string;
}

export interface DocumentPolicy {
  write: boolean;
  read: boolean;
  delete: boolean;
  publish: boolean;
}

export enum SubmissionEventType {
  SUBMIT_FOR_REVIEW = 'submit',
  EDIT = 'edit',
  APPROVE = 'approve',
}

export interface SubmissionEvent {
  type: SubmissionEventType;
  time: Moment;
  usersFullName: string;
  comment?: string;
}

export default class MetaDocument {
  private get notificationID() {
    return `meta-doc-${this.id}`;
  }
  private notificationService: INotificationService;
  private historyService: IHistoryService;
  private metadataService: IMetadataService;
  @observable
  id!: number | string;
  @observable
  author!: Author;
  @observable
  can: DocumentPolicy;
  @observable
  lastModified!: Moment;
  @observable
  datasetName!: string;
  @observable
  shortName!: string;
  /** The dataset (if any) the original author imported from */
  @observable
  importedFrom!: string | null;
  /** The JSON blob as a JS object */
  @observable
  metadata!: Record<string, any> | null;
  @observable
  events!: SubmissionEvent[];
  /** The original author has submitted this for review */
  @observable
  isSubmitted!: boolean;
  /** This is an already published document */
  @observable
  isPublished!: boolean;
  /** Proprietary datasets require mfa */
  @observable
  accessRequiresMfa!: boolean;
  /** The metadata which is already live on the site */
  @observable
  publishedMeta: Record<string, any> | null = null;
  @observable
  publishedMetaLoaded: boolean = false;
  @observable
  metadataLoaded: boolean = false;
  /**
   * Independent of whether it has been submitted, the document has been
   * persisted in the database
   */
  @observable
  hasBeenSaved: boolean;
  @observable
  deleteOnCancel: boolean;
  loadDetails: () => void;

  constructor(
    data,
    notificationService: INotificationService,
    historyService: IHistoryService,
    metadataService: IMetadataService,
    hasBeenSaved: boolean
  ) {
    makeObservable(this);
    this.notificationService = notificationService;
    this.historyService = historyService;
    this.metadataService = metadataService;
    this.hasBeenSaved = hasBeenSaved;
    this.deleteOnCancel = !hasBeenSaved;
    this.can = data.can;
    this.loadDetails = () => this._loadDetails();
    // data can be an already existing doc
    if (data instanceof MetaDocument) {
      this.can = { read: true, write: true, delete: false, publish: false };
      this.updateFromDoc(data);
      //data can be a json doc from the datadoc api
    } else if (data.id) {
      //why is this.can set here in the constructor as well as in updateFromRecord?
      this.can = data.can;
      this.updateFromRecord(data, false);
    } else {
      // if(data._source)
      //can is not set on docs created from an already published doc
      //but we can default the values since we already know what the user can do
      this.can = { read: true, write: true, delete: false, publish: false };
      this.updateFromEsHit(data);
    }
  }

  @action
  /** returns true if successful */
  setDataset = ({ name }: { name: string }): boolean => {
    const oldName = this.datasetName;
    const oldId = this.id;
    if (name !== oldName) {
      const matchingDest = this.metadataService?.getDestination(name);
      if (oldName && this.hasBeenSaved) {
        const oldDest = this.metadataService?.getDestination(oldName);
        if (
          oldDest?.projectName !== matchingDest?.projectName ||
          oldDest?.type !== matchingDest?.type
        ) {
          const proceed = window.confirm(
            `${name} has a different metadata format. ` +
            `Your old metadata will be lost if you switch from ` +
            `${oldName} to ${name}. ` +
            `Do you wish to continue?`
          );
          if (!proceed) {
            return false;
          }
          this.hasBeenSaved = false;
          this.id = uuid();
          this.metadata = {};
          this.publishedMeta = null;
          this.publishedMetaLoaded = false;
          this.metadataService.requestDeletion(oldId);
        }
      }
      this.shortName = matchingDest?.shortName || '';
      this.datasetName = name;
    }
    return true;
  };

  @action
  setImportedFrom = (newVal: string | null) => {
    if (newVal !== this.importedFrom) {
      this.metadata = {};
    }
    this.importedFrom = newVal;
  };

  @action
  setMetadata = (metadata: Record<string, any>) => {
    this.metadata = metadata;
    if (this.metadata.accessLevel === 'public') {
      this.metadata.accessRestriction = 'none';
    }
  };

  @action
  setDeleteOnCancel = (newVal: boolean) => (this.deleteOnCancel = newVal);

  save = async (options?: {
    successMessage?: string;
    successDescription?: string;
    onSuccess?: () => void;
    onError?: (err: Error) => void;
  }): Promise<void> => {
    let successMessage = options?.successMessage;
    let successDescription = options?.successDescription;
    //if going from published to draft make sucessMessage/desc empty strings so that notices about saving draft do not appear
    if (this.isPublished) {
      successMessage = '';
      successDescription = '';
    }
    const persist = async () => {
      if (!this.datasetName) {
        throw new Error('Can Not Save Before Selecting Dataset');
      }
      // If we are creating the doc, the ID will change
      const oldId = this.id;

      let resp: request.Response | null = null;
      if (!this.hasBeenSaved) {
        const project = Dataset.extractProjectName(this.datasetName);
        resp = await DapApiAgent.agent
          .post(withApiPrefix(`/${project}`))
          .send(this.toRecord());
        if (this.isPublished) {
          this.metadataService.addNewDocumentFromPublished(this);
        }
        const dest = this.metadataService.getDestination(this.datasetName);
        if (dest) {
          dest.hasDocument = true;
        }
        this.metadataService.setSelectedDocAction(undefined);
      } else {
        resp = await DapApiAgent.agent
          .put(withApiPrefix(`/${this.id}`))
          .send(this.toRecord());
      }
      this.updateFromRecord(resp.body, this.isPublished);
      runInAction(() => (this.hasBeenSaved = true));
      if (this.id !== oldId) {
        this.historyService.history.replace(`/metadata/edit/${resp.body.id}`);
      }
    };
    return this.notificationService.showStateInUI({
      pending: persist(),
      errorMessage: 'Failed to Save Document',
      notificationId: this.notificationID,
      successMessage,
      successDescription,
      onSuccess: options?.onSuccess,
      onError: options?.onError,
    });
  };

  saveDraft = async (): Promise<void> => {
    this.setDeleteOnCancel(false);
    return this.save({
      successMessage: 'Draft Saved',
      successDescription: 'Continue editing or come back later',
    });
  };

  submit = (): Promise<void> => {
    const submitAndUpdate = async () => {
      await DapApiAgent.agent.put(withApiPrefix(`/${this.id}/submit`));
      runInAction(() => {
        this.isSubmitted = true;
      });
    };
    return this.notificationService.showStateInUI({
      pending: submitAndUpdate(),
      errorMessage: 'Failed to Submit Metadata',
      notificationId: this.notificationID,
      successMessage: 'Success!',
      onSuccess: () => this.historyService.history.replace(`/metadata`),
      successDescription: (
        <>
          <Typography>
            We are reviewing your metadata. In the meantime you can{' '}
            <Link
              onClick={() => {
                this.notificationService.addNotification(
                  this.notificationID,
                  Status.Idle,
                  '',
                  ''
                );
                this.notificationService.setShowNotifications(false);
              }}
              to={`/projects/${this.datasetName}/upload`}
            >
              submit data
            </Link>
            . Data will be available once we approve your metadata.
          </Typography>
        </>
      ),
    });
  };

  private _loadDetails = () => {
    const fetchAndHydrate = async () => {
      const meta = await this.fetchMetadata();
      runInAction(() => {
        this.metadata = meta;
        this.metadataLoaded = true;
      });
    };
    this.notificationService.showStateInUI({
      pending: fetchAndHydrate(),
      notificationId: this.notificationID,
      errorMessage: 'Failed to Load Metadata',
    });
  };

  @action
  loadPublishedMeta = async (): Promise<void> => {
    if (!this.publishedMetaLoaded) {
      const fetchAndHydrate = async () => {
        let meta;
        try {
          const resp = await DapApiAgent.agent.get(
            withApiPrefix(`/${this.datasetName}/published`)
          );
          meta = resp.body;
        } catch (error: any) {
          if (error.status !== 404) {
            throw error;
          }
        }
        runInAction(() => {
          this.publishedMeta = meta || null;
          this.publishedMetaLoaded = true;
        });
      };
      return this.notificationService.showStateInUI({
        pending: fetchAndHydrate(),
        notificationId: this.notificationID,
        errorMessage: 'Failed to Load Metadata',
      });
    }
  };

  private removeEmptyValues = (val: any) => {
    switch (typeof val) {
      case 'object':
        // we have to check since typeof null === object
        if (val === null) {
          return null;
        }
        if (Array.isArray(val)) {
          return val
            .map((item) => this.removeEmptyValues(item))
            .filter((item) => item !== '');
        } else {
          const filteredEntries = Object.entries(val)
            .map(([key, value]) => [key, this.removeEmptyValues(value)])
            .filter(([key, value]) => value !== '');
          return fromPairs(filteredEntries);
        }
      default:
        return val;
    }
  };

  private getCleanMetadata = (): Record<string, any> => {
    if (this.metadata) {
      return this.removeEmptyValues(this.metadata);
    }
    return {};
  };

  private toRecord = () => {
    return {
      id: this.id,
      datasetName: this.datasetName,
      shortName: this.shortName,
      importedFrom: this.importedFrom,
      isSubmitted: this.isSubmitted,
      isPublished: this.isPublished,
      accessRequiresMfa: this.accessRequiresMfa,
      lastModified: this.lastModified.unix(),
      author: this.author,
      // Metadata must be defined when hitting backend
      metadata: this.getCleanMetadata(),
      log: this.events,
    };
  };

  private requiresMfa = (record) => {
    return [
      AccessRestrictionType.PROJECT_MFA,
      AccessRestrictionType.DATASET_MFA,
    ].includes(record.accessRestriction);
  };
  @action
  private updateFromRecord = (record, isPublished) => {
    this.id = record.id;
    this.author = record.author;
    this.can = record.can;
    this.datasetName = record.datasetName || '';
    this.shortName = record.shortName || '';
    this.importedFrom = record.importedFrom || null;
    this.isSubmitted = record.isSubmitted || false;
    this.lastModified = record.lastModified
      ? moment.unix(record.lastModified)
      : moment();
    this.metadata = record.metadata || null;
    this.events = record.log || [];
    this.accessRequiresMfa = this.requiresMfa(record);

    //this field exist in API payloads on initial loading of list but is not
    //returned when creating (POST) or updating (PUT) individual metadata docs so
    //we are losing state
    if (record.isPublished !== null && record.isPublished !== undefined) {
      this.isPublished = record.isPublished;
    } else {
      this.isPublished = isPublished;
    }
  };

  @action
  private updateFromEsHit = (hit) => {
    //documents created from ES hits are used in the published metadata table and are already
    //filtered to be ones the logged in user can edit, and only edit (not delete nor publish [TODO maybe publish?])
    this.id = uuid();
    this.author = {
      firstName: '',
      lastName: '',
      username: '',
    };
    this.can = { read: true, write: true, delete: false, publish: false };
    this.datasetName = hit.name || '';
    this.shortName = hit.name || '';
    this.importedFrom = null;
    this.isSubmitted = false;
    this.isPublished = true;
    this.accessRequiresMfa = this.requiresMfa(hit);
    this.lastModified = moment(hit.lastUpdated);
    this.metadata = null;
    this.events = [];
  };

  @action
  private updateFromDoc = (doc) => {
    this.id = uuid();
    this.author = {
      firstName: '',
      lastName: '',
      username: '',
    };
    this.can = { read: true, write: true, delete: false, publish: false };
    this.datasetName = doc.datasetName || '';
    this.shortName = doc.datasetName || '';
    this.importedFrom = null;
    this.isSubmitted = false;
    this.isPublished = true;
    this.accessRequiresMfa = this.requiresMfa(doc);
    this.lastModified = doc.lastModified;
    this.metadata = null;
    this.events = [];
  };

  private async fetchMetadata(): Promise<Record<string, any> | null> {
    if (this.hasBeenSaved) {
      const resp = await DapApiAgent.agent.get(withApiPrefix(`/${this.id}`));
      return resp.body.metadata;
    }

    return null;
  }
}
