import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { AppBar, Button, Grid, StepIcon, Typography } from '@mui/material';

import theme from '@extensions/services/Theme';

const StyledHeadingTypography = styled(Typography)(({
  margin: '2rem 0 0.5rem 0',
  borderBottom: '3px solid #ddd',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '0.5rem',
  '& .MuiStepIcon-root': {
    display: 'inline-block',
    marginRight: '0.25rem',
    fontSize: '2rem',
  }
}))

export type IStepProps = {
  number: number;
  title?: React.ReactNode;
  body?: React.ReactNode;
  next?: string
  prev?: string
  onNext?: () => void
  onPrev?: () => void
};

export const Step = ({ number, title, body, next, prev, onNext, onPrev, }: IStepProps) => {
  return (
    <>
      <StyledHeadingTypography variant="h2">
        <StepIcon active icon={number} />
        &nbsp;{title}
      </StyledHeadingTypography>
      {body}
      <AppBar
        position="static"
        sx={{
          padding: '0.5rem 0.75rem',
          margin: '1rem 0 0 0',
          backgroundColor: theme.palette.primary.main
        }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {Boolean(prev) && (
              <Button size="small" onClick={onPrev} sx={{ color: theme.palette.common.white }}>
                <ArrowBackIcon fontSize="small" />
                &nbsp;{prev}
              </Button>
            )}
          </Grid>
          <Grid item>
            {Boolean(next) && (
              <Button size="small" onClick={onNext} sx={{ color: theme.palette.common.white }}>
                {next}&nbsp;
                <ArrowForwardIcon fontSize="small" />
              </Button>
            )}
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
};
