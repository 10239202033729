import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography
} from '@mui/material';
import ContactPoint from '@extensions/models/ContactPoint';
import { styled } from '@mui/material/styles';
import React from 'react';
import { inject, observer } from 'mobx-react';

import { ISecurityService } from '@extensions/services/ISecurityService';
import { IContactUsService } from '@extensions/services/IContactUsService';

export interface IContactsTableProps {
  className?: string;
  contacts: ContactPoint[];
  /**
   * Project or Dataset name/identifier
   */
  name?: string;
  /**
   * Project or Dataset title
   */
  title?: string;

  securityService?: ISecurityService;
  contactUsService?: IContactUsService;
}

const StyledDiv = styled('div')({
  width: '100%'
});

const StyledButtonBarDiv = styled('div')({
  paddingTop: '1rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: theme.spacing(1.75),
  color: theme.palette.common.black
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
  }
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50]
}));

@inject('securityService', 'contactUsService')
@observer
class ContactsTable extends React.Component<IContactsTableProps> {
  render() {
    const {
      contacts,
      securityService,
      contactUsService,
      name,
      title,
    } = this.props;

    if (!securityService) {
      return null;
    }
    if (contacts === undefined || contacts === null || contacts.length === 0) {
      return (
        <Typography sx={{ padding: '0 1rem', }}>
          No Contacts Found
        </Typography>
      );
    }

    return (
      <StyledDiv>
        <TableContainer component={Paper}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableHeadCell>
                  Name
                </StyledTableHeadCell>
                <StyledTableHeadCell>Role</StyledTableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contacts.map((contact) => {
                return (
                  <TableRow key={contact.email}>
                    <StyledTableCell>{contact.displayName}</StyledTableCell>
                    <StyledTableCell>{contact.role || 'N/A'}</StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {contactUsService && (
          <StyledButtonBarDiv>
            <StyledButton
              onClick={() => contactUsService.openModal(name, title)}
              variant="contained"
              fullWidth
            >
              Send Message
            </StyledButton>
          </StyledButtonBarDiv>
        )}
      </StyledDiv>
    );
  }
}

export default ContactsTable;
