import React from 'react';
import { useForm, UseFormReturn, UseFormProps } from 'react-hook-form';

export interface IReactHookFormProps<
  FieldValues extends Record<string, any>,
  TContext
> {
  formProps: UseFormProps<FieldValues, TContext>;
  children: (
    args: UseFormReturn<FieldValues, TContext>
  ) => React.ReactElement | null;
}

export default function ReactHookForm<
  FieldValues extends Record<string, any>,
  TContext
>({
  formProps,
  children,
}: IReactHookFormProps<FieldValues, TContext>): React.ReactElement | null {
  const formBag = useForm(formProps);
  return children(formBag);
}
