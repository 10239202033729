import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import find from 'lodash/find';
import filter from 'lodash/filter';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  TextField,
  Tooltip,
  ListItemText,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useConfirm } from 'material-ui-confirm';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  IDs,
  INotificationService,
  Notification,
  Status,
} from '@extensions/services/INotificationService';
import { IProjectService } from '@extensions/services/IProjectService';

import User from '@extensions/models/User';
import Group from '@extensions/models/Group';
import GroupModal from '@extensions/components/project/GroupModal';
import theme from '@extensions/services/Theme';

export interface IGroupListProps {
  projectService: IProjectService;
  notificationService: INotificationService;
}

export interface IGroupListState {
  searchText: string;
  selectedGroup: Group | undefined;
  addUsersGroupModalVisible: boolean;
  editGroupModalVisible: boolean;
}

const StyledRootDiv = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(4)
}));

interface IDeleteGroupButtonProps {
  group: Group;
  deleteCallBack: (group: Group) => void;
}

const DeleteGroupButton = (props: IDeleteGroupButtonProps) => {
  const confirm = useConfirm();

  const handleClick = () => {
    confirm({
      description: `Are you sure you want to delete group ${props.group.name}?`,
    })
      .then(() => {
        props.deleteCallBack(props.group);
      })
      .catch(() => {
        // Deletion cancelled
      });
  };

  return (
    <Tooltip title="Delete Group">
      <IconButton aria-label="delete" onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

@inject('projectService', 'notificationService')
@observer
class GroupList extends React.Component<
  IGroupListProps,
  IGroupListState
> {
  static defaultProps = {
    projectService: undefined,
    notificationService: undefined,
  };
  updateGroupReaction: any;
  // confirm: any;

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      selectedGroup: undefined,
      addUsersGroupModalVisible: false,
      editGroupModalVisible: false,
    };
  }

  componentDidMount() {
    this.updateGroupReaction = reaction(
      () => this.props.notificationService.getNotification(IDs.GROUP_UPDATED),
      (membersAddedNotification: Notification | null) => {
        if (
          membersAddedNotification &&
          membersAddedNotification.status === Status.Success
        ) {
          this.closeModal();
        }
      }
    );
  }
  componentWillUnmount() {
    // dispose of the reactions, new ones will be created when another dataset's data order modal loads
    this.updateGroupReaction();
  }

  closeModal = () => {
    this.setState({
      addUsersGroupModalVisible: false,
      editGroupModalVisible: false,
    });
  };

  search = event => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  deleteGroup = group => {
    this.props.projectService.deleteProjectGroup(group.name);
  };

  openAddMembersModal = (group: Group) => {
    // open group modal but users list is only users not already
    // in this group and group name not editable
    this.setState({ selectedGroup: group, addUsersGroupModalVisible: true });
  };

  openEditGroupModal = (group: Group) => {
    // open group modal but users list is only users already
    // in this group and populate group name to edit it
    this.setState({ selectedGroup: group, editGroupModalVisible: true });
  };

  public render() {
    const { projectService } = this.props;
    const {
      searchText,
      selectedGroup,
      editGroupModalVisible,
      addUsersGroupModalVisible,
    } = this.state;
    const groups: Group[] | null = projectService.groups;

    let nonGroupUsers: User[] = [];
    let groupUsers: User[] = [];
    const projectUsers: User[] | null = projectService.users;
    if (selectedGroup && projectUsers) {
      groupUsers = selectedGroup.users;
      nonGroupUsers = filter(projectUsers, (projectUser: User) => {
        return (
          find(groupUsers, (groupUser: User) => {
            return groupUser.email === projectUser.email;
          }) === undefined
        );
      });
    }

    let content = <div />;

    if (groups && groups.length > 0) {
      let filteredGroups = groups;
      // remove groupsuser's that don't match search string (if entered)
      if (searchText) {
        filteredGroups = filter(groups, (group: Group) => {
          const groupString = `${group.name}${group.label}`;
          const groupMatch = groupString.indexOf(searchText) !== -1;
          let userMatch = false;
          if (!groupMatch) {
            userMatch =
              find(group.users, (groupUser: User) => {
                return (
                  groupUser.email.indexOf(searchText) !== -1 ||
                  groupUser.fullName.indexOf(searchText) !== -1
                );
              }) !== undefined;
          }
          return groupMatch || userMatch;
        });
      }

      const groupListItems: React.ReactNode[] = filteredGroups.map(
        (group: Group) => {
          return (
            <Accordion key={group.name}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="group-content"
                id="group-header"
              >
                <FormControlLabel
                  aria-label="delete"
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <DeleteGroupButton
                      group={group}
                      deleteCallBack={this.deleteGroup}
                    />
                  }
                  label=""
                />
                <FormControlLabel
                  aria-label="Edit"
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <Tooltip title="Edit Group">
                      <IconButton
                        aria-label="edit"
                        onClick={e => this.openEditGroupModal(group)}
                      >
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  label=""
                />
                <FormControlLabel
                  aria-label="Add Users"
                  onClick={event => event.stopPropagation()}
                  onFocus={event => event.stopPropagation()}
                  control={
                    <Tooltip title="Add Users">
                      <IconButton
                        aria-label="add users"
                        onClick={e => this.openAddMembersModal(group)}
                      >
                        <PersonAddIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  label=""
                />
                <Typography style={{ paddingTop: 12 }}>{group.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List dense disablePadding aria-label="groupmembers">
                  {group.users.map((user: User) => (
                    <ListItem key={user.email}>
                      <ListItemText
                        primary={user.fullName}
                        secondary={user.email}
                        primaryTypographyProps={{
                          variant: 'body1',
                          color: theme.palette.common.black
                        }}
                        secondaryTypographyProps={{
                          variant: 'body1',
                          color: theme.palette.grey[700]
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          );
        }
      );

      content = (
        <StyledRootDiv>
          <Grid container>
            <Grid item xs={12}>
              <span style={{ float: 'right' }}>
                <TextField label="Search" onChange={this.search} variant='standard'/>
              </span>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 3 }}>
              {addUsersGroupModalVisible && (
                <GroupModal
                  onCancel={this.closeModal}
                  users={nonGroupUsers}
                  mode="addUsers"
                  group={selectedGroup}
                />
              )}
              {editGroupModalVisible && (
                <GroupModal
                  onCancel={this.closeModal}
                  users={groupUsers}
                  mode="edit"
                  group={selectedGroup}
                />
              )}
              {groupListItems}
            </Grid>
          </Grid>
        </StyledRootDiv>
      );
    }
    return content;
  }
}

export default GroupList;
