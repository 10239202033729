import * as yup from 'yup';
import * as React from 'react';
import { Field, Form, Formik } from 'formik';
import { inject, observer } from 'mobx-react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// import { TextField } from 'formik-material-ui';
import { TextField } from 'formik-mui';
import CloseIcon from '@mui/icons-material/Close';

import { ISecurityService } from '@extensions/services/ISecurityService';

import User from '@extensions/models/User';

const StyledQRCodeImg = styled('img')(({
  width: '140px',
}));

const StyledClosedIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

export interface ISetupMFAModalProps {
  securityService?: ISecurityService;
  visible: boolean;
  onCancel: (event: any) => void;
}

export interface ISetupMFAModalState { }

@inject('securityService')
@observer
class SetupMFAModal extends React.Component<
  ISetupMFAModalProps,
  ISetupMFAModalState
> {
  static defaultProps = {
    visible: false,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  generateMfa = (values, { setSubmitting }) => {
    if (this.props.securityService) {
      this.props.securityService.generateMfa(values.password);
    }
    setSubmitting(false);
  };

  validateMfa = (values, { setSubmitting }) => {
    if (this.props.securityService) {
      this.props.securityService.validateMfa(values.code);
    }
    setSubmitting(false);
  };

  renderSetupComplete = () => {
    return (
      <Typography>
        Well done! Multifactor Authentication (MFA) setup complete.
      </Typography>
    );
  };

  renderPasswordPrompt = () => {
    return (
      <React.Fragment>
        <Typography>
          Multifactor Authentication (MFA) has not yet been setup. Get started
          by entering your password.
        </Typography>
        <Formik
          initialValues={{
            password: '',
          }}
          validationSchema={yup.object({
            password: yup.string().required('Password is required'),
          })}
          onSubmit={this.generateMfa}
        >
          {({ submitForm }) => (
            <Form>
              <Field
                component={TextField}
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                fullWidth
                id="password-input"
                margin="normal"
                size="small"
              />
              <Button
                onClick={submitForm}
                color="secondary"
                variant="contained"
              >
                Get Started
              </Button>
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  };

  renderGoogleCodePrompt = (user: User) => {
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item>
            <StyledQRCodeImg
              src={user.mfaQRCode}
              alt="GA QR Code"
            />
          </Grid>
          <Grid item>
            <Typography>
              <strong>Step 1:</strong>{' '}
              <a
                href="https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345"
                target="_blank"
                rel="noopener noreferrer"
              >
                Install Google Authenticator
              </a>
            </Typography>
            <Typography>
              <strong>Step 2:</strong> Add a new entry using the QR code to the
              left.
            </Typography>
            <Typography>
              <strong>Step 3:</strong> Enter a new, valid code below using your
              newly created entry in Google Authenticator.
            </Typography>
            <Formik
              initialValues={{
                code: '',
              }}
              validationSchema={yup.object({
                code: yup.string().required('Code is required'),
              })}
              onSubmit={this.validateMfa}
            >
              {({ submitForm }) => (
                <Form>
                  <Field
                    component={TextField}
                    name="code"
                    type="text"
                    label="Code"
                    variant="outlined"
                    fullWidth
                    id="code-input"
                    margin="normal"
                    size="small"
                  />
                  <Button
                    onClick={submitForm}
                    color="secondary"
                    variant="contained"
                  >
                    Confirm Code
                  </Button>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  public render() {
    let content = <div />;

    if (this.props.securityService && this.props.securityService.user) {
      const { user } = this.props.securityService;
      content = (
        <Dialog
          open={this.props.visible}
          onClose={this.props.onCancel}
          container={() => document.getElementById('dapRoot')}
        >
          <DialogTitle>
            <Typography variant="h2" component="p">
              Configure MFA
            </Typography>
            <StyledClosedIconButton
              aria-label="close"
              onClick={this.props.onCancel}
            >
              <CloseIcon />
            </StyledClosedIconButton>
          </DialogTitle>
          <DialogContent>
            {user.mfaEnabled
              ? this.renderSetupComplete()
              : user.mfaQRCode
                ? this.renderGoogleCodePrompt(user)
                : this.renderPasswordPrompt()}
          </DialogContent>
        </Dialog>
      );
    }
    return content;
  }
}

export default SetupMFAModal;
