import React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Card,
  CardContent,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Link from '@dapclient/components/core/Link';
import { PubProject } from '@extensions/models/Publication';

const StyledCard = styled(Card)(({
  marginBottom: '0.25rem',
}));

const StyledDiv = styled('div')(({
  marginTop: '1rem'
}));

export interface IProjectsListProps {
  className?: string;
  projects: PubProject[] | string[];
}

export interface IProjectsListState { }

@observer
export class ProjectsList extends React.Component<
  IProjectsListProps,
  IProjectsListState
> {
  render() {
    const { projects } = this.props;
    let body: React.ReactNode;
    if (!projects || projects.length === 0) {
      body = <Typography>No Projects Provided</Typography>;
    } else {
      body = <StyledCard key="dataset-list-card">
        {
          projects.map((project, idx) => {
            return (
              <CardContent key={`${project.name}-${idx}`}>
                <Link to={`/project/${project.name}`} style={{ fontSize: '14px' }}>{project.name + ' | ' + project.title}</Link>
              </CardContent>
            )
          })
        }
      </StyledCard>
    }
    return (
      <StyledDiv>
        <Typography variant="h2">Projects</Typography>
        {body}
      </StyledDiv>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(ProjectsList);
