import React, { MouseEvent } from 'react';
import { observer, inject } from 'mobx-react';

import {
  TextField,
  Typography,
  Rating
} from '@mui/material';
import { styled } from '@mui/material/styles';

import ContactUsService from '@extensions/services/ContactUsService';
import { ISecurityService } from '@extensions/services/ISecurityService';

const StyledTypography = styled(Typography)(({
  marginTop: '0.5rem',
  padding: '0.5rem',
}));

const StyledDiv = styled('div')(({
  justifyContent: 'center',
  width: '25rem',
}));

const StyledButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 'auto',
  backgroundColor: theme.palette.primary.main,
  width: '90px',
  height: '40px',
  borderRadius: '20px',
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  "&:active": {
    outline: 'none',
    border: 'none',
  }
}));

const StyledTextField = styled(TextField)(({
  marginBottom: '1rem',
}));

const StyledWarning = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
}));

const StyledText = styled('div')(({
  color: 'white',
}));

export interface IFeedbackFormProps {
  contactUsService?: ContactUsService;
  securityService?: ISecurityService;
}
export interface IFeedbackFormState {
  feedbackMsgA: string,
  feedbackMsgB: string,
  rating: number,
  submitted: boolean
  required: boolean
}

@inject(
  'contactUsService',
  'securityService'
)
@observer
class FeedbackForm extends React.Component<IFeedbackFormProps, IFeedbackFormState> {
  constructor(props: IFeedbackFormProps) {
    super(props);
    this.state = {
      feedbackMsgA: '',
      feedbackMsgB: '',
      rating: -1,
      submitted: false,
      required: false
    };
  }
  render() {
    const {
      securityService
    } = this.props;

    const handleMouseEvent = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (this.state.rating === -1) {
        this.setState({ required: true })
      } else {
        this.setState({ submitted: true })
        this.setState({ required: false })
        if (this.props.contactUsService && this.props.securityService) {
          this.props.contactUsService.submitMessage({
            emailAddress: securityService?.user ? securityService?.user.email : '',
            subject: 'Order Page User Feedback',
            message: 'Rating: ' + this.state.rating +
              '\n\n ## Tell us how this data will impact your work: ' +
              '\n' + this.state.feedbackMsgA +
              '\n\n ## Tell us how to improve your experience: ' +
              '\n' + this.state.feedbackMsgB,
            showSuccessNotification: true,
          });
        }
      }
    };

    const formEnd = (isSubmitted: boolean) => {
      if (isSubmitted) {
        return <StyledTypography variant="h5" >Thank you for your feedback.</StyledTypography>;
      } else {
        return <StyledButton onClick={handleMouseEvent}><StyledText>Submit</StyledText></StyledButton>;
      }
    }

    const warningMessage = (isRequired: boolean) => {
      if (isRequired) {
        return <StyledWarning >*Rating Required</StyledWarning>;
      } else {
        return null;
      }
    }

    return (
      <StyledDiv >
        <StyledTypography variant="h5" >
          Rate your experience with discovering and ordering data.
        </StyledTypography>
        <Rating
          onChange={(event, value) => {
            if (value === null) {
              this.setState({ rating: -1 });
            } else {
              this.setState({ rating: value });
            }
          }}
          style={{ fontSize: '3rem' }}
          name="rating"
          defaultValue={0}
          precision={1} />
        {warningMessage(this.state.required)}
        <StyledTextField
          onChange={(event) => this.setState({ feedbackMsgA: event.target.value })}
          type="text"
          label="Tell us how this data will impact your work."
          multiline
          minRows={3}
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: 500 }}
        />
        <StyledTextField
          onChange={(event) => this.setState({ feedbackMsgB: event.target.value })}
          type="text"
          label="Tell us how to improve your experience."
          multiline
          minRows={3}
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: 500 }}
        />
        {formEnd(this.state.submitted)}
      </StyledDiv >
    );
  }
}

export default (FeedbackForm);
