import React from 'react';

import {
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';
import AddIcon from '@extensions/utils/AddIcon';
import { formatNumber } from '@extensions/utils/format';

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
}));

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  marginRight: '1rem',
  color: theme.palette.grey[700]
}));

const StyledTypographyDiv = styled('div')(({
  display: 'flex',
  flexDirection: 'column',
}));

type TypographyExtraProps = {
  component: React.ElementType;
};

const StyledValueTypography = styled(Typography)<TypographyExtraProps>(() => ({
  color: theme.palette.secondary.main,
  ...theme.Stat.statValue
}));

const StyledDescTypography = styled(Typography)<TypographyExtraProps>(() => ({
  ...theme.Stat.statDescription
}));

export interface IStatProps {
  className?: string;
  variant?: 'stacked' | 'beside';
  icon: FontAwesomeIconProps['icon'];
  iconSize?: FontAwesomeIconProps['size'];
  fixedWidth?: FontAwesomeIconProps['fixedWidth'];
  value: React.ReactNode;
  description: React.ReactNode;
}

class Stat extends React.Component<IStatProps> {
  render() {
    const {
      icon,
      iconSize = '4x',
      fixedWidth = false,
      value,
      description,
    } = this.props;
    let formattedValue = value;
    if (typeof value === 'number') {
      formattedValue = formatNumber(value);
    }
    return (
      <StyledDiv>
        <StyledAddIcon
          icon={icon}
          size={iconSize}
          fixedWidth={fixedWidth}
        />
        <StyledTypographyDiv
        >
          <StyledValueTypography
            component="div"
          >
            {formattedValue}
          </StyledValueTypography>
          <StyledDescTypography
            component="div"
          >
            {description}
          </StyledDescTypography>
        </StyledTypographyDiv>
      </StyledDiv>
    );
  }
}

export default Stat;
