import {
  action,
  autorun,
  observable,
  runInAction,
  reaction,
  computed,
  makeObservable,
} from 'mobx';
import moment, { Moment } from 'moment';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import sumBy from 'lodash/sumBy';
import filesize from 'filesize';

import {
  INotificationService,
  Status,
} from '@dapclient/services/INotificationService';
import { ICartService, OrderItem } from '@extensions/services/ICartService';
import Dataset, { DatasetStats } from '@extensions/models/Dataset';
import { ISecurityService } from '@extensions/services/ISecurityService';
import LambdaApiAgent from '@extensions/utils/LambdaApiAgent';
import config from '@extensions/utils/ConfigUtil';
import { IContactUsService } from '@extensions/services/IContactUsService';
import { ICachingService } from '@extensions/services/ICachingService';
import FileOrder from '@extensions/models/FileOrder';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { AppliedFilter } from '@extensions/components/dataset/LargeDataOrder';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import buildScript from '@extensions/utils/buildDownloaderScript';
import { OperatingSystem } from '@extensions/models/FileOrderGroup';
import { saveAs } from 'file-saver';
import { DisplayType } from '@extensions/models/FileMetadataSchema';
import { DateRange } from '@extensions/components/core/date-picker';
import isEmptyArray from '@extensions/utils/isEmptyArray';
import isEmptyOject from '@extensions/utils/isEmptyObj';

const STORAGE_KEY = 'cart';

export default class CartService implements ICartService {
  notificationId: string = 'cart';
  cachingService: ICachingService;
  contactUsService: IContactUsService;
  notificationService: INotificationService;
  securityService: ISecurityService;
  historyService: IHistoryService;

  private externalSourceInquiries: Map<string, ((ready: boolean) => void)[]>;
  private externalSourceReadiness: Map<string, boolean>;

  @observable
  orderItems: OrderItem[] = [];
  @observable
  dateRangeFilter: CompleteDateRange | null = null;
  @observable
  // Map from label to selected values
  extensionsFilter: Map<string, Set<string>> = new Map();
  @observable
  fileTypesFilter: Set<string> = new Set();

  @observable
  datasets: Dataset[] | null = null;
  @observable
  statsAfterFilters: Map<string, DatasetStats> | null = null;

  /**Used to display current filter in text form */
  @computed
  get dateRangeFilterStr(): string | null {
    if (this.dateRangeFilter !== null) {
      return `Date Range: ${this.dateRangeFilter.startDate.format(
        'YYYY-MM-DD'
      )} to ${this.dateRangeFilter.endDate.format('YYYY-MM-DD')}`;
    }
    return null;
  }

  /**Used to display current filter(s) in text form */
  @computed
  get extensionsFilterStrs(): { displayStr: string; label: string }[] {
    const returnValue: { displayStr: string; label: string }[] = [];
    for (const [label, values] of this.extensionsFilter.entries()) {
      if (values.size > 0) {
        returnValue.push({
          displayStr: `${[...values.values()].join(', ')}`,
          label,
        });
      }
    }
    return returnValue;
  }

  /**Used to display current filter in text form */
  @computed
  get fileTypesFilterStr(): string | null {
    if (this.fileTypesFilter.size > 0) {
      return `File types: ${[...this.fileTypesFilter.values()].join(', ')}`;
    }
    return null;
  }

  @computed
  get statsBeforeFilters(): Map<string, DatasetStats> | null {
    if (this.datasets === null) {
      return null;
    }

    const stats = new Map<string, DatasetStats>();
    for (const dataset of this.datasets) {
      stats.set(dataset.name, {
        fileCount: dataset.dynamoFileCount || 0,
        byteCount: dataset.dynamoTotalFileSize || 0,
        startDate: dataset.dynamoDataBegins && moment(dataset.dynamoDataBegins),
        endDate: dataset.dynamoDataEnds && moment(dataset.dynamoDataEnds),
      });
    }
    return stats;
  }

  @computed
  get firstDateWithData(): null | Moment {
    if (this.statsBeforeFilters) {
      const firstStats = minBy(
        [...this.statsBeforeFilters.values()],
        (stat) => stat.startDate && stat.startDate.valueOf()
      );
      return firstStats ? firstStats.startDate : null;
    }
    return null;
  }

  @computed
  get lastDateWithData(): null | Moment {
    if (this.statsBeforeFilters) {
      const firstStats = maxBy(
        [...this.statsBeforeFilters.values()],
        (stat) => stat.endDate && stat.endDate.valueOf()
      );
      return firstStats ? firstStats.endDate : null;
    }
    return null;
  }

  @computed
  get currentStats(): Map<string, DatasetStats> | null {
    return this.statsAfterFilters;
  }

  @computed
  get statsAreLoading(): boolean {
    return (
      this.orderItems.length !==
      (this.statsAfterFilters !== null ? this.statsAfterFilters.size : 0)
    );
  }

  @computed
  get total(): { fileCount: number; byteCount: number } | null {
    if (this.currentStats) {
      const values = [...this.currentStats.values()];
      return {
        fileCount: sumBy(values, 'fileCount'),
        byteCount: sumBy(values, 'byteCount'),
      };
    }
    return null;
  }

  @computed
  get unusuallyLarge(): boolean {
    if (this.currentStats) {
      return [...this.currentStats.values()].some(
        (stat) => stat.byteCount > FileOrder.maxSizeWithoutApproval
      );
    }
    return false;
  }

  @computed
  get datasetsByName(): Record<string, Dataset> {
    const result: Record<string, Dataset> = {};
    if (this.datasets) {
      this.datasets.forEach((dataset) => {
        result[dataset.name] = dataset;
      });
    }
    return result;
  }

  @computed
  get datasetsAreLoaded(): boolean {
    return this.orderItems.every((item) => {
      return this.datasetsByName[item.datasetName];
    });
  }

  constructor(
    notificationService: INotificationService,
    securityService: ISecurityService,
    contactUsService: IContactUsService,
    cachingService: ICachingService,
    historyService: IHistoryService
  ) {
    makeObservable(this);
    this.notificationService = notificationService;
    this.securityService = securityService;
    this.contactUsService = contactUsService;
    this.cachingService = cachingService;
    this.historyService = historyService;
    this.readFromStorage();
    autorun(this.writeToStorage);
    window.addEventListener('storage', this.handleStorageChange);

    this.externalSourceInquiries = new Map();
    this.externalSourceReadiness = new Map();

    reaction(
      () => [
        this.securityService.autoLoginDone,
        this.orderItems && [...this.orderItems],
      ],
      ([done]) => {
        if (done) {
          this.loadDatasets();
          this.externalSourceInquiries = new Map();
          this.externalSourceReadiness = new Map();
        }
      },
      {
        fireImmediately: true,
      }
    );
    // The first argument to this reaction is a hack to get it to react
    // to all the dependencies... should be fixed later.
    reaction(
      () => [
        [...this.extensionsFilter.entries()].map(([label, selected]) => [
          label,
          [...selected.values()],
        ]),
        this.dateRangeFilter && this.dateRangeFilter.endDate,
        this.dateRangeFilter && this.dateRangeFilter.startDate,
        [...this.fileTypesFilter.values()],
        this.datasets && [...this.datasets],
        this.securityService.userIsLoggedIn,
        this.orderItems && [...this.orderItems],
      ],
      this.updateStats,
      {
        fireImmediately: true,
      }
    );

    this.securityService.addLogoutListener(this.clearCart);
  }

  private getOrderItemIndex = (
    datasetName: string,
    dateRange?: [Moment, Moment],
    range?: [number, number],
  ) => {
    const fmt = 'YYYYMMDD';
    const ymd = dateRange
      ? [dateRange[0].format(fmt), dateRange[1].format(fmt)]
      : null;
    let i = 0;
    for (let item of this.orderItems) {
      if (
        ymd === null &&
        item.dateRange === undefined &&
        item.range === undefined &&
        item.datasetName === datasetName
      ) {
        return i;
      }
      if (
        item.datasetName === datasetName &&
        ymd !== null &&
        item.dateRange !== undefined &&
        item.dateRange.startDate.format(fmt) === ymd[0] &&
        item.dateRange.endDate.format(fmt) === ymd[1]
      ) {
        return i;
      }
      if (
        item.datasetName === datasetName &&
        ymd === null &&
        range !== undefined &&
        item.range !== undefined &&
        item.range.start === range[0] &&
        item.range.end === range[1]
      ) {
        return i;
      }
      i++;
    }
    return -1;
  };

  isInCart = (datasetName: string, dateRange?: [Moment, Moment]): boolean => {
    return this.getOrderItemIndex(datasetName, dateRange) >= 0;
  };

  @action
  addToCart = (datasetName: string, dateRange?: [Moment, Moment], range?: [number, number]): void => {
    if (!this.isInCart(datasetName, dateRange)) {
      this.orderItems.push({
        datasetName,
        dateRange: dateRange ? {
          startDate: dateRange[0],
          endDate: dateRange[1],
        } : undefined,
        range: range ? {
          start: range[0],
          end: range[1],
        } : undefined,
      });
    }

    this.notificationService.addNotification(
      this.notificationId,
      Status.Info,
      'Added to Cart',
      `${datasetName} added to cart`
    );
  };

  @action
  removeFromCart = (
    datasetNameToRemove: string,
    dateRange?: [Moment, Moment],
    range?: [number, number]
  ): void => {
    const index = this.getOrderItemIndex(datasetNameToRemove, dateRange, range);
    if (index < 0) {
      return;
    }
    this.orderItems.splice(index, 1);
  };

  @action
  clearCart = (): void => {
    this.orderItems = [];
    this.dateRangeFilter = null;
    this.extensionsFilter = new Map();
    this.fileTypesFilter = new Set();
  };

  @action
  private updateUsingStorageObject = (storageObject: StorageObject) => {
    if (storageObject.datasetNames !== undefined) {
      // For backward compatibility
      this.orderItems = storageObject.datasetNames.map((datasetName) => {
        return {
          datasetName,
        };
      });
    }
    if (storageObject.orderItems !== undefined) {
      this.orderItems = storageObject.orderItems.map((d) => {
        return {
          datasetName: d.datasetName,
          dateRange: d.dateRange
            ? {
                startDate: moment(d.dateRange.startDate),
                endDate: moment(d.dateRange.endDate),
              }
            : undefined,
          isDataQualityAffected: d.isDataQualityAffected
        };
      });
    }
    this.dateRangeFilter = storageObject.dateRange && {
      startDate: moment(storageObject.dateRange.startDate),
      endDate: moment(storageObject.dateRange.endDate),
    };
    this.fileTypesFilter = new Set(storageObject.fileTypes);
    this.extensionsFilter = new Map<string, Set<string>>();

    for (const extensionEntry of storageObject.extensions) {
      const [label, selectedValues] = extensionEntry;
      this.extensionsFilter.set(label, new Set(selectedValues));
    }
  };

  private writeToStorage = (): void => {
    const storageObject: StorageObject = {
      orderItems: this.orderItems.map((d) => {
        return {
          datasetName: d.datasetName,
          dateRange: d.dateRange
            ? {
                startDate: d.dateRange.startDate.valueOf(),
                endDate: d.dateRange.endDate.valueOf(),
              }
            : undefined,
        };
      }),
      dateRange: this.dateRangeFilter && {
        startDate: this.dateRangeFilter.startDate.valueOf(),
        endDate: this.dateRangeFilter.endDate.valueOf(),
      },
      extensions: [...this.extensionsFilter.entries()].map(
        ([label, valuesSet]) => [label, [...valuesSet.values()]]
      ),
      fileTypes: [...this.fileTypesFilter.values()],
    };
    const jsonBlob = JSON.stringify(storageObject);
    localStorage.setItem(STORAGE_KEY, jsonBlob);
  };

  private readFromStorage = (): void => {
    const jsonBlob = localStorage.getItem(STORAGE_KEY);
    if (jsonBlob) {
      const storageObject = JSON.parse(jsonBlob) as StorageObject;
      this.updateUsingStorageObject(storageObject);
    }
  };

  private handleStorageChange = (event: StorageEvent): void => {
    if (STORAGE_KEY === event.key && event.newValue !== null) {
      const storageObject = JSON.parse(event.newValue);
      this.updateUsingStorageObject(storageObject);
    }
  };

  @action
  setDateRangeFilter = (newRange: CompleteDateRange | null): void => {
    this.dateRangeFilter = newRange;
  };

  private toggleSetValue = <T extends {}>({
    set,
    value,
  }: {
    set: Set<T>;
    value: T;
  }): void => {
    if (set.has(value)) {
      set.delete(value);
    } else {
      set.add(value);
    }
  };

  @action
  toggleExtensionValue = ({
    label,
    value,
  }: {
    label: string;
    value: string;
  }): void => {
    const selectedValues = this.extensionsFilter.get(label) || new Set();
    this.toggleSetValue({ set: selectedValues, value });

    this.extensionsFilter.set(label, selectedValues);
  };

  @action
  toggleFileType = (fileType: string): void => {
    this.toggleSetValue({ set: this.fileTypesFilter, value: fileType });
  };

  @action
  clearFileTypesFilter = (): void => {
    this.fileTypesFilter.clear();
  };

  @action
  clearExtensionFilter = (label: string): void => {
    this.extensionsFilter.delete(label);
  };

  private loadDatasets = (): void => {
    const datasets: Dataset[] = [];
    const datasetNames = Array.from(
      new Set<string>(this.orderItems.map((item) => item.datasetName))
    );
    if (datasetNames) {
      const fetchPromises = datasetNames.map((name) =>
        this.cachingService
          .getDataset(name)
          .then((dataset) => datasets.push(dataset))
      );
      Promise.all(fetchPromises)
        .then(() => runInAction(() => (this.datasets = datasets)))
        .catch((error) => {
          if (error.response && error.response.status === 403) {
            this.clearCart();
          } else {
            this.notificationService.addNotification(
              this.notificationId,
              Status.Error,
              'Failed to fetch datasets',
              error
            );
          }
        });
    }
  };

  private getCombinedFilter = ({
    dateRangeFilter,
    extensionsFilter,
    fileTypesFilter,
    dataset,
  }: {
    dateRangeFilter: CompleteDateRange | null;
    extensionsFilter: Map<string, Set<string>>;
    fileTypesFilter: Set<string>;
    dataset: Dataset;
  }): Record<string, any> => {
    const filter: Record<string, any> = {};
    if (dateRangeFilter && dataset.dynamoDataBegins && dataset.dynamoDataEnds) {
      const formatDate = (date: Moment) => {
        return date.format('YYYYMMDDHHmmss');
      };
      filter.date_time = {
        between: [
          formatDate(dateRangeFilter.startDate),
          formatDate(dateRangeFilter.endDate),
        ],
      };
    }

    if (fileTypesFilter.size > 0) {
      filter.file_type = [...fileTypesFilter.values()];
    }

    if (extensionsFilter && dataset.dynamoFileExtensions) {
      for (const entry of extensionsFilter.entries()) {
        const [label, selectedValues] = entry;
        if (selectedValues.size > 0) {
          const extensionIndex = dataset.dynamoFileExtensions.findIndex(
            (extension) => extension.label === label
          );
          if (extensionIndex > -1) {
            filter[`ext${extensionIndex + 1}`] = [...selectedValues.values()];
          }
        }
      }
    }

    return filter;
  };

  private buildStatsKey = (orderItem: OrderItem) => {
    return [
      orderItem.datasetName,
      ...(orderItem.dateRange === undefined
        ? []
        : [
            [
              orderItem.dateRange.startDate.format('YYYYMMDD'),
              orderItem.dateRange.endDate.format('YYYYMMDD'),
            ].join('--'),
          ]),
    ].join('@');
  };

  buildOrderItemKey = this.buildStatsKey;

  private updateStats = async (): Promise<void> => {
    if (!this.datasetsAreLoaded) {
      return;
    }

    runInAction(() => (this.statsAfterFilters = null));

    const newStats = new Map<string, DatasetStats>();
    const fetchingStatsPromises = this.orderItems.map((item) => {
      const dataset = this.datasets?.find((d) => d.name === item.datasetName);
      if (dataset === undefined) {
        // This is just to appease TS (there is a check for null this.datasets
        // first thing in this updateStats() function)
        console.log('returning promise which never resolves');
        return new Promise((resolve) => {});
      }
      return this.cachingService
        .getStats({
          ...this.getCombinedFilter({
            dataset,
            dateRangeFilter:
              item.dateRange === undefined
                ? this.dateRangeFilter
                : item.dateRange,
            extensionsFilter: this.extensionsFilter,
            fileTypesFilter: this.fileTypesFilter,
          }),
          Dataset: dataset.name,
          latest: true,
        })
        .then((stats) => newStats.set(this.buildStatsKey(item), stats));
    });
    // Add a timeout to ensure spinner is visible for minimum amount of time
    fetchingStatsPromises.push(
      new Promise((resolve) => setTimeout(resolve, 500))
    );
    Promise.all(fetchingStatsPromises)
      .then(() =>
        runInAction(() => {
          this.statsAfterFilters = newStats;
        })
      )
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          this.clearCart();
        } else {
          this.notificationService.addNotification(
            this.notificationId,
            Status.Error,
            'Failed to fetch dataset statistics',
            error
          );
        }
      });
  };

  sendLargeOrderNotifications = (): Promise<void> => {
    if (this.securityService.user === null) {
      throw new Error('Must be logged in to submit request');
    }

    const user = this.securityService.user;

    let message = [
      `${user.firstname} ${user.lastname} (${user.username}) `,
      'placed the following order: ',
    ].join('');

    const addOrderDetail = (detail: string) => {
      message += '\n\t';
      message += detail;
    };
    const datasetDetails = this.orderItems
      .map((item) => {
        let line = `  - ${item.datasetName}`;
        let range = item.dateRange || this.dateRangeFilter;
        if (range) {
          const rangeFormatted = [
            range.startDate.format('YYYY-MM-DD'),
            range.endDate.format('YYYY-MM-DD'),
          ].join(' - ');
          return `${line} (${rangeFormatted})`;
        }
        return line;
      })
      .join(', ');
    addOrderDetail(`Datasets:\n${datasetDetails}`);
    if (this.fileTypesFilterStr) {
      addOrderDetail(this.fileTypesFilterStr);
    }
    for (const { displayStr, label } of this.extensionsFilterStrs) {
      addOrderDetail(`${label}: ${displayStr}`);
    }
    if (this.total) {
      addOrderDetail(`Total: ${filesize(this.total.byteCount)}`);
      addOrderDetail(`File Count: ${this.total.fileCount}`);
    }
    message += [
      `\nThis notification was generated because the volume of data exceeds`,
      `${filesize(
        FileOrder.maxSizeWithoutApproval
      )}. The order has already been placed and no action is required at this time.`,
    ].join(' ');

    return this.contactUsService
      .submitMessage({
        emailAddress: 'dapdesk@pnnl.gov',
        subject: 'Order Placed',
        message,
        showSuccessNotification: false,
      })
      .then(() => {})
      .catch((error) => {
        // there's nothing the user can do about this, and arguably their order
        // should still go through
        console.log(error);
      });
  };

  @action
  placeOrder = (): void => {
    if (
      this.datasets &&
      this.securityService.userIsLoggedIn &&
      this.notificationService
    ) {
      this.notificationService.addNotification(
        this.notificationId,
        Status.Running,
        '',
        ''
      );
      const requestBody: { datasets: Record<string, { query: any }> } = {
        datasets: {},
      };

      for (const item of this.orderItems) {
        const dataset = this.datasets?.find((d) => d.name === item.datasetName);
        if (dataset === undefined) {
          continue;
        }
        if (this.currentStats === null) {
          continue;
        }
        const statsForDataset = this.currentStats.get(this.buildStatsKey(item));
        if (statsForDataset && statsForDataset.fileCount > 0) {
          requestBody.datasets[dataset.name] = {
            query: this.getCombinedFilter({
              dateRangeFilter: item.dateRange
                ? item.dateRange
                : this.dateRangeFilter,
              extensionsFilter: this.extensionsFilter,
              fileTypesFilter: this.fileTypesFilter,
              dataset,
            }),
          };
        }
      }

      const apiUrl = config.getConfig().lambdaApi;

      LambdaApiAgent.agent
        .put(`${apiUrl}/orders`)
        .send(requestBody)
        .then((resp) => {
          const { id } = resp.body;
          if (this.unusuallyLarge) {
            this.sendLargeOrderNotifications();
          }
          this.clearCart();
          this.historyService.history.push(`/profile/orders/${id}`);
          this.notificationService.addNotification(
            this.notificationId,
            Status.Success,
            '',
            ''
          );
        })
        .catch((error) => {
          this.notificationService.addNotification(
            this.notificationId,
            Status.Error,
            'Could Not Place Order',
            error
          );
        });
    }
  };

  placeOrderNotInCart = (
    os: OperatingSystem,
    filters: Record<string, AppliedFilter>,
    currentDataSource: Dataset
  ): void => {
    if (this.securityService.userIsLoggedIn && this.notificationService) {
      this.notificationService.addNotification(
        this.notificationId,
        Status.Running,
        '',
        ''
      );

      const requestBody = {
        datasets: {},
      };
      requestBody.datasets[currentDataSource.name] = {
        query: {},
      };

      Object.values(filters).forEach((filter) => {
        const { value, attribute } = filter;
        if (
          value == null ||
          value === undefined ||
          isEmptyArray(value) ||
          isEmptyOject(value)
        ) {
          return;
        }
        switch (attribute.downloadDisplay) {
          case DisplayType.RANGE:
            Object.assign(requestBody.datasets[currentDataSource.name].query, {
              [attribute.dynamoFieldName]: { between: value },
            });
            break;
          case DisplayType.LIST:
            Object.assign(requestBody.datasets[currentDataSource.name].query, {
              [attribute.dynamoFieldName]: value,
            });
            break;
          case DisplayType.DATE:
            const dateRange = value as DateRange;
            if (dateRange.startDate !== null && dateRange.endDate !== null) {
              Object.assign(
                requestBody.datasets[currentDataSource.name].query,
                {
                  [attribute.dynamoFieldName]: {
                    between: [
                      dateRange.startDate.format('YYYYMMDD'),
                      dateRange.endDate.format('YYYYMMDD'),
                    ],
                  },
                }
              );
            }

            break;
          case DisplayType.NONE:
          case DisplayType.NO_FILTER:
          default:
            console.log(`Bad filter type values! ${attribute.name}`);
            break;
        }
      });

      const apiUrl = config.getConfig().lambdaApi;
      LambdaApiAgent.agent
        .put(`${apiUrl}/orders`)
        .send(requestBody)
        .then((resp) => {
          const { id } = resp.body;
          this.historyService.history.push(`/ds/${currentDataSource.name}`);
          this.notificationService.addNotification(
            this.notificationId,
            Status.Success,
            '',
            ''
          );
          return id;
        })
        .then((orderId) => {
          this.notificationService.addNotification(
            this.notificationId,
            Status.Running,
            'Building script...',
            '',
            false
          );

          const stopSpinner = () =>
            this.notificationService.addNotification(
              this.notificationId,
              Status.Success,
              '',
              ''
            );

          let idQueryString = '';
          idQueryString = `&id[]=${orderId}`;
          return DapApiAgent.agent
            .get(`/api/file-downloader?os=${os}${idQueryString}`)
            .responseType('blob')
            .then((resp) => {
              let fileName = `spp-downloader-${orderId}`;
              if (os === OperatingSystem.MAC || os === OperatingSystem.LINUX) {
                // const zip = new JSZip();
                // const folder = zip.folder('spp-downloader');
                // const readmeContents = [
                //   `The other file in this folder (i.e. ${fileName}) is an`,
                //   'executable program which you can use to download the files in',
                //   'your order. To run it, do the following:',
                //   '',
                //   '  1. Open Terminal',
                //   '  2. cd to the directory which contains this README',
                //   `  3. Run "chmod +x ${fileName}"`,
                //   `  4. Run "./${fileName}"`,
                //   '  5. Follow the prompts. You will have to enter your SPP password to authenticate',
                //   '',
                //   'If you have any questions or concerns, please email dapteam@pnnl.gov.',
                // ].join('\n');
                // const readmeBlob = new Blob([readmeContents], {
                //   type: 'text/plain',
                // });
                // folder.file('READ_ME.txt', readmeBlob);
                // folder.file(fileName, resp.body);
                const zip = buildScript(resp.body, fileName, 'spp');
                return zip.generateAsync({ type: 'blob' }).then((blob) => {
                  saveAs(blob, 'spp-downloader.zip');
                  stopSpinner();
                });
              } else if (os === OperatingSystem.WINDOWS) {
                fileName += '.exe';
                saveAs(resp.body, fileName);
                stopSpinner();
              }
            });
        })
        .catch((error) => {
          this.notificationService.addNotification(
            this.notificationId,
            Status.Error,
            'Could Not Place Order',
            error
          );
        });
    }
  };

  isExternalSourceReady = (src: string, callback: (ready: boolean) => void) => {
    if (this.externalSourceReadiness.has(src)) {
      return callback(this.externalSourceReadiness.get(src) || false);
    }
    if (this.externalSourceInquiries.has(src)) {
      this.externalSourceInquiries.set(src, [
        ...(this.externalSourceInquiries.get(src) || []),
        callback
      ]);
      return;
    }
    this.externalSourceInquiries.set(src, [callback]);
    const apiUrl = config.getConfig().lambdaApi;
    return LambdaApiAgent.agent
      .get(`${apiUrl}/${src}`)
      .then(response => {
        const ready = response.body.ready || false;
        this.externalSourceReadiness.set(src, ready);
        for (const callback of this.externalSourceInquiries.get(src) || []) {
          callback(ready);
        }
        this.externalSourceInquiries.delete(src);
      });
  }
}

export type CompleteDateRange = {
  startDate: Moment;
  endDate: Moment;
};

type StorageObject = {
  datasetNames?: string[];
  orderItems?: {
    datasetName: string;
    dateRange: { startDate: number; endDate: number } | undefined;
    isDataQualityAffected?: boolean | undefined;
  }[];
  dateRange: { startDate: number; endDate: number } | null;
  extensions: [string, string[]][];
  fileTypes: string[];
};
