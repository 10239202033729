import React from 'react';
import { inject, observer } from 'mobx-react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Autocomplete, createFilterOptions, TextField } from '@mui/material';

import { IPublicationService } from '@extensions/services/IPublicationService';

import { PubOrganization } from '@extensions/models/Publication';
import CenteredCircularProgress from '../core/CenteredCircularProgress';
import { PublicationFormData } from '@extensions/models/PublicationFormData';

export interface ISponsorOrgsFieldProps {
  className?: string;
  publicationService?: IPublicationService;
  control: UseFormReturn<PublicationFormData>['control'];
}

export interface ISponsorOrgsFieldState { }

@observer
export class SponsorOrgsField extends React.Component<
  ISponsorOrgsFieldProps,
  ISponsorOrgsFieldState
> {
  componentDidMount() {
    this.props.publicationService?.loadSponsorOrgs();
  }

  filter = createFilterOptions<PubOrganization>();

  render() {
    const { publicationService, control } = this.props;
    if (!publicationService?.sponsorOrgs) {
      return (
        <CenteredCircularProgress />
      );
    }
    return (
      <Controller
        name="sponsorOrganizations"
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              multiple
              options={publicationService.sponsorOrgs ?? []}
              value={field.value}
              isOptionEqualToValue={(option, value) => {
                return option.name === value.name;
              }}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => field.onChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onBlur={field.onBlur}
                  label="Sponsor Organizations"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          );
        }}
      />
    );
  }
}

export default inject((store: any) => ({
  publicationService: store.publicationService,
}))(SponsorOrgsField);
