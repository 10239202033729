import { inject } from 'mobx-react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';
import { DatasetResult as DapDatasetResult } from '@dapclient/components/dataset-search/DatasetResult';
import Dataset from '@extensions/models/Dataset';
import CardExpansionPanel from '@extensions/components/core/CardExpansionPanel';
import Link from '@dapclient/components/core/Link';


export class DatasetResult extends DapDatasetResult {
    renderReferencesPanel = (dataset: Dataset) => {
        if (dataset.references && dataset.references.length) {
            return (
                <CardExpansionPanel
                    detailsId={`${dataset.name}-references`}
                    summaryContent={
                        <Typography variant="h4">
                            References
                        </Typography>
                    }
                    detailsContent={
                        <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
                            <Table size={'medium'} stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Reference Title
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        dataset.references
                                            .slice()
                                            .map((reference) => {
                                                return (
                                                    <TableRow key={reference.referenceURL}>
                                                        <TableCell>
                                                            <Link to={reference.referenceURL}>{reference.referenceTitle}</Link>
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                />
            )
        }
        return null;
    };
}

export default inject((store: any) => ({
    // TODO: Put any services you need here
    // EXAMPLE => metricsService: store.metricsService
}))(DatasetResult);
