import React from 'react';

import { styled } from '@mui/material/styles';
import { Pagination, Select, MenuItem } from '@mui/material';

import theme from '@extensions/services/Theme';

const StyledPagination = styled(Pagination)(({
  fontFamily: theme.openSansFontFamily,
}));

const StyledSelect = styled(Select)(({
  height: '35px',
  marginLeft: '8px'
}));

export interface ISearchPaginationProps {
  pageSize: number;
  currentPage: number;
  totalPages: number;
  setPage: (newPage: number) => void;
  getPageNumbers: (current: number, pageSize: number) => void;
}

class SearchPagination extends React.Component<ISearchPaginationProps> {
  constructor(props) {
    super(props);
    this.onShowSizeChange = this.onShowSizeChange.bind(this);
  }

  onShowSizeChange(current, pageSize) {
    this.props.getPageNumbers(current, pageSize);
  }

  handlePageSizeChange = (event) => {
    const newPageSize = event.target.value;
    this.setState({ pageSize: newPageSize });
    this.onShowSizeChange(0, newPageSize);
  }

  render() {
    const { currentPage, totalPages, setPage, pageSize } = this.props;
    return (
      <>
        <StyledPagination
          count={totalPages}
          page={currentPage + 1}
          onChange={(event, pageNumber) => setPage(pageNumber - 1)}
          boundaryCount={1}
          siblingCount={1}
          shape="rounded"
          variant="outlined"
        />
        <StyledSelect
          variant="outlined"
          value={pageSize}
          onChange={this.handlePageSizeChange}
        >
          {["10", "20", "50", "70", "100"].map((size) => (
            <MenuItem key={size} value={size}>
              {size + ' / page'}
            </MenuItem>
          ))}
        </StyledSelect>
      </>
    );
  }
}

export default SearchPagination;
