import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TableContainer,
  Paper,
  Typography,
  TableRow,
  TableCell,
  Table,
  TableBody
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import theme from '@extensions/services/Theme';

import DataRelevantEvent from '@extensions/models/DataRelevantEvent';
import QualityWarning from '@extensions/components/dataset/QualityWarning';

type TableContainerExtraProps = {
  component: React.ElementType;
}

const StyledTableContainer = styled(TableContainer)<TableContainerExtraProps>(({
  marginBottom: theme.spacing(2),
  '& td': {
    verticalAlign: 'top',
  },
  '& td:first-of-type': {
    color: theme.palette.grey[500],
    textAlign: 'right',
    minWidth: '180px',
  },
}));

export interface IDataRelevantEventsListProps extends RouteComponentProps {
  className?: string;
  events?: DataRelevantEvent[] | null;
}

export interface IDataRelevantEventsListState {
  isExpanded: boolean;
}

@observer
export class DataRelevantEventsList extends React.Component<
  IDataRelevantEventsListProps,
  IDataRelevantEventsListState
> {

  wrapperRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    }
    this.onChangeExpanded = this.onChangeExpanded.bind(this);
    this.wrapperRef = React.createRef();
  }

  componentDidMount(): void {
    if (this.props.location.hash === "#event") {
      this.setState({ isExpanded: !this.state.isExpanded });
      this.wrapperRef.current?.scrollIntoView();
    }
  }

  componentDidUpdate(prevProps, prevState): void {
    if (this.props.location.hash !== prevProps.location.hash
      && this.props.location.hash === "#event"
    ) {
      this.setState({ isExpanded: !this.state.isExpanded });
      this.wrapperRef.current?.scrollIntoView();
    }
  }

  onChangeExpanded() {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  renderHazardIcon(events: DataRelevantEvent[] | null | undefined) {
    if (events && events.length > 0 && events.some((event) => event.affectsDataQuality)) {
      return (
        <QualityWarning />
      )
    }
    return null;
  }

  renderEventTable(events: DataRelevantEvent[] | null | undefined) {
    if (events && events !== null && events.length > 0) {
      return events.map((event, idx) => (
        <StyledTableContainer component={Paper} key={idx}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Start Date</TableCell>
                <TableCell><code>{event.startDate}</code></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>End Date</TableCell>
                <TableCell><code>{event.endDate || '-'}</code></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Affects Data Quality</TableCell>
                <TableCell>
                  {event.affectsDataQuality ? (
                    <>
                      <QualityWarning />
                      Yes
                    </>
                  ) : 'No'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>{event.description}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      ))
    }
    return null;
  }

  render() {
    const { events } = this.props;
    const { isExpanded } = this.state;
    return (
      <Accordion
        expanded={isExpanded}
        onChange={this.onChangeExpanded}
        ref={this.wrapperRef}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="events-content"
          id="events-header"
        >
          <Typography variant="h3">
            Data Relevant Events
            {this.renderHazardIcon(events)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {this.renderEventTable(events)}
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(withRouter(DataRelevantEventsList));
