import React from 'react';
import startsWith from 'lodash/startsWith';
import { inject, observer } from 'mobx-react';
import { Alert, Snackbar } from '@mui/material';

import ITokenService from '@extensions/services/ITokenService';
import { IDatasetService } from '@extensions/services/IDatasetService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { INotificationService } from '@dapclient/services/INotificationService';

import FileUpload from '@extensions/components/core/FileUpload';

import 'dropzone/dist/min/dropzone.min.css';
import 'react-dropzone-component/styles/filepicker.css';
export interface IDropZoneState {
  uploadCompleted: boolean;
  filesUploaded: number;
}

export interface IDropZoneProps {
  datasetService: IDatasetService;
  securityService: ISecurityService;
  notificationService: INotificationService;
  tokenService: ITokenService;
}
@inject(
  'datasetService',
  'securityService',
  'notificationService',
  'tokenService'
)
@observer
export default class DropZone extends React.Component<
  IDropZoneProps,
  IDropZoneState
> {
  static defaultProps = {
    datasetService: null,
    securityService: null,
    notificationService: null,
    tokenService: null,
  };

  djsConfig: any;
  componentConfig: any;
  successCallback: (file: any) => void;
  dropzone: any;
  queueComplete: any;

  constructor(props) {
    super(props);
    this.state = {
      uploadCompleted: false,
      filesUploaded: 0,
    };

    // remove the project name (anything before the /) from the dataset name
    // and prepend it to the filename if its not already there
    let datasetName;
    if (this.props.datasetService.dataset) {
      datasetName = this.props.datasetService.dataset.getDatasetOnlyName();
    }

    const renameFile = file => {
      let name = file.name;
      if (!startsWith(name, datasetName)) {
        // see if it starts with it as different case, if so strip the wrong case version off and add correct:
        if (startsWith(name.toLowerCase(), datasetName.toLowerCase())) {
          name = name.substring(datasetName.length + 1);
        }
        return `${datasetName}.${name}`;
      }
      return name;
    };

    // For a full list of possible configurations,
    // please consult http://www.dropzonejs.com/#configuration
    this.djsConfig = {
      renameFile,
    };

    this.componentConfig = {
      postUrl: `/api/datasets/${props.datasetService.dataset.name}/uploads`,
    };

    this.successCallback = file => {
      this.setState(prevState => ({
        ...prevState,
        filesUploaded: prevState.filesUploaded + 1,
      }))
      // get new list of files from server, reaction will trigger pending table to reload
      this.props.datasetService.loadUploadedFiles();
    };

    this.queueComplete = () => {
      this.setState({ uploadCompleted: true });
      const pendingUploads = document.getElementById('pendingUploads');
      if (pendingUploads !== null) {
        pendingUploads.scrollIntoView({ behavior: 'smooth' });
      }
    };

    this.dropzone = null;
  }

  dropzoneInit = instance => {
    this.dropzone = instance;
  };

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const eventHandlers = {
      init: this.dropzoneInit,
      success: this.successCallback,
      queuecomplete: this.queueComplete,
    };

    return (
      <>
        <Snackbar
          autoHideDuration={10000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.uploadCompleted && this.state.filesUploaded > 0}
          onClose={() => this.setState({ uploadCompleted: false })}
        >
          <Alert
            onClose={() => this.setState({ uploadCompleted: false })}
            severity="warning"
            style={{
              border: '1px solid #ffe58f',
              backgroundColor: '#fffbe6',
              color: 'rgba(0, 0, 0, 0.65)',
            }}
          >
            Next, submit your files in the Pending Uploads section below.
          </Alert>
        </Snackbar>
        <FileUpload
          config={config}
          eventHandlers={eventHandlers}
          djsConfig={djsConfig}
        />
      </>
    );
  }
}
