// Generated by https://quicktype.io
//
// To change quicktype's target language, run command:
//
//   "Set quicktype target language"

/**
 * ContactPoint as a vCard object
 */

export interface IContactPointRaw {
  hasEmail: string;
  /**The name of the contact */
  fn: string;
  hasRole?: string;
  hasOrg: string;
}
export default class ContactPoint {
  /**
   * A full formatted name, eg Firstname Lastname.
   */
  displayName: string;
  /**
   * Contact email address.
   */
  email: string;
  org: string;
  role?: string;

  constructor(data: IContactPointRaw) {
    this.displayName = data.fn;
    this.email = data.hasEmail;
    this.org = data.hasOrg;
    this.role = data.hasRole;
  }
}
