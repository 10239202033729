import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';

import {
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import { IPublicationService } from '@extensions/services/IPublicationService';

import DapApiAgent from '@extensions/utils/DapApiAgent';
import Publication from '@extensions/models/Publication';
import FormButtonsAndErrors from '@extensions/components/publications/FormButtonsAndErrors';
import theme from '@extensions/services/Theme';

const StyledTextField = styled(TextField)(({
  width: '30rem',
  maxWidth: '100%',
}));

export interface IEnterDoiProps {
  className?: string;
  doi: string;
  onChange: (doi: string) => void;
  setPublication: (publication: Publication) => void;
  moveForward: () => void;
  clickCancelBtn: () => void;
  moveBackward: () => void;
  canMoveForward: boolean;
  canMoveBackward: boolean;
  notificationService?: INotificationService;
  publicationService?: IPublicationService;
}

export interface IEnterDoiState { }

@observer
export class EnterDoi extends React.Component<
  IEnterDoiProps,
  IEnterDoiState
> {
  error: Error | null = null;

  constructor(props) {
    super(props);
    makeObservable(this, {
      error: observable,
    });
  };

  checkExistDoi = async (): Promise<Boolean> => {
    const resp = await DapApiAgent.agent.post(`/api/refs/_msearch`).send(
      JSON.stringify({ preference: "SearchResult" }) + "\n" +
      JSON.stringify({
        query: {
          match_all: {},
        },
        _source: ['doi'],
        size: 2000,
      })
    );
    let isDoiExisted = false;
    resp.body.responses[0].hits.hits.forEach(hit => {
      if (hit._source.doi && hit._source.doi === this.props.doi) {
        isDoiExisted = true;
      };
    });
    return isDoiExisted;
  };

  fetchFromOsti = async (): Promise<Publication> => {
    const { publicationService, doi } = this.props;
    if (!publicationService) {
      throw new Error("Can't fetch publication");
    }
    if (!doi) {
      throw new Error('DOI is Required');
    }
    return await publicationService.fetchFromOsti(doi);
  };

  moveForward = async (): Promise<void> => {
    const { notificationService, moveForward, setPublication } = this.props;
    notificationService?.addNotification(
      'FETCH_FROM_OSTI',
      Status.Running,
      '',
      ''
    );
    try {
      const isDoiExisted = await this.checkExistDoi();
      if (isDoiExisted) {
        runInAction(() => {
          this.error = new Error('Publication with this DOI already exists.');
        });
      } else {
        const publication = await this.fetchFromOsti();
        setPublication(publication);
        moveForward();
      }
    } catch (error: any) {
      runInAction(() => {
        this.error = error ?? new Error('Could not retrieve publication');
      });
    } finally {
      notificationService?.addNotification(
        'FETCH_FROM_OSTI',
        Status.Idle,
        '',
        ''
      );
    }
  };

  render() {
    const {
      canMoveForward,
      canMoveBackward,
      moveBackward,
      clickCancelBtn,
      doi,
      onChange,
    } = this.props;
    return (
      <>
        <Typography variant='body1' sx={{color: theme.palette.grey[700]}}>
          Enter a DOI to retrieve information about the publication from DOE's Office of Scientific and Technical Information.
        </Typography>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(event) => event.preventDefault()}
        >
          <StyledTextField
            id="doi-input"
            label="DOI"
            value={doi}
            onChange={(event) => onChange(event.target.value)}
            variant='standard'
          />
          <FormButtonsAndErrors
            moveForward={this.moveForward}
            moveBackward={moveBackward}
            clickCancelBtn={clickCancelBtn}
            canMoveBackward={canMoveBackward}
            canMoveForward={canMoveForward}
            error={this.error}
          />
        </form>
      </>
    );
  }
}

export default inject((store: any) => ({
  notificationService: store.notificationService,
  publicationService: store.publicationService,
}))(EnterDoi);
