import React from 'react';
import { inject, observer } from 'mobx-react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { Autocomplete, TextField } from '@mui/material';

import { IPublicationService } from '@extensions/services/IPublicationService';

import { PublicationType } from '@extensions/models/Publication';
import PdfField from '@extensions/components/publications/PdfField';
import { PublicationFormData } from '@extensions/models/PublicationFormData';
import ReactHookTextField from '@extensions/components/publications/ReactHookTextField';
import RefFileUploadField from '@extensions/components/publications/RefFileUploadField';
import RefFileTypeSelectionField from '@extensions/components/publications/RefFileTypeSelectionField';

export interface ITypeSpecificFieldsProps {
  control: UseFormReturn<PublicationFormData>['control'];
  publicationService?: IPublicationService;
  selectedUploadOption?: string;
}

export interface ITypeSpecificFieldsState { }

enum UploadOption {
  File = 'Upload A File',
  AttachURL = 'Attach An URL'
}

@observer
export class TypeSpecificFields extends React.Component<
  ITypeSpecificFieldsProps,
  ITypeSpecificFieldsState
> {
  renderJournalArticleFields = (): React.ReactElement => {
    const { control } = this.props;
    return (
      <>
        <ReactHookTextField
          control={control}
          fieldName="journalName"
          textFieldProps={{
            required: true
          }}
        />
        <ReactHookTextField control={control} fieldName="journalVolume" />
        <ReactHookTextField control={control} fieldName="journalIssue" />
      </>
    );
  };

  renderTechnicalReportFields = (): React.ReactElement => {
    const { control } = this.props;
    return (
      <>
        <ReactHookTextField control={control} fieldName="reportNumber" />
        <PdfField control={control} />
      </>
    );
  };

  renderReferenceDocumentFields = (): React.ReactElement => {
    const { control, selectedUploadOption } = this.props;
    let uploadComponent: React.ReactNode;
    if (selectedUploadOption === UploadOption.File) {
      uploadComponent = <RefFileUploadField control={control} />
    } else if (selectedUploadOption === UploadOption.AttachURL) {
      uploadComponent = <ReactHookTextField control={control} fieldName="url" />
    }
    return (
      <>
        <RefFileTypeSelectionField control={control} />
        <Controller
          name="uploadOption"
          control={control}
          render={({ field }) => {
            return (
              <Autocomplete
                options={[UploadOption.File, UploadOption.AttachURL]}
                value={field.value}
                isOptionEqualToValue={(option, value) => {
                  return option === value;
                }}
                getOptionLabel={(option) => option}
                onChange={(event, value) => {
                  return field.onChange(value)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onBlur={field.onBlur}
                    label="How would you like to upload reference document?"
                    variant="outlined"
                    margin="dense"
                    required
                  />
                )}
              />
            )
          }}
        />
        {uploadComponent}
      </>
    )
  }

  render() {
    const { control } = this.props;
    return (
      <Controller
        name="type"
        control={control}
        render={({ field }) => {
          switch (field.value) {
            case PublicationType.JOURNAL_ARTICLE:
              return this.renderJournalArticleFields();
            case PublicationType.TECHNICAL_REPORT:
              return this.renderTechnicalReportFields();
            default:
              return this.renderReferenceDocumentFields();
          }
        }}
      />
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
  publicationService: store.publicationService,
}))(TypeSpecificFields);
