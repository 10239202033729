import { css } from '@emotion/css';

const figureStyle = css`
  width: 550px;
  max-width: 100%;
  color: black;
  img {
    max-width: 100%;
    max-height: 100%;
    border: 1px solid gray;
  }
`;

export interface IMarkdownImageProps {
  src?: string;
  title?: string;
}

const MarkdownImage = (props: IMarkdownImageProps) => (
  <span className={figureStyle}>
    <img src={props.src} alt={props.title} />
  </span>
);

export default MarkdownImage;
