import React from 'react';
import get from 'lodash/get';
import {
  DropzoneComponent,
  DropzoneComponentProps,
} from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server';
import { inject, observer } from 'mobx-react';
import { computed, makeObservable } from 'mobx';

import { styled } from '@mui/material/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import {
  INotificationService,
  Status,
} from '@dapclient/services/INotificationService';
import ITokenService from '@extensions/services/ITokenService';

const StyledRootDiv = styled('div')(({
  '& .dz-filename': {
    paddingTop: '10px',
  },

  '& .dropzone.dz-started .dz-message': {
    display: 'block',
  },

  '& [data-dz-errormessage=true]': {
    color: 'red',
  },
}))

export interface IFileUploadProps extends DropzoneComponentProps {
  className?: string;
  tokenService?: ITokenService;
  notificationService?: INotificationService;
  children?: any;
}

export interface IFileUploadState { }

@observer
export class FileUpload extends React.Component<
  IFileUploadProps,
  IFileUploadState
> {
  dropzone: any;
  @computed
  get headers() {
    const { tokenService } = this.props;
    const csrfToken = tokenService?.dapToken;
    return {
      'X-CSRF-TOKEN': csrfToken,
    };
  }

  @computed
  get defaultDjsConfig() {
    return {
      method: 'post',
      chunking: true,
      forceChunking: true,
      chunkSize: 1024 * 1024, // (B)
      maxFilesize: 2048, // (MB) Also enforced by server and PHP config
      headers: this.headers,
      createImageThumbnails: false,
      addRemoveLinks: true,
      dictRemoveFile: '',
      previewTemplate: ReactDOMServer.renderToStaticMarkup(
        <div>
          <div className="dz-file-preview">
            <span
              className="dap-data-dz-name"
              data-dz-name
              style={{ float: 'left', overflowWrap: 'break-word' }}
            />
            <span
              className="dap-data-dz-succes"
              style={{ float: 'right', display: 'none' }}
            >
              <CheckCircleOutlineIcon />
            </span>
            <span
              className="dap-data-dz-error"
              style={{ float: 'right', display: 'none' }}
            >
              <ErrorOutlineIcon />
            </span>
            <span
              className="dap-data-dz-size"
              data-dz-size
              style={{ float: 'right', paddingRight: '5px' }}
            />
          </div>
          <div>
            <progress
              className="dap-data-dz-uploadprogress"
              max="100"
              value="0"
              style={{ width: '100%' }}
            />
            <span data-dz-errormessage style={{ width: '100%' }} />
          </div>
          <hr />
        </div>
      ),
    };
  }

  @computed
  get defaultComponentConfig() {
    return {
      showFiletypeIcon: false,
    };
  }

  constructor(props) {
    super(props);
    makeObservable(this);
    this.dropzoneInit = this.withProps(this.dropzoneInit, 'init');
    this.successCallback = this.withProps(this.successCallback, 'success');
    this.uploadprogressCallback = this.withProps(
      this.uploadprogressCallback,
      'uploadprogress'
    );
    this.errorCallback = this.withProps(this.errorCallback, 'error');
  }

  dropzoneInit = instance => {
    this.dropzone = instance;
  };

  withProps = (ourCallback, callbackName) => {
    return (...args) => {
      const theirCallback = get(this.props, `eventHandlers.${callbackName}`);
      ourCallback(...args);
      if (Array.isArray(theirCallback)) {
        (theirCallback as Array<any>).forEach(callback => callback(...args));
      } else if (theirCallback) {
        get(this.props, `eventHandlers.${callbackName}`)
      }
    };
  };

  successCallback = file => {
    // get new list of files from server, reaction will trigger pending table to reload
    const element = file.previewElement.querySelector('.dap-data-dz-succes');
    if (element) {
      element.style.display = 'block';
    }
  };

  uploadprogressCallback = (file, progress, bytesSent) => {
    const element = file.previewElement.querySelector(
      '.dap-data-dz-uploadprogress'
    );
    if (element) {
      element.value = Math.round((bytesSent / file.size) * 100);
    }
  };

  errorCallback = (file, error) => {
    this.props.notificationService?.addNotification(
      'upload',
      Status.Error,
      `Failed to upload file ${file.name}`,
      error
    );

    // https://github.com/rowanwins/vue-dropzone/issues/238#issuecomment-603003150
    if (typeof error !== 'string') {
      const errElem = file.previewElement.querySelector('[data-dz-errormessage=true]');
      if (errElem) {
        errElem.textContent = `Upload Failed: ${error.message || JSON.stringify(error)}`;
      }
    }

    const element = file.previewElement.querySelector('.dap-data-dz-error');
    if (element) {
      element.style.display = 'block';
    }
  };

  defaultBody = (
    <div className="dz-message" data-dz-message>
      <span>To upload, drop files here or click to browse</span>
    </div>
  );

  render() {
    const { config, djsConfig, eventHandlers } = this.props;
    return (
      <StyledRootDiv>
        <DropzoneComponent
          children={this.defaultBody}
          // Order is important - props above this comment will be completely
          // overwritten if the parent component also passes them in.
          {...this.props}
          config={{ ...this.defaultComponentConfig, ...config }}
          djsConfig={{ ...this.defaultDjsConfig, ...djsConfig }}
          eventHandlers={{
            ...eventHandlers,
            // We need to do our own event handlers even if the parent component
            // passes in custom ones. The event handlers in this class all call
            // the ones passed in via props if applicable.
            success: this.successCallback,
            error: this.errorCallback,
            uploadprogress: this.uploadprogressCallback,
            init: this.dropzoneInit,
          }}
        />
      </StyledRootDiv>
    );
  }
}

export default inject((store: any) => ({
  tokenService: store.tokenService,
}))(FileUpload);
