import * as React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  Typography,
  Card,
} from '@mui/material';
import {
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AddIcon from '@extensions/utils/AddIcon';
import { formatNumber } from '@extensions/utils/format';

const StyledAddIcon = styled(AddIcon)(({ theme }) => (({
  width: '100% !important',
  color: theme.palette.grey[900],
  height: theme.spacing(7),
})));

export interface IMetricCardProps {
  icon: FontAwesomeIconProps['icon'];
  count: number;
  countSubtitle: string;
  children?: React.ReactNode;
  styleObject: any;
}

class MetricCard extends React.Component<IMetricCardProps> {
  render() {
    const { icon, count, countSubtitle, children, styleObject } = this.props;
    const formattedCount = formatNumber(count);

    return (
      <Card>
        <CardContent
          sx={
            Object.keys(styleObject.cardContent).length === 0
              ? {}
              : styleObject.cardContent
          }
        >
          <StyledAddIcon icon={icon} size="4x" />
          <Typography
            sx={
              Object.keys(styleObject.statNum).length === 0
                ? {
                  fontSize: '3rem',
                  textAlign: 'center',
                  color: (theme) => theme.palette.secondary.main,
                }
                : {
                  ...styleObject.statNum,
                  fontSize: '3rem',
                  textAlign: 'center',
                }
            }
          >
            {formattedCount}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={
              Object.keys(styleObject.statLabel).length === 0
                ? {
                  textAlign: 'center'
                }
                : {
                  ...styleObject.statLabel,
                  textAlign: 'center'
                }
            }
          >
            {countSubtitle}
          </Typography>
        </CardContent>
        {Boolean(children) && (
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={
                    Object.keys(styleObject.expandIcon).length === 0
                      ? {}
                      : styleObject.expandIcon
                  }
                />
              }
              sx={
                Object.keys(styleObject.accordionSummary).length === 0
                  ? {}
                  : { '&.MuiAccordionSummary-root': styleObject.accordionSummary }
              }
            >
              <Typography variant="h3" sx={
                Object.keys(styleObject.detailsText).length === 0
                  ? {}
                  : styleObject.detailsText
              }>
                Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
          </Accordion>
        )}
      </Card>
    );
  }
}

export default MetricCard;
