import {
    observable,
    runInAction,
    when,
    computed,
    makeObservable,
} from 'mobx';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Alert
} from '@mui/material';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { styled } from '@mui/material/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
    INotificationService,
    Status,
} from '@extensions/services/INotificationService';
import { ISecurityService } from '@extensions/services/ISecurityService';

import Dataset from '@extensions/models/Dataset';
import Link from '@dapclient/components/core/Link';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import Publication from '@extensions/models/Publication';
import { typeToLabel } from '@extensions/models/DistributionType';
import DatasetGuard from '@extensions/components/core/DatasetGuard';
import QualityWarning from '@extensions/components/dataset/QualityWarning';
import CenteredCircularProgress from '@dapclient/components/core/CenteredCircularProgress';

// App specific imports
const NOTIFICATION_ID = 'PUBLICATION_DATASETS_TABLE';

export interface IPublicationDatasetsProps
    extends RouteComponentProps {
    publication: Publication;
    securityService?: ISecurityService;
    notificationService?: INotificationService;
    tableSize?: 'small' | 'medium';
}

const StyledCode = styled('code')(({ theme }) => ({
    color: theme.palette.grey[500],
    fontSize: theme.spacing(1.75),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: theme.palette.grey[500],
    fontSize: theme.spacing(1.75),
}));

const StyledLink = styled(Link)(({ theme }) => ({
    fontSize: theme.spacing(1.75),
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50]
}));

@observer
export class PublicationDatasets extends React.Component<IPublicationDatasetsProps> {
    @observable
    datasets: Array<{
        dataset: Dataset;
        distributionTypes: string[];
    }> | null = null;

    constructor(props: IPublicationDatasetsProps) {
        super(props);
        makeObservable(this);
    }

    @computed
    get requiresAuth() {
        return !process.env.REACT_APP_SHOW_PUBLIC_DS;
    }

    componentDidMount() {
        if (this.requiresAuth) {
            when(() => this.props.securityService!.userIsLoggedIn, this.fetchData);
        } else {
            this.fetchData();
        }
    }

    fetchData = async () => {
        const { publication, notificationService } = this.props;
        const datasetsToSearch = publication['datasets'] as unknown as string[];
        const queryStrings = datasetsToSearch.map((dataset) => {
            return {
                query_string: {
                    query: dataset["name"].slice(dataset["name"].indexOf('/') + 1),
                    fields: ["name"],
                    default_operator: "and"
                }
            }
        });
        try {
            const datasetSearchResponse = await DapApiAgent.agent
                .post('/api/datasets/_msearch?')
                .send(
                    JSON.stringify({ preference: "SearchResult" }) + "\n" +
                    JSON.stringify({
                        query: {
                            bool: {
                                should: queryStrings
                            }
                        },
                        size: 500,
                    })
                );
            const pubDatasetsNames = publication.datasets.map((dataset) => dataset.name);
            runInAction(() => {
                this.datasets = datasetSearchResponse.body.responses[0].hits.hits.map((hit) => {
                    // Filter out datasets that are associated with the publication
                    if (pubDatasetsNames.includes(hit._source.name)) {
                        const dataset = new Dataset({
                            meta: hit._source,
                            name: hit._source.name,
                        });
                        if (
                            hit._source.dapFileSummary &&
                            hit._source.dapFileSummary.count > 0
                        ) {
                            dataset.setDynamoFileCount(hit._source.dapFileSummary.count);
                            dataset.setDynamoTotalFileSize(hit._source.dapFileSummary.size);
                            dataset.setDynamoLastModified(hit._source.dapFileSummary.updated);
                            dataset.setDynamoDataBegins(hit._source.dapFileSummary.begins);
                            dataset.setDynamoDataEnds(hit._source.dapFileSummary.ends);
                            dataset.setDynamoFullExtensions(hit._source.dapFileSummary.extensions);
                        }
                        return {
                            dataset,
                            distributionTypes: hit._source.distributionType,
                        };
                    }
                    return null;
                });
            });
        } catch (error: any) {
            notificationService!.addNotification(
                NOTIFICATION_ID,
                Status.Error,
                `Could not fetch datasets for publication ${publication.title}`,
                error.message
            );
        }
    };

    renderDatasetTable = () => {
        const tableSize = this.props.tableSize ? this.props.tableSize : 'medium';
        const associatedDatasets = this.datasets?.filter(dataset => dataset);
        if (associatedDatasets) {
            return (
                <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
                    <Table size={tableSize} stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell>
                                    Dataset <small>({associatedDatasets.length})</small>
                                </StyledTableHeadCell>
                                <StyledTableHeadCell align='right'>Data Access Method</StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {associatedDatasets
                                .slice()
                                .sort((a, b) => a.dataset.name.localeCompare(b.dataset.name))
                                .map(({ dataset, distributionTypes }) => {
                                    const datasetUrl = `/ds/${dataset.name}`;
                                    return (
                                        <TableRow
                                            key={dataset.name}
                                        >
                                            <TableCell sx={{ fontSize: '14px' }}>
                                                {this.renderDatasetTitle(datasetUrl, dataset)}
                                            </TableCell>
                                            <StyledTableCell align='right'>
                                                {distributionTypes.map(t => typeToLabel(t)).join(', ')}
                                            </StyledTableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            );
        } else return null;
    };

    renderDatasetTitle = (datasetUrl: string, dataset: Dataset) => {
        return (
            <>
                <StyledCode>{dataset.name}</StyledCode>
                {Boolean(dataset && dataset.events && dataset.events.some(e => e.affectsDataQuality)) && (
                    <QualityWarning datasetName={dataset.name} />
                )}
                <br />
                <StyledLink to={datasetUrl}>
                    {dataset.title}
                </StyledLink>
            </>
        )
    }

    render() {
        let requiresAuth = this.requiresAuth;
        let content: React.ReactNode;
        if (this.datasets === null) {
            content = <CenteredCircularProgress color="secondary" />;
        } else if (this.datasets && this.datasets.length > 0) {
            content = this.renderDatasetTable();
        } else if (this.datasets && this.datasets.length === 0) {
            requiresAuth = true;
        } else {
            content = <Alert severity="info">No Associated Dataset Found.</Alert>;
        }

        if (requiresAuth) {
            return <DatasetGuard>{content}</DatasetGuard>;
        }
        return <>{content}</>;
    }
}

export default inject((store: any) => ({
    securityService: store.securityService,
    notificationService: store.notificationService,
}))(withRouter(PublicationDatasets));
