import * as React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';

import Permission from '@extensions/models/Permission';
import IControlledAccess from '@extensions/models/IControlledAccess';

export interface IPermissionsTableProps {
  accessControlledItem: IControlledAccess;
}

class PermissionsTable extends React.Component<
  IPermissionsTableProps
> {
  public render() {
    return (
      <Table size="small">
        <TableBody>
          {this.props.accessControlledItem
            .getPermissions()
            .map((row: Permission) => (
              <TableRow key={row.action}>
                <TableCell align="left" sx={{fontSize: '14px'}}>{row.action}</TableCell>
                <TableCell align="left" sx={{fontSize: '14px'}}>{row.roleDescription}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  }
}

export default PermissionsTable;
