import React from 'react';
import { inject, observer } from 'mobx-react';
import { UseFormReturn } from 'react-hook-form';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { styled } from '@mui/material/styles';
import { Button, Card, Typography, Grid, CardContent } from '@mui/material';

import AddIcon from '@extensions/utils/AddIcon';
import { PubAuthor } from '@extensions/models/Publication';
import { PublicationFormData } from '@extensions/models/PublicationFormData';
import ReactHookTextField from '@extensions/components/publications/ReactHookTextField';
import ReactHookFieldArray from '@extensions/components/publications/ReactHookFeildArray';
import theme from '@extensions/services/Theme';

const StyledCard = styled(Card)(({
  marginBottom: '0.5rem',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#e0e0e0',
  color: '#000',
  '&:hover': {
    backgroundColor: '#bab8b8',
  }
}));

export interface IAuthorsFieldProps {
  className?: string;
  control: UseFormReturn<PublicationFormData>['control'];
}

export interface IAuthorsFieldState { }

@observer
export class AuthorsField extends React.Component<
  IAuthorsFieldProps,
  IAuthorsFieldState
> {
  getNewAuthor = (): PubAuthor => ({
    firstName: '',
    lastName: '',
    affiliation: {
      name: '',
    },
  });

  render() {
    const { control } = this.props;
    return (
      <ReactHookFieldArray
        fieldArrayProps={{
          name: 'authors',
          control,
        }}
      >
        {({ append, fields, remove }) => {
          return (
            <div>
              <Typography variant="h2">Authors</Typography>
              {fields.length === 0 && (
                <Typography variant='body1' sx={{ color: theme.palette.grey[700] }}>No Authors Provided</Typography>
              )}
              {fields.map((field, index) => (
                <StyledCard key={field.id}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <ReactHookTextField
                          fieldName={`authors.${index}.firstName`}
                          textFieldProps={{
                            label: 'First Name',
                            required: true,
                          }}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <ReactHookTextField
                          fieldName={`authors.${index}.lastName`}
                          textFieldProps={{
                            label: 'Last Name',
                            required: true,
                          }}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ReactHookTextField
                          fieldName={`authors.${index}.affiliation.name`}
                          control={control}
                          textFieldProps={{
                            multiline: true,
                            rows: 3,
                            label: 'Affiliation',
                            required: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <StyledButton
                          startIcon={
                            <AddIcon icon={faTrash} size="xs" />
                          }
                          size="small"
                          variant="contained"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </CardContent>
                </StyledCard>
              ))}
              <StyledButton
                onClick={() => append(this.getNewAuthor())}
                variant="contained"
              >
                + Add Author
              </StyledButton>
            </div>
          );
        }}
      </ReactHookFieldArray>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(AuthorsField);
