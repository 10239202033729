import Permission, { PermissionAction } from '@extensions/models/Permission';

export enum AccessRestrictionType {
  NONE = 'none',
  COMMUNITY = 'community',
  PROJECT = 'project',
  PROJECT_MFA = 'project-mfa',
  DATASET = 'dataset',
  DATASET_MFA = 'dataset-mfa',
}

export default class AccessRestriction {
  static restrictions = {
    none: new AccessRestriction('none', 'Public'),
    community: new AccessRestriction('community', 'Open to the community'),
    project: new AccessRestriction('project', 'Under project access control'),
    projectMfa: new AccessRestriction(
      'project-mfa',
      'Under project multifactor authentication based access control'
    ),
    dataset: new AccessRestriction('dataset', 'Under dataset access control'),
    datasetMfa: new AccessRestriction(
      'dataset-mfa',
      'Under dataset multifactor authentication based access control'
    ),
  };

  static requiresMfa = (accessRestriction: AccessRestriction) => {
    return (
      accessRestriction === AccessRestriction.restrictions.projectMfa ||
      accessRestriction === AccessRestriction.restrictions.datasetMfa
    );
  };

  static getPermissionLabel = (
    accessRestriction: AccessRestriction,
    datasetName: string,
    projectName: string
  ) => {
    const formattedProjectName = projectName ? projectName.toUpperCase() : '';
    const formattedDatasetName = datasetName ? datasetName.toUpperCase() : '';
    let getPermissionLabel = '';
    if (accessRestriction === AccessRestriction.restrictions.none) {
      getPermissionLabel = 'General Public';
    } else if (accessRestriction === AccessRestriction.restrictions.community) {
      getPermissionLabel = 'All Livewire Users';
    } else if (accessRestriction === AccessRestriction.restrictions.project) {
      getPermissionLabel = `Restricted to ${formattedProjectName} Project Members`;
    } else if (
      accessRestriction === AccessRestriction.restrictions.projectMfa
    ) {
      getPermissionLabel = `Restricted to ${formattedProjectName} Project Members using MFA`;
    } else if (accessRestriction === AccessRestriction.restrictions.dataset) {
      getPermissionLabel = `Restricted to ${formattedDatasetName} Dataset Members`;
    } else if (
      accessRestriction === AccessRestriction.restrictions.datasetMfa
    ) {
      getPermissionLabel = `Restricted to ${formattedDatasetName} Dataset Members using MFA`;
    }
    return getPermissionLabel;
  };

  static getPermissions = (
    accessRestriction: AccessRestriction,
    datasetName: string,
    projectName: string
  ) => {
    const formattedProjectName = projectName ? projectName.toUpperCase() : '';
    const formattedDatasetName = datasetName ? datasetName.toUpperCase() : '';

    let permissions: Permission[] = [];
    if (accessRestriction === AccessRestriction.restrictions.none) {
      permissions = [
        new Permission(PermissionAction.VIEW_METADATA, 'General Public'),
        new Permission(PermissionAction.DOWNLOAD_DATA, 'General Public'),
        new Permission(
          PermissionAction.UPLOAD_DATA,
          `${formattedProjectName} Project Editors`
        ),
        new Permission(
          PermissionAction.MANAGE_ACCESS,
          `${formattedProjectName} Project Leads`
        ),
      ];
    } else if (accessRestriction === AccessRestriction.restrictions.community) {
      permissions = [
        new Permission(PermissionAction.VIEW_METADATA, 'All Livewire Users'),
        new Permission(PermissionAction.DOWNLOAD_DATA, 'All Livewire Users'),
        new Permission(
          PermissionAction.UPLOAD_DATA,
          `${formattedProjectName} Project Editors`
        ),
        new Permission(
          PermissionAction.MANAGE_ACCESS,
          `${formattedProjectName} Project Leads`
        ),
      ];
    } else if (
      accessRestriction === AccessRestriction.restrictions.project ||
      accessRestriction === AccessRestriction.restrictions.projectMfa
    ) {
      permissions = [
        new Permission(
          PermissionAction.VIEW_METADATA,
          `${formattedProjectName} Project Members`
        ),
        new Permission(
          PermissionAction.DOWNLOAD_DATA,
          `${formattedProjectName} Project Members`
        ),
        new Permission(
          PermissionAction.UPLOAD_DATA,
          `${formattedProjectName} Project Editors`
        ),
        new Permission(
          PermissionAction.MANAGE_ACCESS,
          `${formattedProjectName} Project Leads`
        ),
      ];
    } else if (
      accessRestriction === AccessRestriction.restrictions.dataset ||
      accessRestriction === AccessRestriction.restrictions.datasetMfa
    ) {
      permissions = [
        new Permission(
          PermissionAction.VIEW_METADATA,
          `${formattedDatasetName} Dataset Members`
        ),
        new Permission(
          PermissionAction.DOWNLOAD_DATA,
          `${formattedDatasetName} Dataset Members`
        ),
        new Permission(
          PermissionAction.UPLOAD_DATA,
          `${formattedDatasetName} Dataset Editors`
        ),
        new Permission(
          PermissionAction.MANAGE_ACCESS,
          `${formattedProjectName} Project and ${formattedDatasetName} Dataset Leads`
        ),
      ];
    }
    return permissions;
  };

  restrictionType: string;
  title: string;

  constructor(restrictionType, title) {
    this.restrictionType = restrictionType;
    this.title = title;
  }
}
