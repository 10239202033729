import chroma from 'chroma-js';
import { createTheme } from '@mui/material/styles';
import 'fontsource-ibm-plex-mono';
import { green, grey } from '@mui/material/colors';
 
const overriddenDefaultPalette = {
  primary: {
    main: '#005B82',
  },
  secondary: {
    main: '#69BE28',
  },
  highlight: {
    main: '#FEFFE6',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  metrics: {
    main: '#53b03f',
  },
  warning: {
    main: '#faad14',
  },
  timeline: {
    primary: 'rgb(108, 176, 70)',
    secondary: 'rgb(0, 169, 224)',
    chromaGradientScale: chroma.scale('Oranges'),
  },
  background: {
    paper: '#fff',
    default: '#fafafa'
  },
};
 
const theme = createTheme({
  palette: overriddenDefaultPalette,
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h1: {
      color: overriddenDefaultPalette.common.black,
      fontWeight: 'bold',
      fontSize: '1.5rem',
      fontFamily: "'Oswald', sans-serif",
    },
    h2: {
      color: overriddenDefaultPalette.common.black,
      fontWeight: 'regular',
      fontSize: '1.25rem',
      fontFamily: "'Oswald', sans-serif",
    },
    h3: {
      color: overriddenDefaultPalette.common.black,
      fontWeight: 'medium',
      fontSize: '1rem',
      fontFamily: "'Oswald', sans-serif",
    },
    h4: {
      color: overriddenDefaultPalette.common.black,
      fontWeight: 'medium',
      fontSize: '0.875rem',
      fontFamily: "'Oswald', sans-serif",
    },
    h5: {
      color: overriddenDefaultPalette.common.black,
      fontSize: '0.875rem',
      fontFamily: "'Oswald', sans-serif",
    },
    h6: {
      color: overriddenDefaultPalette.common.black,
      fontSize: '0.875rem',
      fontFamily: "'Oswald', sans-serif",
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: '1.5715',
      fontVariant: 'tabular-nums',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.5715',
      fontVariant: 'tabular-nums',
    },
    subtitle1: {
      color: overriddenDefaultPalette.common.black,
    },
  },
  contentWidth: {
    min: '1000px',
    max: '650px',
  },
  FilterTitle: {
    root: {
      backgroundColor: grey[200],
      marginTop: '15px',
    },
    titleText: {},
  },
  linkIcon: {
    marginBottom: 0,
  },
  FullPageForm: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      marginBottom: 15,
      maxWidth: 450,
    },
    formFooter: {
      marginBottom: 15,
      textAlign: 'center',
    },
  },
  Stat: {
    statValue: {
      fontSize: '2rem',
      lineHeight: 1,
      fontFamily: "'Open Sans', sans-serif",
    },
    statDescription: {
      fontSize: 10,
    },
  },
  GreenButton: {
    root: {
      backgroundColor: green[800],
      '&:hover': {
        backgroundColor: green[900],
      },
    },
  },
  openSansFontFamily: "'Open Sans', sans-serif !important",
  MuiAlert: {
    outlinedSuccess: {
      border: '1px solid #b7eb8f',
      backgroundColor: '#f6ffed',
      color: 'rgba(0,0,0,0.65)',
    },
    outlinedInfo: {
      border: '1px solid #91d5ff',
      backgroundColor: '#e6f7ff',
      color: 'rgba(0,0,0,0.65)',
    },
    outlinedWarning: {
      border: '1px solid #ffe58f',
      backgroundColor: '#fffbe6',
      color: 'rgba(0,0,0,0.65)',
    },
    outlinedError: {
      border: '1px solid #ffccc7',
      backgroundColor: '#fff2f0',
      color: 'rgba(0,0,0,0.65)',
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&&:before': {
            opacity: '0',
          },
          border: '1px solid rgba(0, 0, 0, .125)',
          borderBottom: 'none',
          '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: overriddenDefaultPalette.common.black,
          backgroundColor: '#f8f8f8',
          '& p': {
            margin: '0 !important',
          },
        },
        content: {
          marginBottom: 3,
          marginTop: 3,
          '&.Mui-expanded': {
            marginBottom: 3,
            marginTop: 3,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(0, 0, 0, .125)',
          padding: '23px 16px',
        },
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          color: overriddenDefaultPalette.common.black,
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: overriddenDefaultPalette.common.black,
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
        indicator: {
          backgroundColor: overriddenDefaultPalette.secondary.main,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#005b82',
          '&:hover': {
            color: '#005b82',
          },
          fontWeight: 400,
        },
      },
    },
  }
});
 
export default theme;