import { makeAutoObservable } from 'mobx';

import ILayoutService from '@extensions/services/ILayoutService';

export default class LayoutService implements ILayoutService {
  fullWidth: boolean;

  constructor(fullWidth: boolean = false) {
    this.fullWidth = fullWidth;

    makeAutoObservable(this);
  }

  setFullWidth(yes: boolean = true) {
    this.fullWidth = yes;
  }
}
