import { inject } from 'mobx-react';
import { ProjectOverview as DapProjectOverview } from '@dapclient/components/project/ProjectOverview';
import Project from '@extensions/models/Project';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Link from '@dapclient/components/core/Link';

export class ProjectOverview extends DapProjectOverview {
  getReferencesPanel = (project: Project) => {
    if (project.references && project.references.length) {
      return this.getPanel({
        title: 'References',
        body: (
            <TableContainer component={Paper} style={{maxHeight: '500px'}}>
              <Table size={'medium'} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Reference Title
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    project.references
                    .slice()
                    .map((reference) => {
                      return (
                        <TableRow key={reference.referenceURL}>
                          <TableCell>
                            <Link to={reference.referenceURL}>{reference.referenceTitle}</Link>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          
        )
      })
    }
    return null;
  };
}

export default inject((store: any) => ({
  projectService: store.projectService,
  datasetService: store.datasetService,
  metricsService: store.metricsService,
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(ProjectOverview);
