import React from 'react';
import { observer } from 'mobx-react';
import { observable, action, makeObservable } from 'mobx';

import {
  Slider,
  Typography,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SliderValueLabelProps } from '@mui/material/Slider';

import isEqual from 'lodash/isEqual';
import { react } from '@appbaseio/reactivesearch/lib/types';
import { ReactiveComponent } from '@appbaseio/reactivesearch';

import theme from '@extensions/services/Theme';

import FilterTitle from '@extensions/components/search-core/FilterTitle';

class ValueLabelComponent extends React.Component<SliderValueLabelProps> {
  render() {
    const { value, children } = this.props;
    return <Tooltip
      title={value}
      sx={{
        top: `-${theme.typography.pxToRem(66)}`,
        left: `calc(-50% - 1.25rem)`,
        '& > span:nth-of-type(1)': {
          width: theme.typography.pxToRem(64),
          height: theme.typography.pxToRem(64),
        },
      }}
    >{children}</Tooltip>;
  }
}

const StyledRootDiv = styled('div', {
  shouldForwardProp: (props) => props !== 'showBottomBorder'
})<{ showBottomBorder?: boolean | undefined }>(({ showBottomBorder }) => ({
  borderBottom: showBottomBorder ? `1px solid ${theme.palette.grey['400']}` : '',
  margin: `${theme.spacing(1)} 0`
}));

const StyledLegendDiv = styled('div')(({
  display: 'flex',
  justifyContent: 'space-between',
}));

interface IAttachedInputsProps {
  value: string;
  setQuery: ({ query, value }: { query: any; value: string | null }) => void;
  error: any;
  loading: boolean;
  widestRange: null | {
    min: number;
    max: number;
  };
  dataField: string;
  formatValue?: (value: number) => string;
  title: string;
  componentId: string;
  showBottomBorder?: boolean;
}

@observer
class _AttachedInputs extends React.Component<IAttachedInputsProps> {
  @observable
  sliderValues: [number, number] = [0, 0];

  constructor(props) {
    super(props);
    makeObservable(this);
    this.initializeSliderValues();
  }

  componentDidMount() {
    this.updateAfterPropsChange();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.widestRange, this.props.widestRange)) {
      this.initializeSliderValues();
    }
    if (!isEqual(prevProps.value, this.props.value)) {
      this.updateAfterPropsChange();
    }
  }

  encodeValue = (value: [number, number]): string => {
    return `${value[0]} - ${value[1]}`;
  };

  decodeValue = (value: string): [number, number] => {
    const { widestRange } = this.props;
    if (value) {
      const [minStr, maxStr] = value.split(' - ');
      const bringIntoRange = (value: number): number => {
        if (widestRange) {
          return Math.max(widestRange.min, Math.min(widestRange.max, value));
        }
        return value;
      };

      try {
        return [bringIntoRange(+minStr), bringIntoRange(+maxStr)];
      } catch {
        return [0, 0];
      }
    }

    if (widestRange) {
      return [widestRange.min, widestRange.max];
    }

    return [0, 0];
  };

  @action
  updateAfterPropsChange = () => {
    const { value } = this.props;
    if (value) {
      this.sliderValues = this.decodeValue(value);
      this.setReactiveValue(this.sliderValues);
    } else {
      this.initializeSliderValues();
      this.setReactiveValue(null);
    }
  };

  setReactiveValue = (values: [number, number] | null) => {
    const { setQuery, dataField } = this.props;
    if (values) {
      setQuery({
        query: {
          range: {
            [dataField]: {
              gte: values[0],
              lte: values[1],
            },
          },
        },
        value: this.encodeValue(values),
      });
    } else {
      setQuery({
        query: {
          match_all: {},
        },
        value: null,
      });
    }
  };

  @action
  initializeSliderValues = () => {
    const { widestRange, value } = this.props;
    if (!value) {
      if (widestRange !== null) {
        const { min, max } = widestRange;
        this.setSliderValues([min, max]);
      } else {
        this.setSliderValues([0, 0]);
      }
    }
  };

  @action
  setSliderValues = (newValues: [number, number]) =>
    (this.sliderValues = newValues);

  @action
  handleChange = (event: any, newValue: number | number[]) => {
    this.setSliderValues(newValue as [number, number]);
  };

  formatValue = (value: number): string => value.toLocaleString();

  render() {
    const {
      widestRange,
      error,
      loading,
      title,
      componentId,
      showBottomBorder,
      formatValue = this.formatValue,
    } = this.props;
    if (
      error ||
      loading ||
      widestRange === null ||
      widestRange.min === null ||
      widestRange.max === null
    ) {
      return null;
    }
    const titleElementId = `${componentId}-title`;
    return (
      <StyledRootDiv
        showBottomBorder={showBottomBorder}
      >
        <FilterTitle id={titleElementId}>{title}</FilterTitle>
        <Slider
          value={[...this.sliderValues]}
          onChange={this.handleChange}
          onChangeCommitted={() => this.setReactiveValue(this.sliderValues)}
          min={widestRange.min}
          max={widestRange.max}
          aria-labelledby={titleElementId}
          valueLabelDisplay="auto"
          getAriaValueText={(value: number) => `${value}`}
          valueLabelFormat={formatValue}
          // ValueLabelComponent={ValueLabel}
          slots={{
            valueLabel: ValueLabelComponent,
          }}
          sx={{
            '& .MuiSlider-thumb': {
              marginTop: '79px',
              width: '12px',
              height: '12px',
            }
          }}
        />

        <StyledLegendDiv>
          <Typography>{formatValue(widestRange.min)}</Typography>
          <Typography>{formatValue(widestRange.max)}</Typography>
        </StyledLegendDiv>
      </StyledRootDiv>
    );
  }
}
const AttachedInputs = _AttachedInputs;

export interface INumericRangeProps {
  className?: string;
  title: string;
  filterLabel?: string;
  showBottomBorder?: boolean;
  componentId: string;
  dataField: string;
  react?: react;
  formatValue?: (value: number) => string;
}

class NumericRange extends React.Component<INumericRangeProps> {
  render() {
    const {
      title,
      showBottomBorder,
      componentId,
      filterLabel,
      react,
      dataField,
      formatValue,
    } = this.props;

    return (
      <div>
        <ReactiveComponent
          componentId={componentId}
          URLParams
          filterLabel={filterLabel || title}
          react={react}
          defaultQuery={() => ({
            query: {
              match_all: {},
            },
            size: 0,
            aggs: {
              max: {
                max: {
                  field: dataField,
                },
              },
              min: {
                min: {
                  field: dataField,
                },
              },
            },
          })}
          render={({ error, loading, value, setQuery, aggregations }) => {
            let widestRange: null | {
              min: number;
              max: number;
            } = null;
            if (aggregations !== null) {
              widestRange = {
                min: aggregations.min.value,
                max: aggregations.max.value,
              };
            }
            return (
              <AttachedInputs
                {...{ error, loading, value, setQuery }}
                widestRange={widestRange}
                dataField={dataField}
                formatValue={formatValue}
                title={title}
                componentId={componentId}
                showBottomBorder={showBottomBorder}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default NumericRange;
