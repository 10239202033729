import React from 'react';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ContactPoint from '@extensions/models/ContactPoint';
import ContactsTable from '@extensions/components/core/ContactsTable';

export interface IContactsPanelProps {
  contacts: ContactPoint[];
  /**
   * Project or Dataset name/identifier
   */
  name?: string;
  /**
   * Project or Dataset title
   */
  title?: string;
}

const ContactsPanel: React.FunctionComponent<IContactsPanelProps> = props => {
  const { contacts, name, title } = props;
  // leaving commented out to swap back in since table is a bit squished for dataset page
  // const contactsGrid = contacts.map((contact: ContactPoint, index: number) => (
  //   <Grid
  //     className={classes.gridRow}
  //     item
  //     xs={12}
  //     key={`reflink${contact.email}`}
  //   >
  //     <Link target="_blank" href={contact.email}>
  //       {contact.displayName}
  //     </Link>
  //     {contact.role && (
  //       <Typography component="p" color="textSecondary">
  //         {contact.role}
  //       </Typography>
  //     )}
  //     {contact.org && (
  //       <Typography component="p" color="textSecondary">
  //         {contact.org}
  //       </Typography>
  //     )}
  //     {/* <Divider /> */}
  //     {/* {index < contacts.length - 1 && <Divider />} */}
  //   </Grid>
  // ));

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="contacts-content"
        id="contacts-header"
      >
        <Typography variant="h3">Contacts ({contacts.length})</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <ContactsTable
            contacts={contacts}
            name={name}
            title={title}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default ContactsPanel;
