export const formatBytes = (b: number) => {
  const bins = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let value = b;
  for (const bin of bins) {
    if (value < 1024) {
      return `${bin === 'B' ? Math.round(value) : value.toFixed(1)} ${bin}`;
    }
    value /= 1024;
  }
  return `${value.toFixed(1)} ${bins[bins.length - 1]}`;
};

const NUMBER_FORMATTER = Intl.NumberFormat('en', { notation: 'compact' });
export const formatNumber = (value: number) => {
  return NUMBER_FORMATTER.format(value);
};

export const escapeHtml = (txt: string) => {
  return txt.replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
};
