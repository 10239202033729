import * as React from 'react';
import { inject, observer } from 'mobx-react';

import {
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { IProjectService } from '@extensions/services/IProjectService';
import { IMembershipService } from '@extensions/services/IMembershipService';
import { INotificationService } from '@extensions/services/INotificationService';

import Project from '@extensions/models/Project';
import DatasetList from '@extensions/components/project/DatasetList';
import ManageRoles from '@extensions/components/membership/ManageRoles';
import AccessRequestList from '@extensions/components/membership/AccessRequestList';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  paddingTop: theme.spacing(2),
  '& .Mui-selected': {
    background: '#e6f0e1',
  },
}));

const StyledTab = styled(Tab)(({theme}) => ({
  '&.MuiTab-root': {
    color: theme.palette.text.primary,
  },
  '&.Mui-selected': {
    color: '#000',
  }
}));

const StyledDiv = styled('div')(({
  marginTop: '15px',
  paddingBottom: '2px'
}));
export interface IManagePermissionsProps {
  membershipService: IMembershipService;
  notificationService: INotificationService;
  projectService: IProjectService;
  project?: Project;
}

export interface IManagePermissionsState {
  activeTab: number;
}

@inject('projectService', 'membershipService', 'notificationService')
@observer
class ManagePermissions extends React.Component<
  IManagePermissionsProps,
  IManagePermissionsState
> {
  static defaultProps = {
    membershipService: undefined,
    notificationService: undefined,
    projectService: undefined,
    datasetService: undefined,
  };
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };
  }

  public render() {
    const { projectService } = this.props;
    const { activeTab } = this.state;
    const project: Project | null = projectService.project;

    let content = <div />;
    if (project) {
      const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ activeTab: newValue });
      };
      content = (
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={2}>
            <StyledTabs
              orientation="vertical"
              value={activeTab}
              indicatorColor="secondary"
              textColor="primary"
              aria-label="project tabs"
              onChange={handleChange}
            >
              <StyledTab label="Project" />
              <StyledTab label="Dataset" />
            </StyledTabs>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ mt: 2 }}>
            {activeTab === 0 && (
              <React.Fragment>
                <ManageRoles accessControlledItem={project} />
                <StyledDiv>
                  <AccessRequestList />
                </StyledDiv>
              </React.Fragment>
            )}
            {activeTab === 1 && <DatasetList />}
          </Grid>
        </Grid>
      );
    }

    return content;
  }
}

export default ManagePermissions;
