import React from 'react';
import { inject, observer } from 'mobx-react';

import { Typography } from '@mui/material';

import { IPublicationService } from '@extensions/services/IPublicationService';

import Publication, { PublicationType } from '@extensions/models/Publication';
import PublicationSummary from '@extensions/components/publications/PublicationSummary';
import FormButtonsAndErrors from '@extensions/components/publications/FormButtonsAndErrors';

export interface IReviewSubmissionProps {
  className?: string;
  publication: Publication;
  moveBackward: () => void;
  clickCancelBtn: () => void;
  canMoveBackward: boolean;
  publicationService?: IPublicationService;
  isEdit?: boolean;
}

export interface IReviewSubmissionState { }

@observer
export class ReviewSubmission extends React.Component<
  IReviewSubmissionProps,
  IReviewSubmissionState
> {
  submit = () => {
    const { publication, publicationService, isEdit } = this.props;
    if (isEdit !== undefined) {
      publicationService?.submit(publication, isEdit);
    } else {
      publicationService?.submit(publication, false);
    }
  };
  render() {
    const { publication, moveBackward, clickCancelBtn, canMoveBackward } =
      this.props;
    return (
      <div>
        {
          publication.type === PublicationType.REFERENCE_DOCUMENT
            ? <Typography>Review the reference document and submit to finish.</Typography>
            : <Typography>Review the publication and submit to finish.</Typography>
        }
        <PublicationSummary publication={publication} editMode />
        <FormButtonsAndErrors
          moveForward={this.submit}
          moveForwardText="Submit"
          moveBackward={moveBackward}
          clickCancelBtn={clickCancelBtn}
          canMoveBackward={canMoveBackward}
          canMoveForward={true}
          error={null}
        />
      </div>
    );
  }
}

export default inject((store: any) => ({
  publicationService: store.publicationService,
}))(ReviewSubmission);
