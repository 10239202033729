import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

export interface IScrollToTopProps extends RouteComponentProps {}

class ScrollToTop extends Component<IScrollToTopProps> {
  componentDidUpdate(prevProps: RouteComponentProps): void {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
