import { computed, observable, runInAction, makeObservable } from 'mobx';

import { ISecurityService } from '@extensions/services/ISecurityService';
import { INotificationService } from '@extensions/services/INotificationService';

import { fetchSchema } from '@extensions/utils/metadata';
import MetadataSchema from '@extensions/models/MetadataSchema';

// Dataset which may or may not be registered yet
export default class MetadataDest {
  notificationService: INotificationService;
  securityService: ISecurityService;
  @observable
  datasetName: string;
  @observable
  projectName: string;
  @computed
  get notificationId(): string {
    return `${this.datasetName}_METADATA_DEST`;
  }
  /**
   * Used to identify the format of the metadata.
   * @example "Instrument"
   */
  type: string;
  /**
   * Short human readable label.
   */
  shortName: string;
  /** Is metadata already published for this dataset? */
  @observable
  isPublished: boolean;
  /** Does a metadata document already exist in draft form for this dataset? */
  @observable
  hasDocument: boolean;
  @observable
  schema: MetadataSchema | null = null;

  constructor(data, notificationService, securityService) {
    makeObservable(this);
    this.projectName = data.projectName;
    this.datasetName = `${data.projectName}/${data.datasetName}`;
    this.type = data.type;
    this.shortName = data.shortName;
    this.isPublished = data.isPublished;
    this.hasDocument = data.hasDocument;
    this.notificationService = notificationService;
    this.securityService = securityService;
  }

  loadSchema = (): Promise<void> => {
    const fetchAndHydrate = async () => {
      const schemaResp = await fetchSchema({
        projectName: this.projectName,
        type: this.type,
      });
      const schema = new MetadataSchema(schemaResp);
      runInAction(() => {
        this.schema = schema;
      });
    };
    return this.notificationService.showStateInUI({
      pending: fetchAndHydrate(),
      notificationId: this.notificationId,
      errorMessage: 'Failed to Load Form Data',
    });
  };
}
