export enum PermissionAction {
  VIEW_METADATA = 'View Metadata',
  DOWNLOAD_DATA = 'Download Data',
  UPLOAD_DATA = 'Upload Data',
  MANAGE_ACCESS = 'Manage Access',
}

export default class Permission {
  action: PermissionAction;
  roleDescription: string;

  constructor(action: PermissionAction, roleDescription: string) {
    this.action = action;
    this.roleDescription = roleDescription;
  }
}
