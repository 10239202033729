import React from 'react';
import { inject, observer } from 'mobx-react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';

import { IPublicationService } from '@extensions/services/IPublicationService';

import { PublicationType } from '@extensions/models/Publication';
import { PublicationFormData } from '@extensions/models/PublicationFormData';
import theme from '@extensions/services/Theme';

export interface ITypeFieldProps {
  className?: string;
  control: UseFormReturn<PublicationFormData>['control'];
  publicationService?: IPublicationService;
}

export interface ITypeFieldState { }

@observer
export class TypeField extends React.Component<
  ITypeFieldProps,
  ITypeFieldState
> {
  renderRadioGroup = () => {
    const { publicationService } = this.props;
    return Object.values(PublicationType).slice(0, 3).map((type, idx) => {
      return <FormControlLabel
        key={idx}
        value={type}
        control={<Radio color='secondary' />}
        label={publicationService?.prettyPublicationType(type)}
        sx={{
          '& .MuiFormControlLabel-label': {
            color: theme.palette.grey[700]
          }
        }}
      />
    })
  }
  render() {
    const { control } = this.props;
    return (
      <Controller
        control={control}
        name="type"
        render={({ field }) => {
          return (
            <FormControl
              component="fieldset"
            >
              <FormLabel component="legend">Publication Type</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              >
                {this.renderRadioGroup()}
              </RadioGroup>
            </FormControl>
          );
        }}
      />
    );
  }
}

export default inject((store: any) => ({
  publicationService: store.publicationService,
}))(TypeField);
