import React from 'react';
import { inject, observer } from 'mobx-react';

import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';
import Link from '@extensions/components/core/Link';
import SignInLink from '@dapclient/components/core/SignInLink';

const StyledAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedError,
}));

export interface IMustSignInAlertProps {
  className?: string;
  actionDescription: string;
}

export interface IMustSignInAlertState { }

@observer
export class MustSignInAlert<
  ExtraProps extends object = {}
> extends React.Component<
  IMustSignInAlertProps & ExtraProps,
  IMustSignInAlertState
> {
  renderSignInLink = (): React.ReactNode => {
    return <SignInLink styleObject={{ verticalAlign: 'top' }}>Sign In</SignInLink>;
  };

  renderRegisterLink = (): React.ReactNode => {
    return <Link to="/register" sx={{ verticalAlign: 'top' }}>Sign Up</Link>;
  };

  render() {
    const { actionDescription } = this.props;
    return (
      <StyledAlert
        severity="error"
      >
        <span>You must&nbsp;{this.renderSignInLink()}&nbsp;in order to {actionDescription}.</span>
        <p>Don't have an account? {this.renderRegisterLink()}</p>
      </StyledAlert>
    );
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(MustSignInAlert);
