import React from 'react';

export interface IFiltersWrapperProps {
  /** Will be read by screen readers as a label for the filters bar */
  ariaLabel?: string;
  children: React.ReactNode;
}

export type IFilterComponent = {
  reactiveId: string;
} & React.ComponentType<any>;

class FiltersWrapper extends React.Component<
  IFiltersWrapperProps
> {
  render() {
    const { ariaLabel, children } = this.props;
    return (
      <section
        aria-label={ariaLabel || 'search filters'}
      >
        {children}
      </section>
    );
  }
}

export default FiltersWrapper;
