import React from 'react';
import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Typography, Paper, TableContainer, TableHead, Table, TableRow, TableCell, TableBody } from '@mui/material';

import eereLogo from '@extensions/resources/eere_logo_horiz_green_reversed.jpg';
import greenDivider from '@extensions/resources/green-divider_original.png';

import Link from '@extensions/components/core/Link';
import NewsletterService from '@extensions/services/NewsletterService';
import CenteredCircularProgress from '@dapclient/components/core/CenteredCircularProgress';
import theme from '@extensions/services/Theme';

const StyledPaper = styled(Paper)(({
    paddingBottom: '1rem',
}));

const StyledSpinnerDiv = styled('div')(({
    marginTop: '2rem',
    marginBottom: '2rem',
}));

type TableContainerExtraProps = {
    component: React.ElementType;
};
const StyledTableContainer = styled(TableContainer)<TableContainerExtraProps>(({
    marginTop: '2rem',
    marginBottom: '2rem'
}));

const StyledTableCell = styled(TableCell)(({
    whiteSpace: 'nowrap',
}));

const StyledAlertDiv = styled('div')(({
    margin: '2rem 0 2rem'
}));

const StyledImage = styled('img')(({
    width: '100%'
}));

const StyledNewsContainerDiv = styled('div')(({
    marginLeft: '3rem',
    marginRight: '3rem',
}));

const StyledTypographyHOne = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.7rem',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main
}));

const StyledTypographyBodyTwo = styled(Typography)<TableContainerExtraProps>(({ theme }) => ({
    color: 'gray',
    fontStyle: 'italic',
    fontSize: '14px'
}));

const StyledEmailLink = styled(Link)(({ theme }) => ({
    fontStyle: 'italic',
    fontSize: '14px',
}));

const StyledDateLink = styled(Link)(({ theme }) => ({
    fontSize: '14px',
}));

const StyledGreenDividerImg = styled('img')(({ theme }) => ({
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
}));

export interface INewsletterListProps extends RouteComponentProps {
    className?: string;
    newsletterService?: NewsletterService;
}

export interface INewsletterListState { }

@observer
export class NewsletterList extends React.Component<
    INewsletterListProps,
    INewsletterListState
> {
    componentDidMount() {
        this.props.newsletterService &&
            this.props.newsletterService.emptyNewsletters();
        this.props.newsletterService &&
            this.props.newsletterService.loadNewsletters();
    }

    renderContent() {
        const { newsletterService } = this.props;
        if (newsletterService) {
            const { newsletters } = newsletterService;
            if (newsletters === null) {
                return (
                    <StyledSpinnerDiv>
                        <CenteredCircularProgress />
                    </StyledSpinnerDiv>
                )
            } else if (newsletters && newsletters.length) {
                return (
                    <StyledTableContainer component={Paper} >
                        <Table aria-label="newsletter list table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>
                                        Published Date
                                    </StyledTableCell>
                                    <TableCell>
                                        Newsletter Title
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {newsletters.map((news) => (
                                    <TableRow key={news.id}>
                                        <StyledTableCell sx={{fontSize: '14px'}}>
                                            <StyledDateLink to={`/news/${news.id}`}>{news.published_date}</StyledDateLink>
                                        </StyledTableCell>
                                        <TableCell component="th" scope="row" sx={{ fontSize: '14px', color: theme.palette.grey[900] }}>
                                            {news.title}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                )
            }
        }
        return (
            <StyledAlertDiv>
                <Alert severity="info">No Newsletter is Found.</Alert>
            </StyledAlertDiv>
        );
    }

    render() {
        return (
            <div>
                <StyledPaper elevation={9}>
                    <StyledImage src={eereLogo} alt='eere-logo' />
                    <StyledNewsContainerDiv>
                        <StyledTypographyHOne variant='h1'>
                            Livewire Newsletters
                        </StyledTypographyHOne>
                        <StyledTypographyBodyTwo component='span'>
                            Livewire News is a quarterly publication with information about
                            new platform features and data available on the
                            &nbsp;<StyledEmailLink to={'/'}>Livewire Data Platform</StyledEmailLink>.
                        </StyledTypographyBodyTwo>
                        <StyledGreenDividerImg src={greenDivider} alt='divider' />
                        {this.renderContent()}
                    </StyledNewsContainerDiv>
                </StyledPaper>
            </div>
        );
    }
}

export default inject((store: any) => ({
    notificationService: store.notificationService,
    newsletterService: store.newsletterService
}))(withRouter(NewsletterList));
