import React from 'react';
import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';

import theme from '@extensions/services/Theme';

const StyledAlert = styled(Alert)(() => ({
  ...theme.MuiAlert.outlinedError,
}));

export interface IAwaitApprovalAlertProps {
  className?: string;
}

class AwaitApprovalAlert extends React.Component<IAwaitApprovalAlertProps> {
  render() {
    return (
      <StyledAlert
        severity="error"
      >
        <div>
          Your account has not been approved yet. Please wait for an approval
          email or contact us at{' '}
          <a href={process.env.REACT_APP_TEAM_EMAIL}>
            {process.env.REACT_APP_TEAM_EMAIL}
          </a>
        </div>
      </StyledAlert>
    );
  }
}

export default AwaitApprovalAlert;
