import * as React from 'react';
import { createRef } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Button,
  Checkbox,
  IconButton,
  ListItemIcon,
  TextField,
  Tooltip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';

import find from 'lodash/find';
import filter from 'lodash/filter';
import remove from 'lodash/remove';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import pullAllBy from 'lodash/pullAllBy';
import camelCase from 'lodash/camelCase';
import * as EmailValidator from 'email-validator';
import { useConfirm } from 'material-ui-confirm';

import { IProjectService } from '@extensions/services/IProjectService';
import { IMembershipService } from '@extensions/services/IMembershipService';

import User from '@extensions/models/User';
import Group from '@extensions/models/Group';
import theme from '@extensions/services/Theme';
import NewUserEmailTextField from '@dapclient/components/core/NewUserEmailTextField';

const StyledAlert = styled(Alert)(({
  ...theme.MuiAlert.outlinedError,
}));

export interface IGroupModalProps {
  projectService: IProjectService;
  membershipService: IMembershipService;
  onCancel: (event: any) => void;
  users: User[] | null;
  mode: 'create' | 'addUsers' | 'edit';
  group: Group | undefined;
}

export interface IGroupModalState {
  searchText: string;
  open: boolean;
  selectedUsers: User[];
  newUsers: User[];
  alertText: string | null;
}

interface IGroupModalFooterProps {
  onCancel: IGroupModalProps['onCancel'];
  mode: IGroupModalProps['mode'];
  onAddNewUser: (event: any) => void;
  handleGroupActionCallback: (event: any) => void;
  isValidCallback: (event: any) => boolean;
  confirm: boolean;
  getConfirmText: () => string;
}

const GroupModalFooter = (props: IGroupModalFooterProps) => {
  const confirm = useConfirm();

  const handleOkClick = (event: any) => {
    if (props.isValidCallback(event)) {
      props.confirm
        ? confirm({
          description: props.getConfirmText(),
        })
          .then(() => {
            props.handleGroupActionCallback(event);
          })
          .catch(() => {
            // action cancelled
          })
        : props.handleGroupActionCallback(event);
    }
  };

  return (
    <React.Fragment>
      <div style={{marginTop: '24px'}}>
      {props.mode !== 'edit' && (
        <Button
          variant="outlined"
          key="newUser"
          color="primary"
          style={{ float: 'left' }}
          onClick={props.onAddNewUser}
        >
          New User
        </Button>
      )}
      <Button variant="contained" color="secondary" onClick={handleOkClick} style={{ float: 'right' }}>
        OK
      </Button>
      <Button
        variant="contained"
        onClick={props.onCancel}
        style={{ marginRight: 10, float: 'right' }}
      >
        Cancel
      </Button>
      </div>
    </React.Fragment>
  );
};

@inject('membershipService', 'projectService')
@observer
class GroupModal extends React.Component<IGroupModalProps, IGroupModalState> {
  static defaultProps = {
    projectService: undefined,
    membershipService: undefined,
  };
  nameRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.nameRef = createRef();
    this.state = {
      open: false,
      searchText: '',
      selectedUsers: [],
      newUsers: [],
      alertText: null,
    };
  }

  componentDidMount() {
    // group modal is only used from a project so use the project service's project identifier to get the users
    const { projectService } = this.props;

    if (projectService.project) {
      projectService.getProjectUsersIfNeeded(
        projectService.project.getIdentifier()
      );
    }
  }

  isValid = () => {
    const { mode, group } = this.props;
    const { selectedUsers, newUsers } = this.state;
    const validName =
      this.nameRef.current &&
      this.nameRef.current.value &&
      /^[a-z]+[a-z0-9.-]*[a-z0-9]+$/.test(this.nameRef.current.value);

    // when creating a group, needs at least 1 user and a valid name:
    let validUsers = selectedUsers.length > 0;
    let valid = true;
    let alertText = '';

    // when creating or adding users to a group check if any invalid emails were added
    if (mode === 'create' || mode === 'addUsers') {
      const invalidNewUsers = filter(
        newUsers,
        (user: User) =>
          EmailValidator.validate(user.email) === false &&
          find(selectedUsers, ['email', user.email])
      );
      if (invalidNewUsers.length > 0) {
        alertText = 'Correct invalid email addresses.';
        valid = false;
      }
    }

    if (mode === 'create' || mode === 'edit') {
      // if editing, group name can be changed and/or users removed so check that the name
      // is still valid and at least 1 member remains
      if (mode === 'edit' && group) {
        const existingUsers: User[] = cloneDeep(group.users);
        pullAllBy(existingUsers, selectedUsers, 'email');
        validUsers = existingUsers.length > 0;
      }

      if (!validName || !validUsers) {
        if (!validName) {
          alertText = 'Group name required (lowercase alphanumeric only). ';
        }
        if (!validUsers) {
          alertText += 'At least 1 member required.';
        }

        valid = false;
      }
    } else {
      if (!validUsers) {
        alertText = 'Add at least one member.';
        valid = false;
      }
    }
    this.setState({
      alertText,
    });
    return valid;
  };

  getConfirmText = () => {
    const { mode, group } = this.props;
    const selectedUsers = this.state.selectedUsers;
    let confirmText = '';
    if (group && this.nameRef.current) {
      if (mode === 'addUsers' && this.nameRef.current) {
        confirmText = `Are you sure you would like to add users to the group ${group.name}?  Please go to the permissions tab to assign roles.`;
      } else {
        if (selectedUsers.length > 0) {
          confirmText = `Are you sure you would like to delete users from the group ${group.name}? `;
        }
        if (group.name !== this.nameRef.current.value) {
          confirmText += 'Are you sure you want to rename the group?';
        }
      }
    }
    return confirmText;
  };

  handleGroupAction = (e) => {
    const { mode, group } = this.props;
    // remove any users that don't have email address (case where new user button clicked but nothing entered)
    const selectedUsers = filter(
      this.state.selectedUsers,
      (user: User) => user.email && user.email.length > 0
    ) as User[];
    if (mode === 'create') {
      this.handleCreateGroup(selectedUsers);
    } else if (group && this.nameRef.current) {
      const existingUsers: User[] = cloneDeep(group.users);
      if (mode === 'addUsers' && this.nameRef.current) {
        // take the list selected and add to existing list
        const newUsers = existingUsers.concat(selectedUsers);
        this.props.projectService.updateProjectGroup(
          group.name,
          this.nameRef.current.value,
          newUsers
        );
      } else {
        // else mode is edit where users can be removed or name changed
        // take the selected list and remove from existing list
        // also pass in old and new name in case it changed too
        // open cofirm to remove users and change name
        pullAllBy(existingUsers, selectedUsers, 'email');
        this.props.projectService.updateProjectGroup(
          group.name,
          this.nameRef.current.value,
          existingUsers
        );
      }
    }
  };

  handleCreateGroup = (selectedUsers) => {
    if (this.nameRef.current) {
      this.props.projectService.createProjectGroup(
        this.nameRef.current.value,
        selectedUsers
      );
    }
  };

  addNewUser = () => {
    const user: User = User.getGuestUser();
    user.username = '' + new Date().getMilliseconds();
    // add the new (blank) user to list of selected users via addUser
    this.addUser(user);

    // also add the new (blank) user to the list of newUsers so UI can render differently
    const newUsers = cloneDeep(this.state.newUsers);
    newUsers.unshift(user);
    this.setState({ newUsers });
  };

  toggleUser = (user) => {
    if (find(this.state.selectedUsers, ['email', user.email]) !== undefined) {
      this.removeUser(user);
    } else {
      this.addUser(user);
    }
  };

  addUser = (user) => {
    const selectedUsers = cloneDeep(this.state.selectedUsers);
    selectedUsers.unshift(user);
    this.setState({ selectedUsers });
  };

  removeUser = (userRoleToRemove: User) => {
    const selectedUsers = cloneDeep(this.state.selectedUsers);
    remove(selectedUsers, (user: User) => {
      return user.email === userRoleToRemove.email;
    });
    this.setState({ selectedUsers });
  };

  search = (event) => {
    const searchText = event.target.value;
    this.setState({ searchText });
  };

  public render() {
    let users: User[] | null = this.props.users;
    const { mode, group } = this.props;
    const { searchText, selectedUsers, newUsers } = this.state;

    let content = <div />;

    if (users && users.length > 0) {
      // sort users alphabetically by last name
      users = sortBy(users, (user: User) => user.lastname);

      // then remove user's that don't match search string (if entered)
      if (searchText) {
        remove(users, (user: User) => {
          const userString = `${user.email}${user.firstname}${user.lastname}`;
          return (
            camelCase(userString)
              .toLowerCase()
              .indexOf(camelCase(searchText).toLowerCase()) === -1
          );
        });
      }

      const getUserButton = (user: User) => {
        let button = (
          <Checkbox
            edge="start"
            checked={find(selectedUsers, ['email', user.email]) !== undefined}
            onClick={(e) => this.toggleUser(user)}
          />
        );
        if (mode === 'edit') {
          if (find(selectedUsers, ['email', user.email]) !== undefined) {
            button = (
              <Tooltip title="Undo">
                <IconButton
                  aria-label="delete"
                  onClick={(e) => this.toggleUser(user)}
                >
                  <UndoIcon />
                </IconButton>
              </Tooltip>
            );
          } else {
            button = (
              <Tooltip title="Remove user from group">
                <IconButton
                  aria-label="delete"
                  onClick={(e) => this.toggleUser(user)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            );
          }
        }
        return button;
      };

      const userListItems: React.ReactNode[] = [];
      if (users) {
        users.forEach((user) => {
          userListItems.push(
            <ListItem key={user.email}>
              <ListItemIcon>{getUserButton(user)}</ListItemIcon>
              <ListItemText
                primary={user.fullName}
                secondary={user.email}
                primaryTypographyProps={{
                  variant: 'body1',
                  color: theme.palette.common.black
                }}
                secondaryTypographyProps={{
                  variant: 'body1',
                  color: theme.palette.grey[700]
                }}
              />
            </ListItem>
          );
        });
      }
      const newUserListItems: React.ReactNode[] = [];
      if (newUsers) {
        newUsers.forEach((user) => {
          newUserListItems.push(
            <ListItem key={user.username}>
              <ListItemIcon>{getUserButton(user)}</ListItemIcon>
              <NewUserEmailTextField
                email={user.email}
                setEmail={(email) => user.setEmail(email)}
              />
            </ListItem>
          );
        });
      }

      const headerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 16px',
      };

      const textFieldStyle = {
        margin: '8px',
      };

      let title = 'Create Group';
      if (mode === 'edit') {
        title = 'Edit Group';
      } else if (mode === 'addUsers') {
        title = 'Add Users To Group';
      }

      content = (
        <Dialog
          open={true}
          onClose={this.props.onCancel}
        >
          <DialogTitle sx={{color: theme.palette.secondary.main}}>{title}</DialogTitle>
          <DialogContent>
            <div>
              <div style={headerStyle}>
                <TextField
                  label="Group Name"
                  required
                  inputRef={this.nameRef}
                  defaultValue={group ? group.name : ''}
                  disabled={mode === 'addUsers'}
                  style={textFieldStyle}
                  variant='standard'
                />
                <TextField
                  label="Search Members"
                  onChange={this.search}
                  style={textFieldStyle}
                  variant='standard'
                />
              </div>

              <Divider />
              <div
                style={{
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 300,
                }}
              >
                <List dense disablePadding aria-label="users">
                  {newUserListItems}
                  {userListItems}
                </List>
              </div>
              {this.state.alertText && (
                <StyledAlert severity="error" >
                  {this.state.alertText}
                </StyledAlert>
              )}
            </div>

            <GroupModalFooter
              mode={mode}
              onAddNewUser={this.addNewUser}
              onCancel={this.props.onCancel}
              isValidCallback={this.isValid}
              handleGroupActionCallback={this.handleGroupAction}
              confirm={this.props.mode === 'create' ? false : true}
              getConfirmText={this.getConfirmText}
            />
          </DialogContent>
        </Dialog>
      );
    }
    return content;
  }
}

export default GroupModal;
