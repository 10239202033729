import { createBrowserHistory, History } from 'history';
import { IHistoryService } from '@dapclient/services/IHistoryService';
import { observable, action, makeObservable } from 'mobx';

export default class HistoryService implements IHistoryService {
  history: History;
  @observable redirectPath: string | null = null;
  @observable redirectFilter: string | null = null;
  @observable redirectHash: string | null = null;

  constructor() {
    makeObservable(this);
    this.history = createBrowserHistory();
  }

  // will leave in history.push impl for now in case Carina wants to revisit getting this way to work
  redirect(path: string) {
    this.history.push(path);
  }

  @action setRedirectPath(path: string): void {
    this.redirectPath = path;
  }

  @action setRedirectFilter(filter: string): void {
    this.redirectFilter = filter;
  }

  @action setRedirectHash(hash: string): void {
    this.redirectHash = hash;
  }
}
