import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function Tracker() {
  useEffect(() => {
    const gaTag: string = process.env.REACT_APP_GATAG
      ? process.env.REACT_APP_GATAG
      : 'notfound';

    console.log('using GA tag:', gaTag);
    ReactGA.initialize(gaTag);
  }, []);
  usePageViews();
  // Don't render anything since this component is only used to track page views
  return null;
}

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.ga('set', 'page', location.pathname + location.search);
    ReactGA.ga('send', 'pageview');
  }, [location]);
}
