import {
  IDs,
  INotificationService,
  Status,
} from '@dapclient/services/INotificationService';
import AccessRequest from '@extensions/models/AccessRequest';
import AccessRestriction from '@extensions/models/AccessRestriction';
import Dataset from '@extensions/models/Dataset';
import Group from '@extensions/models/Group';
import GroupMember from '@extensions/models/GroupMember';
import { Type } from '@extensions/models/IControlledAccess';
import Project from '@extensions/models/Project';
import Role, { RoleType } from '@extensions/models/Role';
import User from '@extensions/models/User';
import UserMember from '@extensions/models/UserMember';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import filter from 'lodash/filter';
import {
  action,
  observable,
  reaction,
  transaction,
  makeObservable,
} from 'mobx';

import { IMembershipService } from '@extensions/services/IMembershipService';
import { ISecurityService } from '@extensions/services/ISecurityService';

export default class MembershipService implements IMembershipService {
  @observable usersByRole: Map<Role, User[]> | null = null;
  @observable groupsByRole: Map<Role, Group[]> | null = null;
  @observable accessRequests: AccessRequest[] | null = null;
  currentContextName: string | null = null;
  notificationService: INotificationService;
  securityService: ISecurityService;

  constructor(
    notificationService: INotificationService,
    securityService: ISecurityService
  ) {
    makeObservable(this);
    this.notificationService = notificationService;
    this.securityService = securityService;

    // SJB: changed this from observe() to reaction() per the docs
    reaction(
      () =>
        filter(
          this.notificationService.notifications,
          (n) =>
            (n.id === IDs.USER_LOGGED_OUT && n.status === Status.Success) ||
            n.id === IDs.GET_PROJECT ||
            n.id === IDs.GET_DATASET ||
            n.id === IDs.GROUP_UPDATED ||
            n.id === IDs.GROUP_DELETED
        ).length > 0,
      (found: boolean) => {
        if (found) {
          this.clearState();
        }
      }
    );
  }

  checkIfNewContext(contextName: string) {
    if (contextName !== this.currentContextName) {
      this.clearState();
      this.currentContextName = contextName;
    }
  }

  @action async deleteAccessRequest(
    request: AccessRequest,
    projectName: string,
    dataset: Dataset | undefined
  ) {
    try {
      this.notificationService.addNotification(
        'deleteDatasetAccessRequest',
        Status.Running,
        '',
        ''
      );

      let url = `/api/projects/${projectName}`;
      if (request.datasetName) {
        url = `/api/datasets/${request.datasetName}`;
      }
      url += `/requested-access/${request.user.username}`;

      await DapApiAgent.agent.delete(url);
      if (dataset) {
        await this.getDatasetAccessRequests(dataset);
      } else {
        await this.getProjectAccessRequests(projectName);
      }

      this.notificationService.addNotification(
        'deleteDatasetAccessRequest',
        Status.Success,
        '',
        ''
      );
    } catch (error) {
      this.notificationService.addNotification(
        'deleteDatasetAccessRequest',
        Status.Error,
        'Failed to remove request.',
        error
      );
    }
  }

  @action async approveAccessRequest(
    request: AccessRequest,
    project: Project,
    dataset: Dataset | undefined
  ) {
    try {
      this.notificationService.addNotification(
        'approveDatasetAccessRequest',
        Status.Running,
        '',
        ''
      );

      // if the dataset is under project level control add as member to project
      // otherwise add as member to dataset
      const projectLevel = (
        request.datasetRestriction !== AccessRestriction.restrictions.dataset &&
        request.datasetRestriction !== AccessRestriction.restrictions.datasetMfa
      );

      const memberRole = {
        member: request.user,
        role: {
          label: 'member',
          level: projectLevel ? 'project' : 'dataset',
        },
      };

      if (projectLevel) {
        await this.addMembersImpl(
          [memberRole],
          [],
          project.identifier,
          Type.PROJECT
        );
      } else if (request.datasetName) {
        await this.addMembersImpl(
          [memberRole],
          [],
          request.datasetName,
          Type.DATASET
        );
      }

      await this.deleteAccessRequest(request, project.identifier, dataset);
      if (dataset) {
        this.getMembers(dataset.getIdentifier(), dataset.getType());
      } else {
        this.getMembers(project.getIdentifier(), project.getType());
      }

      this.notificationService.addNotification(
        'approveDatasetAccessRequest',
        Status.Success,
        `${memberRole.member.fullName} successfully added`,
        `${memberRole.member.fullName} has been granted ${memberRole.role.label} permissions.`
      );
    } catch (error) {
      this.notificationService.addNotification(
        'approveDatasetAccessRequest',
        Status.Error,
        'Failed to approve request.',
        error
      );
    }
  }

  @action async getMembersIfNeeded(identifier: string, type: Type) {
    this.checkIfNewContext(identifier);
    if (this.usersByRole === null || this.groupsByRole === null) {
      this.getMembers(identifier, type);
    }
  }

  @action async getMembers(identifier: string, type: Type) {
    try {
      this.notificationService.addNotification(
        'getMembers',
        Status.Running,
        '',
        ''
      );

      const apiResponse = await DapApiAgent.agent.get(
        `/api/${type}s/${identifier}/members`
      );
      this.setMembers(apiResponse);
    } catch (error) {
      this.notificationService.addNotification(
        'getMembers',
        Status.Error,
        'Failed to get dataset access list.',
        error
      );
    }
  }

  setMembers(apiResponse) {
    transaction(() => {
      this.notificationService.addNotification(
        'getMembers',
        Status.Success,
        '',
        ''
      );

      const userRoles = JSON.parse(apiResponse.text);
      const userMap = new Map<Role, User[]>();
      const groupMap = new Map<Role, Group[]>();
      for (const role in userRoles) {
        if (userRoles.hasOwnProperty(role)) {
          // if level is undefined (members from projects don't need level info at API level but UI does) default it to 'project'
          const level = userRoles[role].level || 'project';
          const roleLevel: Role = new Role(userRoles[role].role, level);
          if (userRoles[role].users) {
            const users: User[] = userRoles[role].users.map(
              (user) => new User(user)
            );
            userMap.set(roleLevel, users);
          }
          if (userRoles[role].groups) {
            const groups: Group[] = userRoles[role].groups.map(
              (group) => new Group(group)
            );
            groupMap.set(roleLevel, groups);
          }
        }
      }

      this.setUsersByRole(userMap);
      this.setGroupsByRole(groupMap);
    });
  }

  async removeMemberFromRoleImpl(
    role: string,
    identifier: string,
    type: Type,
    userToRemove?: User,
    groupToRemove?: Group
  ) {
    const datasetOrProjectName = identifier;

    // if editing a dataset's membership, can only edit dataset level roles. if project, level is not used and undefined
    const level = type;

    let existingGroups: Group[] = this.getExistingGroups(role, level);
    let existingUsers: User[] = this.getExistingUsers(role, level);
    if (groupToRemove) {
      existingGroups = filter(
        existingGroups,
        (group: Group) => group.name !== groupToRemove.name
      );
    } else if (userToRemove) {
      existingUsers = filter(
        existingUsers,
        (user: User) => user.email !== userToRemove.email
      );
    }
    await DapApiAgent.agent
      .put(`/api/membership/${datasetOrProjectName}`)
      .send(
        this.getMembershipPayload(role, [], [], existingUsers, existingGroups)
      );
  }

  @action async removeMemberFromRole(
    role: string,
    identifier: string,
    type: Type,
    userToRemove?: User,
    groupToRemove?: Group
  ) {
    try {
      this.notificationService.addNotification(
        IDs.ROLE_REMOVED,
        Status.Running,
        '',
        ''
      );
      await this.removeMemberFromRoleImpl(
        role,
        identifier,
        type,
        userToRemove,
        groupToRemove
      );
      const name = groupToRemove
        ? groupToRemove.name
        : userToRemove
        ? userToRemove.fullName
          ? userToRemove.fullName
          : userToRemove.email
        : 'unknown';
      this.notificationService.addNotification(
        IDs.ROLE_REMOVED,
        Status.Success,
        `Role ${role} successfully removed`,
        `Role ${role} has been removed from ${name}.`
      );

      // now refresh the list of members for the project or dataset:
      this.getMembers(identifier, type);
    } catch (error) {
      this.notificationService.addNotification(
        IDs.ROLE_REMOVED,
        Status.Error,
        'Failed to remove member from role.',
        error
      );
    }
  }

  getExistingUsers(role: string, level: string) {
    let existingUsers: User[] = [];
    if (this.usersByRole) {
      const roles = this.usersByRole.keys();
      let result = roles.next();

      while (!result.done) {
        const roleLevel: Role = result.value;
        if (roleLevel.label === role && roleLevel.level === level) {
          const found = this.usersByRole.get(result.value);
          if (found) {
            existingUsers = found;
          }
          break;
        }
        result = roles.next();
      }
    }
    return existingUsers;
  }

  getExistingGroups(role: string, level: string) {
    let existingGroups: Group[] = [];

    if (this.groupsByRole) {
      const roles = this.groupsByRole.keys();
      let result = roles.next();

      while (!result.done) {
        const roleLevel: Role = result.value;
        if (roleLevel.label === role && roleLevel.level === level) {
          const found = this.groupsByRole.get(result.value);
          if (found) {
            existingGroups = found;
          }
          break;
        }
        result = roles.next();
      }
    }
    return existingGroups;
  }

  getMembershipPayload(
    role: string,
    newUsers: UserMember[],
    newGroups: GroupMember[],
    existingUsers: User[] | undefined,
    existingGroups: Group[] | undefined
  ) {
    const userEmails: string[] = [];
    const groupNames: string[] = [];
    if (newUsers) {
      newUsers.forEach((user: UserMember) => {
        userEmails.push(user.member.email);
      });
    }
    if (existingUsers) {
      existingUsers.forEach((user: User) => {
        userEmails.push(user.email);
      });
    }
    if (newGroups) {
      newGroups.forEach((group: GroupMember) => {
        groupNames.push(group.member.name);
      });
    }
    if (existingGroups) {
      existingGroups.forEach((group: Group) => {
        groupNames.push(group.name);
      });
    }
    return {
      role,
      users: userEmails,
      groups: groupNames,
    };
  }

  @action async addMembers(
    users: UserMember[],
    groups: GroupMember[],
    identifier: string,
    type: Type,
    removeFromExistingRole: string | undefined
  ) {
    try {
      this.notificationService.addNotification(
        IDs.MEMBERS_ADDED,
        Status.Running,
        '',
        ''
      );

      await this.addMembersImpl(users, groups, identifier, type);
      // if removeFromExistingRole is passed in, get the existing members list for that role
      // and remove this member from it
      if (removeFromExistingRole) {
        for (const user of users) {
          await this.removeMemberFromRoleImpl(
            removeFromExistingRole,
            identifier,
            type,
            user.member,
            undefined
          );
        }
        for (const group of groups) {
          await this.removeMemberFromRoleImpl(
            removeFromExistingRole,
            identifier,
            type,
            undefined,
            group.member
          );
        }
      }

      transaction(() => {
        users.forEach((user: UserMember) => {
          this.notificationService.addNotification(
            `${IDs.MEMBERS_ADDED}${user.member.email}`,
            Status.Success,
            `${user.member.fullName} successfully added`,
            `${user.member.fullName} has been granted ${user.role.label} permissions.`
          );
        });
        groups.forEach((group: GroupMember) => {
          this.notificationService.addNotification(
            `${IDs.MEMBERS_ADDED}${group.member.name}`,
            Status.Success,
            `${group.member.name} successfully added`,
            `${group.member.name} has been granted ${group.role.label} permissions.`
          );
        });
      });

      this.notificationService.addNotification(
        IDs.MEMBERS_ADDED,
        Status.Success,
        '',
        ''
      );

      // now refresh the list of members for the project or dataset:
      this.getMembers(identifier, type);
    } catch (error) {
      this.notificationService.addNotification(
        IDs.MEMBERS_ADDED,
        Status.Error,
        'Failed to add member.',
        error
      );
    }
  }

  async addMembersImpl(
    users: UserMember[],
    groups: GroupMember[],
    identifier: string,
    type: Type
  ) {
    await this.updateMembersOfRole(
      RoleType.LEAD,
      users,
      groups,
      identifier,
      type
    );
    await this.updateMembersOfRole(
      RoleType.EDITOR,
      users,
      groups,
      identifier,
      type
    );
    await this.updateMembersOfRole(
      RoleType.MEMBER,
      users,
      groups,
      identifier,
      type
    );
  }

  async updateMembersOfRole(
    role: string,
    users: UserMember[],
    groups: GroupMember[],

    identifier: string,
    type: Type
  ) {
    const newUsers: UserMember[] = filter(
      users,
      (user: UserMember) => user.role.label === role
    );
    const newGroups: GroupMember[] = filter(
      groups,
      (group: GroupMember) => group.role.label === role
    );
    if (newUsers.length > 0 || newGroups.length > 0) {
      const level = type;
      const datasetOrProjectName = identifier;

      // add these new members to existing
      const existingUsers = this.getExistingUsers(role, level);
      const existingGroups = this.getExistingGroups(role, level);

      await DapApiAgent.agent
        .put(`/api/membership/${datasetOrProjectName}`)
        .send(
          this.getMembershipPayload(
            role,
            newUsers,
            newGroups,
            existingUsers,
            existingGroups
          )
        );
    }
  }

  @action async getDatasetAccessRequestsIfNeeded(dataset: Dataset) {
    this.checkIfNewContext(dataset.name);
    if (this.accessRequests === null) {
      this.getDatasetAccessRequests(dataset);
    }
  }

  @action async getDatasetAccessRequests(dataset: Dataset) {
    try {
      this.notificationService.addNotification(
        'getDatasetAccessRequests',
        Status.Running,
        ''
      );

      const response = await DapApiAgent.agent.get(
        `/api/datasets/${dataset.name}/requested-access`
      );
      transaction(() => {
        this.notificationService.addNotification(
          'getDatasetAccessRequests',
          Status.Success,
          ''
        );

        const requestsJson = JSON.parse(response.text);
        const requests: AccessRequest[] = requestsJson.map(
          (request) =>
            new AccessRequest(request, dataset.name, dataset.restriction)
        );

        this.setAccessRequests(requests);
      });
    } catch (error) {
      this.notificationService.addNotification(
        'getDatasetAccessRequests',
        Status.Error,
        'Failed to get dataset access requests list',
        error
      );
    }
  }

  @action async getProjectAccessRequestsIfNeeded(projectName: string) {
    this.checkIfNewContext(projectName);
    if (this.accessRequests === null) {
      this.getProjectAccessRequests(projectName);
    }
  }

  @action async getProjectAccessRequests(projectName: string) {
    try {
      this.notificationService.addNotification(
        'getProjectAccessRequests',
        Status.Running,
        ''
      );

      const response = await DapApiAgent.agent.get(
        `/api/projects/${projectName}/requested-access`
      );
      transaction(() => {
        this.notificationService.addNotification(
          'getProjectAccessRequests',
          Status.Success,
          ''
        );

        const requestsJson = JSON.parse(response.text);
        const getRestriction = (
          isProjectRestricted: boolean | undefined,
          isDatasetRestricted: boolean | undefined
        ) => {
          if (
            isProjectRestricted === undefined ||
            isDatasetRestricted === undefined
          ) {
            return undefined;
          }
          if (isProjectRestricted) {
            return AccessRestriction.restrictions.project;
          }
          if (isDatasetRestricted) {
            return AccessRestriction.restrictions.dataset;
          }
          return AccessRestriction.restrictions.none;
        };
        const requests: AccessRequest[] = requestsJson.map(
          (request) =>
            new AccessRequest(
              request,
              request.dataset,
              getRestriction(
                request.isProjectRestricted,
                request.isDatasetRestricted
              )
            )
        );

        this.setAccessRequests(requests);
      });
    } catch (error) {
      this.notificationService.addNotification(
        'getProjectAccessRequests',
        Status.Error,
        'Failed to get project access requests list',
        error
      );
    }
  }

  @action async requestProjectAccess(
    projectName: string,
    justification: string
  ) {
    return this.requestAccess(
      projectName,
      `/api/projects/${projectName}/request-access`,
      justification
    );
  }

  @action async requestDatasetAccess(
    datasetName: string,
    justification: string
  ) {
    return this.requestAccess(
      datasetName,
      `/api/datasets/${datasetName}/request-access`,
      justification
    );
  }

  @action async requestAccess(
    resourceName: string,
    url: string,
    justification: string
  ) {
    if (this.securityService.user === null) {
      this.notificationService.addNotification(
        IDs.USER_LOGGED_OUT,
        Status.Error,
        'Not logged in'
      );
      return;
    }
    try {
      this.notificationService.addNotification(
        IDs.REQUEST_SENT,
        Status.Running,
        'Sending request ...'
      );

      const payload = {
        justification,
      };

      await DapApiAgent.agent.post(url).send(payload);

      this.notificationService.addNotification(
        IDs.REQUEST_SENT,
        Status.Success,
        'Request sent'
      );

      this.securityService.user.resourceNamesRequested.push(resourceName);
    } catch (error) {
      this.notificationService.addNotification(
        IDs.REQUEST_SENT,
        Status.Error,
        'Failed to request access.',
        error
      );
    }
  }

  clearState() {
    transaction(() => {
      this.setUsersByRole(null);
      this.setGroupsByRole(null);
      this.setAccessRequests(null);
    });
  }

  @action setAccessRequests(requests: AccessRequest[] | null): void {
    this.accessRequests = requests;
  }

  @action setUsersByRole(access: Map<Role, User[]> | null): void {
    this.usersByRole = access;
  }
  @action setGroupsByRole(access: Map<Role, Group[]> | null): void {
    this.groupsByRole = access;
  }
}
