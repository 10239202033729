import { Measure } from '@dapclient/models/Quality';

export default class DatasetMetrics {
    measures: Measure[] = [];

    //data is the "datasetMetrics" json segment
    constructor(data) {

        if (data.measures) {
            this.measures = data.measures.map(measure => new Measure(measure));
        }
    }
}
