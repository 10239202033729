import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link
} from '@mui/material';
import removeMd from 'remove-markdown';
import kebabCase from 'lodash/kebabCase';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';

import theme from '@extensions/services/Theme';

import { PublicationType } from '@extensions/models/Publication';
import ExpandableText from '@extensions/components/core/ExpandableText';
import { ISecurityService } from '@extensions/services/ISecurityService';
import CardExpansionPanel from '@extensions/components/core/CardExpansionPanel';
import PublicationDatasets from '@extensions/components/publications/PublicationDatasets';
import PublicationProjects from '@extensions/components/publications/PublicationProjects';

type TypographyExtraProps = {
  component: React.ElementType;
};

const StyledTypographyBodyOne = styled(Typography)<TypographyExtraProps>(() => ({
  color: 'rgba(0, 0, 0, 0.54)',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: '1rem',
  display: 'flex',
  columnGap: '15px',
  marginTop: theme.spacing(1),
}));

const StyledTypographyHTwo = styled(Typography)<TypographyExtraProps>(() => ({
  fontSize: `${theme.typography.pxToRem(20.8)} !important`,
  fontWeight: `${theme.typography.fontWeightRegular} !important`,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

const StyledCardExpansionPanel = styled(CardExpansionPanel)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const StyledLink = styled(Link)(() => ({
  marginBottom: theme.spacing(1),
  display: 'block',
  color: '#005b82',
  textTransform: 'none',
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(3),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(0),
  paddingRight: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
}));

const StyledKeywordDiv = styled('div')(({
  marginLeft: '1rem'
}));

const StyledChip = styled(Chip)(({
  marginTop: '5px',
  marginRight: '5px',
}));

const StyledAuthorsDiv = styled('div')(({
  marginLeft: '1rem'
}));


export interface ISearchHitProps {
  className?: string;
  publication: Record<string, any>;
  securityService?: ISecurityService;
}

export interface ISearchHitState { }

@observer
export class SearchHit<
  ExtraProps extends object = {}
> extends React.Component<
  ISearchHitProps & ExtraProps,
  ISearchHitState
> {

  getPanel = ({
    title,
    body,
  }: {
    title: string;
    body: React.ReactNode;
  }): React.ReactNode => {
    const { publication } = this.props;
    return (
      <StyledCardExpansionPanel
        detailsId={`${publication._id}-${kebabCase(title)}`}
        summaryContent={
          <Typography
            variant="h4"
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightMedium,
              paddingLeft: (theme) => theme.spacing(2),
              fontSize: '1rem !important'
            }}
          >
            {title}
          </Typography>
        }
        detailsContent={body}
      />
    );
  };

  renderProjectsPanel = (publication: any) => {
    if (publication.projects && publication.projects.length > 0) {
      return this.getPanel({
        title: 'Associated Projects',
        body: <PublicationProjects publication={publication} tableSize="small" />
      })
    }
  }

  renderDatasetsPanel = (publication: any) => {
    if (publication.datasets && publication.datasets.length > 0) {
      return this.getPanel({
        title: 'Associated Datasets',
        body: <PublicationDatasets publication={publication} tableSize="small" />
      })
    }
  }

  renderKeywordsPanel = (publication: any) => {
    if (publication.keywords && publication.keywords.length > 0) {
      return (
        <CardExpansionPanel
          detailsId={`${publication._id}-keywords`}
          summaryContent={
            <Typography
              variant="h4"
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightMedium,
                paddingLeft: (theme) => theme.spacing(2),
                fontSize: '1rem !important',
              }}
            >
              Keywords
            </Typography>
          }
          detailsContent={
            <StyledKeywordDiv>
              {publication.keywords.map((word) => (
                <StyledChip
                  label={word}
                  variant="outlined"
                  size="small"
                  key={word}
                />
              ))}
            </StyledKeywordDiv>
          }
        />
      )
    }
  }

  renderAuthorsPanel = (publication: any) => {
    if (publication.authorNames && publication.authorNames.length > 0) {
      return (
        <CardExpansionPanel
          detailsId={`${publication._id}-authors`}
          summaryContent={
            <Typography
              variant="h4"
              sx={{
                fontWeight: (theme) => theme.typography.fontWeightMedium,
                paddingLeft: (theme) => theme.spacing(2),
                fontSize: '1rem !important',
              }}
            >
              Authors
            </Typography>
          }
          detailsContent={
            <StyledAuthorsDiv>
              {publication.authorNames.map((name) => name).join(', ')}
            </StyledAuthorsDiv>
          }
        />
      )
    }
  }

  renderAuthorsTable = () => {
    const { publication } = this.props;
    const tableSize = 'small';
    if (publication && publication.authorNames.length > 0) {
      return (
        <TableContainer component={Paper} style={{ maxHeight: '500px' }}>
          <Table size={tableSize} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  Authors <small>({publication.authorNames.length})</small>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publication.authorNames
                .map((author) => {
                  return (
                    <TableRow
                      key={author}
                    >
                      <TableCell>{author}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else return null;
  };

  checkIsMember = (publication: any) => {
    const { securityService } = this.props;
    const userMembers = securityService?.user?.memberOf;
    var result = false;
    if (publication['datasets'] && publication['datasets'].length) {
      publication['datasets'].forEach(dataset => {
        if (userMembers?.includes(dataset.name)) {
          result = true;
        }
      });
    } else if (publication['projects'] && publication['projects'].length) {
      publication['projects'].forEach(project => {
        if (userMembers?.includes(project.name)) {
          result = true;
        }
      });
    }
    return result;
  }

  renderPublicationTitle = (publication: any) => {
    const { securityService } = this.props;
    if (publication.type !== PublicationType.JOURNAL_ARTICLE && publication.type !== PublicationType.TECHNICAL_REPORT) {
      if (securityService && securityService.user && (securityService.user.canAdminPubs || this.checkIsMember(publication))) {
        return (
          <StyledLink href={`/publication/${publication.id}`} underline='hover'>
            {publication.title}
          </StyledLink>
        )
      } else {
        return (
          <StyledLink href={publication.url.startsWith('/api/') ? `${window.location.origin}${publication.url}` : publication.url} underline='hover'>
            {publication.title}
          </StyledLink>
        )
      }
    } else {
      return (
        <StyledLink href={`/publication/${publication.id}`} underline='hover'>
          {publication.title}
        </StyledLink>
      )
    }
  }

  render() {
    const { publication } = this.props;
    return (
      <StyledCard>
        <StyledCardHeader
          title={
            <StyledTypographyHTwo
              variant='h2'
              component='h2'
            >
              <div style={{ marginBottom: '8px' }}>
                {this.renderPublicationTitle(publication)}
              </div>
              {
                publication.publicationDate &&
                <StyledTypographyBodyOne
                  variant="body1"
                  component="span"
                >
                  {'Publication Date: ' + publication.publicationDate.slice(0, 10)}
                </StyledTypographyBodyOne>
              }
            </StyledTypographyHTwo>
          }
        />
        <StyledCardContent>
          <ExpandableText>{removeMd(publication.abstract)}</ExpandableText>
        </StyledCardContent>
        {this.renderProjectsPanel(publication)}
        {this.renderDatasetsPanel(publication)}
        {this.renderKeywordsPanel(publication)}
        {this.renderAuthorsPanel(publication)}
      </StyledCard>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
}))(SearchHit);
