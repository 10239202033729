import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { IPublicationService } from '@extensions/services/IPublicationService';

import PublicationNotFound from '../publications/PublicationNotFound';
import PublicationForm from '@extensions/components/publications/PublicationForm';

const StyledPublicationForm = styled(PublicationForm)(({
  marginTop: '1rem'
}));

export interface IEditPublicationProps extends RouteComponentProps<{ id: string }> {
  publicationService?: IPublicationService;
}

export interface IEditPublicationState { }

@observer
export class EditPublication extends React.Component<
  IEditPublicationProps,
  IEditPublicationState
> {
  componentDidMount() {
    const { publicationService } = this.props;
    if (publicationService) {
      const id = this.getId();
      publicationService.selectPublication(id);
    }
  }
  getId = (): number => {
    const { match } = this.props;
    return Number(match.params.id);
  };
  render() {
    const { publicationService } = this.props;

    if (publicationService?.selectedPublicationNotFound) {
      return <PublicationNotFound />;
    }
    if (publicationService?.selectedPublication) {
      return (
        <div>
          <Typography variant="h1">Edit Publication</Typography>
          <StyledPublicationForm
            publication={publicationService.selectedPublication}
            isEdit={true}
          />
        </div>
      );
    }

    return null;
  }
}

export default inject((store: any) => ({
  publicationService: store.publicationService,
}))(EditPublication);
