import User from '@extensions/models/User';
import AccessRestriction from '@extensions/models/AccessRestriction';

export default class AccessRequest {
  requested: string;
  justification: string;
  user: User;
  datasetName?: string;
  datasetRestriction?: AccessRestriction;

  constructor(data, datasetName, datasetRestriction) {
    this.requested = data.requested;
    this.justification = data.justification;
    this.user = new User(data.user);
    this.datasetName = datasetName;
    this.datasetRestriction = datasetRestriction;
  }
}
