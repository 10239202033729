import React from 'react';

export interface IGlobalErrorBoundaryProps {}

export default class GlobalErrorBoundary extends React.Component<
  IGlobalErrorBoundaryProps
> {
  render() {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '30rem',
        }}
      >
        <h1 style={{ marginBottom: '1rem' }}>Whoops, something went wrong!</h1>
        <p>
          Our team is working to address the issue. If you'd like to help,{' '}
          <a href={`mailto:${process.env.REACT_APP_TEAM_EMAIL}`}>
            send us an email
          </a>{' '}
          explaining what happened.
        </p>
      </div>
    );
  }
}
