import React from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '@extensions/services/Theme';

const StyledRootDiv = styled('div')(({ theme }) => ({
  ...theme.FilterTitle.root,
  minWidth: undefined,
}));

const StyledTypography = styled(Typography)(({
  fontSize: '0.875rem',
  // Selectivity bump needed to deal with antd styles
  '&&': {
    ...theme.FilterTitle.titleText,
    fontWeight: 'normal',
  },
}));

export interface IFilterTitleProps extends React.HTMLProps<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
  hidden?: boolean;
}

class FilterTitle extends React.Component<IFilterTitleProps> {
  render() {
    const { children, hidden } = this.props;
    if (hidden) {
      return null;
    }
    return (
      <StyledRootDiv>
        <StyledTypography
          variant="h6"
          color="textPrimary"
        >
          {children}
        </StyledTypography>
      </StyledRootDiv>
    );
  }
}

export default FilterTitle;
