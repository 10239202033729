import React from 'react';
import { observer } from 'mobx-react';
import cloneDeep from 'lodash/cloneDeep';
import Carousel from 'react-material-ui-carousel';
import { action, makeObservable, observable } from 'mobx';

import { styled } from '@mui/material/styles';
import { Card, Typography, CardMedia } from '@mui/material';

import { downloadDataPlot } from '@extensions/services/DatasetService';

import DatasetModel from '@extensions/models/Dataset';
import DataPlot from '@extensions/components/core/DataPlot';
import CenteredCircularProgress from '@extensions/components/core/CenteredCircularProgress';

const StyledRootDiv = styled('div')(({
  width: '100%',
  height: '100%',
}));

const StyledCircularProgressDiv = styled('div')(({
  display: 'flex',
  marginTop: '25%',
}));

const StyledCarouselCoreWrapperDiv = styled('div')(({
  marginBottom: '20px',
}));

export interface IViewDataPlotPaneProps {
  className?: string;
  dataset: DatasetModel;
}

export interface IViewDataPlotPaneState { }

export interface DataPoints {
  dataPoints: Array<{
    messageDate: string,
    plotLabels: string[],
    plotValues: number[],
  }>;
}

@observer
export class ViewDataPlotPane extends React.Component<
  IViewDataPlotPaneProps,
  IViewDataPlotPaneState
> {
  @observable
  isLoading: boolean = true;
  @observable
  fetchedDataPoints: any[] = [];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    const { dataset } = this.props;
    if (dataset.realtimeData?.viewDataPlot && dataset.realtimeData.defaultQuery && dataset.realtimeData.sensorIDs) {
      dataset.realtimeData.sensorIDs.forEach(async (sensorID) => {
        if (dataset.realtimeData?.viewDataPlot && dataset.realtimeData.defaultQuery && dataset.realtimeData.sensorIDs) {
          await downloadDataPlot(dataset.realtimeData.defaultQuery, sensorID)
            ?.then((response) => {
              if (response.body !== null) {
                this.updateFetchedDataPoints(this.reshapeData(response.body, sensorID), sensorID);
              }
            })
            .catch((err: any) => {
              console.log('err=>', err);
            })
        }
      })
    }
  }

  @action
  updateIsLoading = () => {
    this.isLoading = false;
  }

  reshapeData = (body: any[], sensorID: number): any[] => {
    let inputData = cloneDeep(body);
    let result: any[] = [];
    inputData.forEach(element => {
      let dataPointObj: { [key: string]: number } = {};
      dataPointObj["date"] = element["messageDate"];
      /* Hard coded sensorID for different plotting pattern,
         will update it once we have clear instructions of which 
         sensor ID needs its own plotting logic 
        */
      if (sensorID === 656355) {
        dataPointObj[`${element["plotLabels"][0]}`] = element.plotValues[0];
        dataPointObj[`${element["plotLabels"][element["plotLabels"].length - 1]}`] = element.plotValues[element["plotLabels"].length - 1];
        result.push(dataPointObj);
        for (let i = 0; i < element["plotValues"].length; i++) {
          dataPointObj[`${element["plotLabels"][i]}`] = element.plotValues[i]
        }
      } else {
        for (let i = 0; i < element["plotValues"].length; i++) {
          dataPointObj[`${element["plotLabels"][i]}`] = element.plotValues[i]
        }
      }
      result.push(dataPointObj);
    });
    return result;
  }

  @action
  updateFetchedDataPoints = (dataPointsCache: DataPoints[], sensorID: number) => {
    let tempResult: { sensorID: number, dataPointsCache: DataPoints[] } = {
      sensorID: sensorID,
      dataPointsCache: dataPointsCache
    }
    this.fetchedDataPoints = [...this.fetchedDataPoints, tempResult];
    this.updateIsLoading();
  }

  carouselGen = () => {
    if (!this.fetchedDataPoints) {
      return null;
    }

    if (this.fetchedDataPoints && this.fetchedDataPoints.length < 1 && !this.isLoading) {
      return (
        <Typography variant="body1"
          sx={{
            mt: '1rem',
            mb: '1rem'
          }}
        >
          No available data plot to view.
        </Typography>
      )
    }

    if (!this.isLoading && this.fetchedDataPoints && this.fetchedDataPoints.length > 1) {
      return (
        <StyledCarouselCoreWrapperDiv>
          <Carousel
            sx={{ height: '100%' }}
            indicators={false}
            navButtonsAlwaysVisible={true}
            navButtonsProps={{
              style: {
                backgroundColor: "rgba(0, 0, 0, 0.15)"
              }
            }}
            autoPlay={false}
            cycleNavigation={false}
            fullHeightHover={false}
          >
            {this.cardGen(this.fetchedDataPoints)}
          </Carousel>
        </StyledCarouselCoreWrapperDiv>
      )
    }
  }

  cardGen = (fetchedDataPoints: any[]) => {
    return fetchedDataPoints.map((dataPoints) => {
      if (dataPoints) {
        return (
          <div key={dataPoints.sensorID}>
            <Card
              sx={{
                width: '100%',
                height: '100%'
              }}
              key={dataPoints.sensorID}
            >
              <CardMedia
                sx={{
                  height: '100%',
                  width: '100%',
                  backgroundSize: 'contain',
                }}
              >
                <DataPlot data={dataPoints.dataPointsCache} sensorID={dataPoints.sensorID} />
              </CardMedia>
            </Card>
          </div>
        );
      }
      return null;
    });
  };

  render() {
    return (
      <StyledRootDiv>
        {this.isLoading && (
          <StyledCircularProgressDiv>
            <CenteredCircularProgress />
          </StyledCircularProgressDiv>
        )}
        {this.carouselGen()}
      </StyledRootDiv>
    );
  }
}

export default ViewDataPlotPane;
