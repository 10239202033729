import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from '@mui/material';
import { Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { inject, observer } from 'mobx-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Reference from '@extensions/models/Reference';
import Project from '@extensions/models/Project';
import Dataset from '@extensions/models/Dataset';
import ProjectPublications from '@extensions/components/project-search/ProjectPublications';
import DatasetPublications from '@extensions/components/dataset-search/DatasetPublications';

const StyledAccordionDetails = styled(AccordionDetails)(({
  display: 'flex',
  flexDirection: 'column',
}));

export interface IReferencesPanelProps {
  className?: string;
  references?: Reference[] | null;
  refDescription?: string | null;
  project?: Project | null;
  dataset?: Dataset | null;
  styleAdjust?: boolean | null;
}

export interface IReferencesPanelState { }

@observer
export class ReferencesPanel<
  ExtraProps extends object = {}
> extends React.Component<
  IReferencesPanelProps & ExtraProps,
  IReferencesPanelState
> {

  renderProjectPublications = (project: Project) => {
    return <ProjectPublications
      project={project}
      tableSize="small"
    />
  }

  renderDatasetPublications = (dataset: Dataset) => {
    return <DatasetPublications dataset={dataset} tableSize="small" />
  }

  render() {
    const { project, styleAdjust, dataset } = this.props;
    let content: React.ReactNode;
    if (project) {
      content = this.renderProjectPublications(project)
    } else if (dataset) {
      content = this.renderDatasetPublications(dataset);
    } else {
      content = <Alert severity="info">No Associated Reference Found</Alert>
    }
    if (styleAdjust) {
      return content;
    } else {
      return (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="references-content"
              id="references-header"
            >
              <Typography variant="h3">References</Typography>
            </AccordionSummary>
            <StyledAccordionDetails>
              {content}
            </StyledAccordionDetails>
          </Accordion>
        </>
      );
    }
  }
}

export default inject((store: any) => ({
  // EXAMPLE => metricsService: store.metricsService
}))(ReferencesPanel);
