import React from 'react';
import { observer } from 'mobx-react';
import Chart from 'react-google-charts';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import filter from 'lodash/filter';
import { useTheme, Theme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Dataset from '@extensions/models/Dataset';
import Link from '@extensions/components/core/Link';
import { Measure } from '@extensions/models/Quality';

interface IQualityMetricsPanelProps {
  dataset: Dataset;
  dapTheme: Theme;
}

@observer
class QualityMetricsPanelComp<ExtraProps extends object = {}> extends React.Component<
  IQualityMetricsPanelProps & ExtraProps
> {
  render() {
    const { dataset, dapTheme } = this.props;
    //known bug and this is a workaround for wanting to not show tooltips
    // see https://github.com/rakannimer/react-google-charts/issues/288
    // const chartEvents = [
    //   {
    //     callback: ({ chartWrapper, google }) => {
    //       const chart = chartWrapper.getChart();
    //       chart.container.addEventListener('click', ev => console.log(ev));
    //     },
    //     eventName: 'ready',
    //   },
    // ];

    let qualityPanel = <React.Fragment />;
    if (dataset.quality) {
      const measureGraphs = filter(
        dataset.quality.measures,
        (measure: Measure) => measure.showAsGraphic
      );
      if (measureGraphs) {
        const data: [string, number][] = [];

        measureGraphs.forEach((measure: Measure) => {
          const barData: [string, number] = [
            measure.graphicLegend ? measure.graphicLegend : '',
            measure.value,
          ];
          data.push(barData);
        });

        let allHundred = true;
        for (let i = 0; i < data.length; i++) {
          if (data[i][1] !== 100) {
            allHundred = false;
          }
        }

        const getValue = (row: Measure) => {
          //Ron would like for the UI to show hard coded < 0.01% or > 99.9% for values
          //that are approaching (but not equal to) 0 or 100 percentages
          if (row.units === '%' && row.value > 0.0 && row.value < 0.01) {
            return '< 0.01%';
          } else if (
            row.units === '%' &&
            row.value > 99.99 &&
            row.value < 100.0
          ) {
            return '> 99.99%';
          } else {
            return parseFloat(`${row.value.toFixed(2)}${row.units}`);
          }
        };

        qualityPanel = (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="references-content"
              id="references-header"
            >
              <Typography variant="h3">Quality Metrics</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Chart
                    width={'100%'}
                    height={'250px'}
                    chartType="BarChart"
                    data={[['', 'Metric in %'], ...data]}
                    options={
                      allHundred
                        ? {
                          chartArea: { width: '50%' },
                          hAxis: {
                            viewWindow: {
                              min: 0,
                              max: 100,
                            },
                          },

                          legend: { position: 'none' },
                          colors: [
                            dapTheme.palette.secondary.light,
                            dapTheme.palette.secondary.main,
                            dapTheme.palette.secondary.dark,
                          ],
                        }
                        : {
                          chartArea: { width: '50%' },
                          legend: { position: 'none' },
                          colors: [
                            dapTheme.palette.secondary.light,
                            dapTheme.palette.secondary.main,
                            dapTheme.palette.secondary.dark,
                          ],
                        }
                    }
                    chartEvents={[
                      {
                        callback: ({ chartWrapper, google }) => {
                          const chart: any = chartWrapper.getChart();
                          chart.container.addEventListener('click', (ev) =>
                            console.log(ev)
                          );
                          chart.container.addEventListener('mouseover', (ev) =>
                            console.log(ev)
                          );
                        },
                        eventName: 'ready',
                      },
                    ]}
                    // chartEvents={
                    //   [
                    //     {
                    //       callback: ({ chartWrapper, google }) => {
                    //         const chart = chartWrapper.getChart();
                    //         console.log(chart);
                    //         // chart.container.addEventListener('click', ev => console.log(ev));
                    //       },
                    //       eventName: 'ready',
                    //     },
                    //   ];
                    // }
                    rootProps={{ 'data-testid': '1' }}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 15 }}>
                  <Table size="small">
                    <TableBody>
                      {dataset.quality.measures.map((row: Measure) => (
                        <TableRow key={row.name}>
                          <TableCell align="left" sx={{ fontSize: '14px' }}>{row.name}</TableCell>
                          <TableCell align="right" sx={{ fontSize: '14px' }}>{getValue(row)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 15 }}>
                  <Typography display="block">
                    <Link
                      target="_blank"
                      to={dataset.quality.detailsUrl}
                      style={{ float: 'left' }}
                    >
                      Additional Information
                    </Link>
                    {/* <Link to={'/faq#quality'} style={{ float: 'right' }}>
                      Quality Metrics FAQs
                    </Link> */}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      }
    }
    return qualityPanel;
  }
}

export default function QualityMetricsPanel(props) {
  const dapTheme = useTheme();
  return <QualityMetricsPanelComp {...props} dapTheme={dapTheme} />
};
