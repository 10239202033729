import { observable, runInAction, transaction, makeObservable } from 'mobx';
import nth from 'lodash/fp/nth';
import compose from 'lodash/fp/compose';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import minBy from 'lodash/fp/minBy';
import maxBy from 'lodash/fp/maxBy';
import moment, { Moment } from 'moment';
import LambdaApiAgent from '@extensions/utils/LambdaApiAgent';
import {
  INotificationService,
  Status,
} from '@extensions/services/INotificationService';
import config from '@extensions/utils/ConfigUtil';
import Dataset from '@extensions/models/Dataset';
import {
  generateZip,
  generateWindowsScript,
  generateLinuxScript,
} from '@extensions/services/DatasetService';
import { ICachingService } from '@extensions/services/ICachingService';

const NOTIFICATION_ID = 'FILE_ORDER_GROUP';
const API_URL = config.getConfig().lambdaApi;

export enum DownloadOption {
  ZIP = 'zip',
  LINKS = 'file links',
  SCRIPT = 'script',
}

export enum OrderStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  READY = 'ready',
}

export const DOWNLOAD_LIMITS: Record<
  DownloadOption,
  { fileCount: number | null; totalSize: number | null }
> = {
  [DownloadOption.ZIP]: {
    fileCount: 1250,
    totalSize: 3_000_000_000,
  },
  [DownloadOption.LINKS]: {
    fileCount: 500,
    totalSize: null,
  },
  [DownloadOption.SCRIPT]: {
    fileCount: null,
    totalSize: null,
  },
};

export default class FileOrder {
  get fileTypes(): string[] {
    if (this.files !== null) {
      return this.files.map(fileName => this.parseFileName(fileName).fileType);
    }
    if (this.query !== null) {
      if (Array.isArray(this.query.file_type)) {
        return this.query.file_type;
      } else {
        return [this.query.file_type];
      }
    }
    return [];
  }

  get dateRange(): {
    startDate: Moment | undefined;
    endDate: Moment | undefined;
  } {
    if (this.files !== null) {
      const dateTimes = compose(
        filter(dateTime => dateTime !== null),
        map(name => this.parseFileName(name as string).dateTime)
      )(this.files) as moment.Moment[];
      const toValue = (dateTime: Moment) => dateTime.valueOf();
      return {
        startDate: minBy(toValue)(dateTimes),
        endDate: maxBy(toValue)(dateTimes),
      };
    }
    if (
      this.query !== null &&
      this.query.date_time &&
      this.query.date_time.between
    ) {
      const [rawStart, rawEnd] = this.query.date_time.between;
      const format = ['YYYYMMDDHHmmss', 'YYYYMMDD'];
      return {
        startDate: moment(rawStart, format),
        endDate: moment(rawEnd, format),
      };
    }
    return {
      startDate: undefined,
      endDate: undefined,
    };
  }
  static maxSizeWithoutApproval: number = 10_737_418_240;
  @observable
  downloadUrls: DownloadURL[] | null = null;
  @observable
  dataset: Dataset | null = null;
  @observable
  /**
   * This order contains proprietary data, and the user is not signed in with
   * 2fa.
   */
  insufficientAuth: boolean | null = null;
  fileCount: number;
  totalSize: number;
  created: number;
  id: string;
  groupId: string;
  status: OrderStatus;
  expired: boolean;
  isMfaRestricted: boolean | null = null;
  files: string[] | null;
  query: any;
  datasetName: string;
  private notificationService: INotificationService;
  private cachingService: ICachingService;

  constructor(
    data,
    notificationService: INotificationService,
    cachingService: ICachingService
  ) {
    makeObservable(this);
    this.cachingService = cachingService;
    this.notificationService = notificationService;
    this.datasetName = data.dataset;
    this.groupId = data.group_id;
    this.status = data.status;
    this.expired = data.expired;
    this.fileCount = data.count;
    this.totalSize = data.bytes;
    this.created = data.created;
    this.isMfaRestricted = null;
    this.id = data.id;
    this.files = data.files;
    this.query =
      data.query !== null && data.query !== undefined && JSON.parse(data.query);
  }

  // Would be private, except that it's needed by FileOrderGroup when
  // creating a ZIP
  fetchDownloadUrls = (): Promise<DownloadURL[]> => {
    return this.fetchDownloadUrlsRec({ cursor: null, pageSize: null });
  };

  loadDownloadUrls = (): void => {
    if (this.downloadUrls !== null) {
      return;
    }
    if (canDownloadUsingOption(DownloadOption.LINKS, this)) {
      this.fetchDownloadUrls()
        .then(urls => {
          runInAction(() => (this.downloadUrls = urls));
        })
        .catch(error => {
          this.notificationService.addNotification(
            NOTIFICATION_ID,
            Status.Error,
            'Failed to retrieve download URLs',
            error
          );
        });
    } else {
      throw new Error('Order too large to use download links');
    }
  };

  async loadDataset(): Promise<void> {
    if (this.dataset !== null) {
      return;
    }
    await this.cachingService
      .getDataset(this.datasetName)
      .then(dataset =>
        runInAction(() => {
          this.dataset = dataset;
          this.insufficientAuth = false;
        })
      )
      .catch(error => {
        if (error.status === 403) {
          runInAction(() => (this.insufficientAuth = true));
        } else {
          this.notificationService.addNotification(
            NOTIFICATION_ID,
            Status.Error,
            'Failed to load dataset',
            error
          );
        }
      });
  }

  async downloadScriptFiles(downloadType: string) {
    const orderResponse = await LambdaApiAgent.agent.get(
      `${API_URL}/orders/${this.id}`
    );
    const orderFiles = JSON.parse(orderResponse.text);
    const urlsResponse = await LambdaApiAgent.agent.get(
      `${API_URL}/orders/${this.id}/urls`
    );
    const orderUrls = JSON.parse(urlsResponse.text);
    this.downloadFiles(downloadType, orderUrls, orderFiles);
  }

  // script download function for orders page download button
  async downloadFiles(downloadType: string, orderUrls, orderFiles) {
    this.notificationService.addNotification(
      'downloadScript',
      Status.Running,
      'Generating script ...'
    );
    await this.loadDataset();
    try {
      // Wrap in a mobx transaction to not update observers until the whole block is complete
      transaction(async () => {
        let func = generateZip;
        if (downloadType === 'windowsScript') {
          func = generateWindowsScript;
        } else if (downloadType === 'linuxScript') {
          func = generateLinuxScript;
        }
        await func(this.dataset?.name, orderUrls, orderFiles)
        this.notificationService.addNotification(
          'downloadScript',
          Status.Success,
          'Script generated successfully'
        );
      });
    } catch (error) {
      this.notificationService.addNotification(
        'downloadScript',
        Status.Error,
        'Failed to generate script',
        error
      );
    }
  }

  private parseFileName = (
    rawName: string
  ): {
    dateTime: Moment | null;
    fileType: string;
  } => {
    const nameParts = rawName.split(`${this.datasetName}.`);
    if (nameParts.length === 2) {
      const attributes = nameParts[1].split('.');
      if (attributes.length < 1) {
        throw new Error(`Bad filename: ${rawName}`);
      }
      const fileType = nth(-1)(attributes) as string;

      if (/^\d{8}$/.test(attributes[0]) && attributes.length >= 3) {
        return {
          dateTime: moment(
            `${attributes[0]}${attributes[1]}`,
            'YYYYMMDDHHmmss'
          ),
          fileType,
        };
      }

      return {
        dateTime: null,
        fileType,
      };
    }
    throw new Error(`Bad filename: ${rawName}`);
  };

  private fetchDownloadUrlsRec = async ({
    cursor,
    pageSize,
  }: {
    cursor: string | null;
    pageSize: number | null;
  }): Promise<DownloadURL[]> => {
    const urlsEndpoint = `${API_URL}/orders/${this.id}/urls`;
    const params: string[] = [];
    if (pageSize) {
      params.push(`page_size=${pageSize}`);
    }
    if (cursor) {
      params.push(`cursor=${cursor}`);
    }
    const response = await LambdaApiAgent.agent.get(
      `${urlsEndpoint}?${params.join('&')}`
    );
    const body = response.body;
    const urls = body.files.map(
      ({ name, size }, index): DownloadURL => ({
        name,
        size,
        url: body.urls[index],
      })
    );
    if (body.cursor) {
      const restOfUrls = await this.fetchDownloadUrlsRec({
        cursor: body.cursor,
        pageSize,
      });
      urls.push(...restOfUrls);
    }
    return urls;
  };
}

// use interface so that both FileOrder and FileOrderGroup can be used
interface OrderInfo {
  fileCount: number;
  totalSize: number;
  status: OrderStatus;
  expired: boolean;
}

export interface DownloadURL {
  url: string;
  name: string;
  size: number; // bytes
}
export interface OrderURL {
  url: string;
}

export function reasonCantUseOption(
  option: DownloadOption,
  order: OrderInfo
): string | null {
  // Commenting this out since order.expired appears to be incorrect in many
  // cases
  // if (order.expired) {
  //   return 'Order has expired';
  // }
  if (order.status === OrderStatus.FAILED) {
    return 'Order failed';
  }
  if (order.status === OrderStatus.PENDING) {
    return 'Order pending';
  }
  const limits = DOWNLOAD_LIMITS[option];
  if (limits.fileCount !== null && order.fileCount > limits.fileCount) {
    return `File count exceeds ${limits.fileCount} for ${option}`;
  }
  if (limits.totalSize !== null && order.totalSize > limits.totalSize) {
    return `Order size exceeds ${limits.totalSize/1000000000}GB for ${option}`;
  }
  return null;
}

export function canDownloadUsingOption(
  option: DownloadOption,
  order: OrderInfo
): boolean {
  return !reasonCantUseOption(option, order);
}
